import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import { ButtonElement } from "../../../../components/input";
import AddCustomFieldType from "./CustomFieldType/AddCustomFieldType";
const { Option } = Select;

const UpdateCustomFields = ({
  isUpdateCustomFieldsModalVisible,
  setIsUpdateCustomFieldsModalVisible,
}) => {
  const [
    isAddCustomFieldTypeModalVisible,
    setIsAddCustomFieldTypeModalVisible,
  ] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState({
    fieldUsedFor: "OFFER",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateCustomFieldsSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateCustomFields_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateCustomFieldsModalVisible(false);
  };
  return (
    <Drawer
      title="Update Custom Fields"
      open={isUpdateCustomFieldsModalVisible}
      onClose={() => {
        setIsUpdateCustomFieldsModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateCustomFieldsSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        {/* <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div className="flex flex-row  items-center justify-between gap-4 w-full">
            <Form.Item
              label="Custom Field Type"
              name={"customFieldType"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a Custom Field Type!",
                },
              ]}
            >
              <Select placeholder="Custom Field Type">
                <Option>Student Orientation Date</Option>
                <Option>Total Holiday Weeks</Option>
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCustomFieldTypeModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Field Used For"
            name={"fieldUsedFor"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter field used for!",
              },
            ]}
          >
            <Select
              placeholder="Field used for"
              onChange={(e) => {
                setCustomFieldsData((prevData) => {
                  return {
                    ...prevData,
                    fieldUsedFor: e,
                  };
                });
              }}
            >
              <Option value={"STUDENT"}>Student</Option>
              <Option value={"STUDENT_COURSE"}>Student Course</Option>
              <Option value={"OFFER"}>Offer</Option>
              <Option value={"COURSE_SETUP"}>Course Setup</Option>
              <Option value={"SUBJECT_SETUP"}>Subject Setup</Option>
              <Option value={"UNIT_SETUP"}>Unit Setup</Option>
              <Option value={"ALUMNI_SETUP"}>Alumni Setup</Option>
              <Option value={"SERVICE_PROVIDER"}>Service Provider</Option>
              <Option value={"SERVICE_REQUEST"}>Service Request</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Data Type"
            name={"dataType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a data type!",
              },
            ]}
          >
            <Select placeholder="Data Type" defaultValue={"STRING"}>
              <Option value="INTEGER">Integer</Option>
              <Option value="DOUBLE">Double</Option>
              <Option value="STRING">String</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Input Control Type"
            name={"inputControlType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a input control type",
              },
            ]}
          >
            <Select placeholder="Input Control Type">
              <Option value={"TEXTBOX"}>TextBox</Option>
              <Option value="TEXTAREA">TextArea</Option>
              <Option value="DROPDOWN">Dropdown</Option>
              <Option value="RADIOBUTTON">Radiobutton</Option>
            </Select>
          </Form.Item>
        </div> */}
        <Form.Item label="Display value" name={"displayValue"}>
          <Input placeholder="Display Value" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Other Settings"
            name={"otherSettings"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox.Group>
              <Checkbox value={"MANDETORY"}>Mandatory</Checkbox>
              {(customFieldsData.fieldUsedFor === "STUDENT" ||
                customFieldsData.fieldUsedFor === "OFFER" ||
                customFieldsData.fieldUsedFor === "STUDENT_COURSE" ||
                customFieldsData.fieldUsedFor === "SERVICE_REQUEST" ||
                customFieldsData.fieldUsedFor === "SERVICE_PROVIDER") && (
                <>
                  <Checkbox value={"SHOW_IN_PUBLIC_APPLICATION"}>
                    Show In Public Application
                  </Checkbox>
                  <Checkbox value={"SHOW_IN_AGENT_APPLICATION"}>
                    Show In Agent Application
                  </Checkbox>
                  <Checkbox value={"SHOW_IN_PUBLIC_SHORT_APPLICATION"}>
                    Show In Public Short Application
                  </Checkbox>
                </>
              )}
            </Checkbox.Group>
          </Form.Item>
          {(customFieldsData.fieldUsedFor === "OFFER" ||
            customFieldsData.fieldUsedFor === "STUDENT_COURSE" ||
            customFieldsData.fieldUsedFor === "STUDENT") && (
            <Form.Item
              label="Student Origin"
              name={"studentOrigin"}
              style={{ width: "100%" }}
            >
              <Select placeholder="Student Origin">
                <Option value="OVERSEAS_STUDENT">Overseas Student</Option>
                <Option value="OVERSEAS_STUDENT_IN_AUSTRALIA">
                  Overseas Student in Australia
                </Option>
                <Option value="RESIDENT_STUDENT">Resident Student</Option>
              </Select>
            </Form.Item>
          )}
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Custom Fields"
          />
        </Form.Item>
      </Form>
      {isAddCustomFieldTypeModalVisible && (
        <AddCustomFieldType
          isAddCustomFieldTypeModalVisible={isAddCustomFieldTypeModalVisible}
          setIsAddCustomFieldTypeModalVisible={
            setIsAddCustomFieldTypeModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default UpdateCustomFields;
