import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdateDocumentChecklist = ({
  isUpdateDocumentChecklistModalVisible,
  setIsUpdateDocumentChecklistModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateDocumentChecklistSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_CERTIFICATE_REGISTER_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateDocumentChecklistModalVisible(false);
  };
  return (
    <Modal
      title="Update Document Checklist"
      open={isUpdateDocumentChecklistModalVisible}
      onCancel={() => {
        setIsUpdateDocumentChecklistModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={UpdateDocumentChecklistSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Document File"
          name={"documentFile"}
          style={{ width: "100%" }}
        >
          <Input type="file" />
        </Form.Item>
        <Form.Item
          label="Comment"
          name={"certificateFile"}
          style={{ width: "100%" }}
        >
          <TextArea />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Document Checklist"
          >
            Update Document Checklist
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDocumentChecklist;
