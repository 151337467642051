import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  activeNav: null,
};

const SiderDemoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_ACTIVE_NAV_REQUEST": {
      return {
        ...state,
        activeNav: action.payload?.activeNav,
      };
    }
    case "FETCH__REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH__REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        institutes: action.payload,
        isLoading: false,
      };
    }
    case "FETCH__REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD__REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD__REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! SiderDemo added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD__REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add SiderDemo.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE__REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE__REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! SiderDemo updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE__REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update SiderDemo.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE__REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE__REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! SiderDemo deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE__REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete SiderDemo.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE__STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE__STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! SiderDemo status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE__STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change SiderDemo status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default SiderDemoReducer;
