import { Button, Card, Divider } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const SubscriptionPlan = ({ profileRecord }) => {
  console.log("subscription plan record", profileRecord);
  const navigate = useNavigate();

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" className="my-2" />
      </div>
    );
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: "16px", fontWeight: "500" }}>
            CURRENT PLAN
          </div>
          <h4>{profileRecord?.profile?.subscriptionName}</h4>
        </div>

        <Button
          type="primary"
          danger
          style={{ borderRadius: "8px" }}
          onClick={() => {
            navigate("/subscription");
          }}
        >
          Upgrade Plan
        </Button>
      </div>
      <div className="border mt-4 p-3 rounded-[0.5rem]" bordered={false}>
        <span className="text-[1rem] font-semibold border-b border-b-[2px]">
          SUBSCRIPTION DETAILS:
        </span>
        <div
          className="mt-2"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {detailFun(
            `User Count`,
            profileRecord?.profile?.subscriptionDetails?.existingUserCount
          )}

          {detailFun(
            "Total User Count",
            profileRecord?.profile?.subscriptionDetails?.totalUserCount
          )}
          {detailFun(
            "Lead Forms",
            profileRecord?.profile?.subscriptionDetails?.existingLeadForms
          )}

          {detailFun(
            "Total Lead Forms",
            profileRecord?.profile?.subscriptionDetails?.totalLeadForms
          )}
          {detailFun(
            "Total Storage",
            profileRecord?.profile?.subscriptionDetails?.totalStorage
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
