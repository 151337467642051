import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
import AgentCommissionSummary from "./AgentCommissionSummary";
const { Option } = Select;
const { TextArea } = Input;

const ModifyAgentCommission = ({
  isModifyAgentCommissionModalVisible,
  setIsModifyAgentCommissionModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ModifyAgentCommissionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "Subject Enrollment StudentSubjectEnrollment_REQUEST",
      payload: formData,
    });
    // form.resetFields();
    setIsModifyAgentCommissionModalVisible(false);
  };
  return (
    <Drawer
      title="Modify Agent Commission"
      open={isModifyAgentCommissionModalVisible}
      onClose={() => {
        setIsModifyAgentCommissionModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
          }}
        >
          <Form
            layout="vertical"
            onFinish={ModifyAgentCommissionSubmitHandler}
            colon={true}
            form={form}
            style={{ width: "100%" }}
          >
            <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
              <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
                Commission Information
              </span>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Commission payable (AUD)"
                  name={"commissionPayable"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a payable commission!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Commission Payable" />
                </Form.Item>
                <Form.Item
                  label="Commission Paid (AUD)"
                  name={"commissionPaid"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select a payable commission!",
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Commission Paid" />
                </Form.Item>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Payment mode"
                  name={"paymentMode"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select payment mode!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Payment mode"
                    defaultValue={"BANK_DEPOSIT"}
                  >
                    <Option value="BANK_DEPOSIT">Bank Diposit</Option>
                    <Option value="CREDIT_CARD">Credit Card</Option>
                    <Option value="OTHER">Other</Option>
                    <Option value="NOT_PAID">Not Paid</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Invoice No"
                  name={"invoiceNo"}
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Invoice No" />
                </Form.Item>
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Payment date"
                  name={"paymentDate"}
                  style={{ width: "100%" }}
                >
                  <DatePicker format="DD-MM-YYYY" />
                </Form.Item>
                <Form.Item
                  label="Remarks"
                  name={"remarks"}
                  style={{ width: "100%" }}
                >
                  <TextArea placeholder={"Write something..."} />
                </Form.Item>
              </div>
            </div>
            <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
              <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
                Agent Bonus Info
              </span>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <div
                  className="flexRowWithoutStyle"
                  style={{
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    label="Bonus Amount"
                    name={"bonusAmount"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a bonus amount:!",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Bonus Amount:" />
                  </Form.Item>
                  <Form.Item
                    name={"isGst"}
                    style={{ width: "100%", alignSelf: "flex-end" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a commission percentage!",
                      },
                    ]}
                  >
                    <Radio.Group defaultValue={"NO_GST"}>
                      <Radio value={"GST"}>GST</Radio>
                      <Radio value={"NO_GST"}>No GST</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Form.Item
                  label="Bonus Paid Date"
                  name={"Bonus Paid Date"}
                  style={{ width: "100%" }}
                >
                  <DatePicker format={"DD-MM-YYYY"} />
                </Form.Item>
              </div>
              <Form.Item>
                <ButtonElement
                  block={true}
                  type="primary"
                  name="Update Bonus Info"
                />
              </Form.Item>
            </div>

            <Form.Item>
              <ButtonElement
                block={true}
                type="primary"
                htmlType="submit"
                name="Update"
              />
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <AgentCommissionSummary
            detail={{
              name: "Anish Banjara",
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ModifyAgentCommission;
