import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;

const AddBankDepositDate = ({
  isAddBankDepositDateModalVisible,
  setIsAddBankDepositDateModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddBankDepositDateSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddBankDepositDate_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddBankDepositDateModalVisible(false);
  };
  return (
    <Modal
      title="Add Bank Deposit"
      open={isAddBankDepositDateModalVisible}
      onCancel={() => {
        setIsAddBankDepositDateModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddBankDepositDateSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Bank Deposit Date"
            name={"bankDepositDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a bank deposit date!",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Deposit Type"
            name={"depositType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a deposit type !",
              },
            ]}
          >
            <Select placeholder="Deposit Type">
              <Option value="FEES">Fees</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Deposit Amount"
            name={"depositAmount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a deposit amount!",
              },
            ]}
          >
            <Input type="number" placeholder="Deposit Amount" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Bank Deposit"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddBankDepositDate;
