import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddCourseDeferral from "./AddCourseDeferral";
import UpdateCourseDeferral from "./UpdateCourseDeferral";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    width: 250,
    align: "center",
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 150,
    align: "center",
  },
  {
    title: "Course Attempt",
    dataIndex: "courseAttempt",
    width: 150,
    align: "center",
  },
  {
    title: "Request Date",
    dataIndex: "requestDate",
    width: 100,
    align: "center",
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    width: 100,
    align: "center",
  },
  {
    title: "Finish Date",
    dataIndex: "finishDate",
    width: 100,
    align: "center",
  },
  {
    title: "New Start Date",
    dataIndex: "newStartDate",
    width: 100,
    align: "center",
  },
  {
    title: "Approved",
    dataIndex: "approved",
    width: 100,
    align: "center",
  },
  {
    title: "Approval Date",
    dataIndex: "approvalDate",
    width: 100,
    align: "center",
  },
  {
    title: "Approval By",
    dataIndex: "approvalBy",
    width: 100,
    align: "center",
  },
  {
    title: "DHA Notified Date",
    dataIndex: "dhaNotifiedDate",
    width: 100,
    align: "center",
  },
  {
    title: "Reason",
    dataIndex: "reason",
    width: 100,
    align: "center",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const selectCourse = [
  {
    label: "All Courses",
    value: "ALL",
  },
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const courseDeferralData = [
  {
    id: 1,
    type: "Approved Leave – AL",
    courseName: "IELTS:IELTS Preparation",
    courseAttempt: "1",
    requestDate: "17 Aug 2023",
    startDate: "17 Aug 2023",
    finishDate: "17 Sep 2023",
    newStartDate: "18 Sep 2023",
    approved: "YES",
    approvalDate: "18 Sep 2023",
    approvalBy: "Ms. Anu Rani Regmi (Anu)",
    dhaNotifiedDate: "18 Sep 2023",
    reason: "Change in personal circumstance",
    comments: "Change in personal circumstance",
  },
];

const CourseDeferral = () => {
  const [isAddCourseDeferralModalVisible, setIsAddCourseDeferralModalVisible] =
    useState(false);
  const [
    isUpdateCourseDeferralModalVisible,
    setIsUpdateCourseDeferralModalVisible,
  ] = useState(false);

  const data = courseDeferralData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      type: dataObj?.type,
      courseName: dataObj?.courseName,
      courseAttempt: dataObj?.courseAttempt,
      requestDate: dataObj?.requestDate,
      startDate: dataObj?.startDate,
      finishDate: dataObj?.finishDate,
      newStartDate: dataObj?.newStartDate,
      approved: dataObj?.approved,
      approvalDate: dataObj?.approvalDate,
      approvalBy: dataObj?.approvalBy,
      dhaNotifiedDate: dataObj?.dhaNotifiedDate,
      reason: dataObj?.reason,
      comments: dataObj?.comments,
      actions: (
        <Space size="middle">
          <Tooltip title="Edit Selected Deferral record">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateCourseDeferralModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Defer Report">
            <a>
              <div className="bordered" onClick={() => {}}>
                <TbReport />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete this deferral record">
            <Popconfirm
              title="Are you sure to delete this deferral record?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Course deferral, holiday or suspension</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddCourseDeferralModalVisible(true);
              }}
            >
              Defer Course
            </AddButton>
          </div>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddCourseDeferralModalVisible && (
        <AddCourseDeferral
          isAddCourseDeferralModalVisible={isAddCourseDeferralModalVisible}
          setIsAddCourseDeferralModalVisible={
            setIsAddCourseDeferralModalVisible
          }
        />
      )}
      {isUpdateCourseDeferralModalVisible && (
        <UpdateCourseDeferral
          isUpdateCourseDeferralModalVisible={
            isUpdateCourseDeferralModalVisible
          }
          setIsUpdateCourseDeferralModalVisible={
            setIsUpdateCourseDeferralModalVisible
          }
        />
      )}
    </Card>
  );
};

export default CourseDeferral;
