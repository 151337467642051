import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;

const AddInterventionType = ({
  isAddInterventionTypeModalVisible,
  setIsAddInterventionTypeModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddInterventionTypeSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddInterventionType_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddInterventionTypeModalVisible(false);
  };
  return (
    <Modal
      title="Add Intervention Type"
      open={isAddInterventionTypeModalVisible}
      onCancel={() => {
        setIsAddInterventionTypeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddInterventionTypeSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Intervention Type"
          name={"interventionType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter intervention type!",
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Intervention Type"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddInterventionType;
