import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import React from "react";
import { ButtonElement } from "../../../components/input";
import { useDispatch } from "react-redux";
import AddSemesterDivision from "./AddSemesterDivision";

export default function ManageSemesterDivision() {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Semester",
      dataIndex: "semester",
      align: "center",
    },
    {
      title: "Term",
      dataIndex: "Term",
      align: "center",
    },
    {
      title: "Week",
      dataIndex: "week",
      align: "center",
    },
    {
      title: "Week Start",
      dataIndex: "week_start",
      align: "center",
    },
    {
      title: "Week Finish",
      dataIndex: "week_finish",
      align: "center",
    },
    {
      title: "No of Hours/Week",
      dataIndex: "no_hours",
      align: "center",
    },
    {
      title: "Hoilday Week",
      dataIndex: "hoilday_week",
      align: "center",
    },
    {
      title: "Update Hoilday Week",
      align: "center",
      fixed: "center",
      render: () => (
        <Tooltip placement="top" title="Delete Record">
          <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
        </Tooltip>
      ),
    },
  ];

  const data = [
    {
      semester: 2023,
      term: 1,
      week: 1,
      week_start: "02 Jan 2023	",
      week_finish: "08 Jan 2023	",
      no_hours: 20,
      hoilday_week: "No",
    },
    {
      semester: 2023,
      term: 1,
      week: 1,
      week_start: "02 Jan 2023	",
      week_finish: "08 Jan 2023	",
      no_hours: 20,
      hoilday_week: "No",
    },
    {
      semester: 2023,
      term: 1,
      week: 1,
      week_start: "02 Jan 2023	",
      week_finish: "08 Jan 2023	",
      no_hours: 20,
      hoilday_week: "No",
    },
    {
      semester: 2023,
      term: 1,
      week: 1,
      week_start: "02 Jan 2023	",
      week_finish: "08 Jan 2023	",
      no_hours: 20,
      hoilday_week: "No",
    },
  ];

  const handleAddSemesterDivision = (value) => {
    dispatch({ type: "FETCH_OPEN_MODAL", payload: value });
  };

  return (
    <div className="flex flex-col gap-4">
      <ButtonElement
        handleClick={() => handleAddSemesterDivision("addSemesterDivision")}
        Icon={<PlusOutlined className="text-sm" />}
        name="Semester Division"
      />
      <Table dataSource={data} columns={columns} />
      <AddSemesterDivision />
    </div>
  );
}
