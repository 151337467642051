import React from "react";
import { Button, Checkbox, Drawer, Form, Input, Select, Steps } from "antd";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;

const StaffPersonalInfo = ({
  staffData,
  setStaffData,
  current,
  setCurrent,
}) => {
  const [form] = Form.useForm();

  const addStaffPersonalInfoHandler = () => {
    setCurrent(1);
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addStaffPersonalInfoHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["photo"],
            value: staffData?.photo,
          },
          {
            name: ["title"],
            value: staffData?.title,
          },
          {
            name: ["firstName"],
            value: staffData?.firstName,
          },
          {
            name: ["lastName"],
            value: staffData?.lastName,
          },
          {
            name: ["country"],
            value: staffData?.countryName,
          },
          {
            name: ["address"],
            value: staffData?.address,
          },
          {
            name: ["suburb"],
            value: staffData?.suburb,
          },
          {
            name: ["state"],
            value: staffData?.state,
          },
          {
            name: ["postCode"],
            value: staffData?.postCode,
          },
          {
            name: ["phone"],
            value: staffData?.phone,
          },
          {
            name: ["mobile"],
            value: staffData?.mobile,
          },
          {
            name: ["email"],
            value: staffData?.email,
          },
          {
            name: ["collegeEmail"],
            value: staffData?.collegeEmail,
          },
          {
            name: ["signatoryText"],
            value: staffData?.signatoryText,
          },
          {
            name: ["emergencyContactName"],
            value: staffData?.emergencyContactName,
          },
          {
            name: ["emergencyContactNumber"],
            value: staffData?.emergencyContactNumber,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Photo" name={"photo"} style={{ width: "100%" }}>
            <Input
              type="file"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    photo: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Title" name={"title"} style={{ width: "100%" }}>
            <Select
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    title: e,
                  };
                });
              }}
            >
              <Option value="Mr">Mr</Option>
              <Option value="Miss">Miss</Option>
              <Option value="Mrs">Mrs</Option>
              <Option value="Ms">Ms</Option>
              <Option value="Dr">Dr</Option>
              <Option value="Rev">Rev</Option>
              <Option value="Hon">Hon</Option>
              <Option value="">Not Specified</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              placeholder="First Name"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    firstName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              placeholder="Last Name"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    lastName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <Form.Item label="Country" name={"country"}>
          <Input
            placeholder="Country"
            onChange={(e) => {
              setStaffData((prevData) => {
                return {
                  ...prevData,
                  countryName: e.target.value,
                };
              });
            }}
          />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Address"
            name={"address"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter address!",
              },
            ]}
          >
            <Input
              placeholder="Address"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    address: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="City/Town/Suburb"
            name={"suburb"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter suburb, city or town!",
              },
            ]}
          >
            <Input
              placeholder="City/Town/Suburb"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    suburb: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {staffData?.countryName?.toLowerCase() === "australia" ? (
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              <Select
                onChange={(e) => {
                  setStaffData((prevData) => {
                    return {
                      ...prevData,
                      state: e,
                    };
                  });
                }}
              >
                <Option value="NSW|New South Wales">NSW|New South Wales</Option>
                <Option value="QLD|Queensland">QLD|Queensland</Option>
                <Option value="SA|South Australia">SA|South Australia</Option>
                <Option value="TAS|Tasmania">TAS|Tasmania</Option>
                <Option value="VIC|Victoria">VIC|Victoria</Option>
                <Option value="WA|Western Australia">
                  WA|Western Australia
                </Option>
                <Option value="ACT|Australian Capital Territory">
                  ACT|Australian Capital Territory
                </Option>
                <Option value="NT|Northern Territory">
                  NT|Northern Territory
                </Option>
              </Select>
            </Form.Item>
          ) : (
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              <Input
                placeholder="State"
                onChange={(e) => {
                  setStaffData((prevData) => {
                    return {
                      ...prevData,
                      state: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Post Code"
            name={"postCode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Post Code!",
              },
            ]}
          >
            <Input
              placeholder="PostCode"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    postCode: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Phone"
            name={"phone"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Phone number"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    phone: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Mobile"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    mobile: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Address"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="College Email"
            name={"collegeEmail"}
            style={{ width: "100%" }}
            rules={[
              {
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="College Email"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    collegeEmail: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Signatory Text"
          name={"signatoryText"}
          style={{ width: "100%" }}
        >
          <Input
            placeholder="Signatory Text"
            onChange={(e) => {
              setStaffData((prevData) => {
                return {
                  ...prevData,
                  signatoryText: e.target.value,
                };
              });
            }}
          />
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Emergency Contact Name"
            name={"emergencyContactName"}
            style={{ width: "100%" }}
          >
            <Input
              placeholder="Emergency Contact Name"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    emergencyContactName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Emergency Contact Number"
            name={"emergencyContactNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Contact number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Emergency Contact Number"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    emergencyContactNumber: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Form.Item>
            <ButtonElement type="primary" htmlType="submit" name="Next">
              Next
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default StaffPersonalInfo;
