import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const GenerateStudentCourseInvoice = ({
  isGenerateStudentCourseInvoiceModalVisible,
  setIsGenerateStudentCourseInvoiceModalVisible,
}) => {
  const [generateInvoiceData, setGenerateInvoiceData] = useState({
    isGenerateTutionFeeInvoice: false,
    invoiceStartDate: null,
    totalAmount: null,
    noOfInstallment: null,
    installmentFrequency: "WEEK",
    installmentFrequencyDuration: null,
    isGenerateEnrolmentFeeInvoice: false,
    enrolmentFee: null,
    isGenerateMaterialFeeInvoice: false,
    materialFee: null,
    isGenerateOhscFeeInvoice: false,
    provider: null,
    type: null,
    duration: null,
    startDate: null,
    amount: null,
    isGenerateOtherInvoice: false,
    feeType: "ENROLMENT_FEES",
    dueAmount: null,
    invoiceNo: null,
    paymentDueDate: null,
    remark: null,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const GenerateStudentCourseInvoiceSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "GenerateStudentCourseInvoice_REQUEST",
      payload: formData,
    });
    // form.resetFields();
    setIsGenerateStudentCourseInvoiceModalVisible(false);
  };
  return (
    <Drawer
      title="Generate Invoice"
      open={isGenerateStudentCourseInvoiceModalVisible}
      onClose={() => {
        setIsGenerateStudentCourseInvoiceModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={GenerateStudentCourseInvoiceSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["isGenerateTutionFeeInvoice"],
            value: generateInvoiceData?.isGenerateTutionFeeInvoice,
          },
          {
            name: ["invoiceStartDate"],
            value: generateInvoiceData?.invoiceStartDate,
          },
          { name: ["totalAmount"], value: generateInvoiceData?.totalAmount },
          {
            name: ["noOfInstallment"],
            value: generateInvoiceData?.noOfInstallment,
          },
          {
            name: ["installmentFrequency"],
            value: generateInvoiceData?.installmentFrequency,
          },
          {
            name: ["installmentFrequencyDuration"],
            value: generateInvoiceData?.installmentFrequencyDuration,
          },
          {
            name: ["isGenerateEnrolmentFeeInvoice"],
            value: generateInvoiceData?.isGenerateEnrolmentFeeInvoice,
          },
          { name: ["enrolmentFee"], value: generateInvoiceData?.enrolmentFee },
          {
            name: ["isGenerateMaterialFeeInvoice"],
            value: generateInvoiceData?.isGenerateMaterialFeeInvoice,
          },
          { name: ["materialFee"], value: generateInvoiceData?.materialFee },
          {
            name: ["isGenerateOhscFeeInvoice"],
            value: generateInvoiceData?.isGenerateOhscFeeInvoice,
          },
          { name: ["provider"], value: generateInvoiceData?.provider },
          { name: ["type"], value: generateInvoiceData?.type },
          { name: ["duration"], value: generateInvoiceData?.duration },
          { name: ["startDate"], value: generateInvoiceData?.startDate },
          { name: ["amount"], value: generateInvoiceData?.amount },
          {
            name: ["isGenerateOtherInvoice"],
            value: generateInvoiceData?.isGenerateOtherInvoice,
          },
          { name: ["feeType"], value: generateInvoiceData?.feeType },
          { name: ["dueAmount"], value: generateInvoiceData?.dueAmount },
          { name: ["invoiceNo"], value: generateInvoiceData?.invoiceNo },
          {
            name: ["paymentDueDate"],
            value: generateInvoiceData?.paymentDueDate,
          },
          { name: ["remark"], value: generateInvoiceData?.remark },
        ]}
        className="drawerStyle"
      >
        <Form.Item
          name={"isGenerateTutionFeeInvoice"}
          valuePropName="checked"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <Checkbox
            onChange={(e) => {
              setGenerateInvoiceData((prevData) => {
                return {
                  ...prevData,
                  isGenerateTutionFeeInvoice: e.target.checked,
                };
              });
            }}
          >
            Generate tuition fee invoice
          </Checkbox>
        </Form.Item>

        {generateInvoiceData?.isGenerateTutionFeeInvoice && (
          <>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Invoice start date"
                name={"invoiceStartDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter invoice start date!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Invoice Start Date"
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        invoiceStartDate: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Total Amount"
                name={"totalAmount"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter total amount!",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Total Amount"
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        totalAmount: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Form.Item
                label="No. Of Installment"
                name={"noOfInstallment"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter number of installments!",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="No. of Installment"
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        noOfInstallment: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <Form.Item
                  label="Installment Frequency"
                  name={"installmentFrequency"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter installment frequency!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Installment Frequency"
                    onChange={(e) => {
                      setGenerateInvoiceData((prevData) => {
                        return {
                          ...prevData,
                          installmentFrequency: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name={"installmentFrequencyDuration"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter installment frequency!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Installment Frequency Duration"
                    onChange={(e) => {
                      setGenerateInvoiceData((prevData) => {
                        return {
                          ...prevData,
                          installmentFrequencyDuration: e,
                        };
                      });
                    }}
                  >
                    <Option value="DAY">Day</Option>
                    <Option value="WEEK">Week</Option>
                    <Option value="MONTH">Month</Option>
                    <Option value="YEAR">Year</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          </>
        )}
        <Form.Item
          name={"isGenerateEnrolmentFeeInvoice"}
          valuePropName="checked"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <Checkbox
            onChange={(e) => {
              setGenerateInvoiceData((prevData) => {
                return {
                  ...prevData,
                  isGenerateEnrolmentFeeInvoice: e.target.checked,
                };
              });
            }}
          >
            Generate enrolment fee invoice
          </Checkbox>
        </Form.Item>

        {generateInvoiceData?.isGenerateEnrolmentFeeInvoice && (
          <Form.Item
            label="Enrolment Fee"
            name={"enrolmentFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a enrolment fee!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Enrolment Fee"
              onChange={(e) => {
                setGenerateInvoiceData((prevData) => {
                  return {
                    ...prevData,
                    enrolmentFee: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        )}

        <Form.Item
          name={"isGenerateMaterialFeeInvoice"}
          valuePropName="checked"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <Checkbox
            onChange={(e) => {
              setGenerateInvoiceData((prevData) => {
                return {
                  ...prevData,
                  isGenerateMaterialFeeInvoice: e.target.checked,
                };
              });
            }}
          >
            Generate material fee invoice
          </Checkbox>
        </Form.Item>
        {generateInvoiceData?.isGenerateMaterialFeeInvoice && (
          <Form.Item
            label="Material Fee"
            name={"materialFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a material fee!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Material Fee"
              onChange={(e) => {
                setGenerateInvoiceData((prevData) => {
                  return {
                    ...prevData,
                    materialFee: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          name={"isGenerateOhscFeeInvoice"}
          valuePropName="checked"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <Checkbox
            onChange={(e) => {
              setGenerateInvoiceData((prevData) => {
                return {
                  ...prevData,
                  isGenerateOhscFeeInvoice: e.target.checked,
                };
              });
            }}
          >
            Generate OSHC fee invoice
          </Checkbox>
        </Form.Item>
        {generateInvoiceData?.isGenerateOhscFeeInvoice && (
          <>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Provider:"
                name={"provider"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a provider !",
                  },
                ]}
              >
                <Input
                  placeholder="Select a Provider"
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        provider: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <Form.Item
                  label="Type"
                  name={"type"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a OSHC type!",
                    },
                  ]}
                >
                  <Select
                    placeholder="OSHC type"
                    onChange={(e) => {
                      setGenerateInvoiceData((prevData) => {
                        return {
                          ...prevData,
                          type: e,
                        };
                      });
                    }}
                  >
                    <Option value="Medicare Private">Medicare Private</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"duration"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a OSHC duration!",
                    },
                  ]}
                >
                  <Select
                    placeholder="OSHC duration"
                    onChange={(e) => {
                      setGenerateInvoiceData((prevData) => {
                        return {
                          ...prevData,
                          duration: e,
                        };
                      });
                    }}
                  >
                    <Option value="Medicare Private">
                      08-12-2023/08/12/2024
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Start Date:"
                name={"startDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick a start date !",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        startDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Amount"
                name={"amount"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a amount!",
                  },
                ]}
              >
                <Input
                  placeholder="Amount"
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        amount: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
          </>
        )}
        <Form.Item
          name={"isGenerateOtherInvoice"}
          valuePropName="checked"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <Checkbox
            onChange={(e) => {
              setGenerateInvoiceData((prevData) => {
                return {
                  ...prevData,
                  isGenerateOtherInvoice: e.target.checked,
                };
              });
            }}
          >
            Generate Other invoice
          </Checkbox>
        </Form.Item>
        {generateInvoiceData?.isGenerateOtherInvoice && (
          <>
            {" "}
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Fee Type"
                name={"feeType"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a fee type!",
                  },
                ]}
              >
                <Select
                  placeholder="OSHC duration"
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        feeType: e,
                      };
                    });
                  }}
                >
                  <Option value="ENROLMENT_FEES">Enrolment Fees</Option>
                  <Option value="Late_PAYMENT_FEES">Late Payment Fees</Option>
                  <Option value="PRINTING">Printing</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Due Amount"
                name={"dueAmount"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a due amount!",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Due amount"
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        dueAmount: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label={
                  <div className="fxRJsb">
                    <span>Invoice No</span>
                    <ButtonElement
                      buttonType="default"
                      size="small"
                      name="Generate Next Auto Invoice No"
                    />
                  </div>
                }
                name={"invoiceNo"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a invoice number!",
                  },
                ]}
              >
                <Input
                  placeholder="Invoice No"
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        invoiceNo: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Payment Due Date"
                name={"paymentDueDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick a payment due date!",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setGenerateInvoiceData((prevData) => {
                      return {
                        ...prevData,
                        paymentDueDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item label="Remark" name={"remark"} style={{ width: "100%" }}>
              <TextArea
                format={"DD-MM-YYYY"}
                onChange={(e) => {
                  setGenerateInvoiceData((prevData) => {
                    return {
                      ...prevData,
                      remark: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Generate Invoice"
          >
            Generate Invoice
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default GenerateStudentCourseInvoice;
