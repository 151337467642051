import { Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const DrawerElement2 = ({ children, title, keyType }) => {
  const dispatch = useDispatch();
  const { openDrawer2, drawerType2 } = useSelector(
    (store) => store.DrawerReducer
  );

  const handleCloseDrawer = () => {
    dispatch({ type: "FETCH_CLOSE_DRAWER2" });
  };

  return (
    <Drawer
      footer={null}
      title={title}
      placement="right"
      onClose={handleCloseDrawer}
      open={
        openDrawer2 && keyType?.toLowerCase() === drawerType2?.toLowerCase()
      }
    >
      {children}
    </Drawer>
  );
};

export default DrawerElement2;
