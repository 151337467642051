import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddProvider = ({
  isAddProviderModalVisible,
  setIsAddProviderModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddProviderSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_PROVIDER_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddProviderModalVisible(false);
  };
  return (
    <Modal
      title="Add Provider"
      open={isAddProviderModalVisible}
      onCancel={() => {
        setIsAddProviderModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddProviderSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Provider"
            name={"provider"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a provider!",
              },
            ]}
          >
            <Input placeholder="Provider" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Provider"
          >
            Add Provider
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProvider;
