import { Menu, Layout, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Children } from "react";
import { GoDashboard } from "react-icons/go";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SiderDemo } from "../input";
const { Header, Sider, Content } = Layout;

const OrganizationalSettings = ({ children }) => {
  const orgnizationalSettingsState = useSelector(
    (state) => state.OrganizationalSettingsReducer
  );

  const [record, setRecord] = useState({});

  const dispatch = useDispatch();

  return (
    <SiderDemo organizationalSettings={true}>
      <div className="flex h-[95vh] grid grid-cols-5 w-full">
        <div
          style={{
            backgroundColor: "white",
            border: "2px solid #f0f2f5",
          }}
        >
          <div style={{ padding: "1rem 1rem" }}>
            <h3 style={{ fontWeight: "500" }}>Organization Settings</h3>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

          <div
            className="orgSettingMenu flexColumnWithoutStyle"
            style={{
              overflowX: "hidden",
              overflowY: "scroll",
              height: "80vh",
            }}
          >
            <Link to={"/organizational-settings/general-settings"}>
              <div style={{ padding: "1.2rem" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  General
                </div>
                <div style={{ color: "#5B5B5B" }}>
                  Manage your general settings.
                </div>
              </div>
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizational-settings/course-settings"}>
              <div style={{ padding: "1.2rem" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Course
                </div>
                <div style={{ color: "#5B5B5B" }}>Manage your courses.</div>
              </div>
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizational-settings/offer-settings"}>
              <div style={{ padding: "1.2rem" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Offer
                </div>
                <div style={{ color: "#5B5B5B" }}>Manage your offers.</div>
              </div>
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizational-settings/student-settings"}>
              <div style={{ padding: "1.2rem" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Student
                </div>
                <div style={{ color: "#5B5B5B" }}>Manage your students.</div>
              </div>
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizational-settings/service-settings"}>
              <div style={{ padding: "1.2rem" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Service
                </div>
                <div style={{ color: "#5B5B5B" }}>Manage your services.</div>
              </div>
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizational-settings/employer-settings"}>
              <div style={{ padding: "1.2rem" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Employer
                </div>
                <div style={{ color: "#5B5B5B" }}>Manage your employers.</div>
              </div>
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizationalSettings/compliance-settings"}>
              <SettingMenu
                title="Compliance"
                description="Manage your compliance settings."
              />
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizationalSettings/marketing-settings"}>
              <SettingMenu
                title="Marketing"
                description="Manage your marketing settings."
              />
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizationalSettings/email-settings"}>
              <SettingMenu
                title="Email"
                description="Manage your email settings."
              />
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizationalSettings/report-settings"}>
              <SettingMenu
                title="Report"
                description="Manage your reports settings."
              />
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <Link to={"/organizationalSettings/calender-settings"}>
              <SettingMenu
                title="Calender"
                description="Manage your calender settings."
              />
            </Link>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
          </div>
        </div>
        <div className="col-span-4">{children}</div>
      </div>
    </SiderDemo>
  );
};

export default OrganizationalSettings;

export const SettingMenu = ({ title, description }) => {
  return (
    <div style={{ padding: "1.2rem" }}>
      <div
        style={{
          fontWeight: "500",
          fontSize: "16px",
          color: "black",
        }}
      >
        {title}
      </div>
      <div style={{ color: "#5B5B5B" }}>{description}</div>
    </div>
  );
};
