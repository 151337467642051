import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../components/input";
import AddApplicants from "./AddApplicants/AddApplicants";
import AddApplicantsForShortCourse from "./AddApplicantsForShortCourse/AddApplicantsForShortCourse";
const { Option } = Select;

const ChooseCourseApplication = ({
  isChooseCourseApplicationModalVisible,
  setIsChooseCourseApplicationModalVisible,
}) => {
  const [isAddApplicantsModalVisible, setIsAddApplicantsModalVisible] =
    useState(false);
  const [isApplyShortCourseModalVisible, setIsApplyShortCourseModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const chooseCourseApplicationSubmitHandler = (value) => {
    console.log("value", value);
    if (value?.selectCourseType === "SHORT_COURSE") {
      setIsApplyShortCourseModalVisible(true);
    } else {
      setIsAddApplicantsModalVisible(true);
    }
  };
  return (
    <Modal
      //   title="Start new online application"
      open={isChooseCourseApplicationModalVisible}
      onCancel={() => {
        setIsChooseCourseApplicationModalVisible(false);
      }}
      footer={null}
      closeIcon={false}
      width={"43vw"}
    >
      <Form
        layout="vertical"
        onFinish={chooseCourseApplicationSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item name={"selectCourseType"} style={{ width: "100%" }}>
            <Radio.Group
              defaultValue="LONG_COURSE"
              optionType="button"
              style={{ whiteSpace: "nowrap", borderRadius: "3px" }}
              size="large"
            >
              <Radio value={"LONG_COURSE"}>Student Online Application</Radio>
              <Radio value={"SHORT_COURSE"}>
                Student Short Course Online Application
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="fxRAc">
          <Form.Item>
            <ButtonElement
              type="primary"
              htmlType="submit"
              name="Start new online application"
            >
              Start new online application
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
      {isAddApplicantsModalVisible && (
        <AddApplicants
          isAddApplicantsModalVisible={isAddApplicantsModalVisible}
          setIsAddApplicantsModalVisible={setIsAddApplicantsModalVisible}
          setIsChooseCourseApplicationModalVisible={
            setIsChooseCourseApplicationModalVisible
          }
        />
      )}
      {isApplyShortCourseModalVisible && (
        <AddApplicantsForShortCourse
          isAddApplicantsForShortCourseModalVisible={
            isApplyShortCourseModalVisible
          }
          setIsAddApplicantsForShortCourseModalVisible={
            setIsApplyShortCourseModalVisible
          }
          setIsChooseCourseApplicationModalVisible={
            setIsChooseCourseApplicationModalVisible
          }
        />
      )}
    </Modal>
  );
};

export default ChooseCourseApplication;
