import React, { useState } from "react";
import SiderDemo from "../../components/Layout/SiderDemo";
import ComponentHeader from "../../components/ComponentHeader/ComponentHeader";
import BreadcrumbElement from "../../components/breadCrumb/BreadcrumbElement";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Tabs } from "antd";

const crumbList = [
  {
    title: (
      <NavLink style={{ color: "black" }} to="/student-payment">
        Accounts
      </NavLink>
    ),
  },
  {
    title: (
      <NavLink style={{ color: "black" }} to="/student-payment">
        Student Payment
      </NavLink>
    ),
  },
];
const tabs = [
  {
    key: "/student-payment",
    label: (
      <NavLink style={{ color: "black" }} to="/student-payment">
        Student Payment
      </NavLink>
    ),
  },
  {
    key: "/agent-payment",
    label: (
      <NavLink style={{ color: "black" }} to="/agent-payment">
        Agent Payment
      </NavLink>
    ),
  },
  {
    key: "/bulk-update-commission",
    label: (
      <NavLink style={{ color: "black" }} to="/bulk-update-commission">
        Bulk Update Commission
      </NavLink>
    ),
  },

  {
    key: "/bulk-agent-payment",
    label: (
      <NavLink style={{ color: "black" }} to="/bulk-agent-payment">
        Bulk Agent Payment
      </NavLink>
    ),
  },
  {
    key: "/bank-reconciliation",
    label: (
      <NavLink style={{ color: "black" }} to="/bank-reconciliation">
        Bank Reconciliation
      </NavLink>
    ),
  },
  {
    key: "/accounts-set-up",
    label: (
      <NavLink style={{ color: "black" }} to="/accounts-set-up">
        Accounts Set-Up
      </NavLink>
    ),
  },
  {
    key: "/accounts-generate-invoice",
    label: (
      <NavLink style={{ color: "black" }} to="/accounts-generate-invoice">
        Generate Invoice
      </NavLink>
    ),
  },
  {
    key: "/generate-employer-invoice",
    label: (
      <NavLink style={{ color: "black" }} to="/generate-employer-invoice">
        Employer Invoice
      </NavLink>
    ),
  },
  {
    key: "/provider-payment",
    label: (
      <NavLink style={{ color: "black" }} to="/provider-payment">
        Provider Payment
      </NavLink>
    ),
  },
  {
    key: "/bulk-sanction",
    label: (
      <NavLink style={{ color: "black" }} to="/bulk-sanction">
        Bulk Sanction
      </NavLink>
    ),
  },
];

const Accounts = ({ children, tab }) => {
  const navigate = useNavigate();
  crumbList.pop();
  crumbList.push({
    title: (
      <NavLink style={{ color: "black" }} to={tab}>
        {tab.slice(1)}
      </NavLink>
    ),
  });
  return (
    <SiderDemo>
      <ComponentHeader>
        <BreadcrumbElement crumbList={crumbList} />
        <Tabs
          defaultActiveKey="ViewStaffList"
          animated={{
            inkBar: false,
            tabPane: true,
          }}
          items={tabs}
          activeKey={tab}
          onChange={(e) => {
            navigate(e);
            console.log("crumblist", crumbList);
            console.log("crumblist tabs", tabs);
            console.log("crumblist e", e);
            crumbList.pop();
            crumbList.push({
              title: (
                <NavLink style={{ color: "black" }} to={e}>
                  {e.slice(1)}
                </NavLink>
              ),
            });
          }}
        />
      </ComponentHeader>
      {children}
    </SiderDemo>
  );
};

export default Accounts;
