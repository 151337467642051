import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../components/button/AddButton";
import { Pagename } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { FcExpired } from "react-icons/fc";
import { BiBlock } from "react-icons/bi";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: 250,
    align: "center",
  },
  {
    title: "Size",
    dataIndex: "size",
    width: 150,
    align: "center",
  },
  {
    title: "Extension",
    dataIndex: "extension",
    width: 100,
    align: "center",
  },
  {
    title: "Last Modified",
    dataIndex: "lastModified",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    name: "File1",
    size: "0 bytes",
    extension: "Dir",
    lastModified: "	10/08/2023 7:57:23 PM",
  },
];

const StaffDocuments = () => {
  const [isAddStaffFolderModalVisible, setIsAddStaffFolderModalVisible] =
    useState(false);
  const [isAddStaffFileModalVisible, setIsAddStaffFileModalVisible] =
    useState(false);
  const [isRenameStaffFolderModalVisible, setIsRenameStaffFolderModalVisible] =
    useState(false);
  const [isRenameStaffFileModalVisible, setIsRenameStaffFileModalVisible] =
    useState(false);
  const [
    isSetDocumentExpiryDateModalVisible,
    setIsSetDocumentExpiryDateModalVisible,
  ] = useState(false);

  const data = matrixData.map((dataObj) => {
    return {
      id: dataObj?.id,
      name: dataObj?.name,
      size: dataObj?.size,
      extension: dataObj?.extension,
      lastModified: dataObj?.lastModified,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Rename">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsRenameStaffFileModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Manage document expiry date">
            <a>
              <div
                onClick={() => {
                  setIsSetDocumentExpiryDateModalVisible(true);
                }}
              >
                <FcExpired />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this file/folder?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Staff Documents</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddStaffFileModalVisible(true);
              }}
            >
              Add File
            </AddButton>
            <AddButton
              handleClick={() => {
                setIsAddStaffFolderModalVisible(true);
              }}
            >
              Add Folder
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table columns={columns} dataSource={data} />
      </div>
      {/* {isAddStaffFolderModalVisible && (
        <AddFolder
          isAddStaffFolderModalVisible={isAddStaffFolderModalVisible}
          setIsAddStaffFolderModalVisible={setIsAddStaffFolderModalVisible}
        />
      )}
      {isAddStaffFileModalVisible && (
        <AddFile
          isAddStaffFileModalVisible={isAddStaffFileModalVisible}
          setIsAddStaffFileModalVisible={setIsAddStaffFileModalVisible}
        />
      )}
      {isUpdateStaffDocumentsModalVisible && (
        <RenameFile
          isUpdateStaffDocumentsModalVisible={
            isUpdateStaffDocumentsModalVisible
          }
          setIsUpdateStaffDocumentsModalVisible={
            setIsUpdateStaffDocumentsModalVisible
          }
        />
      )} */}
    </Card>
  );
};

export default StaffDocuments;
