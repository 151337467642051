import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const UpdateTeacherMatrix = ({
  isUpdateTeacherMatrixModalVisible,
  setIsUpdateTeacherMatrixModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const UpdateTeacherMatrixSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsUpdateTeacherMatrixModalVisible(false);
  };
  return (
    <Modal
      title="Update Teacher Matrix"
      open={isUpdateTeacherMatrixModalVisible}
      onCancel={() => {
        setIsUpdateTeacherMatrixModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={UpdateTeacherMatrixSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <Form.Item
          label="Knowledge level"
          name={"knowledgeLevel"}
          style={{ width: "100%" }}
          rules={[
            {
              required: selectOrInput === "INPUT",
              message: "Please enter knowledge level!",
            },
          ]}
        >
          {selectOrInput === "INPUT" ? (
            <Input placeholder="Knowledge Level" />
          ) : (
            <Select
              onChange={(e) => {
                setSelectOrInput(e);
              }}
            >
              <Option value="NOT_GIVEN">-</Option>
              <Option value="INPUT">Manually Specify</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Teacher Matrix"
          >
            Update Teacher Matrix
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateTeacherMatrix;
