import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import AddButton from "../../../../components/button/AddButton";
import { MdAssignmentAdd } from "react-icons/md";
import SelectAlert from "../../../../components/SelectAlert/SelectAlert";
import AddClaimTracking from "./AddClaimTracking";
import UpdateClaimTracking from "./UpdateClaimTracking";

const columns = [
  {
    title: "State",
    dataIndex: "state",
    width: 250,
    align: "center",
  },
  {
    title: "Year",
    dataIndex: "year",
    width: 150,
    align: "center",
  },
  {
    title: "Lodgement Date",
    dataIndex: "lodgementDate",
    width: 150,
    align: "center",
  },
  {
    title: "Lodgement Type",
    dataIndex: "lodgementType",
    width: 150,
    align: "center",
  },
  {
    title: "Lodgement ID",
    dataIndex: "lodgementId",
    width: 100,
    align: "center",
  },
  {
    title: "Claim Amount",
    dataIndex: "claimAmount",
    width: 100,
    align: "center",
  },
  {
    title: "Amount Received",
    dataIndex: "amountReceived",
    width: 100,
    align: "center",
  },
  {
    title: "Received Date",
    dataIndex: "receivedDate",
    width: 100,
    align: "center",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    width: 100,
    align: "center",
  },
  {
    title: "Claim Status",
    dataIndex: "claimStatus",
    width: 100,
    align: "center",
  },
  {
    title: "Notes",
    dataIndex: "notes",
    width: 300,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const vaccineDetailsData = [
  {
    id: 1,
    state: "NSW",
    year: "1",
    lodgementDate: "21-08-2023",
    lodgementType: "Initial",
    lodgementId: "LD23ID12",
    claimAmount: "500.00",
    amountReceived: "100.00",
    receivedDate: "21-08-2023",
    invoiceNo: "IV23ID23",
    claimStatus: "lodged",
    notes: "Student Claim Notes Here",
  },
];

const ClaimTracking = () => {
  const [isAddClaimTrackingModalVisible, setIsAddClaimTrackingModalVisible] =
    useState(false);
  const [
    isUpdateClaimTrackingModalVisible,
    setIsUpdateClaimTrackingModalVisible,
  ] = useState(false);

  const data = vaccineDetailsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      state: dataObj?.state,
      year: dataObj?.year,
      lodgementDate: dataObj?.lodgementDate,
      lodgementType: dataObj?.lodgementType,
      lodgementId: dataObj?.lodgementId,
      claimAmount: dataObj?.claimAmount,
      amountReceived: dataObj?.amountReceived,
      receivedDate: dataObj?.receivedDate,
      invoiceNo: dataObj?.invoiceNo,
      claimStatus: dataObj?.claimStatus,
      notes: dataObj?.notes,
      actions: (
        <Space size="middle">
          <Tooltip title="Update student training plan detail">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateClaimTrackingModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete training plan">
            <Popconfirm
              title="Are you sure to delete selected student training plan?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Student AVETMISS Claim (Funded) Record</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddClaimTrackingModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Course</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Course"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddClaimTrackingModalVisible && (
        <AddClaimTracking
          isAddClaimTrackingModalVisible={isAddClaimTrackingModalVisible}
          setIsAddClaimTrackingModalVisible={setIsAddClaimTrackingModalVisible}
        />
      )}
      {isUpdateClaimTrackingModalVisible && (
        <UpdateClaimTracking
          isUpdateClaimTrackingModalVisible={isUpdateClaimTrackingModalVisible}
          setIsUpdateClaimTrackingModalVisible={
            setIsUpdateClaimTrackingModalVisible
          }
        />
      )}
    </Card>
  );
};

export default ClaimTracking;
