import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Pagename from "../../../components/Pagename/pagename";
import AddButton from "../../../components/button/AddButton";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import { Popconfirm, Radio, Space, Table, Tooltip } from "antd";
import ButtonElement from "../../../components/button/ButtonElement";
import Teacher from "../Teacher";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { SelectField } from "../../../components/input";
import AddTeacherMatrix from "../../Staff/StaffDetails/StaffMatrix/AddTeacherMatrix";
import UpdateTeacherMatrix from "../../Staff/StaffDetails/StaffMatrix/UpdateTeacherMatrix";

const columns = [
  {
    title: "Subject",
    dataIndex: "subject",
    width: 250,
    align: "center",
  },
  {
    title: "Knowledge Level",
    dataIndex: "knowledgeLevel",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    subject: "EAP-B1-A: English for Academic Purpose",
    knowledgeLevel: "-",
  },
  {
    id: 1,
    subject: "GE-A2-A: GE Elementary",
    knowledgeLevel: "-",
  },
  {
    id: 1,
    subject: "GE-B1-A: GE Pre-Intermediate",
    knowledgeLevel: "-",
  },
];

const selectTeacher = [
  {
    label: "Mr Alexander Freitag(alex.freitag)",
    value: "Mr Alexander Freitag(alex.freitag)",
  },
  {
    label: "Ms Anu Rani Regmi(Anu)",
    value: "Ms Anu Rani Regmi(Anu)",
  },
  {
    label: "Mr Benjamin Fairley(benjamin.fairley)",
    value: "Mr Benjamin Fairley(benjamin.fairley)",
  },
  {
    label: "Mr Gaurab Thapa(gaurab.thapa)",
    value: "Mr Gaurab Thapa(gaurab.thapa)",
  },
  {
    label: "Mr James McDonald(james.mcdonald)",
    value: "Mr James McDonald(james.mcdonald)",
  },
  {
    label: "Mr Kadam Roka(kadam.roka)",
    value: "Mr Kadam Roka(kadam.roka)",
  },
];

const TeacherMatrix = () => {
  const [isAddTeacherMatrixModalVisible, setIsAddTeacherMatrixModalVisible] =
    useState(false);
  const [
    isUpdateTeacherMatrixModalVisible,
    setIsUpdateTeacherMatrixModalVisible,
  ] = useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      key: dataObj.id,
      subject: dataObj.subject,
      knowledgeLevel: dataObj.knowledgeLevel,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Teacher Matrix">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateTeacherMatrixModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Teacher Matrix">
            <Popconfirm
              title="Are you sure to delete selected teacher matrix?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Teacher tab={"/teacher-matrix"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Teacher Matrix</Pagename>
            <AddButton
              handleClick={() => {
                setIsAddTeacherMatrixModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Select Teacher:</div>
              <SelectField
                name="searchTeacherBy"
                placeholder="Search Teacher By"
                options={selectTeacher}
                handleChange={(e) => {}}
                fieldWidth="18rem"
              />
            </div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table dataSource={data} columns={columns} pagination={false} />
        </div>
      </ComponentBody>
      {isAddTeacherMatrixModalVisible && (
        <AddTeacherMatrix
          isAddTeacherMatrixModalVisible={isAddTeacherMatrixModalVisible}
          setIsAddTeacherMatrixModalVisible={setIsAddTeacherMatrixModalVisible}
        />
      )}
      {isUpdateTeacherMatrixModalVisible && (
        <UpdateTeacherMatrix
          isUpdateTeacherMatrixModalVisible={isUpdateTeacherMatrixModalVisible}
          setIsUpdateTeacherMatrixModalVisible={
            setIsUpdateTeacherMatrixModalVisible
          }
        />
      )}
    </Teacher>
  );
};

export default TeacherMatrix;
