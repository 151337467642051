import React from "react";
import { Form, Checkbox } from "antd";

const CheckboxElement = ({
  name,
  label = "",
  hasRule = false,
  size = "medium",
  options = [],
  handleChange = () => {},
  fieldWidth = "fit-content",
  classNameStyle = "",
}) => {
  return (
    <Form.Item
      style={{ margin: 0, width: fieldWidth }}
      name={name}
      label={label}
      rules={[
        {
          required: hasRule,
          message: `${label ? label : name} is required`,
        },
      ]}
    >
      <Checkbox.Group
        className={classNameStyle}
        onChange={handleChange}
        size={size}
        options={options}
      />
    </Form.Item>
  );
};

export default CheckboxElement;
