import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdateRefund = ({
  isUpdateRefundModalVisible,
  setIsUpdateRefundModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateRefundSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateRefund_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateRefundModalVisible(false);
  };
  return (
    <Drawer
      title="Update Refund"
      open={isUpdateRefundModalVisible}
      onClose={() => {
        setIsUpdateRefundModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateRefundSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Student Refund Details
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Transaction No"
                // name={"transactionNo"}
                style={{ width: "100%" }}
              >
                120
              </Form.Item>
              <Form.Item
                label="Student paid amount"
                // name={"studentPaidAmount"}
                style={{ width: "100%" }}
              >
                AUD 0
              </Form.Item>
            </div>
            <Form.Item
              label="Student refund amount"
              name={"studentRefundAmount"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter an student refund amount",
                },
              ]}
            >
              <Input type="number" placeholder="Student refund amount" />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Administration cost"
              name={"administrationCost"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter an administration cost",
                },
              ]}
            >
              <Input type="number" placeholder="Administration cost" />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Refund mode"
                name={"refundMode"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a refund mode",
                  },
                ]}
              >
                <Select placeholder="Payment Mode">
                  <Option value="BANK_DEPOSIT">Bank Deposit</Option>
                  <Option value="CREDIT_CARD">Credit Card</Option>
                  <Option value="OTHER">Other</Option>
                  <Option value="STUDENT_CREDIT">Student Credit</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Student Refund Date"
                name={"studentRefundDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a student refund date",
                  },
                ]}
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Agent Refund Details
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Agent commission refund amount"
              name={"agentCommissionRefundAmount"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a student refund date",
                },
              ]}
            >
              <Input type="number" placeholder="Administration cost" />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="GST refund amount"
                // name={"gstRefundAmount"}
                style={{ width: "100%" }}
              >
                AUD 0
              </Form.Item>
              <Form.Item
                label="Agent refunded"
                name={"agentRefunded"}
                style={{ width: "100%" }}
              >
                <Input type="number" placeholder="Agent Refunded" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Bonus Refund
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Paid"
                //   name={"paid"}
                style={{ width: "100%" }}
              >
                10.00 + GST: $1.00
              </Form.Item>
              <Form.Item
                label="Refunded"
                name={"refunded"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please ener a refunded!",
                  },
                ]}
              >
                <Input type="number" placeholder="Refunded" />
              </Form.Item>
            </div>
            <Form.Item
              label="Refund Mode"
              name={"refundMode"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a refund mode !",
                },
              ]}
            >
              <Select placeholder="Refund Mode">
                <Option value="BANK_DEPOSIT">Bank Deposit</Option>
                <Option value="CREDIT_CARD">Credit Card</Option>
                <Option value="OTHER">Other</Option>
                <Option value="NOT_REFUNDED">Not Refunded</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Net Refund
          </span>
          <Form.Item
            label="Student Net Received"
            name={"studentNetReceived"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a refund mode !",
              },
            ]}
          >
            <Input type="number" placeholder="Student Net Received" />
          </Form.Item>
          <Form.Item label="Remarks" name={"remarks"} style={{ width: "100%" }}>
            <TextArea placeholder="Write something..." />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Refund"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateRefund;
