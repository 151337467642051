import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddPaymentSchedule = ({
  isAddPaymentScheduleModalVisible,
  setIsAddPaymentScheduleModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddPaymentScheduleSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddPaymentSchedule_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddPaymentScheduleModalVisible(false);
  };
  return (
    <Drawer
      title="Add Payment Schedule"
      open={isAddPaymentScheduleModalVisible}
      onClose={() => {
        setIsAddPaymentScheduleModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddPaymentScheduleSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Invoice No"
            name={"invoiceNo"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Invoice No" />
          </Form.Item>
          <Form.Item
            label="Invoice Start Date"
            name={"invoiceStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a invoice start date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Amount"
            name={"amount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter an amount",
              },
            ]}
          >
            <Input type="number" placeholder="Amount" />
          </Form.Item>
          <Form.Item
            label="Invoice Due Date"
            name={"invoiceDueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter invoice due date",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <Form.Item
          label="Suggested Agent Commission:"
          name={"suggestedAgentCommission"}
          rules={[
            {
              required: true,
              message: "Please select a suggested agent commission: !",
            },
          ]}
        >
          <Select placeholder="Suggested agent commission:">
            <Option value="">0% + No GST</Option>
          </Select>
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label={
                <div style={{ whiteSpace: "nowrap" }}>Payment duration</div>
              }
              name={"paymentDuration"}
              rules={[
                {
                  required: true,
                  message: "Please enter a payment duration!",
                },
              ]}
            >
              <Input type="number" placeholder="Payment duration" />
            </Form.Item>
            <Form.Item
              name={"durationType"}
              style={{ width: "100%", alignSelf: "flex-end" }}
            >
              <Select placeholder="Duration Type" defaultValue={"WEEK"}>
                <Option value="Day">Day</Option>
                <Option value="WEEK">Week</Option>
                <Option value="MONTH">Month</Option>
                <Option value="YEAR">YEAR</Option>
              </Select>
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Applied commission (%)"
              name={"appliedCommission"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter applied commission!",
                },
              ]}
            >
              <Input type="number" placeholder="Applied commission" />
            </Form.Item>
            <Form.Item name={"isGst"} style={{ width: "100%" }}>
              <Radio.Group defaultValue={true}>
                <Radio value={true}>GST</Radio>
                <Radio value={false}>No GST</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label="Remarks"
          name={"remarks"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a remarks!",
            },
          ]}
        >
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Payment Schedule"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddPaymentSchedule;
