const INIT_STATE = {
  openDrawer: false,
  openDrawer2: false,
  openModal: false,
  drawerType: "",
  drawerType2: "",
  modalType: "",
};

const DrawerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_OPEN_DRAWER": {
      return {
        ...state,
        openDrawer: true,
        drawerType: action?.payload,
      };
    }

    case "FETCH_CLOSE_DRAWER": {
      return {
        ...state,
        openDrawer: false,
        drawerType: "",
      };
    }

    case "FETCH_OPEN_DRAWER2": {
      return {
        ...state,
        openDrawer2: true,
        drawerType2: action?.payload,
      };
    }

    case "FETCH_CLOSE_DRAWER2": {
      return {
        ...state,
        openDrawer2: false,
        drawerType2: "",
      };
    }

    case "FETCH_OPEN_MODAL": {
      return {
        ...state,
        openModal: true,
        modalType: action?.payload,
      };
    }

    case "FETCH_CLOSE_MODAL": {
      return {
        ...state,
        openModal: false,
        modalType: "",
      };
    }
    default:
      return state;
  }
};

export default DrawerReducer;
