import React, { useEffect, useState } from "react";
import { ImArrowUp } from "react-icons/im";

const ScrollUpElement = () => {
  const [isScroll, setIsScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className={
        isScroll ? "scrollup--container" : "hidden--scrollup--container"
      }
      onClick={scrollToTop}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.8rem",
          gap: "0.2rem",
        }}
      >
        <ImArrowUp
          style={{ color: "white", width: "2.5rem", height: "auto" }}
        />
        <span style={{ color: "white", fontWeight: "600" }}>Go To Top</span>
      </div>
    </div>
  );
};

export default ScrollUpElement;
