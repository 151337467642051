import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFilePdf,
  AiOutlinePlus,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddRefund from "./AddRefund";
import UpdateRefund from "./UpdateRefund";

const columns = [
  {
    title: "Transaction No",
    dataIndex: "transactionNo",
    width: 250,
    align: "center",
  },
  {
    title: "Student Paid",
    dataIndex: "studentPaid",
    width: 150,
    align: "center",
  },
  {
    title: "Amount Refunded",
    dataIndex: "amountRefunded",
    width: 150,
    align: "center",
  },
  {
    title: "Admin Cost",
    dataIndex: "adminCost",
    width: 100,
    align: "center",
  },
  {
    title: "Net Refunded",
    dataIndex: "netRefunded",
    width: 100,
    align: "center",
  },
  {
    title: "Refund Mode",
    dataIndex: "refundMode",
    width: 100,
    align: "center",
  },
  {
    title: "Student Refund Date",
    dataIndex: "studentRefundDate",
    width: 100,
    align: "center",
  },
  {
    title: "Agent To Refund",
    dataIndex: "agentToRefund",
    width: 100,
    align: "center",
  },
  {
    title: "GST To Refund",
    dataIndex: "gstToRefund",
    width: 100,
    align: "center",
  },
  {
    title: "Agent Refunded",
    dataIndex: "agentRefunded",
    width: 100,
    align: "center",
  },
  {
    title: "Agent Refund Date",
    dataIndex: "agentRefundDate",
    width: 100,
    align: "center",
  },
  {
    title: "Bonus Refund",
    dataIndex: "bonusRefund",
    width: 100,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const selectCourse = [
  {
    label: "All Courses",
    value: "ALL",
  },
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const RefundHistoryData = [
  {
    id: 1,
    transactionNo: "120",
    studentPaid: "900.00",
    amountRefunded: "10.00",
    adminCost: "0.00",
    netRefunded: "10.00",
    refundMode: "Bank Deposit",
    studentRefundDate: "07-09-2023",
    agentToRefund: "0.00",
    gstToRefund: "0.00",
    agentRefunded: "0.00",
    agentRefundDate: "",
    bonusRefund: "N/A",
    remarks: "",
  },
];

const RefundHistory = () => {
  const [isAddRefundHistoryModalVisible, setIsAddRefundHistoryModalVisible] =
    useState(false);
  const [
    isUpdateRefundHistoryModalVisible,
    setIsUpdateRefundHistoryModalVisible,
  ] = useState(false);

  const data = RefundHistoryData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      transactionNo: dataObj?.transactionNo,
      studentPaid: dataObj?.studentPaid,
      amountRefunded: dataObj?.amountRefunded,
      adminCost: dataObj?.adminCost,
      netRefunded: dataObj?.netRefunded,
      refundMode: dataObj?.refundMode,
      studentRefundDate: dataObj?.studentRefundDate,
      agentToRefund: dataObj?.agentToRefund,
      gstToRefund: dataObj?.gstToRefund,
      agentRefunded: dataObj?.agentRefunded,
      agentRefundDate: dataObj?.agentRefundDate,
      bonusRefund: dataObj?.bonusRefund,
      remarks: dataObj?.remarks,
      actions: (
        <Space size="middle">
          <Tooltip title="Edit Refund Info">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateRefundHistoryModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Student Refund Receipt">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Agent Payment Refund Invoice">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete this Refund Info">
            <Popconfirm
              title="Are you sure to delete this refund info?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Payment Refund</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddRefundHistoryModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Course name:</div>
            <SelectField
              name="courseName"
              placeholder="Course name"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 0,
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddRefundHistoryModalVisible && (
        <AddRefund
          isAddRefundModalVisible={isAddRefundHistoryModalVisible}
          setIsAddRefundModalVisible={setIsAddRefundHistoryModalVisible}
        />
      )}
      {isUpdateRefundHistoryModalVisible && (
        <UpdateRefund
          isUpdateRefundModalVisible={isUpdateRefundHistoryModalVisible}
          setIsUpdateRefundModalVisible={setIsUpdateRefundHistoryModalVisible}
        />
      )}
    </Card>
  );
};

export default RefundHistory;
