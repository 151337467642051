import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
import { AiOutlinePlus } from "react-icons/ai";
import AddProvider from "./AddProvider";
const { Option } = Select;
const { TextArea } = Input;

const AddExitInterview = ({
  isAddExitInterviewModalVisible,
  setIsAddExitInterviewModalVisible,
}) => {
  const [isAddProviderModalVisible, setIsAddProviderModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddExitInterviewSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_VACCINATION_DOSE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddExitInterviewModalVisible(false);
  };
  return (
    <Drawer
      title="Add Exit Interview"
      open={isAddExitInterviewModalVisible}
      onClose={() => {
        setIsAddExitInterviewModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddExitInterviewSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Exit Type"
            name={"exitType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a exit type!",
              },
            ]}
          >
            <Select defaultValue={"PROVIDER_EXIT"}>
              <Option value="PROVIDER_EXIT">Provider Exit</Option>
              <Option value="COURSE_EXIT">Course Exit</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Reason:"
            name={"reason"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a reason!",
              },
            ]}
          >
            <Input
              defaultValue={"Withdrawn from course"}
              placeholder="Reason"
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Comment"
            name={"Comment"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please write a comment!",
              },
            ]}
          >
            <TextArea placeholder="Write a comment" />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="New Provider"
              name={"newProvider"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please write a comment!",
                },
              ]}
            >
              <Select>
                <Option>Not Going To Another Provider</Option>
                <Option>New Provider</Option>
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddProviderModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <Form.Item name={"newProvider"} style={{ width: "100%" }}>
          <Checkbox>Add to Student Communication Log</Checkbox>
        </Form.Item>
        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Exit Interview"
          >
            Add Exit Interview
          </ButtonElement>
        </Form.Item>
      </Form>
      {isAddProviderModalVisible && (
        <AddProvider
          isAddProviderModalVisible={isAddProviderModalVisible}
          setIsAddProviderModalVisible={setIsAddExitInterviewModalVisible}
        />
      )}
    </Drawer>
  );
};

export default AddExitInterview;
