import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import countries from "./../../../../data/CountryList.json";
import { TbReport } from "react-icons/tb";
import AddCountry from "./AddCountry";
import UpdateCountry from "./UpdateCountry";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Code",
    dataIndex: "code3",
    width: 50,
    align: "center",
  },
  {
    title: "Country",
    dataIndex: "country",
    width: 150,
    align: "center",
  },
  {
    title: "Nationality",
    dataIndex: "nationality",
    width: 150,
    align: "center",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    width: 50,
    align: "center",
  },
  // {
  //   title: "Country Level",
  //   dataIndex: "countryLevel",
  //   width: 50,
  //   align: "center",
  // },
  {
    title: "Calling Code",
    dataIndex: "countryCode",
    width: 100,
    align: "center",
  },
  {
    title: "Region",
    dataIndex: "region",
    width: 100,
    align: "center",
  },
  {
    title: "Capital",
    dataIndex: "capital",
    width: 100,
    align: "center",
  },
  {
    title: "ABS",
    dataIndex: "abs",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 100,
    align: "center",
  },
];

const CountryList = () => {
  console.log("country list", countries);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isUpdateCountryModalVisible, setIsUpdateCountryModalVisible] =
    useState(false);

  const data = countries.countries.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      code3: dataObj?.code3,
      capital: dataObj?.capital,
      type: dataObj?.type,
      country: dataObj?.name,
      nationality: dataObj?.nationality,
      nationality: dataObj?.nationality,
      currency: dataObj?.currency,
      countryLevel: dataObj?.countryLevel,
      countryCode: dataObj?.phone,
      region: dataObj?.region,
      abs: dataObj?.abs,
      actions: (
        <Space size="middle">
          <Tooltip title="Edit Selected Deferral record">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateCountryModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete this country">
            <Popconfirm
              title="Are you sure to delete this country?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Country List</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          scroll={{
            y: 350,
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isUpdateCountryModalVisible && (
        <UpdateCountry
          isUpdateCountryModalVisible={isUpdateCountryModalVisible}
          setIsUpdateCountryModalVisible={setIsUpdateCountryModalVisible}
        />
      )}
    </div>
  );
};

export default CountryList;
