import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddAdvancedStanding = ({
  isAddAdvancedStandingModalVisible,
  setIsAddAdvancedStandingModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddAdvancedStandingSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_CERTIFICATE_REGISTER_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddAdvancedStandingModalVisible(false);
  };
  return (
    <Modal
      title="Record Exemption"
      open={isAddAdvancedStandingModalVisible}
      onCancel={() => {
        setIsAddAdvancedStandingModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddAdvancedStandingSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Select Course Template"
            name={"selectCourseTemplate"}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select a Course"></Select>
          </Form.Item>
          <Form.Item
            label="Subject"
            name={"subject"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a subject!",
              },
            ]}
          >
            <Select placeholder="Select a Subject">
              <Option>GE: General English-12-04-2021-Enrolled</Option>
              <Option>IELTS: IELTS Preparation-02-01-2023-Enrolled</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Select Unit"
            name={"selectUnit"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a unit",
              },
            ]}
          >
            <Select placeholder="Select a Unit"></Select>
          </Form.Item>
          <Form.Item name={"isApproved"} style={{ width: "100%" }}>
            <Checkbox>Is Approved?</Checkbox>
          </Form.Item>
        </div>
        <Form.Item label="Comment" name={"comment"} style={{ width: "100%" }}>
          <TextArea placeholder="Write a Comment" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Record Exemption"
          >
            Record Exemption
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAdvancedStanding;
