import { Button, Card, Form, Input, Modal, Select, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import OrganizationalSettings from "../../../components/OrganizationalSettings/OrganizationalSettings";
import CountryList from "./CountryList/CountryList";
import LanguageList from "./Language List/Language List";
import ChecklistSetup from "./ChecklistSetup/ChecklistSetup";
import CustomFields from "./CustomFields/CustomFields";
import ImprovementRegister from "./ImprovementRegister/ImprovementRegister";
import ContractSchedule from "./contractSchedule/ContractSchedule";
import CourseSite from "./courseSite/CourseSite";
import LocationRoom from "./locationRoom/LocationRoom";

const staffTabList = [
  {
    key: "countryList",
    label: "Country List",
  },
  {
    key: "languageList",
    label: "Language List",
  },
  {
    key: "checklistSetup",
    label: "Checklist Setup",
  },
  {
    key: "customFields",
    label: "Custom Fields",
  },
  {
    key: "improvementRegister",
    label: "Improvement Register",
  },
  {
    key: "contractSchedule",
    label: "Contract Schedule",
  },
  {
    key: "courseSite",
    label: "Course Site",
  },
  {
    key: "trainingLocationRoom",
    label: "Training Location/Room",
  },
];
const GeneralSettings = () => {
  const [current, setCurrent] = useState("countryList");

  return (
    <OrganizationalSettings>
      <div style={{ margin: "0.5rem 0 0 0.5rem" }}>
        <h3 className="font-medium text-base text-black">General Settings</h3>
        <div className="bg-white rounded px-4 py-2">
          <Tabs
            defaultActiveKey="profile"
            items={staffTabList}
            activeTabKey={current}
            onChange={(key) => {
              console.log("current key", key);
              setCurrent(key);
            }}
          />
          {current === "countryList" && (
            <>
              <CountryList />
            </>
          )}
          {current === "languageList" && (
            <>
              <LanguageList />
            </>
          )}
          {current === "checklistSetup" && (
            <>
              <ChecklistSetup />
            </>
          )}
          {current === "customFields" && (
            <>
              <CustomFields />
            </>
          )}
          {current === "improvementRegister" && (
            <>
              <ImprovementRegister />
            </>
          )}
          {current === "contractSchedule" && <ContractSchedule />}
          {current === "courseSite" && <CourseSite />}
          {current === "trainingLocationRoom" && <LocationRoom />}
        </div>
      </div>
    </OrganizationalSettings>
  );
};

export default GeneralSettings;
