import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
import { AiOutlineDelete } from "react-icons/ai";
const { Option } = Select;

const columns = [
  {
    title: "Course",
    dataIndex: "course",
    width: 100,
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];
const AddAdditionalFieldForSpecificCourseData = [
  {
    id: "1",
    course: "EAP",
  },
  {
    id: "2",
    course: "GE-Other",
  },
];

const AddAdditionalFieldForSpecificCourse = ({
  isAddAdditionalFieldForSpecificCourseModalVisible,
  setIsAddAdditionalFieldForSpecificCourseModalVisible,
}) => {
  const data = AddAdditionalFieldForSpecificCourseData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      course: dataObj?.course,

      actions: (
        <Space size="middle">
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddAdditionalFieldForSpecificCourseSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "AddAdditionalFieldForSpecificCourse_REQUEST",
      payload: formData,
    });
    // form.resetFields();
    setIsAddAdditionalFieldForSpecificCourseModalVisible(false);
  };
  return (
    <Drawer
      title="Add Additional Field for Specific Course"
      open={isAddAdditionalFieldForSpecificCourseModalVisible}
      onClose={() => {
        setIsAddAdditionalFieldForSpecificCourseModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddAdditionalFieldForSpecificCourseSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Custom Field Name/Type"
            name={"customFieldName"}
            style={{ width: "100%" }}
          >
            Total Holiday Weeks{" "}
          </Form.Item>
          <Form.Item
            label="Course Type"
            name={"courseType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course type !",
              },
            ]}
          >
            <Select placeholder="Course Type">
              <Option value="ELICOS">ELICOS</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course"
            name={"course"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course",
              },
            ]}
          >
            <Select>
              <Option>EAP: English for Academic Purposes</Option>
              <Option>EAP: English for Academic Purposes - Offshore</Option>
              <Option>EAP: English for Academic Purposes - Other</Option>
              <Option>GE: General English</Option>
              <Option>GE-OS: General English - Offshore</Option>
              <Option>GE-Other: General English - Other</Option>
              <Option>IELTS: IELTS Prepration</Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Additional Field for Specific Course"
          />
        </Form.Item>
      </Form>
      <Table bordered pagination={false} dataSource={data} columns={columns} />
    </Drawer>
  );
};

export default AddAdditionalFieldForSpecificCourse;
