import React, { useState } from "react";
import { Drawer, Table } from "antd";
import { useDispatch } from "react-redux";
import SelectAlert from "../../../../../components/SelectAlert/SelectAlert";
import { ButtonElement } from "../../../../../components/input";
import ProcessingAgentCommission from "../../../../Accounts/BulkAgentPayment/Process/ProcessingAgentCommission";

const columns = [
  {
    title: "InvId",
    dataIndex: "InvId",
    width: 50,
    align: "center",
  },
  {
    title: "Transaction No",
    dataIndex: "transactionNo",
    width: 50,
    align: "center",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    width: 250,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 100,
    align: "center",
  },
  {
    title: "Commission",
    dataIndex: "commission",
    width: 200,
    align: "center",
  },
  {
    title: "GST",
    dataIndex: "gst",
    width: 200,
    align: "center",
  },
  {
    title: "Total Payable",
    dataIndex: "totalPayable",
    width: 100,
    align: "center",
  },
];

const agentData = [
  {
    id: "1",
    InvId: "259",
    transactionNo: "259",
    invoiceNo: "1264",
    dueDate: "12/04/2021",
    commission: "0.00",
    gst: "0.00",
    totalPayable: "0.00",
  },
];

const ProcessCommission = ({
  searchBy,
  isProcessCommissionModalVisible,
  setIsProcessCommissionModalVisible,
}) => {
  const [
    isProcessingAgentCommissionModalVisible,
    setIsProcessingAgentCommissionModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let data = agentData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      InvId: dataObj?.InvId,
      transactionNo: dataObj?.transactionNo,
      invoiceNo: dataObj?.invoiceNo,
      dueDate: dataObj?.dueDate,
      commission: dataObj?.commission,
      gst: dataObj?.gst,
      totalPayable: dataObj?.totalPayable,
    };
  });

  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  return (
    <Drawer
      title="Process Payments"
      open={isProcessCommissionModalVisible}
      onClose={() => {
        setIsProcessCommissionModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        {selectedRowKeys.length > 0 && selectedVisible && (
          <SelectAlert
            length={selectedRowKeys.length}
            type={"success"}
            closable={true}
            selectAction={
              <ButtonElement
                size="small"
                buttonType="primary"
                handleClick={(e) => {
                  setIsProcessingAgentCommissionModalVisible(true);
                }}
                name={"Process"}
              />
            }
          />
        )}
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          scroll={{
            x: 1200,
          }}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </div>
      {isProcessingAgentCommissionModalVisible && (
        <ProcessingAgentCommission
          isProcessingAgentCommissionModalVisible={
            isProcessingAgentCommissionModalVisible
          }
          setIsProcessingAgentCommissionModalVisible={
            setIsProcessingAgentCommissionModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default ProcessCommission;
