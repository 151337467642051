import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddCustomFields from "./AddCustomFields";
import UpdateCustomFields from "./UpdateCustomFields";
import { BiBlock } from "react-icons/bi";
import AddAdditionalFieldForSpecificCourse from "./AdditionalFieldForCourse/AddAdditionalFieldForSpecificCourse";

const columns = [
  {
    title: "Custom Field Type",
    dataIndex: "customFieldType",
    width: 100,
    align: "center",
  },
  {
    title: "Field Used For",
    dataIndex: "fieldUsedFor",
    width: 200,
    align: "center",
  },
  {
    title: "Input Control Type",
    dataIndex: "inputControlType",
    width: 50,
    align: "center",
  },
  {
    title: "Data Type",
    dataIndex: "dataType",
    width: 50,
    align: "center",
  },
  {
    title: "Other Settings",
    dataIndex: "otherSettings",
    width: 50,
    align: "center",
  },
  {
    title: "Student Origin",
    dataIndex: "studentOrigin",
    width: 50,
    align: "center",
  },
  {
    title: "Order By",
    dataIndex: "orderBy",
    width: 50,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const CustomFieldsData = [
  {
    id: "1",
    customFieldType: "Total Holiday Weeks",
    fieldUsedFor: "Student Course",
    inputControlType: "TextBox",
    dataType: "String",
    otherSettings: "",
    studentOrigin: "",
    orderBy: "0",
    status: "ACTIVE",
  },
];

const CustomFields = () => {
  const [isAddCustomFieldsModalVisible, setIsAddCustomFieldsModalVisible] =
    useState(false);
  const [
    isUpdateCustomFieldsModalVisible,
    setIsUpdateCustomFieldsModalVisible,
  ] = useState(false);
  const [
    isAddAdditionalFieldForSpecificCourseModalVisible,
    setIsAddAdditionalFieldForSpecificCourseModalVisible,
  ] = useState(false);

  const data = CustomFieldsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      customFieldType: dataObj?.customFieldType,
      fieldUsedFor: dataObj?.fieldUsedFor,
      inputControlType: dataObj?.inputControlType,
      dataType: dataObj?.dataType,
      otherSettings: dataObj?.otherSettings,
      studentOrigin: dataObj?.studentOrigin,
      orderBy: dataObj?.orderBy,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Add Additional Field for Specific Course">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsAddAdditionalFieldForSpecificCourseModalVisible(true);
                }}
              >
                <AiOutlinePlus />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Update">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateCustomFieldsModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Activate Selected Field">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlinePoweroff />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Deactivate Selected Fields">
            <a style={{ color: "red" }}>
              <div className="bordered" onClick={() => {}}>
                <BiBlock />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Custom Fields</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddCustomFieldsModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddCustomFieldsModalVisible && (
        <AddCustomFields
          isAddCustomFieldsModalVisible={isAddCustomFieldsModalVisible}
          setIsAddCustomFieldsModalVisible={setIsAddCustomFieldsModalVisible}
        />
      )}
      {isUpdateCustomFieldsModalVisible && (
        <UpdateCustomFields
          isUpdateCustomFieldsModalVisible={isUpdateCustomFieldsModalVisible}
          setIsUpdateCustomFieldsModalVisible={
            setIsUpdateCustomFieldsModalVisible
          }
        />
      )}
      {isAddAdditionalFieldForSpecificCourseModalVisible && (
        <AddAdditionalFieldForSpecificCourse
          isAddAdditionalFieldForSpecificCourseModalVisible={
            isAddAdditionalFieldForSpecificCourseModalVisible
          }
          setIsAddAdditionalFieldForSpecificCourseModalVisible={
            setIsAddAdditionalFieldForSpecificCourseModalVisible
          }
        />
      )}
    </div>
  );
};

export default CustomFields;
