import React from "react";
import { DatePicker, Form, Input, Radio, Select } from "antd";
import { ButtonElement } from "../../../../components/input";
import InitialPaymentSummary from "./InitialPaymentSummary";
const { Option } = Select;

const CoursePaymentInfo = ({
  initialPaymentData,
  setInitialPaymentData,
  current,
  setCurrent,
}) => {
  const [form] = Form.useForm();

  const addCoursePaymentInfoHandler = () => {
    setCurrent(1);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexFlow: "wrap",
        gap: "1rem",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
        }}
      >
        <Form
          layout="vertical"
          onFinish={addCoursePaymentInfoHandler}
          colon={true}
          form={form}
          className="drawerStyle"
          fields={[
            { name: ["course"], value: initialPaymentData?.course },
            {
              name: ["commissionPeriod"],
              value: initialPaymentData?.commissionPeriod,
            },
            {
              name: ["commissionRate"],
              value: initialPaymentData?.commissionRate,
            },
            { name: ["paymentType"], value: initialPaymentData?.paymentType },
            {
              name: ["appliedCommission"],
              value: initialPaymentData?.appliedCommission,
            },
            { name: ["isGst"], value: initialPaymentData?.isGst },
            {
              name: ["invoiceDueDate"],
              value: initialPaymentData?.invoiceDueDate,
            },
            {
              name: ["invoiceDueDate"],
              value: initialPaymentData?.invoiceDueDate,
            },
          ]}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item label="Course" name={"course"} style={{ width: "100%" }}>
              <Select
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      course: e,
                    };
                  });
                }}
                placeholder="Course"
              >
                <Option value="GE: General English-02/01/2023 - Enrolled">
                  GE: General English-02/01/2023 - Enrolled
                </Option>
                <Option value="IELTS: IELTS Preparation-02/01/2023 - Enrolled">
                  IELTS: IELTS Preparation-02/01/2023 - Enrolled
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Commission Period"
              name={"commissionPeriod"}
              style={{ width: "100%" }}
            >
              <Select
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      commissionPeriod: e,
                    };
                  });
                }}
                placeholder="Course"
              >
                <Option value="Full Course">Full Course</Option>
                <Option value="2 Semester">2 Semester</Option>
                <Option value="1 Year">2 Semester</Option>
                <Option value="Once Off">Once Off</Option>
                <Option value="No Commission + No GST">
                  No commission + No GST
                </Option>
              </Select>
            </Form.Item>
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Commission Rate"
              name={"commissionRate"}
              style={{ width: "100%" }}
            >
              <Select
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      commissionRate: e,
                    };
                  });
                }}
                placeholder="Commission Rate"
              >
                <Option value="No commission found">No commission found</Option>
                <Option value="0% + No GST">0% + No GST</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Payment type"
              name={"paymentType"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a gender!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      paymentType: e,
                    };
                  });
                }}
                placeholder="Payment Type"
              >
                <Option value="INITIAL_PAYMENT_WITH_SCHEDULE">
                  Initial Payment With Schedule
                </Option>
                <Option value="INITIAL_PAYMENT_ONLY">
                  Initial Payment Only
                </Option>
                <Option value="SCHEDULE_ONLY">Schedule Only</Option>
              </Select>
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Applied commission (%)"
                name={"appliedCommission"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter applied commission!",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Applied commission"
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        appliedCommission: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name={"isGst"}
                style={{ width: "100%", alignSelf: "flex-end" }}
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      isGst: e.target.value,
                    };
                  });
                }}
              >
                <Radio.Group defaultValue={true}>
                  <Radio value={true}>GST</Radio>
                  <Radio value={false}>No GST</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <Form.Item
              label="Invoice due date"
              name={"invoiceDueDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="Date of Birth"
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      invoiceDueDate: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Invoice due date"
              name={"invoiceDueDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="Date of Birth"
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      invoiceDueDate: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Form.Item>
              <ButtonElement type="primary" htmlType="submit" name="Next">
                Next
              </ButtonElement>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div
        style={{
          width: "30%",
        }}
      >
        <InitialPaymentSummary
          detail={{
            name: "Anish Banjara",
          }}
        />
      </div>
    </div>
  );
};

export default CoursePaymentInfo;
