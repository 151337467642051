import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddLedgerValue = ({
  isAddLedgerValueModalVisible,
  setIsAddLedgerValueModalVisible,
}) => {
  const [ledgerValueData, setLedgerValueData] = useState({
    feeType: "TUTION_FEE",
    courseType: "ELICOS",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddLedgerValueSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddLedgerValue_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddLedgerValueModalVisible(false);
  };
  return (
    <Drawer
      title="Add Ledger Value"
      open={isAddLedgerValueModalVisible}
      onClose={() => {
        setIsAddLedgerValueModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddLedgerValueSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["feeType"],
            value: ledgerValueData?.feeType,
          },
          {
            name: ["courseType"],
            value: ledgerValueData?.courseType,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Fee Type"
            name={"feeType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a fee type!",
              },
            ]}
          >
            <Select
              placeholder="Fee Type"
              onChange={(e) => {
                setLedgerValueData((prevData) => {
                  return {
                    ...prevData,
                    feeType: e,
                  };
                });
              }}
            >
              <Option value="MISCELLANEOUS_FEE">Miscellaneous Fee</Option>
              <Option value="TUTION_FEE">Tution Fee</Option>
            </Select>
          </Form.Item>
          {ledgerValueData.feeType === "MISCELLANEOUS_FEE" && (
            <Form.Item
              label="Fee Name"
              name={"feeName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter fee name!",
                },
              ]}
            >
              <Input placeholder="Fee Name" />
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course Type"
            name={"courseType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course type",
              },
            ]}
          >
            <Select
              placeholder="Course Type"
              onChange={(e) => {
                setLedgerValueData((prevData) => {
                  return {
                    ...prevData,
                    courseType: e,
                  };
                });
              }}
            >
              <Option value="ELICOS">ELICOS</Option>
              <Option value="NO_COURSE_TYPE">No Course Type</Option>
            </Select>
          </Form.Item>
          {ledgerValueData.courseType !== "NO_COURSE_TYPE" && (
            <Form.Item
              label="Course"
              name={"course"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a course",
                },
              ]}
            >
              <Select>
                <Option>All Course</Option>
                <Option>EAP: English for Academic Purposes</Option>
                <Option>EAP: English for Academic Purposes - Offshore</Option>
                <Option>EAP: English for Academic Purposes - Other</Option>
                <Option>GE: General English</Option>
                <Option>GE-OS: General English - Offshore</Option>
                <Option>GE-Other: General English - Other</Option>
                <Option>IELTS: IELTS Prepration</Option>
              </Select>
            </Form.Item>
          )}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Student Origin"
            name={"studentOrigin"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a student origin!",
              },
            ]}
          >
            <Select placeholder="Student Origin">
              <Option value="ALL_STUDENTS">All Students</Option>
              <Option value="OVERSEAS_STUDENT_IN_AUSTRALIA">
                Overseas Student in Australia
              </Option>
              <Option value="OVERSEAS_STUDENT">Overseas Student</Option>
              <Option value="RESIDENT_STUDENT">Resident Student</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Account Number (Ledger Value)"
            name={"accountNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter an account number!",
              },
            ]}
          >
            <Input placeholder="Account Number" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Description"
            name={"description"}
            style={{ width: "100%" }}
          >
            <TextArea placeholder="Write something..." />
          </Form.Item>
        </div>
        <Form.Item
          label="Receivable Account Number"
          name={"receivableAccountNumber"}
          style={{ width: "100%" }}
        >
          <Select placeholder="Receivable Account Number">
            <Option value="NO_ACCOUNT">No Account</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Ledger Value"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddLedgerValue;
