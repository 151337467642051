import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Pagename from "../../../components/Pagename/pagename";
import AddButton from "../../../components/button/AddButton";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import { Input, Popconfirm, Radio, Space, Table, Tooltip } from "antd";
import ButtonElement from "../../../components/button/ButtonElement";
import Teacher from "../Teacher";
import { SelectField } from "../../../components/input";
import AddStaff from "../../Staff/AddStaff";
import StaffDetails from "../../Staff/StaffDetails/StaffDetails";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlinePoweroff,
} from "react-icons/ai";
import UpdateStaff from "../../Staff/UpdateStaff/UpdateStaff";
import { BiBlock } from "react-icons/bi";
const { Search } = Input;

const columns = [
  {
    title: "Staff Number",
    dataIndex: "staffNumber",
    width: 250,
    align: "center",
  },
  {
    title: "Teacher",
    dataIndex: "teacher",
    width: 250,
    align: "center",
  },
  {
    title: "User Name",
    dataIndex: "userName",
    width: 250,
    align: "center",
  },
  {
    title: "Email Address",
    dataIndex: "email",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const staffData = [
  {
    id: 1,
    staffNumber: "01",
    teacher: "Mr. Anish Banjara",
    userName: "Anees",
    email: "aneesbanjara65@gmail.com",
    status: "ACTIVE",
    actions: "",
  },
  {
    id: 2,
    staffNumber: "02",
    teacher: "Mr. Saroj Limbu",
    userName: "saroj",
    email: "sarojLimbu68@gmail.com",
    status: "ACTIVE",
    actions: "",
  },
];

const searchTeacherBy = [
  {
    label: "Status",
    value: "status",
  },
  {
    label: "All Teacher",
    value: "allTeacher",
  },
  {
    label: "Teacher ID/Username",
    value: "teacherIdUsername",
  },
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Email Address",
    value: "emailAddress",
  },
];

const ViewTeacher = () => {
  const [searchBy, setSearchBy] = useState("status");
  const [teacherStatus, setTeacherStatus] = useState("ACTIVE");
  const [isAddStaffModalVisible, setIsAddStaffModalVisible] = useState(false);
  const [isUpdateStaffModalVisible, setIsUpdateStaffModalVisible] =
    useState(false);
  const [isStaffDetailsModalVisible, setIsStaffDetailsModalVisible] =
    useState(false);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  let data = staffData.map((dataObj) => {
    return {
      key: dataObj.id,
      staffNumber: dataObj.staffNumber,
      teacher: dataObj.teacher,
      userName: dataObj.userName,
      email: dataObj.email,
      status: dataObj.status,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Update Teacher">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStaffModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Teacher Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStaffDetailsModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Teacher">
            <Popconfirm
              title="Are you sure to delete this selected teacher?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Activate Selected Teacher">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlinePoweroff />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Deactivate Selected Teacher">
            <a style={{ color: "red" }}>
              <div className="bordered" onClick={() => {}}>
                <BiBlock />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Teacher tab={"/manage-teacher"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Teacher</Pagename>
            <AddButton
              handleClick={() => {
                setIsAddStaffModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>View Subjects By</div>
              <SelectField
                name="searchTeacherBy"
                placeholder="Search Teacher By"
                options={searchTeacherBy}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="12rem"
              />
            </div>
            {searchBy === "status" && (
              <Radio.Group
                defaultValue="ACTIVE"
                value={teacherStatus}
                onChange={(e) => {
                  setTeacherStatus(e.target.value);
                }}
              >
                <Radio.Button value="ACTIVE" style={{ borderRadius: "3px" }}>
                  Active
                </Radio.Button>
                <Radio.Button value="INACTIVE" style={{ borderRadius: "3px" }}>
                  Inactive
                </Radio.Button>
              </Radio.Group>
            )}
            {searchBy !== "status" && searchBy !== "allTeacher" && (
              <Search
                style={{ borderRadius: "3px", width: "100%" }}
                className="search"
                placeholder="Search teacher."
                allowClear
                size="Medium"
                //   value={searchValue}
                onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   onSearch(e.target.value);
                }}
              />
            )}
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {selectedRowKeys.length > 0 && selectedVisible && (
            <SelectAlert
              length={selectedRowKeys.length}
              type={"success"}
              closable={true}
              selectAction={
                <ButtonElement
                  size="small"
                  buttonType="primary"
                  onClick={(e) => {}}
                  name="Download CSV"
                >
                  Download CSV
                </ButtonElement>
              }
            />
          )}
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isAddStaffModalVisible && (
        <AddStaff
          fromAddTeacher={true}
          isAddStaffModalVisible={isAddStaffModalVisible}
          setIsAddStaffModalVisible={setIsAddStaffModalVisible}
        />
      )}
      {isStaffDetailsModalVisible && (
        <StaffDetails
          fromAddTeacher={true}
          isStaffDetailsModalVisible={isStaffDetailsModalVisible}
          setIsStaffDetailsModalVisible={setIsStaffDetailsModalVisible}
        />
      )}
      {isUpdateStaffModalVisible && (
        <UpdateStaff
          fromAddTeacher={true}
          isUpdateStaffModalVisible={isUpdateStaffModalVisible}
          setIsUpdateStaffModalVisible={setIsUpdateStaffModalVisible}
        />
      )}
    </Teacher>
  );
};

export default ViewTeacher;
