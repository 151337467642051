import {
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
import Icon from "@ant-design/icons/lib/components/Icon";
import AddContract from "./AddContract";
import ViewContractList from "./ViewContractList";

export default function ContractSchedule() {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Contract Code",
      dataIndex: "contract_code",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: () => (
        <div className="flex justify-center gap-2">
          <Tooltip placement="top" title="Manage Contract">
            <EyeOutlined
              onClick={() => handleViewDetail("viewContract")}
              className="text-[blue] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[blue] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000"
            />
          </Tooltip>{" "}
          <Tooltip placement="top" title="Delete Record">
            <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
          </Tooltip>{" "}
        </div>
      ),
    },
  ];

  const data = [
    {
      contract_code: 123,
      description: "New Contract",
    },
    {
      contract_code: 123,
      description: "New Contract",
    },
    {
      contract_code: 123,
      description: "New Contract",
    },
  ];

  const handleViewDetail = (value) => {
    dispatch({ type: "FETCH_OPEN_DRAWER", payload: value });
  };

  const handleAddEditContract = (value) => {
    dispatch({ type: "FETCH_OPEN_MODAL", payload: value });
  };

  return (
    <div className="flex flex-col gap-4 mt-4">
      <ButtonElement
        handleClick={() => handleAddEditContract("addContract")}
        Icon={<PlusOutlined className="text-sm" />}
        name="Contract Setup"
      />
      <Table dataSource={data} columns={columns} />
      <AddContract />
      <ViewContractList />
    </div>
  );
}
