import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddRefund = ({ isAddRefundModalVisible, setIsAddRefundModalVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddRefundSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddRefund_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddRefundModalVisible(false);
  };
  return (
    <Drawer
      title="Add Refund"
      open={isAddRefundModalVisible}
      onClose={() => {
        setIsAddRefundModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddRefundSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Student paid amount"
            // name={"studentPaidAmount"}
            style={{ width: "100%" }}
          >
            AUD 0
          </Form.Item>
          <Form.Item
            label="Student refund amount"
            name={"studentRefundAmount"}
            style={{ width: "100%" }}
          >
            AUD 0
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Administration cost"
            name={"administrationCost"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter an administration cost",
              },
            ]}
          >
            <Input type="number" placeholder="Administration cost" />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Refund mode"
              name={"refundMode"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a refund mode",
                },
              ]}
            >
              <Select placeholder="Payment Mode">
                <Option value="BANK_DEPOSIT">Bank Deposit</Option>
                <Option value="CREDIT_CARD">Credit Card</Option>
                <Option value="OTHER">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Student Refund Date"
              name={"studentRefundDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a student refund date",
                },
              ]}
            >
              <DatePicker format={"DD-MM-YYYY"} />
            </Form.Item>
          </div>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Agent commission refund amount"
            style={{ width: "100%" }}
          >
            AUD 0
          </Form.Item>
          <Form.Item
            label="GST refund amount"
            // name={"gstRefundAmount"}
            style={{ width: "100%" }}
          >
            AUD 0
          </Form.Item>
        </div>
        <Form.Item
          label="Agent Refund Mode"
          name={"agentRefundMode"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a agent refund mode !",
            },
          ]}
        >
          <Select placeholder="Payment Mode">
            <Option value="BANK_DEPOSIT">Bank Deposit</Option>
            <Option value="CREDIT_CARD">Credit Card</Option>
            <Option value="OTHER">Other</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Remarks" name={"remarks"} style={{ width: "100%" }}>
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Refund"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddRefund;
