import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Accounts from "../Accounts";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import PageHeader from "../../../components/PageHeader/PageHeader";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
const { Search } = Input;

const columns = [
  {
    title: "Payment Invoice",
    dataIndex: "paymentInvoice",
    width: 100,
    align: "center",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    width: 250,
    align: "center",
  },
  {
    title: "Invoice Sent",
    dataIndex: "invoiceSent",
    width: 150,
    align: "center",
  },
  {
    title: "Student Id",
    dataIndex: "studentId",
    width: 50,
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "Name",
    width: 150,
    align: "center",
  },
  {
    title: "Course Id",
    dataIndex: "courseId",
    width: 150,
    align: "center",
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 150,
    align: "center",
  },
  {
    title: "Course Attempt",
    dataIndex: "courseAttempt",
    width: 50,
    align: "center",
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    width: 150,
    align: "center",
  },
  {
    title: "Student Email",
    dataIndex: "studentEmail",
    width: 250,
    align: "center",
  },
  {
    title: "College Email",
    dataIndex: "collegeEmail",
    width: 250,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 250,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 150,
    align: "center",
  },
  {
    title: "Due Amount",
    dataIndex: "dueAmount",
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
];

const selectAgentStatus = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "New Application Request",
    value: "newApplicationRequest",
  },
  {
    label: "Principal Agent",
    value: "principalAgent",
  },
  {
    label: "Provisional",
    value: "Provisional",
  },
  {
    label: "All",
    value: "all",
  },
];
const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];
const selectStudentType = [
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Current Student",
    value: "currentStudent",
  },
  {
    label: "Enrolled",
    value: "enrolled",
  },
  {
    label: "Expired Offer",
    value: "expiredOffer",
  },
  {
    label: "Finished",
    value: "finished",
  },
  {
    label: "New Application Request",
    value: "newApplicationRequest",
  },
  {
    label: "New Course Request",
    value: "newCourseRequest",
  },
  {
    label: "Offered",
    value: "offered",
  },
  {
    label: "Withdrawn",
    value: "withdrawn",
  },
  {
    label: "All Status",
    value: "allStatus",
  },
];

const invoiceData = [
  {
    id: "1",
    paymentInvoice: "150",
    invoiceNo: "1154",
    invoiceSent: "",
    studentId: "OPS0001563",
    Name: "Prantanya Muangklang",
    courseId: "GE",
    courseName: "General English",
    courseAttempt: "1",
    startDate: "24-07-2023",
    studentEmail: "prantanyafront@gmail.com",
    collegeEmail: "OPS0001563@opera.nsw.edu.au",
    agentName: "Boss Education & Visa",
    dueDate: "04-09-2023",
    dueAmount: "$960.00",
    status: "Enrolled",
  },
];

const EmployerInvoice = () => {
  const [isAddCommissionModalVisible, setIsAddCommissionModalVisible] =
    useState(false);
  const [isUpdateCommissionModalVisible, setIsUpdateCommissionModalVisible] =
    useState(false);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let data = invoiceData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      paymentInvoice: dataObj?.paymentInvoice,
      invoiceNo: dataObj?.invoiceNo,
      invoiceSent: dataObj?.invoiceSent,
      studentId: dataObj?.studentId,
      Name: dataObj?.Name,
      courseId: dataObj?.courseId,
      courseName: dataObj?.courseName,
      courseAttempt: dataObj?.courseAttempt,
      startDate: dataObj?.startDate,
      studentEmail: dataObj?.studentEmail,
      collegeEmail: dataObj?.collegeEmail,
      dueDate: dataObj?.dueDate,
      dueAmount: dataObj?.dueAmount,
      status: dataObj?.status,
    };
  });
  return (
    <Accounts tab={"/generate-employer-invoice"}>
      <ComponentBody>
        <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Select Employer:</div>
            <SelectField
              name="selectEmployer"
              placeholder="Select Employer"
              options={[
                {
                  label: "No Employer Found",
                  value: "NO_EMPLOYER_FOUND",
                },
              ]}
              fieldWidth="12rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>From Date:</div>
            <DatePicker format={"DD-MM-YYYY"} />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>To Date:</div>
            <DatePicker format={"DD-MM-YYYY"} />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Payment Type:</div>
            <SelectField
              name="paymentType"
              placeholder="Payment Type"
              options={[
                {
                  label: "Tution Fee",
                  value: "TUTION_FEE",
                },
                {
                  label: "Miscellaneous Fee",
                  value: "MISCELLANEOUS_FEE",
                },
              ]}
              fieldWidth="12rem"
            />
          </div>

          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Student Status:</div>
            <SelectField
              name="studentStatus"
              placeholder="Student Status"
              options={selectStudentType}
              fieldWidth="12rem"
            />
          </div>

          <ButtonElement name="View Schedules" />
        </div>
      </ComponentBody>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Generate Invoice</Pagename>
            <div
              style={{ display: "flex", justifyContent: "flex-start" }}
            ></div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {selectedRowKeys.length > 0 && selectedVisible && (
            <SelectAlert
              length={selectedRowKeys.length}
              type={"success"}
              closable={true}
              selectAction={
                <div className="fxRJs">
                  <ButtonElement
                    size="small"
                    buttonType="primary"
                    onClick={(e) => {}}
                    name="Employer Invoice"
                  />
                  <ButtonElement
                    size="small"
                    buttonType="primary"
                    onClick={(e) => {}}
                    name="Export to Excel"
                  />
                </div>
              }
            />
          )}
          <Table
            scroll={{
              x: 0,
            }}
            bordered
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
    </Accounts>
  );
};

export default EmployerInvoice;
