import { Button } from "antd";
import React, { useState } from "react";
import { ButtonElement } from "../../../../components/input";
import ManageRoom from "./ManageRoom";
import ManageVenue from "./ManageVenue";

export default function LocationRoom() {
  const [current, setCurrent] = useState("manageRoom");
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-4 items-center my-4">
        <span className="text-[1.2rem] font-semibold text-[gray]">
          Setting Up Training Location/Venue & Rooms
        </span>
        <Button.Group type="primary">
          <Button onClick={() => setCurrent("manageRoom")}>Manage Room</Button>
          <Button onClick={() => setCurrent("manageVenue")}>
            Manage Venue
          </Button>
        </Button.Group>
      </div>
      {current === "manageRoom" && <ManageRoom />}
      {current === "manageVenue" && <ManageVenue />}
    </div>
  );
}
