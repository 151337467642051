import {
  Badge,
  Button,
  Card,
  Divider,
  Pagination,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React from "react";
import classes from "./Mailbox.module.css";
import { useState } from "react";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineMail,
  AiOutlineSend,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import SendComposedMail from "./SendComposedMail";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment-timezone";
import FullLengthEmail from "./FullLengthEmail";
import ReplyToMaiSubmitHandler from "./ReplyToMail";
import { RiDraftLine } from "react-icons/ri";
import { BiRefresh } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import { SlRefresh } from "react-icons/sl";
import { SiderDemo } from "../../components/input";
const { Paragraph } = Typography;

const Mailbox = () => {
  const store = useSelector((state) => {
    return {
      mailboxState: state.MailboxReducer,
      profileState: state.ProfileReducer,
    };
  });
  const dispatch = useDispatch();
  const [active, setActive] = useState("inbox");
  const [composeMailRecord, setComposeMailRecord] = useState(null);
  const seenSuccessFunction = () => {};
  const mailSuccessFunction = (resposnse) => {
    dispatch({
      type: "UPDATE_PREVIOUS_DATA_REQUEST",
      payload: resposnse?.data?.totalData,
    });

    console.log("mailbox fetch", resposnse);
  };

  const [mailRecord, setMailRecord] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    console.log("current pagesize", current, pageSize);
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
    if (active === "inbox") {
      dispatch({
        type: "FETCH_MAILBOX_INBOX_REQUEST",
        payload: {
          page: current,
          pageSize,
          mailSuccessFunction: mailSuccessFunction,
        },
      });
    } else if (active === "sent") {
      dispatch({
        type: "FETCH_MAILBOX_SENT_REQUEST",
        payload: { page: current, pageSize },
      });
    } else if (active === "draft") {
      dispatch({
        type: "FETCH_MAILBOX_DRAFT_REQUEST",
        payload: { page: current, pageSize, isFromSiderDemo: false },
      });
    }
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_MAILBOX_INBOX_REQUEST",
      payload: { page, pageSize, mailSuccessFunction: mailSuccessFunction },
    });
    dispatch({
      type: "FETCH_MAILBOX_SENT_REQUEST",
      payload: { page, pageSize },
    });
    dispatch({
      type: "FETCH_MAILBOX_DRAFT_REQUEST",
      payload: { page, pageSize },
    });
  }, []);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  console.log("sent", store.mailboxState);
  const changeTimeZone = (inputTime = "Thu Mar 16 11:54:14 GMT 2023") => {
    const inputMoment = moment(inputTime, "ddd MMM DD HH:mm:ss z YYYY").tz(
      "Australia/Sydney"
    );

    // convert the input time to GMT and format the output
    const outputTime = inputMoment
      .clone()
      .tz("GMT")
      .format("ddd MMM DD HH:mm:ss z YYYY");

    console.log(outputTime); // output: "Thu Mar 16 11:54:14 GMT 2023"
    return outputTime;
  };
  return (
    <SiderDemo organizationalSettings={true}>
      <div
        style={{
          //   display: "flex",
          margin: "24px 0px 24px 16px",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            minHeight: "82vh",
            padding: "0.5rem",
          }}
        >
          <div
            style={{
              width: "20%",
              backgroundColor: "white",
              padding: "0.5rem",
            }}
          >
            <div
              style={{
                border: "10px",
                display: "flex",
                flexDirection: "column",
                padding: "0 0.5rem 0.5rem 0",
              }}
            >
              <div className="center" style={{}}>
                <Button
                  type="primary"
                  block
                  danger
                  style={{
                    borderRadius: "0.25rem",
                    padding: "0.3rem",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    setComposeMailRecord(null);
                    setIsSendComposedEmailModalVisible(true);
                  }}
                >
                  COMPOSE
                </Button>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div>
                <h4>MAILBOX</h4>
              </div>
              <div className="flexColumnwithoutStyle" style={{ gap: "1px" }}>
                <div
                  onClick={() => {
                    // console.log("inbox clicked");

                    setActive("inbox");
                    // setPage(1);
                    // setPageSize(10);
                    dispatch({
                      type: "FETCH_MAILBOX_INBOX_REQUEST",
                      payload: {
                        page: 1,
                        pageSize: 10,
                        mailSuccessFunction: mailSuccessFunction,
                      },
                    });
                  }}
                  className={
                    active === "inbox"
                      ? classes.mailboxActiveTab
                      : classes.mailboxNonActiveTab
                  }
                >
                  <div className="mailboxLogoAndTextTab">
                    <div
                      className={
                        active === "inbox"
                          ? classes.mailboxIcon
                          : classes.mailboxBlackIcon
                      }
                    >
                      <AiOutlineMail />
                    </div>
                    <div
                      style={{
                        color: active === "inbox" ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      Inbox
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <Badge
                      className="badgeClass"
                      color="#f95959"
                      count={store.mailboxState.mailboxInbox?.totalData ?? 0}
                      showZero
                      //   style={{ backgroundClip: "white", color: "black" }}
                    ></Badge> */}
                  </div>
                </div>

                <div
                  onClick={() => {
                    console.log("sent clicked");
                    // setPage(1);
                    // setPageSize(10);
                    setActive("sent");
                    dispatch({
                      type: "FETCH_MAILBOX_SENT_REQUEST",
                      payload: { page: 1, pageSize: 10 },
                    });
                  }}
                  className={
                    active === "sent"
                      ? classes.mailboxActiveTab
                      : classes.mailboxNonActiveTab
                  }
                >
                  <div className="mailboxLogoAndTextTab">
                    <div
                      className={
                        active === "sent"
                          ? classes.mailboxIcon
                          : classes.mailboxBlackIcon
                      }
                    >
                      <AiOutlineSend />
                    </div>
                    <div
                      style={{
                        color: active === "sent" ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      Sent
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <Badge
                      className={" badgeClass "}
                      //   color={active === "sent" ? "#2BB3C0" : "#f95959"}
                      count={store.mailboxState?.mailboxSent?.totalData}
                      showZero
                      //   style={{ backgroundClip: "white", color: "black" }}
                    ></Badge> */}
                  </div>
                </div>

                <div
                  onClick={() => {
                    console.log("draft clicked");
                    // setPage(1);
                    // setPageSize(10);
                    setActive("draft");
                    dispatch({
                      type: "FETCH_MAILBOX_DRAFT_REQUEST",
                      payload: { page: 1, pageSize: 10 },
                    });
                  }}
                  className={
                    active === "draft"
                      ? classes.mailboxActiveTab
                      : classes.mailboxNonActiveTab
                  }
                >
                  <div className="mailboxLogoAndTextTab">
                    <div
                      className={
                        active === "draft"
                          ? classes.mailboxIcon
                          : classes.mailboxBlackIcon
                      }
                    >
                      <RiDraftLine />
                    </div>
                    <div
                      style={{
                        color: active === "draft" ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      Draft
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <Badge
                      className={" badgeClass "}
                      //   color={active === "sent" ? "#2BB3C0" : "#f95959"}
                      count={store.mailboxState?.mailboxDraft?.totalData}
                      showZero
                      //   style={{ backgroundClip: "white", color: "black" }}
                    ></Badge> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider
            type="vertical"
            style={{
              height: "82vh",
              color: "red",
              margin: "0.5rem 0 0 0",
              padding: "0",
            }}
          />
          <div className="mailboxScroll">
            <Card
              className={"mailboxMaillist"}
              bordered={false}
              // loading={store.mailboxState.isLoading}
              style={{ margin: 0, padding: 0 }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: "0.5rem",
                  gap: "0.5rem",
                }}
              >
                {active === "inbox" && (
                  <div
                    style={{
                      // border: "1px solid black",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      dispatch({
                        type: "FETCH_MAILBOX_INBOX_REQUEST",
                        payload: {
                          page,
                          pageSize,
                          mailSuccessFunction: mailSuccessFunction,
                        },
                      });
                    }}
                  >
                    <Tooltip title="Refresh">
                      <SlRefresh
                        className={"refreshIcon"}
                        style={{ fontSize: "20px" }}
                      />
                    </Tooltip>
                  </div>
                )}
                <Pagination
                  size="small"
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={
                    active === "inbox"
                      ? store.mailboxState?.mailboxInbox?.totalData
                      : active === "sent"
                      ? store.mailboxState?.mailboxSent?.totalData
                      : active === "draft"
                      ? store.mailboxState?.mailboxDraft?.totalData
                      : 10
                  }
                />

                {/* {instituteState.institutes?.totalData && (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={instituteState.institutes?.totalPage * 10}
                />
              )} */}
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

              {active === "inbox" ? (
                <>
                  {console.log("hwllow orld")}
                  {store.mailboxState?.mailboxInbox?.data?.map(
                    (dataObj, index) => {
                      const mailSydneyTimeMoment = moment(
                        dataObj.receivedDateTime,
                        "ddd MMM DD HH:mm:ss z YYYY"
                      ).format("YYYY-MM-DD HH:mm");
                      const sydneyTime = moment.tz(
                        mailSydneyTimeMoment,
                        "Australia/Sydney"
                      );
                      const localTime = moment(sydneyTime, "HH:mm:ss")
                        .utc()
                        .local()
                        .format("h:mm A");
                      // console.warn(
                      //   dataObj.receivedDateTime &&
                      //     moment(dataObj.receivedDateTime, "HH:mm:ss")
                      //       .local()
                      //       .format("h:mm A"),
                      //   dataObj.receivedDateTime
                      // );

                      // console.log(
                      //   changeTimeZone(
                      //     dataObj.receivedDateTime,
                      //     "Thu Mar 16 11:54:14 GMT 2023"
                      //   )
                      // );

                      return (
                        <div
                          key={index}
                          className="flexColumnwithoutStyle"
                          id="mailboxSent"
                          style={{
                            padding: "0.5rem",
                          }}
                          onClick={() => {
                            setMailRecord(dataObj);
                            if (!dataObj?.isSeen) {
                              dispatch({
                                type: "MARK_MAIL_AS_SEEN_REQUEST",
                                payload: {
                                  mailId: dataObj.mailId,
                                  page: page,
                                  pageSize: pageSize,
                                  seenSuccessFunction: seenSuccessFunction,
                                },
                              });
                            }
                            // dispatch({type: ""})
                          }}
                        >
                          <div
                            className="flexRowWithoutStyle"
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              className="mailboxEmailText"
                              style={{ width: "80%" }}
                            >
                              {dataObj?.fromUser}
                            </span>
                            <span className="mailboxEmailTime">
                              {localTime}
                            </span>
                          </div>
                          <Paragraph ellipsis={true}>
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                height: "1.2rem",
                                fontWeight: dataObj.isSeen ? "normal" : "bold",
                              }}
                            >
                              {dataObj.subjectContent}
                            </div>
                          </Paragraph>
                        </div>
                      );
                    }
                  )}
                </>
              ) : active === "sent" ? (
                <>
                  {store.mailboxState?.mailboxSent?.data?.map(
                    (dataObj, index) => {
                      const mailSydneyTimeMoment = moment(
                        dataObj.sentDateTime,
                        "ddd MMM DD HH:mm:ss z YYYY"
                      ).format("YYYY-MM-DD HH:mm");
                      const sydneyTime = moment.tz(
                        mailSydneyTimeMoment,
                        "Australia/Sydney"
                      );
                      const localTime = moment(sydneyTime, "HH:mm:ss")
                        .utc()
                        .local()
                        .format("h:mm A");
                      // console.warn("changeTimezone sent", localTime);

                      // console.log(moment(dataObj.sentDateTime).toISOString());
                      return (
                        <div
                          key={index}
                          className="flexColumnwithoutStyle"
                          id="mailboxSent"
                          style={{
                            padding: "0.5rem",
                          }}
                          onClick={() => {
                            setMailRecord(dataObj);
                          }}
                        >
                          <div
                            className="flexRowWithoutStyle"
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="mailboxEmailText">
                              {dataObj?.toUser?.[0]}
                            </span>
                            <span className="mailboxEmailTime">
                              {localTime}
                            </span>
                          </div>
                          <Paragraph ellipsis={true}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: dataObj.subjectContent,
                              }}
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            ></div>
                          </Paragraph>
                        </div>
                      );
                    }
                  )}
                </>
              ) : active === "draft" ? (
                <>
                  {store.mailboxState?.mailboxDraft?.data?.map(
                    (dataObj, index) => {
                      return (
                        <div
                          className="anish flexRowWithoutStyle"
                          // style={{
                          //   border: "1px solid red",
                          // }}
                        >
                          <div
                            key={index}
                            className="flexColumnwithoutStyle"
                            id="mailboxSent"
                            style={{
                              padding: "0.5rem",
                              width: "100%",
                            }}
                            onClick={() => {
                              setComposeMailRecord(dataObj);
                              setIsSendComposedEmailModalVisible(true);
                            }}
                          >
                            <div
                              className="flexRowWithoutStyle"
                              style={{
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <span
                                className="mailboxEmailText"
                                style={{
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {dataObj?.toUser?.[0] !== "" &&
                                dataObj?.toUser?.length > 0
                                  ? dataObj?.toUser?.[0]
                                  : "(No Recipients)"}
                              </span>
                              <span
                                className="mailboxEmailTime"
                                // style={{ width: "100%" }}
                              >
                                {dataObj.lastChangedTime &&
                                  moment
                                    .utc(dataObj.lastChangedTime)
                                    .local()
                                    .format("DD-MM-YYYY, h:mm A")}
                              </span>
                            </div>
                            <Paragraph ellipsis={true}>
                              <div
                                // dangerouslySetInnerHTML={{
                                //   __html: dataObj.bodyContent,
                                // }}
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  height: "1.2rem",
                                }}
                              >
                                {dataObj.subjectContent !== "" &&
                                dataObj.subjectContent !== null
                                  ? dataObj.subjectContent
                                  : "(no subject)"}
                              </div>
                            </Paragraph>
                          </div>

                          <div
                            className="deleteDraft"
                            // style={{
                            //   display: "flex",

                            //   justifyContent: "center",
                            //   alignItems: "center",
                            // }}
                          >
                            <Tooltip title="Delete Draft">
                              <AiFillDelete
                                style={{
                                  fontSize: "20",
                                  // borderRadius: "50%",
                                  // padding: "0.5rem",
                                  // border: "1px solid black ",
                                }}
                                onClick={() => {
                                  dispatch({
                                    type: "DELETE_DRAFT_REQUEST",
                                    payload: { draftId: dataObj.draftId },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              ) : null}
            </Card>
          </div>
          <Divider
            type="vertical"
            style={{
              height: "82vh",
              color: "red",
              margin: "0.5rem 0 0 0",
              padding: "0",
            }}
          />
          <div
            style={{
              backgroundColor: "white",
              padding: "0rem",
              width: "50%",
            }}
          >
            <FullLengthEmail
              mailRecord={mailRecord}
              fromInbox={active === "inbox" ? true : false}
            />
          </div>
        </div>
      </div>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={composeMailRecord}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default Mailbox;
