import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddChecklist = ({
  isAddChecklistModalVisible,
  setIsAddChecklistModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddChecklistSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddChecklist_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddChecklistModalVisible(false);
  };
  return (
    <Modal
      title="Add Checklist"
      open={isAddChecklistModalVisible}
      onCancel={() => {
        setIsAddChecklistModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddChecklistSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Checklist Name"
          name={"checklistName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter checklist name!",
            },
          ]}
        >
          <Input placeholder="Checklist Name" />
        </Form.Item>
        <Form.Item
          label="Description"
          name={"description"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a description!",
            },
          ]}
        >
          <TextArea placeholder="Write something" />
        </Form.Item>

        <Form.Item
          label="Checklist For"
          name={"checklistFor"}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <Select placeholder="Checklist For">
            <Option value="OFFER">Offer</Option>
            <Option value="PAYMENT">Payment</Option>
            <Option value="STUDENT_COURSE">Student Course</Option>
          </Select>
        </Form.Item>

        <Form.Item name={"isMandatory"} valuePropName="checked">
          <Checkbox>Is Mandatory?</Checkbox>
        </Form.Item>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Checklist"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddChecklist;
