import React, { useEffect } from "react";
import SiderDemo from "../../components/Layout/SiderDemo";
import { Link } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import DashboardComponentAnalysis from "./DashboardComponentAnalysis";
import DashboardComponentList from "./DashboardComponentList";
import { BiCube } from "react-icons/bi";
import { IoLayersOutline } from "react-icons/io5";
import { BsBagCheck, BsTags } from "react-icons/bs";
import { Pie } from "@ant-design/plots";
import { AiOutlineUser } from "react-icons/ai";

const Dashboard = () => {
  let data;
  data = [
    {
      // new application
      type: "New Application Request",
      value: 87,
      color: "white",
    },

    {
      // Offered
      type: "Offered",
      value: 230,
    },
    {
      // Enrolled
      type: "Enrolled",
      value: 400,
    },
    {
      // completed
      type: "Completed",
      value: 310,
    },
  ];

  const config = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Pending",
      // value: 10,
      value: 25,
    },
    {
      type: "Unapproved",
      // value: 5,
      value: 10,
    },
    {
      type: "Offered",
      // value: 5,
      value: 53,
    },
  ];
  const config2 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Active",
      // value: 10,
      value: 27,
    },
    {
      type: "Inactive",
      // value: 5,
      value: 12,
    },
  ];

  const config3 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "New Application",
      // value: 10,
      value: 5,
    },
    {
      type: "Active",
      // value: 5,
      value: 23,
    },
    {
      type: "Provisional",
      // value: 5,
      value: 9,
    },
  ];
  const config5 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  return (
    <SiderDemo dashboard={true}>
      <>
        <div
          className="m-6"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "0.3rem",
            }}
          >
            <Link to={"/student"}>
              <DashboardHeader
                title={"STUDENTS"}
                end={1027}
                dashboardHeaderIcon={<BiCube className="dashboardIconStyle" />}
              />
            </Link>
            <Link to={"/offers"}>
              <DashboardHeader
                title={"APPLICANTS"}
                end={87}
                dashboardHeaderIcon={
                  <IoLayersOutline className="dashboardIconStyle" />
                }
              />
            </Link>

            <Link to={"/staff"}>
              <DashboardHeader
                title={"STAFFS"}
                end={39}
                dashboardHeaderIcon={<BsTags className="dashboardIconStyle" />}
              />
            </Link>
            <Link to={"/user/manage-account"}>
              <DashboardHeader
                title={"USERS"}
                end={53}
                dashboardHeaderIcon={
                  <AiOutlineUser className="dashboardIconStyle" />
                }
              />
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "0.3rem",
            }}
          >
            <DashboardComponentAnalysis
              title={"STUDENT ANALYSIS"}
              subtitle1Count={87}
              subtitle2Count={230}
              subtitle3Count={400}
              subtitle4Count={310}
              subtitle1={"NEW APPLICATION"}
              subtitle2={"OFFERED"}
              subtitle3={"ENROLLED"}
              subtitle4={"COMPLETED"}
              graph={<Pie {...config} />}
            />
            <DashboardComponentAnalysis
              title={"APPLICANT ANALYSIS"}
              subtitle1Count={25}
              subtitle2Count={10}
              subtitle3Count={53}
              subtitle1={"PENDING"}
              subtitle2={"UNAPPROVED"}
              subtitle3={"OFFERED"}
              graph={<Pie {...config2} />}
            />
            <DashboardComponentAnalysis
              title={"STAFF ANALYSIS"}
              subtitle1Count={27}
              subtitle2Count={12}
              subtitle1={"ACTIVE"}
              subtitle2={"INACTIVE"}
              graph={<Pie {...config3} />}
            />

            <DashboardComponentAnalysis
              title={"AGENT ANALYSIS"}
              subtitle1Count={5}
              subtitle2Count={23}
              subtitle3Count={9}
              subtitle1={"NEW APPLICATION"}
              subtitle2={"ACTIVE"}
              subtitle3={"PROVISIONAL"}
              graph={<Pie {...config5} />}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "0.3rem",
            }}
          >
            <DashboardComponentList
              title={"LATEST STUDENTS"}
              isAppointment={true}
              appointmentList={[]}
              myAppointmentList={[]}
              totalMyCounselling={0}
            />
            <DashboardComponentList
              title={"LATEST APPLICANTS"}
              contactList={[]}
            />
            <DashboardComponentList
              title={"LATEST COURSES"}
              caseInProgressList={[]}
              caseNotAssignedList={[]}
              caseCompletedList={[]}
              isCase={true}
            />
            <DashboardComponentList title={"LATEST USERS"} clientList={[]} />
            <DashboardComponentList title={"LATEST STAFFS"} leadList={[]} />

            <DashboardComponentList title={"LATEST AGENTS"} taskAssigned={[]} />
          </div>
        </div>
      </>
    </SiderDemo>
  );
};

export default Dashboard;
