import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import { Input } from "antd";
import Offers from "../Offers";
const { Search } = Input;

const ApplyOnlineByAdmin = () => {
  return (
    <Offers tab={"/apply-online-by-admin"}>
      <ComponentBody>Apply Online by admin</ComponentBody>
    </Offers>
  );
};

export default ApplyOnlineByAdmin;
