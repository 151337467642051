import React, { useState } from "react";
import { Button, Checkbox, Drawer, Form, Input, Select, Steps } from "antd";
import ApplicantPersonalInformation from "./ApplicantPersonalInformation";
import ApplicantCurrentStreetAddress from "./ApplicantCurrentStreetAddress";
import ApplicantSpecificInformation from "./ApplicantSpecificInformation";
import ApplicantCourseInformation from "./ApplicantCourseInformation";
import ApplicantServicesInformation from "./ApplicantServicesInformation";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddApplicants = ({
  isAddApplicantsModalVisible,
  setIsAddApplicantsModalVisible,
  setIsChooseCourseApplicationModalVisible,
  record,
}) => {
  //   const dispatch = useDispatch();
  const [applicantData, setApplicantData] = useState({
    areYou: "OVERSEAS_STUDENT_OFFSHORE",
    title: record?.title,
    firstName: record?.firstName,
    middleName: record?.middleName,
    lastName: record?.lastName,
    preferredName: record?.preferredName,
    gender: record?.gender,
    dateOfBirth: record?.dateOfBirth,
    email: record?.email,
    beforeEachirthplace: record?.beforeEachirthplace,
    countryOfBirth: record?.countryOfBirth,
    nationality: record?.nationality,
    passportNo: record?.passportNo,
    expiryDate: record?.expiryDate,
    visaStatus: record?.visaStatus,
    visaNumber: record?.visaNumber,
    visaExpiryDate: record?.visaExpiryDate,
    residencyStatus: record?.residencyStatus,
    haveOverseasPermanentAddress: false,
    havePostalAddress: false,
    currentAddress: {
      country: null,
      buildingPropertyName: null,
      flatUnitDetails: null,
      streetNumber: null,
      streetName: null,
      suburb: null,
      state: null,
      state: null,
      postcode: null,
      homePhone: null,
      workPhone: null,
      mobilePhone: null,
    },
    postalAddress: {
      country: null,
      buildingPropertyName: null,
      flatUnitDetails: null,
      streetNumber: null,
      postalDeliveryInformation: null,
      streetName: null,
      suburb: null,
      state: null,
      state: null,
      postcode: null,
      homePhone: null,
      workPhone: null,
      mobilePhone: null,
    },
    overseasPermanentAddress: {
      country: null,
      buildingPropertyName: null,
      flatUnitDetails: null,
      streetNumber: null,
      streetName: null,
      suburb: null,
      state: null,
      state: null,
      postcode: null,
      homePhone: null,
      workPhone: null,
      mobilePhone: null,
    },
    isAustralianAboriginalOrStraitIslander: "NO",
    isEnglishMainLanguage: false,
    languageSpokenAtHome: null,
    wasPreviousLanguageOfInstruction: true,
    haveCompletedEnglishLanguageProficiencyTest: false,
    testType: null,
    when: null,
    Listening: null,
    reading: null,
    writing: null,
    speaking: null,
    overall: null,
    highestCompletedSchoolLevel: null,
    areStillInSecondarySchool: false,
    wouldAddPreviousQualifications: false,
    highestCompletedSchoolLevel: null,
    wouldAddEmploymentHistory: false,
    aboutUs: "PRESS_AND_PRINT_MEDIA",
    agentName: null,
    aboutUsSpecify: null,
    agentMailingAddress: null,
    agentCountry: null,
    service: null,
    wouldLikeToArrangeOshc: true,
    OSHCProvider: null,
    oshcType: null,
    durationInMonths: null,
    appliedOshcFee: null,
    oshcStartDate: null,
    haveDisability: "NO",
    wouldReceiveAdviceOnSupportServices: false,
    emergencyContactName: null,
    emergencyRelationship: null,
    emergancyPersonAddress: null,
    emergencyPhone: null,
    emergencyEmail: null,
    accountManager: null,
    admissionStaff: null,
    agreeToTermsAndConditions: false,
  });
  const [applicantCourseData, setApplicantCourseData] = useState({
    agent: null,
    agentContact: null,
    intake: null,
    numberOfWeeks: null,
    course: null,
    startDate: null,
    courseActualFee: null,
    enrollmentFee: null,
    upfrontFee: null,
    materialFee: null,
    status: "NEW_APPLICATION_REQUEST",
    applicationRequest: null,
    areApplyingForAdvancedStanding: false,
    applyingForAdvancedStandingSpecify: null,
    specialCondition: null,
    holidayStartDate: null,
    holidayEndDate: null,
    studentOrientationDate: null,
    totalHolidayWeeks: null,
  });

  const [qualification, setQualification] = useState([
    {
      qualificationLevel: "",
      qualificationName: "",
      institution: "",
      country: "",
      yearsOfCompletion: "",
    },
  ]);
  const [employment, setEmployment] = useState([
    {
      employer: "",
      occupation: "",
      durationFrom: "",
      durationTo: "",
      duties: "",
    },
  ]);
  const [disability, setDisability] = useState([
    {
      disability: "Hearing/Deaf",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Physical",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Intellectual",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Learning",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Mental Illness",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Acquired Brain Impairment",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Vision",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Medical Condition",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Neurological condition",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Other",
      fromDate: "",
      toDate: "",
    },
    {
      disability: "Not Specified",
      fromDate: "",
      toDate: "",
    },
  ]);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);

  const addApplicatnHandler = () => {
    // dispatch({ type: "ADD_APPLICANT_REQUEST", payload: { ...value } });
    form.resetFields();
    setIsAddApplicantsModalVisible(false);
    console.log("Will be added when api is provided");
  };
  const forms = [
    <ApplicantPersonalInformation
      applicantData={applicantData}
      setApplicantData={setApplicantData}
      current={current}
      setCurrent={setCurrent}
    />,
    <ApplicantCurrentStreetAddress
      applicantData={applicantData}
      setApplicantData={setApplicantData}
      current={current}
      setCurrent={setCurrent}
    />,
    <ApplicantSpecificInformation
      applicantData={applicantData}
      setApplicantData={setApplicantData}
      current={current}
      setCurrent={setCurrent}
      qualification={qualification}
      setQualification={setQualification}
      employment={employment}
      setEmployment={setEmployment}
    />,
    <ApplicantCourseInformation
      applicantCourseData={applicantCourseData}
      setApplicantCourseData={setApplicantCourseData}
      current={current}
      setCurrent={setCurrent}
    />,
    <ApplicantServicesInformation
      applicantData={applicantData}
      setApplicantData={setApplicantData}
      current={current}
      setCurrent={setCurrent}
      disability={disability}
      setDisability={setDisability}
    />,
  ];

  console.log("ApplicantData", applicantData);
  console.log("applicantCourseData", applicantCourseData);
  console.log("qualification", qualification);
  console.log("employment", employment);
  return (
    <Drawer
      title={
        <div className="fxRJsb">
          <span>Student Online Application </span>
          <h3>Application reference ID: 1360401983</h3>
          <ButtonElement
            name="Save and Exit"
            handleClick={() => {
              setIsAddApplicantsModalVisible(false);
            }}
          />
        </div>
      }
      open={isAddApplicantsModalVisible}
      onClose={() => {
        setIsAddApplicantsModalVisible(false);
        setIsChooseCourseApplicationModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Personal Information",
            },
            {
              title: "Address Information",
            },
            {
              title: "Specific Information",
            },
            {
              title: "Course Information",
            },
            {
              title: "Services Information",
            },
          ]}
        />
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default AddApplicants;
