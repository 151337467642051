import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddOhscProviderName = ({
  isAddProviderModalVisible,
  setIsAddProviderModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddOhscProviderNameSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddOhscProviderName_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddProviderModalVisible(false);
  };
  return (
    <Modal
      title="Provider Name"
      open={isAddProviderModalVisible}
      onCancel={() => {
        setIsAddProviderModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddOhscProviderNameSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Provider Name"
          name={"providerName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a provider name!",
            },
          ]}
        >
          <Input placeholder="Provider Name" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name={"Add Provider Name"}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOhscProviderName;
