import React from "react";
import {
  ButtonElement,
  InputFieldElement,
  ModalElement,
  SelectField,
} from "../../../../components/input";
import { Form } from "antd";
import InputNumberField from "../../../../components/inputNumberField/InputNumberField";

export default function AddManageRoom() {
  const venueList = [
    {
      label: "Venue 1",
      value: 1,
    },
    {
      label: "Venue 2",
      value: 2,
    },
  ];
  return (
    <ModalElement title="Add Room" keyType="addRoom">
      <Form layout="vertical" className="flex flex-col gap-4">
        <InputNumberField
          name="room_id"
          label="Room Id"
          placeholder="Room Id"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="room_name"
          label="Room Name"
          placeholder="Room Room"
          fieldWidth="100%"
        />
        <SelectField
          name="venue"
          label="Training location/venue"
          placeholder="Seelect Venue"
          options={venueList}
          fieldWidth="100%"
        />

        <InputNumberField
          name="capacity"
          label="Max Capacity"
          placeholder="Seat number"
          fieldWidth="100%"
        />
        <ButtonElement name="Add Room " bottonWidth="100% " />
      </Form>
    </ModalElement>
  );
}
