import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment/moment";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;

const UpdateEvent = ({
  isUpdateEventModalVisible,
  setIsUpdateEventModalVisible,
}) => {
  const [eventData, setEventData] = useState({ recurringInterval: "WEEKLY" });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const UpdateEventSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsUpdateEventModalVisible(false);
  };
  return (
    <Drawer
      title="Update Event"
      open={isUpdateEventModalVisible}
      onClose={() => {
        setIsUpdateEventModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateEventSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Posted Date" style={{ width: "100%" }}>
            {moment.utc().local().format("dddd, DD MMMM YYYY")}
          </Form.Item>
          <Form.Item
            label="Event Type"
            name={"eventType"}
            style={{ width: "100%" }}
          >
            <Radio.Group defaultValue={"NEWS_AND_EVENTS"}>
              <Radio value={"NEWS_AND_EVENTS"}>News & Events</Radio>
              <Radio value={"REMINDER"}>Reminder</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Heading"
            name={"heading"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a heading!",
              },
            ]}
          >
            <Input placeholder="Heading" />
          </Form.Item>
        </div>

        <Form.Item
          label="Body"
          name={"body"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter some comments!",
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            style={{ height: "250px", marginBottom: "4rem" }}
          />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Display Period From"
            name={"displayPeriodFrom"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a display period from date!",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Display Period To"
            name={"displayPeriodTo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a display period to date!",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Recurring Interval"
            name={"recurringInterval"}
            style={{ width: "100%" }}
          >
            <Select
              defaultValue={"WEEKLY"}
              onChange={(e) => {
                setEventData((prevData) => {
                  return {
                    recurringInterval: e,
                  };
                });
              }}
            >
              <Option value={"ONCE_ONLY"}>Once Only</Option>
              <Option value={"WEEKLY"}>Weekly</Option>
              <Option value={"FORTNIGHTLY"}>Fortnightly</Option>
              <Option value={"MONTHLY"}>Monthly</Option>
            </Select>
          </Form.Item>

          {(eventData.recurringInterval === "WEEKLY" ||
            eventData.recurringInterval === "FORTNIGHTLY") && (
            <Form.Item
              label="Recurring Day"
              name={"recurringDay"}
              style={{ width: "100%" }}
            >
              <Select>
                <Option value="MONDAY">Monday</Option>
                <Option value="TUESDAY">Tuesday</Option>
                <Option value="WEDNESDAY">Wednesday</Option>
                <Option value="THURSDAY">Thrusday</Option>
                <Option value="FRIDAY">Friday</Option>
                <Option value="SATURDAY">Saturday</Option>
                <Option value="SUNDAY">Sunday</Option>
              </Select>
            </Form.Item>
          )}
          {eventData.recurringInterval === "MONTHLY" && (
            <Form.Item
              label="Recurring Date"
              name={"recurringDate"}
              style={{ width: "100%" }}
            >
              <Select>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
                <Option value="11">11</Option>
                <Option value="12">12</Option>
                <Option value="13">13</Option>
                <Option value="14">14</Option>
                <Option value="15">15</Option>
                <Option value="16">16</Option>
                <Option value="17">17</Option>
                <Option value="18">18</Option>
                <Option value="19">19</Option>
                <Option value="20">20</Option>
                <Option value="21">21</Option>
                <Option value="22">22</Option>
                <Option value="23">23</Option>
                <Option value="24">24</Option>
                <Option value="25">25</Option>
                <Option value="26">26</Option>
                <Option value="27">27</Option>
                <Option value="28">28</Option>
                <Option value="29">29</Option>
                <Option value="30">30</Option>
                <Option value="31">31</Option>
              </Select>
            </Form.Item>
          )}
        </div>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Event"
          >
            Update Event
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateEvent;
