import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddCoeForOffer = ({
  isAddCoeForOfferModalVisible,
  setIsAddCoeForOfferModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddCoeForOfferSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_PROVIDER_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddCoeForOfferModalVisible(false);
  };
  return (
    <Modal
      title="Add CoE Number to application"
      open={isAddCoeForOfferModalVisible}
      onCancel={() => {
        setIsAddCoeForOfferModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddCoeForOfferSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Application" style={{ width: "100%" }}>
            19
          </Form.Item>
          <Form.Item label="Course" style={{ width: "100%" }}>
            GE (Attempt: 1)
          </Form.Item>
        </div>
        <Form.Item name={"isCoeApplicable"} style={{ width: "100%" }}>
          <Checkbox>Check if CoE Not Applicable</Checkbox>
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="CoE No"
            name={"provider"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a CoE number!",
              },
            ]}
          >
            <Input placeholder="CoE No" />
          </Form.Item>
          <Form.Item
            label="Select CoE"
            name={"selectCoe"}
            style={{ width: "100%" }}
          >
            <Input type="file" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add CoE Number to application"
          >
            Add CoE Number to application
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCoeForOffer;
