import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profile from "../../Assets/logo/client.png";
import { Divider, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import ProfileTabs from "./ProfileTabs";
import { ButtonElement, SiderDemo } from "../../components/input";
import { formattedDate } from "../../Helpers/HelperFunction";
import EditUserProfile from "./EditUserProfile";
const { Paragraph } = Typography;

const Profile = ({ children }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
    };
  });
  const profileRef = useRef();

  const handleUpdateUserProfile = (value) => {
    dispatch({ type: "FETCH_OPEN_DRAWER", payload: value });
  };

  return (
    <SiderDemo organizationalSettings={true}>
      <div className="flex flex-col md:grid md:grid-cols-3 gap-2 md:m-4 h-[94%]">
        <div className="bg-white p-2 md:p-4">
          <div className="fxRAc">
            <div
              className="fxRAc"
              style={{
                margin: "1rem",
              }}
            >
              <div className="flex flex-row justify-center items-center relative">
                <img
                  className="w-[10rem] rounded-[100%] user--profile"
                  src={
                    store.loginState?.profile?.profileImage
                      ? store.loginState?.profile?.profileImage
                      : profile
                  }
                  alt="profile image"
                />
                <button
                  onClick={() => profileRef.current.click()}
                  className="upload--button m-auto px-2 py-1 border border border-[#ff4d4f] rounded-[1rem] text-[#ff4d4f]"
                >
                  Upload Profile
                </button>
                <input ref={profileRef} type="file" className="hidden" />
              </div>
            </div>
          </div>
          <div className="fxRAc" style={{}}>
            <h3 style={{ fontSize: "16px", fontWeight: "400" }}>Saroj Limbu</h3>
          </div>
          <Divider orientationMargin="0" className="my-4" />

          <div className="flex flex-row gap-4 justify-between">
            <h4>PERSONAL INFORMATION:</h4>
            <ButtonElement
              handleClick={() => handleUpdateUserProfile("updateUserProfile")}
              size="small"
              name="Edit User Profile"
            />
          </div>

          <div>
            <div className="profileTitle">Email:</div>
            <div>
              <Paragraph ellipsis={true}>
                {store.loginState?.profile.emailId
                  ? store.loginState?.profile.emailId
                  : "N/A"}
              </Paragraph>
            </div>
          </div>
          <div>
            <div className="profileTitle">Registration Number:</div>
            <div>
              {store.loginState?.profile.companyRegistrationNumber
                ? store.loginState?.profile.companyRegistrationNumber
                : "N/A"}
            </div>
          </div>
          <div>
            <div className="profileTitle">Subscription Type:</div>
            <div>{store.loginState?.profile.subscriptionName ?? "N/A"}</div>
          </div>
          <div>
            <div className="profileTitle">Subscription Ends in:</div>
            <div>
              {store.loginState?.profile.subscriptionEndDate
                ? formattedDate(store.loginState?.profile.subscriptionEndDate)
                : "N/A"}
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <ProfileTabs />
        </div>
      </div>
      <EditUserProfile />
    </SiderDemo>
  );
};

export default Profile;
