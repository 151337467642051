import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./Helpers/PrivateRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Suspense } from "react";
import ViewTimetableLayout from "./layout/ViewTimetableLayout";

import {
  Staff,
  ManageUserAccount,
  UserIpActivityTrack,
  BulkCreateStudentUserAccount,
  BulkStudentUserAccountManagement,
  ManageClassTimetable,
  ManageAssessmentGroup,
  // TimetableReport,
  ViewTimetable,
  // ClassCapacityReport,
  ReplacementTeacher,
  // PrintAttendanceList,
  Subject,
  ViewCourses,
  CourseSubject,
  CourseTemplate,
  CourseUpfrontFee,
  CoursePromotion,
  IntakeDates,
  PublicHoliday,
  AddClassAttendance,
  AttendanceSummary,
  StudentAttendanceSummery,
  BulkAttendance,
  SetupAssessmentTask,
  TaskEntry,
  TaskResultEntry,
  TransferResult,
  TransferResultByUnit,
  ElicosBulkResultEntry,
  Timetable,
  ClassCapacityReport,
  TimetableReport,
  PrintAttendanceList,
  InterventionPage,
  IntakeGroup,
  ManageOrientation,
  ValidatePrisms,
  ServiceProviderAllocation,
  GenerateBulkCertificate,
  UpdateFinalOutcome,
  UpdateStudentCourseStatus,
  UpdateStudentCourse,
  UpdateStudentCourseTemplate,
  Agents,
  GenerateReport,
  EmailTempleteSetup,
  MfAuthentication,
  ProfilePage,
  ComplianceSetting,
  MarketingSetting,
  EmailSetting,
  ReportSetting,
  CalanderSetting,
  MailBoxPage,
  LeaveInfo,
  Avetmiss,
  CqrReport,
} from "./pages";
import ViewTeacher from "./pages/Teacher/ViewTeacher/ViewTeacher";
import TeacherMatrix from "./pages/Teacher/TeacherMatrix/TeacherMatrix";
import ViewApplicantList from "./pages/Offers/ViewApplicantList/ViewApplicantList";
import ApplyOnlineByAdmin from "./pages/Offers/ApplyOnlineByAdmin/ApplyOnlineByAdmin";
import ApplyShortCourse from "./pages/Offers/ApplyShortCourse/ApplyShortCourse";
import GenerateInvoice from "./pages/Offers/GenerateInvoice/GenerateInvoice";
import OfferMailingList from "./pages/Offers/OfferMailingList/OfferMailingList";
import Student from "./pages/Student/Student";
import MailingList from "./pages/Communication/MailingList/MailingList";
import ManageNewsAndReminder from "./pages/Communication/ManageNewsAndReminder/ManageNewsAndReminder";
import CommunicationLog from "./pages/Communication/CommunicationLog/CommunicationLog";
// import Agents from "./pages/Agnets/Agents";
import BulkUpdateCommission from "./pages/Accounts/BulkUpdateCommission/BulkUpdateCommission";
import BulkAgentPayment from "./pages/Accounts/BulkAgentPayment/BulkAgentPayment";
import BankReconciliation from "./pages/Accounts/BankReconciliation/BankReconciliation";
import AccountsSetUp from "./pages/Accounts/AccountsSetUp/AccountsSetUp";
import StudentPayment from "./pages/Accounts/StudentPayment/StudentPayment";
import AgentPayment from "./pages/Accounts/AgentPayment/AgentPayment";
import AccountsGenerateInvoice from "./pages/Accounts/AccountsGenerateInvoice/AccountsGenerateInvoice";
import EmployerInvoice from "./pages/Accounts/EmployerInvoice/EmployerInvoice";
import ProviderPayment from "./pages/Accounts/ProviderPayment/ProviderPayment";
import BulkSanction from "./pages/Accounts/BulkSanction/BulkSanction";
import Profile from "./pages/Profile/Profile";
import GeneralSettings from "./pages/Settings/GeneralSettings/GeneralSettings";
import CourseSettings from "./pages/Settings/CourseSettings/CourseSettings";
import OfferSettings from "./pages/Settings/OfferSettings/OfferSettings";
import StudentSettings from "./pages/Settings/StudentSettings/StudentSettings";
import ServiceSettings from "./pages/Settings/ServiceSettings/ServiceSettings";
import EmployerSettings from "./pages/Settings/EmployerSettings/EmployerSettings";
import CollegeMaterials from "./pages/CollegeMaterials/CollegeMaterials";
import { Spin } from "antd";
import CalenderSetting from "./pages/Settings/calenderSetting/CalenderSetting";
import Mailbox from "./pages/Mailbox/Mailbox";
import Support from "./pages/Support/Support";

const App = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Spin className="flex flex-Row justify-center align-center h-full" />
        }
      >
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/staff" element={<Staff />} />
          {/* USER MANAGEMENT PAGE */}
          <Route path="/user/manage-account" element={<ManageUserAccount />} />
          <Route
            path="/user/bulk-student-account-management"
            element={<BulkStudentUserAccountManagement />}
          />
          <Route
            path="/user/create-bulk-student-account"
            element={<BulkCreateStudentUserAccount />}
          />
          <Route
            path="/user/ip-activity-track"
            element={<UserIpActivityTrack />}
          />
          {/* profile */}
          <Route path="/profile" element={<ProfilePage />} />
          {/* COURSE PAGE */}
          <Route path="/courses" element={<ViewCourses />} />
          <Route path="/subject" element={<Subject />} />
          <Route path="/course-subject" element={<CourseSubject />} />
          <Route path="/course-template" element={<CourseTemplate />} />
          <Route path="/course-upfront-fee" element={<CourseUpfrontFee />} />
          <Route path="/course-promotion" element={<CoursePromotion />} />
          <Route path="/intake-dates" element={<IntakeDates />} />
          <Route path="/public-holiday" element={<PublicHoliday />} />
          {/* Timetable Page */}
          <Route
            path="/manage-class-timetable"
            element={<ManageClassTimetable />}
          />
          <Route
            path="/manage-assessment-group"
            element={<ManageAssessmentGroup />}
          />
          <Route path="/view-timetable" element={<ViewTimetable />} />

          <Route path="/replacement-teacher" element={<ReplacementTeacher />} />

          {/* Compliance page */}
          <Route
            path="/compliance/attendance-management/add-class-attendance"
            element={<AddClassAttendance />}
          />
          <Route
            path="/compliance/attendance-management/attendance-summary"
            element={<AttendanceSummary />}
          />
          <Route
            path="/compliance/attendance-management/bulk-attendance"
            element={<BulkAttendance />}
          />
          <Route
            path="/compliance/attendance-management/attendance-history/:studentId"
            element={<StudentAttendanceSummery />}
          />
          <Route
            path="/compliance/academic-progress/setup-assessment-task"
            element={<SetupAssessmentTask />}
          />
          <Route
            path="/compliance/academic-progress/task-entry"
            element={<TaskEntry />}
          />
          <Route
            path="/compliance/academic-progress/task-result-entry"
            element={<TaskResultEntry />}
          />
          <Route
            path="/compliance/academic-progress/transfer-result"
            element={<TransferResult />}
          />
          <Route
            path="/compliance/academic-progress/transfer-result-by-unit"
            element={<TransferResultByUnit />}
          />
          <Route
            path="/compliance/academic-progress/elicos-bulk-result-entry"
            element={<ElicosBulkResultEntry />}
          />
          {/* compliance nested route */}
          <Route path="/compliance" element={<ViewTimetableLayout />}>
            <Route path="view-timetable" element={<Timetable />} />
            <Route
              path="class-capacity-report"
              element={<ClassCapacityReport />}
            />
            <Route path="timetable-report" element={<TimetableReport />} />
            <Route
              path="print-attendance-list"
              element={<PrintAttendanceList />}
            />
            {/* intervention */}
            <Route path="intervention" element={<InterventionPage />} />
            {/* group management */}
            <Route path="intake-group" element={<IntakeGroup />} />
            {/* manage orientation */}
            <Route path="manage-orientation" element={<ManageOrientation />} />
            {/* validate prisms */}
            <Route path="validate-prisms" element={<ValidatePrisms />} />
            {/* serviceProviderAllocation */}
            <Route
              path="service-provider-allocation"
              element={<ServiceProviderAllocation />}
            />
            {/* generate bulk certificates */}
            <Route
              path="generate-bulk-certificate"
              element={<GenerateBulkCertificate />}
            />
            {/* bulk update */}
            <Route
              path="update-final-outcome"
              element={<UpdateFinalOutcome />}
            />
            <Route
              path="/compliance/update-student-course-status"
              element={<UpdateStudentCourseStatus />}
            />
            <Route
              path="/compliance/update-student-course"
              element={<UpdateStudentCourse />}
            />
            <Route
              path="/compliance/update-student-course-template"
              element={<UpdateStudentCourseTemplate />}
            />
          </Route>

          {/* mf authentication */}
          <Route
            path="/setting/mf-authentication"
            element={<MfAuthentication />}
          />

          {/* reports */}
          <Route path="/report" element={<ViewTimetableLayout />}>
            <Route path="generate-report" element={<GenerateReport />} />
            <Route
              path="email-template-setup"
              element={<EmailTempleteSetup />}
            />
            <Route path="avetmiss" element={<Avetmiss />} />
            <Route path="tac-cqr" element={<CqrReport />} />
          </Route>

          {/* marketing */}
          <Route path="/marketing/agents" element={<Agents />} />

          {/* TEACHER PAGE */}
          <Route path="/manage-teacher" element={<ViewTeacher />} />
          <Route path="/teacher-matrix" element={<TeacherMatrix />} />

          {/* OFFER PAGE */}
          <Route path="/offers" element={<ViewApplicantList />} />
          <Route
            path="/apply-online-by-admin"
            element={<ApplyOnlineByAdmin />}
          />
          <Route path="/apply-short-course" element={<ApplyShortCourse />} />
          <Route path="/generate-invoice" element={<GenerateInvoice />} />
          <Route path="/offer-mailing-list" element={<OfferMailingList />} />

          {/* STUDENT PAGE */}
          <Route path="/student" element={<Student />} />

          {/* COMMUNICATION */}
          <Route path="/mailing-list" element={<MailingList />} />
          <Route
            path="/manage-news-and-reminder"
            element={<ManageNewsAndReminder />}
          />
          <Route path="/communication-log" element={<CommunicationLog />} />
          {/* AGNETS */}
          {/* <Route path="/agents" element={<Agents />} /> */}
          {/* ACCOUNTS */}
          <Route path="/student-payment" element={<StudentPayment />} />
          <Route path="/agent-payment" element={<AgentPayment />} />
          <Route
            path="/bulk-update-commission"
            element={<BulkUpdateCommission />}
          />
          <Route path="/bulk-agent-payment" element={<BulkAgentPayment />} />
          <Route path="/bank-reconciliation" element={<BankReconciliation />} />
          <Route path="/accounts-set-up" element={<AccountsSetUp />} />
          <Route
            path="/accounts-generate-invoice"
            element={<AccountsGenerateInvoice />}
          />
          <Route
            path="/generate-employer-invoice"
            element={<EmployerInvoice />}
          />
          <Route path="/provider-payment" element={<ProviderPayment />} />
          <Route path="/bulk-sanction" element={<BulkSanction />} />

          {/* MAILBOX */}
          {/* <Route path="/mailbox" element={<Mailbox />} /> */}

          {/* PROFILE */}
          <Route path="/profile" element={<Profile />} />

          {/* ORGANIZATION SETTINGS */}
          <Route
            path="/organizational-settings/general-settings"
            element={<GeneralSettings />}
          />
          <Route
            path="/organizational-settings/course-settings"
            element={<CourseSettings />}
          />
          <Route
            path="/organizational-settings/offer-settings"
            element={<OfferSettings />}
          />
          <Route
            path="/organizational-settings/student-settings"
            element={<StudentSettings />}
          />
          <Route
            path="/organizational-settings/service-settings"
            element={<ServiceSettings />}
          />
          <Route
            path="/organizational-settings/employer-settings"
            element={<EmployerSettings />}
          />

          {/* settings */}
          <Route
            path="/organizationalSettings"
            element={<ViewTimetableLayout />}
          >
            <Route path="compliance-settings" element={<ComplianceSetting />} />
            <Route path="marketing-settings" element={<MarketingSetting />} />
            <Route path="email-settings" element={<EmailSetting />} />
            <Route path="report-settings" element={<ReportSetting />} />
            <Route path="calender-settings" element={<CalenderSetting />} />
          </Route>
          <Route path="/mail-box" element={<MailBoxPage />} />
          <Route path="/leave-info" element={<LeaveInfo />} />
          {/* COLLEGE MATERIALS */}
          <Route path="/college-materials" element={<CollegeMaterials />} />
          {/* MAILBOX */}
          <Route path="mailbox" element={<MailBoxPage />} />
          {/* SUPPORT */}
          <Route path="/support" element={<Support />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
