import {
  Card,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../../components/input";
const { Option } = Select;

const selectCourse = [
  {
    label: "All Courses",
    value: "ALL",
  },
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const EnglishTest = () => {
  const [englishTestData, setEnglishTestData] = useState({
    isExemptFromLanguageProficiancy: false,
    hasStudentTakenEnglishTest: false,
    isOtherMeansOfTesting: false,
    otherFormOfTesting: "SELECT",
    englishTestTypeForMigrationEnglish: "SELECT",
  });
  const [form] = Form.useForm();
  const addTeacherMatrixSubmitHandler = () => [];

  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>English Language Testing and Exemptions</Pagename>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Courses:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Form
          onFinish={addTeacherMatrixSubmitHandler}
          fields={[
            {
              name: ["isExemptFromLanguageProficiancy"],
              value: englishTestData.isExemptFromLanguageProficiancy,
            },
            {
              name: ["hasStudentTakenEnglishTest"],
              value: englishTestData.hasStudentTakenEnglishTest,
            },
            {
              name: ["isOtherMeansOfTesting"],
              value: englishTestData.isOtherMeansOfTesting,
            },
          ]}
          colon={true}
          form={form}
          className="drawerStyle"
          labelCol={{ span: 11 }}
        >
          <Form.Item
            label={
              <div style={{ whiteSpace: "pre-wrap" }}>
                Is the student exempt from providing evidence of English
                language proficiency?
              </div>
            }
            name={"isExemptFromLanguageProficiancy"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a exemption reason!",
              },
            ]}
          >
            <Radio.Group
              value={englishTestData.isExemptFromLanguageProficiancy}
              onChange={(e) => {
                setEnglishTestData((prevData) => {
                  return {
                    ...prevData,
                    isExemptFromLanguageProficiancy: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {englishTestData.isExemptFromLanguageProficiancy && (
            <Form.Item
              label="Exemption reason"
              name={"exemptionReason"}
              style={{ width: "100%" }}
            >
              <Select placeholder="Exemption Reason">
                <Option>
                  Citizen of, and passport issued by: United Kingdom
                </Option>
                <Option>
                  Citizen of, and passport issued by: United States of America
                </Option>
                <Option>Citizen of, and passport issued by: Canada</Option>
                <Option>Citizen of, and passport issued by: New Zealand</Option>
                <Option>
                  Citizen of, and passport issued by: Republic of Ireland
                </Option>
                <Option>
                  Enrolling, or enrolled, in principal course study: Non-English
                  language course
                </Option>
                <Option>
                  Enrolling, or enrolled, in principal course study: ELICOS
                </Option>
                <Option>
                  Enrolling, or enrolled, in principal course study: Schools
                </Option>
                <Option>
                  Enrolling, or enrolled, in principal course study:
                  Post-graduate
                </Option>
                <Option>Student is a Foreign Affairs student</Option>
                <Option>Student is a Defence student</Option>
                <Option>Student is a Secondary exchange student</Option>
                <Option>
                  Completed (within the past 2 years) in Australia in English:
                  Requirements for senior secondary certificate of education
                </Option>
                <Option>
                  Completed (within the past 2 years) in Australia in English:
                  Substantial component of AQF level 4 or higher on a student
                  visa
                </Option>
                <Option>
                  Minimum 5 years of study in English in: Australia
                </Option>
                <Option>Minimum 5 years of study in English in: Canada</Option>
                <Option>
                  Minimum 5 years of study in English in: New Zealand
                </Option>
                <Option>
                  Minimum 5 years of study in English in: South Africa
                </Option>
                <Option>
                  Minimum 5 years of study in English in: Republic of Ireland
                </Option>
                <Option>
                  Minimum 5 years of study in English in: United Kingdom
                </Option>
                <Option>
                  Minimum 5 years of study in English in: United States of
                  America
                </Option>
              </Select>
            </Form.Item>
          )}

          {!englishTestData.isExemptFromLanguageProficiancy && (
            <>
              <Form.Item
                label="Has the student undertaken an English test?"
                name={"hasStudentTakenEnglishTest"}
                style={{ width: "100%" }}
              >
                <Radio.Group
                  onChange={(e) => {
                    setEnglishTestData((prevData) => {
                      return {
                        ...prevData,
                        hasStudentTakenEnglishTest: e.target.value,
                      };
                    });
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {englishTestData.hasStudentTakenEnglishTest && (
                <>
                  <Form.Item
                    label={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        English Test Type undertaken to meet Migration English
                        requirements
                      </div>
                    }
                    name={"englishTestTypeForMigrationEnglish"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a english test type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Exemption Reason"
                      onChange={(e) => {
                        setEnglishTestData((prevData) => {
                          return {
                            ...prevData,
                            englishTestTypeForMigrationEnglish: e,
                          };
                        });
                      }}
                    >
                      <Option value="TOEFL">
                        Test of English as a Foreign Language internet-based
                        test (TOEFL iBT)
                      </Option>
                      <Option value="IELTS">
                        International English Language Testing System (IELTS)
                      </Option>
                      <Option value="C1_ADVANCED">
                        C1 Advanced (Formerly Cambridge English: Advanced (CAE))
                      </Option>
                      <Option value="PTE">
                        Pearson Test of English (PTE) Academic
                      </Option>
                      <Option value="OCCUPATIONAL_ENGLISH_TEST">
                        Occupational English Test
                      </Option>
                    </Select>
                  </Form.Item>
                  {englishTestData.englishTestTypeForMigrationEnglish ===
                    "TOEFL" && (
                    <Form.Item
                      label="Total Score:"
                      name={"totalScore"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a total score!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter score between 0 and 120"
                      />
                    </Form.Item>
                  )}
                  {englishTestData.englishTestTypeForMigrationEnglish ===
                    "IELTS" && (
                    <Form.Item
                      label="Overall Band Score:"
                      name={"overallBandScore"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a overall band score!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter score between 0 and 9"
                      />
                    </Form.Item>
                  )}
                  {englishTestData.englishTestTypeForMigrationEnglish ===
                    "C1_ADVANCED" && (
                    <Form.Item
                      label="Cambridge English Scale Score:"
                      name={"cambridgeEnglishScaleScore"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter a cambridge english scale score!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter score between 142 and 210"
                      />
                    </Form.Item>
                  )}
                  {englishTestData.englishTestTypeForMigrationEnglish ===
                    "PTE" && (
                    <Form.Item
                      label="Overall Score:"
                      name={"pteOverallScore"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a overall score!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter score between 10 and 90"
                      />
                    </Form.Item>
                  )}
                  {englishTestData.englishTestTypeForMigrationEnglish ===
                    "OCCUPATIONAL_ENGLISH_TEST" && (
                    <>
                      <Form.Item
                        label="Listening Score"
                        name={"listeningScore"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a listening score!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter score between 0 and 500"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Reading Score"
                        name={"readingScore"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a reading score!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter score between 0 and 500"
                        />
                      </Form.Item>
                      <Form.Item
                        label="writing Score"
                        name={"WritingScore"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a writing score!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter score between 0 and 500"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Speaking Score"
                        name={"speakingScore"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a speaking score!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter score between 0 and 500"
                        />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    label="Test date:"
                    name={"testDate"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please pick a test date!",
                      },
                    ]}
                  >
                    <DatePicker format="DD-MM-YYYY" />
                  </Form.Item>
                </>
              )}

              {!englishTestData.hasStudentTakenEnglishTest && (
                <>
                  <Form.Item
                    label={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        The institution used, or will use, other means of
                        testing to ensure the student has, or will have, met the
                        English language requirements for the course
                      </div>
                    }
                    name={"isOtherMeansOfTesting"}
                    style={{ width: "100%" }}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setEnglishTestData((prevData) => {
                          return {
                            ...prevData,
                            isOtherMeansOfTesting: e.target.value,
                          };
                        });
                      }}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {englishTestData.isOtherMeansOfTesting && (
                    <>
                      <Form.Item
                        label="Other form of testing"
                        name={"otherFormOfTesting"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select other form of testing!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Other Forms of Testing"
                          onChange={(e) => {
                            setEnglishTestData((prevData) => {
                              return {
                                ...prevData,
                                otherFormOfTesting: e,
                              };
                            });
                          }}
                        >
                          <Option value="INTERVIEW">Interview</Option>
                          <Option value="GCE_A_LEVELS">GCE A-levels</Option>
                          <Option value="INTERNATIONAL_BACCALAUREATE">
                            International Baccalaureate
                          </Option>
                          <Option value="ENGLISH_TEST">English Test</Option>
                          <Option value="PATHWAY_WITH_ELICOS">
                            Pathway with ELICOS
                          </Option>
                          <Option value="PATHWAY_WITHOUT_ELICOS">
                            Pathway without ELICOS
                          </Option>
                          <Option value="CONTINUING_STUDENT">
                            {" "}
                            Continuing Student
                          </Option>
                          <Option value="MEDIUM_OF_INSTRUCTION">
                            Medium of Instruction
                          </Option>
                          <Option value="LANGUAGE_OF_INSTRUCTION">
                            Language of Instruction
                          </Option>
                          <Option value="OTHERS">Other</Option>
                        </Select>
                      </Form.Item>
                      {(englishTestData.otherFormOfTesting === "ENGLISH_TEST" ||
                        englishTestData.otherFormOfTesting === "OTHERS") && (
                        <Form.Item
                          label="Other form of testing comments"
                          name={"otherFormOfTestingComments"}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter other form of testing comments!",
                            },
                          ]}
                        >
                          <Input placeholder="Other form of testing comments" />
                        </Form.Item>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          <Form.Item>
            <ButtonElement
              block={true}
              type="primary"
              htmlType="submit"
              name="Save Changes"
            >
              Save Changes
            </ButtonElement>
          </Form.Item>
        </Form>
      </div>
      {/* {isAddEnglishTestModalVisible && (
        <AddEnglishTest
          isAddEnglishTestModalVisible={isAddEnglishTestModalVisible}
          setIsAddEnglishTestModalVisible={setIsAddEnglishTestModalVisible}
        />
      )}
      {isUpdateEnglishTestModalVisible && (
        <UpdateEnglishTest
          isUpdateEnglishTestModalVisible={isUpdateEnglishTestModalVisible}
          setIsUpdateEnglishTestModalVisible={
            setIsUpdateEnglishTestModalVisible
          }
        />
      )} */}
    </Card>
  );
};

export default EnglishTest;
