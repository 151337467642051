import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import React from "react";
import { ButtonElement, Pagename } from "../../../components/input";
import { useDispatch } from "react-redux";
import AddCourseCalanderType from "./AddCourseCalanderType";

export default function CourseCalenderType() {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Course",
      dataIndex: "course",
      align: "center",
    },
    {
      title: "Calender Type",
      dataIndex: "calender_type",
      align: "center",
    },
    {
      title: "Year",
      dataIndex: "year",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: () => (
        <Tooltip placement="top" title="Delete Record">
          <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
        </Tooltip>
      ),
    },
  ];

  const data = [
    {
      course: "EAP English for Academic Purposes	",
      calender_type: "Calendar Set1",
      year: 2023,
    },
    {
      course: "EAP-OS English for Academic Purposes - Offshore	",
      calender_type: "Calendar Set1",
      year: 2023,
    },
    {
      course: "EAP English for Academic Purposes	",
      calender_type: "Calendar Set1",
      year: 2023,
    },
    {
      course: "EAP-OS English for Academic Purposes - Offshore	",
      calender_type: "Calendar Set1",
      year: 2023,
    },
  ];

  const handleAddSemester = (value) => {
    dispatch({ type: "FETCH_OPEN_DRAWER", payload: value });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2 items-center">
        <Pagename>Calendar Type to Course</Pagename>
        <ButtonElement
          handleClick={() => handleAddSemester("addCalanderTypeToCourse")}
          Icon={<PlusOutlined className="text-sm" />}
          name="Setup"
        />
      </div>
      <Table dataSource={data} columns={columns} />
      <AddCourseCalanderType />
    </div>
  );
}
