import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BsReply } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { ComponentBody, Pagename, SiderDemo } from "../../components/input";
import PageHeader from "../../components/PageHeader/PageHeader";
import AddButton from "../../components/button/AddButton";
import { formattedDateTime } from "../../Helpers/HelperFunction";
import AddSupport from "./AddSupport";
import UpdateSupport from "./UpdateSupport";
import ViewSupportTicket from "./ViewSupportTicket";
const columns = [
  {
    title: "Support Category",
    dataIndex: "supportCategory",
    align: "center",
  },
  {
    title: "Added Date/Time",
    dataIndex: "addedTime",
    align: "center",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    align: "center",
  },
  {
    title: "Ticket Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const supports = [
  {
    id: "1",
    supportCategory: "MIGRATION ERROR",
    addedTime: "27-07-2023 11:28:05",
    createdBy: "anilsth488@gmail.com",
    status: "OPEN",
  },
];
const Support = () => {
  const store = useSelector((state) => {
    return {
      supportState: state.SupportReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [isAddSupportModalVisible, setIsAddSupportModalVisible] =
    useState(false);
  const [isUpdateSupportModalVisible, setIsUpdateSupportModalVisible] =
    useState(false);
  const [isViewSupportModalVisible, setIsViewSupportModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [supportType, setSupportType] = useState("get-all-ticket");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_SUPPORT_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
  }, [supportType, store.branchState?.branchRecord]);

  const data = supports.map((dataObj) => {
    let ticketStatus;
    if (dataObj.status === "OPEN") {
      ticketStatus = <p className="greenTag">OPEN</p>;
    } else if (dataObj.status === "PENDING") {
      ticketStatus = <p className="pendingTag">OPEN</p>;
    } else if (dataObj.status === "ASSIGNED") {
      ticketStatus = <p className="assignedTag">ASSIGNED</p>;
    } else if (dataObj.status === "CLOSE") {
      ticketStatus = <p className="pendingTag">CLOSE</p>;
    }

    return {
      key: dataObj.id,
      id: dataObj.id,
      supportCategory: dataObj?.supportCategory,
      addedTime: dataObj?.addedTime,
      createdBy: dataObj?.createdBy,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Reply to Ticket">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateSupportModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <BsReply size={20} />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="View Ticket">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsViewSupportModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });

  return (
    <SiderDemo>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Support</Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <AddButton
                handleClick={() => {
                  setIsAddSupportModalVisible(true);
                }}
              >
                Add
              </AddButton>
            </div>
          </div>
        </PageHeader>

        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </div>
      </ComponentBody>

      {isAddSupportModalVisible && (
        <AddSupport
          isaddSupportModalVisible={isAddSupportModalVisible}
          setIsaddSupportModalVisible={setIsAddSupportModalVisible}
        />
      )}
      {isUpdateSupportModalVisible && (
        <UpdateSupport
          record={record}
          isUpdateSupportModalVisible={isUpdateSupportModalVisible}
          setIsUpdateSupportModalVisible={setIsUpdateSupportModalVisible}
        />
      )}
      {isViewSupportModalVisible && (
        <ViewSupportTicket
          record={record}
          isViewSupportTicketModalVisible={isViewSupportModalVisible}
          setIsViewSupportTicketModalVisible={setIsViewSupportModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Support;
