import {
  Alert,
  Card,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { useDispatch } from "react-redux";
import SelectAlert from "../../../../components/SelectAlert/SelectAlert";
const { Option } = Select;

const StudentIdFormat = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddSetupStudentIdFormatSubmitHandler = () => {};

  return (
    <div className="py-4 px-2">
      <Form
        layout="vertical"
        onFinish={AddSetupStudentIdFormatSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Form.Item
            label="Country code (It will get country code according to student country of birth.)"
            style={{ width: "100%" }}
          >
            061 (061 is sample value)
          </Form.Item>
          <Form.Item
            label="Order Set"
            name={"orderSet1"}
            style={{ width: "60%", alignSelf: "flex-end" }}
          >
            <Radio.Group placeholder="Document Type" defaultValue={"1"}>
              <Radio value={"NOT_USED"}>Not Used</Radio>
              <Radio value={"1"}>1</Radio>
              <Radio value={"2"}>2</Radio>
              <Radio value={"3"}>3</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="	Alphanumeric (Max. 5 chars. Text in Student ID)"
            style={{ width: "100%" }}
          >
            <Input defaultValue={"ID"} placeholder={"Alphanumeric"} />
          </Form.Item>
          <Form.Item
            label="Order Set"
            name={"orderSet2"}
            style={{ width: "60%", alignSelf: "flex-end" }}
          >
            <Radio.Group placeholder="Document Type" defaultValue={"2"}>
              <Radio value={"NOT_USED"}>Not Used</Radio>
              <Radio value={"1"}>1</Radio>
              <Radio value={"2"}>2</Radio>
              <Radio value={"3"}>3</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Year Digit (Current Year digit chars. 2: for last two year digits, 4: for full year digits)"
            style={{ width: "100%" }}
          >
            <Radio.Group placeholder="Document Type">
              <Radio value={"2"}>2</Radio>
              <Radio value={""}>4</Radio>
            </Radio.Group>{" "}
          </Form.Item>
          <Form.Item
            label="Order Set"
            name={"orderSet3"}
            style={{ width: "60%", alignSelf: "flex-end" }}
          >
            <Radio.Group placeholder="Document Type" defaultValue={"3"}>
              <Radio value={"NOT_USED"}>Not Used</Radio>
              <Radio value={"1"}>1</Radio>
              <Radio value={"2"}>2</Radio>
              <Radio value={"3"}>3</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Number Type (Max. 6 digits. Auto increment. This value will be the starting point.)"
              style={{ width: "100%" }}
            >
              <Select placeholder="Number Type">
                <Option value={"AUTO_NUMBER"}>Auto Number</Option>
                <Option value={"RANDOM_NUMBER"}>Random Number</Option>
              </Select>{" "}
            </Form.Item>
            <Form.Item style={{ width: "100%", alignSelf: "flex-end" }}>
              <Input defaultValue={"00"} placeholder={"Alphanumeric"} />
            </Form.Item>
          </div>
          <Form.Item
            label="Order Set"
            name={"orderSet4"}
            style={{ width: "60%", alignSelf: "flex-end" }}
          >
            <Radio.Group
              placeholder="Document Type"
              disabled
              defaultValue={"4"}
            >
              <Radio value={"NOT_USED"}>Not Used</Radio>
              <Radio value={"1"}>1</Radio>
              <Radio value={"2"}>2</Radio>
              <Radio value={"3"}>3</Radio>
              <Radio value={"4"}>4</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "flex-start",
          }}
        >
          <Form.Item>
            <ButtonElement block htmlType="submit" name="Preview" />
          </Form.Item>
          <Alert
            message={`Sample of Student ID format: ID20230001`}
            type={"success"}
            style={{ borderRadius: "3px", width: "100%" }}
          />
        </div>
        <Form.Item>
          <ButtonElement block type="primary" htmlType="submit" name="Setup" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default StudentIdFormat;
