import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";

const columns = [
  {
    title: "Course Type",
    dataIndex: "courseType",
    width: 100,
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: 250,
    align: "center",
  },
  //   {
  //     title: "Actions",
  //     dataIndex: "actions",
  //     width: 50,
  //     align: "center",
  //   },
];

const CourseTypeData = [
  {
    id: "1",
    courseType: "ELICOS",
    description: "English Language Intensive Courses for Overseas Students",
  },
];

const CourseType = () => {
  const [isAddLanguageModalVisible, setIsAddLanguageModalVisible] =
    useState(false);

  const data = CourseTypeData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      courseType: dataObj?.courseType,
      description: dataObj?.description,
      //   actions: (
      //     <Space size="middle">
      //       <Tooltip title="Delete Language">
      //         <Popconfirm
      //           title="Are you sure to delete this language?"
      //           onConfirm={() => {}}
      //           onCancel={() => {}}
      //           okText="Yes"
      //           cancelText="No"
      //         >
      //           <a style={{ color: "red" }} s>
      //             <div className="bordered">
      //               <AiOutlineDelete />
      //             </div>
      //           </a>
      //         </Popconfirm>
      //       </Tooltip>
      //     </Space>
      //   ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Course Type</Pagename>
          {/* <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddLanguageModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div> */}
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {/* {isAddLanguageModalVisible && (
        <AddLanguage
          isAddLanguageModalVisible={isAddLanguageModalVisible}
          setIsAddLanguageModalVisible={setIsAddLanguageModalVisible}
        />
      )} */}
    </div>
  );
};

export default CourseType;
