import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddRemarks = ({
  isAddRemarksModalVisible,
  setIsAddRemarksModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddRemarksSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_REMARKS_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddRemarksModalVisible(false);
  };
  return (
    <Modal
      title="Add Remarks"
      open={isAddRemarksModalVisible}
      onCancel={() => {
        setIsAddRemarksModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddRemarksSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Remarks"
          name={"remarks"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please write a remark!",
            },
          ]}
        >
          <TextArea placeholder="Write Something" />
        </Form.Item>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Remark"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRemarks;
