import { Divider, Tag } from "antd";
import moment from "moment";
import React from "react";

const UpfrontFeeSummary = ({ detail }) => {
  const caseDetails = (title, value) => {
    return (
      <div>
        <div className="profileTitle">{title}:</div>
        <div>{value}</div>
      </div>
    );
  };
  return (
    <div
      style={{
        borderLeft: "1px solid #f0f2f5",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
      }}
    >
      <div>
        <h4>STUDENT INFORMATION:</h4>
      </div>
      <Divider orientationMargin="0" style={{ margin: "0" }} />
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>{caseDetails("Offer ID", "900")}</div>
        <div style={{ width: "100%" }}>
          {caseDetails("Course", "GE -General English")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Start Date", "28-08-2023")}
        </div>
        <div style={{ width: "100%" }}>
          {caseDetails("Finish Date", "12-01-2024")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Course Duration", "20 Weeks")}
        </div>
        <div style={{ width: "100%" }}>{caseDetails("Attempt", "1")}</div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Remaining Fee To Schedule", "0.00")}
        </div>
      </div>
    </div>
  );
};

export default UpfrontFeeSummary;
