import React, { useState } from "react";
import { DrawerElement } from "../../../components/input";
import { Form, Steps } from "antd";
import CompanyDetailsComponent from "./CompanyDetailsComponent";
import ToilBankComponent from "./ToilBankComponent";
import CompanyDetailPreview from "./CompanyDetailPreview";

export default function EditCompanyProfile() {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({
    rto_code: "",
    cricos_code: "",
    college_name: "",
    legal_name: "",
    oganisation_type: "",
    contact_person: "",
    abn: "",
    telephone: "",
    fax: "",
    contact_email: "",
    it_email: "",
    marketing_email: "",
    academic_email: "",
    admissions_email: "",
    accounts_email: "",
    other_email: "",
    college_url: "",
    college_email_setup: "",
    type_of_industry: "",

    college_address: "",
    college_suburb: "",
    college_state: "",
    college_postcode: "",

    postal_address: "",
    postal_suburb: "",
    postal_state: "",
    postal_postcode: "",

    toid_act: "",
    toid_nsw: "",
    toid_nt: "",
    toid_qld: "",
    toid_sa: "",
    toid_tas: "",
    toid_vic: "",
    toid_wa: "",

    bank_name: "",
    branch: "",
    account_name: "",
    account_number: "",
    bsb: "",
    swift_code: "",
  });
  const formList = [
    <CompanyDetailsComponent
      formData={formData}
      setFormData={setFormData}
      setCurrent={setCurrent}
    />,
    <ToilBankComponent
      formData={formData}
      setCurrent={setCurrent}
      setFormData={setFormData}
    />,
    <CompanyDetailPreview formData={formData} setCurrent={setCurrent} />,
  ];
  return (
    <DrawerElement title="Edit Company Details" keyType="editCompanyDetails">
      <div className="flex flex-col gap-6 mx-4">
        <Steps
          current={current}
          items={[
            {
              title: "College Information",
            },
            {
              title: "TOID & Bank Infromation",
            },
            {
              title: "Preview Information",
            },
          ]}
        />
        {formList[current]}
      </div>
    </DrawerElement>
  );
}
