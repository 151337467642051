import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Table,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const columns = [
  {
    title: "Inv ID",
    dataIndex: "invId",
    width: 100,
    align: "center",
  },
  {
    title: "Student ID",
    dataIndex: "studentId",
    width: 100,
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: 100,
    align: "center",
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentMode",
    width: 100,
    align: "center",
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
    width: 100,
    align: "center",
  },
];
const feePaymentInfoData = [
  {
    id: "1",
    invId: "1019",
    studentId: "OPS0001294",
    amount: "1570.00",
    paymentMode: "Bank Deposit",
    paymentDate: "12-09-2022",
  },
  {
    id: "2",
    invId: "1047",
    studentId: "OPS0001287",
    amount: "4160.00",
    paymentMode: "Bank Deposit",
    paymentDate: "26-09-2022",
  },
];
const oshcPaymentInfoData = [
  {
    id: "1",
    invId: "1019",
    studentId: "OPS0001321",
    amount: "1950.00",
    paymentMode: "Bank Deposit",
    paymentDate: "30-09-2022",
  },
];
const otherPaymentInfoData = [
  {
    id: "1",
    invId: "1019",
    studentId: "OPS0001294",
    amount: "1570.00",
    paymentMode: "Bank Deposit",
    paymentDate: "12-09-2022",
  },
  {
    id: "2",
    invId: "",
    studentId: "OPS0001164",
    amount: "250.00",
    paymentMode: "Enrolment Fees",
    paymentDate: "12-09-2022",
  },
];
const ReconcileBankDepositDetails = ({
  isReconcileBankDepositDetailsModalVisible,
  setIsReconcileBankDepositDetailsModalVisible,
}) => {
  let feePaymentData = feePaymentInfoData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      invId: dataObj?.invId,
      studentId: dataObj?.studentId,
      amount: dataObj?.amount,
      paymentMode: dataObj?.paymentMode,
      paymentDate: dataObj?.paymentDate,
    };
  });
  let oshcPaymentData = oshcPaymentInfoData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      invId: dataObj?.invId,
      studentId: dataObj?.studentId,
      amount: dataObj?.amount,
      paymentMode: dataObj?.paymentMode,
      paymentDate: dataObj?.paymentDate,
    };
  });
  let otherPaymentData = otherPaymentInfoData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      invId: dataObj?.invId,
      studentId: dataObj?.studentId,
      amount: dataObj?.amount,
      paymentMode: dataObj?.paymentMode,
      paymentDate: dataObj?.paymentDate,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ReconcileBankDepositDetailsSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "ReconcileBankDepositDetails_REQUEST",
      payload: formData,
    });
    // form.resetFields();
    setIsReconcileBankDepositDetailsModalVisible(false);
  };
  return (
    <Drawer
      title="View Payment List"
      open={isReconcileBankDepositDetailsModalVisible}
      onClose={() => {
        setIsReconcileBankDepositDetailsModalVisible(false);
      }}
      footer={null}
      width={"95vw"}
    >
      <Form
        layout="vertical"
        onFinish={ReconcileBankDepositDetailsSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "flexStart",
              width: "100%",
              gap: "1rem",
            }}
          >
            <Form.Item label="Reconcile Amount" style={{ width: "100%" }}>
              344
            </Form.Item>
            <Form.Item>
              <ButtonElement
                block
                type="primary"
                name="Update Reconcile Amount"
              />
            </Form.Item>
          </div>
          <Form.Item label="Deposit" style={{ width: "100%" }}>
            04 Dec 2022
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Payment list from Date"
            name={"paymentListFromDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a payment list from date",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Payment list to Date"
            name={"paymentListToDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a payment list to date",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <Form.Item>
          <ButtonElement block type="primary" name="View" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <Table
            scroll={{
              x: 0,
            }}
            rowSelection={{
              selectedRowKeys: [],
              onChange: () => {},
            }}
            style={{ width: "100%" }}
            bordered
            dataSource={feePaymentData}
            columns={columns}
            pagination={false}
          />
          <Table
            scroll={{
              x: 0,
            }}
            rowSelection={{
              selectedRowKeys: [],
              onChange: () => {},
            }}
            style={{ width: "100%" }}
            bordered
            dataSource={oshcPaymentData}
            columns={columns}
            pagination={false}
          />
          <Table
            scroll={{
              x: 0,
            }}
            rowSelection={{
              selectedRowKeys: [],
              onChange: () => {},
            }}
            style={{ width: "100%" }}
            bordered
            dataSource={otherPaymentData}
            columns={columns}
            pagination={false}
          />
        </div>

        <Form.Item style={{ marginTop: "1rem" }}>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Bank Deposit Date"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ReconcileBankDepositDetails;
