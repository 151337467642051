import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../components/button/AddButton";
import { Pagename } from "../../../../components/input";
import { AiOutlineDelete } from "react-icons/ai";

const columns = [
  {
    title: "Staff",
    dataIndex: "staff",
    width: 250,
    align: "center",
  },
  {
    title: "Semester",
    dataIndex: "semester",
    width: 150,
    align: "center",
  },
  {
    title: "Request Date",
    dataIndex: "requestDate",
    width: 100,
    align: "center",
  },
  {
    title: "Leave From",
    dataIndex: "leaveFrom",
    width: 150,
    align: "center",
  },

  {
    title: "Leave To",
    dataIndex: "leaveTo",
    width: 350,
    align: "center",
  },
  {
    title: "Leave Details",
    dataIndex: "leaveDetails",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    staff: "Anu Rani Regmi",
    semester: "2023",
    requestDate: "13 Sep 2023",
    leaveFrom: "13 Sep 2023",
    leaveTo: "	13 Sep 2023",
    leaveDetails: "",
  },
];

const LeaveInfo = () => {
  const [isAddLeaveInfoModalVisible, setIsAddLeaveInfoModalVisible] =
    useState(false);
  const [isUpdateLeaveInfoModalVisible, setIsUpdateLeaveInfoModalVisible] =
    useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      staff: dataObj?.staff,
      semester: dataObj?.semester,
      requestDate: dataObj?.requestDate,
      leaveFrom: dataObj?.leaveFrom,
      leaveTo: dataObj?.leaveTo,
      leaveDetails: dataObj?.leaveDetails,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "150px" }}
        >
          <Tooltip title="Delete Leave Info">
            <Popconfirm
              title="Are you sure to delete this leave info?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Leave Info</Pagename>
          {/* <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddLeaveInfoModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div> */}
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </Card>
  );
};

export default LeaveInfo;
