import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "../../../../Assets/logo/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ApplicantProfile from "./AppicantProfile/AppicantProfile";
import StudentCourse from "../../../Student/StudentDetails/StudentCourse/StudentCourse";
import DocumentChecklist from "../../../Student/StudentDetails/DocumentChecklist/DocumentChecklist";
import StaffDiary from "../../../Staff/StaffDetails/StaffDiary/StaffDiary";
import StaffDocuments from "../../../Staff/StaffDetails/StaffDocuments/StaffDocuments";
import { ButtonElement } from "../../../../components/input";
import EnglishTest from "../../../Student/StudentDetails/EnglishTest/EnglishTest";
import CourseLog from "./CourseLog/CourseLog";
import AdvancedStanding from "../../../Student/StudentDetails/StudentCourse/EnrollSubjectForCourse/AdvancedStanding/AdvancedStanding";
import AddCoeForOffer from "./AddCoeForOffer";
import GeneratePermanentStudntId from "./GeneratePermanentStudntId";
import GenerateReserveId from "./GenerateReserveId";
import OfferChecklist from "./OfferChecklist/OfferChecklist";
import UpdateUpfrontFeeSchedule from "./UpdateUpfrontFeeSchedule/UpdateUpfrontFeeSchedule";

const ApplicantDetails = ({
  isApplicantDetailsModalVisible,
  setIsApplicantDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return { staffState: state.StaffReducer };
  });

  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [
    isGeneratePermanentStudntIdModalVisible,
    setIsGeneratePermanentStudntIdModalVisible,
  ] = useState(false);
  const [isGenerateReserveIdModalVisible, setIsGenerateReserveIdModalVisible] =
    useState(false);
  const [isAddCoeForOfferModalVisible, setIsAddCoeForOfferModalVisible] =
    useState(false);
  const [
    isUpdateUpfrontFeeScheduleModalVisible,
    setIsUpdateUpfrontFeeScheduleModalVisible,
  ] = useState(false);

  const [current, setCurrent] = useState("profile");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const staffTabList = [
    {
      key: "profile",
      label: "Profile",
    },
    {
      key: "offerChecklist",
      label: "Offer Checklist",
    },
    {
      key: "course",
      label: "Course",
    },
    {
      key: "courseLog",
      label: "Course Log",
    },
    {
      key: "advancedStanding",
      label: "Advanced Standing",
    },
    {
      key: "upload",
      label: "Upload Documents",
    },
    {
      key: "englishTest",
      label: "English Test",
    },
    {
      key: "documentChecklist",
      label: "Document Checklist",
    },
    {
      key: "diary",
      label: "Diary",
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  const navigate = useNavigate();
  console.log("record from lead details");
  console.log("record from lead store");
  console.log("store.staffState?.leadById");
  return (
    <Drawer
      title="Applicant Details"
      open={isApplicantDetailsModalVisible}
      onClose={() => {
        setIsApplicantDetailsModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Card style={{ borderRadius: "3px" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "inherit",
          }}
        >
          <Image
            src={client}
            className="clientImage"
            height={160}
            width={160}
          />
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
              width: "80%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`Mr Oscar Javier Romero Espitia (ID: AP0898)`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlinePhone
                  style={{
                    fontSize: "20",
                  }}
                />
                0478290611
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <GoLocation
                  style={{
                    fontSize: "16",
                  }}
                />
                1/544 Willoughby Road Willoughby NSW Australia, 2068
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlineMail
                  style={{
                    fontSize: "16",
                  }}
                />{" "}
                awesomesabinkhadka@gmail.com
              </span>
            </div>
            {/* <div>
                  <div>
                    <span className="profileTitle">Campaign:</span>
                    <span>
                      Further Details Here
                    </span>
                  </div>
                </div> */}
            <div
              className="fxRAc"
              style={{
                gap: "0.5rem",
                position: "absolute",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>

              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>

              <ButtonElement
                type="primary"
                handleClick={() => {
                  setIsAddCoeForOfferModalVisible(true);
                }}
                name=" Add CoE for Offer"
              >
                Add CoE for Offer
              </ButtonElement>
              <ButtonElement
                type="primary"
                handleClick={() => {
                  setIsGeneratePermanentStudntIdModalVisible(true);
                }}
                name=" Add CoE for Offer"
              >
                Generate Student ID
              </ButtonElement>
              <ButtonElement
                type="primary"
                handleClick={() => {
                  setIsGenerateReserveIdModalVisible(true);
                }}
                name="Generate Reserve ID"
              >
                Generate Reserve ID
              </ButtonElement>
              <ButtonElement
                type="primary"
                handleClick={() => {
                  setIsUpdateUpfrontFeeScheduleModalVisible(true);
                }}
                name="Update Upfront Fee"
              >
                Update Upfront Fee
              </ButtonElement>
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            ></div>
          </div>
        </div>
        <Tabs
          defaultActiveKey="profile"
          items={staffTabList}
          activeTabKey={current}
          onChange={(key) => {
            console.log("current key", key);
            setCurrent(key);
          }}
        />
        {current === "profile" && (
          <>
            <ApplicantProfile />
          </>
        )}
        {current === "course" && (
          <>
            <StudentCourse />
          </>
        )}
        {current === "courseLog" && (
          <>
            <CourseLog />
          </>
        )}
        {current === "advancedStanding" && (
          <>
            <AdvancedStanding />
          </>
        )}
        {current === "englishTest" && (
          <>
            <EnglishTest />
          </>
        )}
        {current === "documentChecklist" && (
          <>
            <DocumentChecklist />
          </>
        )}

        {current === "diary" && (
          <>
            <StaffDiary />
          </>
        )}
        {current === "upload" && (
          <>
            <StaffDocuments />
          </>
        )}
        {current === "offerChecklist" && (
          <>
            <OfferChecklist />
          </>
        )}
      </Card>
      {/* {isSendComposedEmailModalVisible && (
            <SendComposedMail
              composeMailRecord={{
                toUser: [store?.staffState?.leadById?.email],
              }}
              isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
              setIsSendComposedEmailModalVisible={
                setIsSendComposedEmailModalVisible
              }
            />
          )} */}

      {isGeneratePermanentStudntIdModalVisible && (
        <GeneratePermanentStudntId
          isGeneratePermanentStudntIdModalVisible={
            isGeneratePermanentStudntIdModalVisible
          }
          setIsGeneratePermanentStudntIdModalVisible={
            setIsGeneratePermanentStudntIdModalVisible
          }
        />
      )}
      {isGenerateReserveIdModalVisible && (
        <GenerateReserveId
          isGenerateReserveIdModalVisible={isGenerateReserveIdModalVisible}
          setIsGenerateReserveIdModalVisible={
            setIsGenerateReserveIdModalVisible
          }
        />
      )}
      {isAddCoeForOfferModalVisible && (
        <AddCoeForOffer
          isAddCoeForOfferModalVisible={isAddCoeForOfferModalVisible}
          setIsAddCoeForOfferModalVisible={setIsAddCoeForOfferModalVisible}
        />
      )}
      {isUpdateUpfrontFeeScheduleModalVisible && (
        <UpdateUpfrontFeeSchedule
          isUpdateUpfrontFeeScheduleModalVisible={
            isUpdateUpfrontFeeScheduleModalVisible
          }
          setIsUpdateUpfrontFeeScheduleModalVisible={
            setIsUpdateUpfrontFeeScheduleModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default ApplicantDetails;
