import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Steps,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;

const StaffEmploymentInfo = ({
  staffData,
  setStaffData,
  current,
  setCurrent,
}) => {
  const [isAddPositionModalVisible, setIsAddPositionModalVisible] =
    useState(false);
  const [form] = Form.useForm();
  const addStaffEmploymentInfo = () => {
    setCurrent(2);
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addStaffEmploymentInfo}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["staffNumber"],
            value: staffData?.staffNumber,
          },
          {
            name: ["position"],
            value: staffData?.positionName,
          },
          {
            name: ["status"],
            value: staffData?.status,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Staff Number"
            name={"staffNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter staff number!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Staff Number"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    staffNumber: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Position"
              name={"position"}
              style={{ width: "100%" }}
            >
              <Select></Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddPositionModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>

        <Form.Item label="Status" name={"status"} style={{ width: "100%" }}>
          <Radio.Group
            onChange={(e) => {
              setStaffData((prevData) => {
                return {
                  ...prevData,
                  status: e.target.value,
                };
              });
            }}
          >
            <Radio value={"ACTIVE"}>Active</Radio>
            <Radio value={"INACTIVE"}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {current === 1 && (
            <Form.Item>
              <Button
                type={"default"}
                onClick={() => {
                  setCurrent(0);
                }}
                name="Back"
                style={{ borderRadius: "3px" }}
              >
                Back
              </Button>
            </Form.Item>
          )}
          <Form.Item>
            <ButtonElement type="primary" htmlType="submit" name="Next">
              Next
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default StaffEmploymentInfo;
