import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../components/button/AddButton";
import { Pagename } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import AddStaffDiary from "./AddStaffDiary";
import UpdateStaffDiary from "./UpdateStaffDiary";

const columns = [
  {
    title: "Date/Time Log",
    dataIndex: "dateTimeLog",
    width: 250,
    align: "center",
  },
  {
    title: "Added/Commented By",
    dataIndex: "addedBy",
    width: 150,
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "type",
    width: 100,
    align: "center",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    width: 150,
    align: "center",
  },

  {
    title: "Comment Log",
    dataIndex: "commentLog",
    width: 350,
    align: "center",
  },
  {
    title: "File Attachment",
    dataIndex: "fileAttachment",
    width: 150,
    align: "center",
  },
  {
    title: "Is Visible",
    dataIndex: "isVisible",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    dateTimeLog: "10/08/2023 3:22:34 PM",
    addedBy: "anu",
    type: "type",
    Subject: "GE-B1-A: GE Pre-Intermediate",
    commentLog: "Check homework for subject GE Pre-intermediate",
    fileAttachment: "HomeworkFile",
    isVisible: "YES",
    status: "INFORMED",
  },
];

const StaffDiary = () => {
  const [isAddStaffDiaryModalVisible, setIsAddStaffDiaryModalVisible] =
    useState(false);
  const [isUpdateStaffDiaryModalVisible, setIsUpdateStaffDiaryModalVisible] =
    useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      id: dataObj?.id,
      key: dataObj?.id,
      dateTimeLog: dataObj?.dateTimeLog,
      addedBy: dataObj?.addedBy,
      type: dataObj?.type,
      Subject: dataObj?.Subject,
      commentLog: dataObj?.commentLog,
      fileAttachment: dataObj?.fileAttachment,
      isVisible: dataObj?.isVisible,
      status: dataObj?.status,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "150px" }}
        >
          <Tooltip title="Edit Comment">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStaffDiaryModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete communtication log?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Enable Log">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlinePoweroff />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Disable Log">
            <a style={{ color: "red" }}>
              <div className="bordered" onClick={() => {}}>
                <BiBlock />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Communication Log (Diary)</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddStaffDiaryModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table columns={columns} dataSource={data} />
      </div>
      {isAddStaffDiaryModalVisible && (
        <AddStaffDiary
          isAddStaffDiaryModalVisible={isAddStaffDiaryModalVisible}
          setIsAddStaffDiaryModalVisible={setIsAddStaffDiaryModalVisible}
        />
      )}
      {isUpdateStaffDiaryModalVisible && (
        <UpdateStaffDiary
          isUpdateStaffDiaryModalVisible={isUpdateStaffDiaryModalVisible}
          setIsUpdateStaffDiaryModalVisible={setIsUpdateStaffDiaryModalVisible}
        />
      )}
    </Card>
  );
};

export default StaffDiary;
