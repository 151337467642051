import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const GenerateReserveId = ({
  isGenerateReserveIdModalVisible,
  setIsGenerateReserveIdModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const GenerateReserveIdSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_PROVIDER_REQUEST", payload: formData });
    // form.resetFields();
    setIsGenerateReserveIdModalVisible(false);
  };
  return (
    <Modal
      title="Generate Reserve ID"
      open={isGenerateReserveIdModalVisible}
      onCancel={() => {
        setIsGenerateReserveIdModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={GenerateReserveIdSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Offer ID" style={{ width: "100%" }}>
            19
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Suggested Reserve ID"
            name={"suggestedReserveId"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Reserve ID" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Generate Reserve ID"
          >
            Generate Reserve ID
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GenerateReserveId;
