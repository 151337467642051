import React, { useState } from "react";
import { ButtonElement, Pagename } from "../../../../components/input";
import { Divider, Popconfirm, Space, Table, Tooltip } from "antd";
import moment from "moment";
import {
  AiOutlineDelete,
  AiOutlineDollar,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFileAdd,
  AiOutlineFilePdf,
  AiOutlineMail,
} from "react-icons/ai";
import { HiArrowUturnUp } from "react-icons/hi2";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../components/button/AddButton";
import SelectAlert from "../../../../components/SelectAlert/SelectAlert";
import CreateMiscellaneousInvoice from "./CreateMiscellaneousInvoice";
import ReverseTransaction from "../PaymentSummary/PaidPayment/ReverseTransaction";
import SendComposedMail from "../../../Mailbox/SendComposedMail";
import AddMiscellaneousPayment from "./AddMiscellaneousPayment";
import UpdateMiscellaneousPayment from "./UpdateMiscellaneousPayment";
import AddRefund from "../RefundHistory/AddRefund";
import { RiRefund2Line } from "react-icons/ri";
const paymentScheduleColumn = [
  {
    title: "Student Id",
    dataIndex: "studentId",
    width: 150,
    align: "center",
  },
  {
    title: "Offer Id",
    dataIndex: "offerId",
    width: 150,
    align: "center",
  },
  {
    title: "Couse List",
    dataIndex: "couseList",
    width: 150,
    align: "center",
  },
  {
    title: "Category Name",
    dataIndex: "categoryName",
    width: 150,
    align: "center",
  },
  {
    title: "Facility Name",
    dataIndex: "facilityName",
    width: 250,
    align: "center",
  },
  {
    title: "Service Start",
    dataIndex: "serviceStart",
    width: 150,
    align: "center",
  },
  {
    title: "Service Finish",
    dataIndex: "serviceFinish",
    width: 150,
    align: "center",
  },
  {
    title: "Std Price",
    dataIndex: "stdPrice",
    width: 150,
    align: "center",
  },
];
const miscellaneousPaymentColumn = [
  {
    title: "InvId",
    dataIndex: "InvId",
    width: 250,
    align: "center",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    width: 250,
    align: "center",
  },
  {
    title: "Invoice Sent",
    dataIndex: "invoiceSent",
    width: 250,
    align: "center",
  },
  {
    title: "Trans Type",
    dataIndex: "transType",
    width: 250,
    align: "center",
  },
  {
    title: "Course",
    dataIndex: "course",
    width: 100,
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: 100,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 250,
    align: "center",
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentMode",
    width: 250,
    align: "center",
  },
  {
    title: "Refund",
    dataIndex: "refund",
    width: 250,
    align: "center",
  },

  {
    title: "Transaction Details",
    dataIndex: "transactionDetails",
    width: 100,
    align: "center",
  },
  {
    title: "Reversed",
    dataIndex: "reversed",
    width: 100,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const miscellaneousPaymentRecordData = [
  {
    id: 1,
    InvId: "40",
    invoiceNo: "1273",
    invoiceSent: "No",
    transType: "Misce",
    course: "Enrolment Fees(Course : GE - Attempt : 1)",
    amount: "$250.00 (GST :$22.73)",
    dueDate: "08-09-2023",
    paymentMode: "Bank Deposit",
    refund: "$0",
    transactionDetails: "Paid On: 08/09/2023 (Receipt: 1130)",
    reversed: "No",
    remarks: " Last updated by: anu @ 08/09/2023",
  },
];

const paymentScheduleDataSrc = [
  {
    id: 1,
    studentId: "SD2023001",
    offerId: "2",
    couseList: "",
    categoryName: "Transport",
    facilityName: "Taxi",
    serviceStart: "13/04/2021",
    serviceFinish: "",
    stdPrice: "50",
  },
];

const MiscellaneousPayment = ({ formData }) => {
  const [
    isAddMiscellaneousPaymentModalVisible,
    setIsAddMiscellaneousPaymentModalVisible,
  ] = useState(false);

  const [isProcessCommissionModalVisible, setIsProcessCommissionModalVisible] =
    useState(false);
  const [
    isCreateMiscellaneousInvoiceModalVisible,
    setIsCreateMiscellaneousInvoiceModalVisible,
  ] = useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [
    isReverseTransactionModalVisible,
    setIsReverseTransactionModalVisible,
  ] = useState(false);
  const [isModifyTransactionModalVisible, setIsModifyTransactionModalVisible] =
    useState(false);
  const [isAddRefundModalVisible, setIsAddRefundModalVisible] = useState(false);

  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let paymentScheduleData = paymentScheduleDataSrc.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      studentId: dataObj?.studentId,
      offerId: dataObj?.offerId,
      couseList: dataObj?.couseList,
      categoryName: dataObj?.categoryName,
      facilityName: dataObj?.facilityName,
      serviceStart: dataObj?.serviceStart,
      serviceFinish: dataObj?.serviceFinish,
      stdPrice: dataObj?.stdPrice,
    };
  });
  let miscellaneousPaymentData = miscellaneousPaymentRecordData.map(
    (dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        InvId: dataObj?.InvId,
        invoiceNo: dataObj?.invoiceNo,
        invoiceSent: dataObj?.invoiceSent,
        transType: dataObj?.transType,
        course: dataObj?.course,
        amount: dataObj?.amount,
        dueDate: dataObj?.dueDate,
        paymentMode: dataObj?.paymentMode,
        refund: dataObj?.refund,
        transactionDetails: dataObj?.transactionDetails,
        reversed: dataObj?.reversed,
        remarks: dataObj?.remarks,
        actions: (
          <Space
            size="middle"
            style={{ display: "flex", flexWrap: "wrap", width: "200px" }}
          >
            <Tooltip title="Modify Transaction">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsModifyTransactionModalVisible(true);
                  }}
                >
                  <AiOutlineEdit />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Refund Payment">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAddRefundModalVisible(true);
                  }}
                >
                  <RiRefund2Line />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Generate Receipt">
              <a>
                <div className="bordered" onClick={() => {}}>
                  <AiOutlineFilePdf />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Generate Invoice">
              <a>
                <div className="bordered" onClick={() => {}}>
                  <AiOutlineFilePdf />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Reverse Transaction">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsReverseTransactionModalVisible(true);
                  }}
                >
                  <HiArrowUturnUp />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Send Email">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsSendComposedEmailModalVisible(true);
                  }}
                >
                  <AiOutlineMail />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Transaction">
              <Popconfirm
                title="Are you sure to delete this selected transaction?"
                onConfirm={() => {}}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a style={{ color: "red" }}>
                  <div className="bordered">
                    <AiOutlineDelete />
                  </div>
                </a>
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
      };
    }
  );

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fxRJsb">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem 0" }} />
      </div>
    );
  };
  return (
    <div className="grid grid-cols-1 gap-4 mt-8">
      <div className="flex flex-col gap-4">
        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Student Information & Payment Details
          </span>

          {detailFun("Total Miscellaneous Payment", "$2,000.00")}
          {detailFun("OSHC Payment", "$0.00")}
          {detailFun("Payment Due", "$2,000.00")}
        </div>

        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Service Request Pending Invoice List
          </span>
          <PageHeader>
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <Pagename>Miscellaneous Payment</Pagename>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <AddButton
                  handleClick={() => {
                    setIsAddMiscellaneousPaymentModalVisible(true);
                  }}
                >
                  Add
                </AddButton>
                <ButtonElement
                  handleClick={() => {
                    setIsProcessCommissionModalVisible(true);
                  }}
                  name="Process Payment"
                />
              </div>
            </div>
          </PageHeader>
          <div
            className="fxC"
            style={{
              marginTop: "1rem",
            }}
          >
            {selectedRowKeys.length > 0 && selectedVisible && (
              <SelectAlert
                length={selectedRowKeys.length}
                type={"success"}
                closable={true}
                selectAction={
                  <ButtonElement
                    size="small"
                    buttonType="primary"
                    handleClick={(e) => {
                      setIsCreateMiscellaneousInvoiceModalVisible(true);
                    }}
                    name="Create Invoice"
                  />
                }
              />
            )}
            <Table
              rowSelection={{
                selectedRowKeys,
                onChange: onSelectChange,
              }}
              bordered
              dataSource={paymentScheduleData}
              columns={paymentScheduleColumn}
              pagination={false}
            />
          </div>
        </div>
        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Miscellaneous payment record
          </span>

          <div
            className="fxC"
            style={{
              marginTop: "1rem",
            }}
          >
            <Table
              scroll={{
                x: 0,
              }}
              bordered
              dataSource={miscellaneousPaymentData}
              columns={miscellaneousPaymentColumn}
              pagination={false}
            />
          </div>
        </div>
      </div>
      {isCreateMiscellaneousInvoiceModalVisible && (
        <CreateMiscellaneousInvoice
          isCreateMiscellaneousInvoiceModalVisible={
            isCreateMiscellaneousInvoiceModalVisible
          }
          setIsCreateMiscellaneousInvoiceModalVisible={
            setIsCreateMiscellaneousInvoiceModalVisible
          }
        />
      )}
      {isAddRefundModalVisible && (
        <AddRefund
          isAddRefundModalVisible={isAddRefundModalVisible}
          setIsAddRefundModalVisible={setIsAddRefundModalVisible}
        />
      )}
      {isAddMiscellaneousPaymentModalVisible && (
        <AddMiscellaneousPayment
          isAddMiscellaneousPaymentModalVisible={
            isAddMiscellaneousPaymentModalVisible
          }
          setIsAddMiscellaneousPaymentModalVisible={
            setIsAddMiscellaneousPaymentModalVisible
          }
        />
      )}
      {isModifyTransactionModalVisible && (
        <UpdateMiscellaneousPayment
          isUpdateMiscellaneousPaymentModalVisible={
            isModifyTransactionModalVisible
          }
          setIsUpdateMiscellaneousPaymentModalVisible={
            setIsModifyTransactionModalVisible
          }
        />
      )}

      {isReverseTransactionModalVisible && (
        <ReverseTransaction
          isReverseTransactionModalVisible={isReverseTransactionModalVisible}
          setIsReverseTransactionModalVisible={
            setIsReverseTransactionModalVisible
          }
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </div>
  );
};

export default MiscellaneousPayment;
