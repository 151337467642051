import React from "react";
import {
  ButtonElement,
  DrawerElement,
  InputFieldElement,
  SelectField,
} from "../../components/input";
import { Form, InputNumber } from "antd";
import InputNumberField from "../../components/inputNumberField/InputNumberField";

export default function EditUserProfile() {
  const countryLists = [
    {
      label: "Afganistan",
      value: 1,
    },
    {
      label: "Australia",
      value: 2,
    },
  ];

  const stateList = [
    {
      label: "NSW",
      value: 1,
    },
    {
      label: "NT",
      value: 2,
    },
    {
      label: "ACT",
      value: 3,
    },
  ];

  return (
    <DrawerElement title="Edit User Profile" keyType="updateUserProfile">
      <Form
        layout="vertical"
        className="grid grid-cols-2 gap-4 mx-4 items-center"
      >
        <span>Name: Ms Anu Rani Regmi</span>
        <span>Username: anu</span>
        <span>Payroll staff ID: S002</span>
        <span>Position: Staff-Teacher</span>
        <InputFieldElement
          name="email"
          label="Email"
          placeholder="Email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="college_email"
          label="College Email"
          placeholder="College Email"
          fieldWidth="100%"
        />
        <SelectField
          name="country"
          placeholder="Select Country"
          label="Country"
          fieldWidth="100%"
          options={countryLists}
        />
        <SelectField
          name="state"
          placeholder="Select State"
          label="State"
          fieldWidth="100%"
          options={stateList}
        />
        <InputFieldElement
          name="city"
          label="City/Town/Suburb"
          placeholder="City/Town/Suburb"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="address"
          label="Address"
          placeholder="address"
          fieldWidth="100%"
        />
        <InputNumberField
          name="phone"
          label="Phone"
          placeholder="Phone"
          fieldWidth="100%"
        />

        <InputNumberField
          name="postcode"
          label="Postcode"
          placeholder="Postcode"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="emergency_name"
          label="Emergency Contact Name"
          placeholder="Contact Name"
          fieldWidth="100%"
        />
        <InputNumberField
          name="emergency_number"
          label="Emergency Contact Number"
          placeholder="Contact Number"
          fieldWidth="100%"
        />
        <ButtonElement name="Update" />
      </Form>
    </DrawerElement>
  );
}
