import React, { useState } from "react";
import { ButtonElement, Pagename } from "../../../../components/input";
import { Divider, Popconfirm, Space, Table, Tooltip } from "antd";
import moment from "moment";
import {
  AiOutlineDelete,
  AiOutlineDollar,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFileAdd,
  AiOutlineFilePdf,
  AiOutlineMail,
} from "react-icons/ai";
import UpdatePaymentSchedule from "./SchedulePayment/UpdatePaymentSchedule";
import SchedulePaymentInfo from "./SchedulePayment/SchedulePaymentInfo";
import PayScheduleFee from "./SchedulePayment/PayScheduleFee";
import SendComposedMail from "../../../Mailbox/SendComposedMail";
import InvoiceCredit from "./SchedulePayment/InvoiceCredit";
import ModifyTransaction from "./PaidPayment/ModifyTransaction";
import { RiRefund2Line } from "react-icons/ri";
import { HiArrowUturnUp } from "react-icons/hi2";
import ReverseTransaction from "./PaidPayment/ReverseTransaction";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddPaymentSchedule from "./SchedulePayment/AddPaymentSchedule";
import AddButton from "../../../../components/button/AddButton";
import ProcessCommission from "./SchedulePayment/ProcessCommission";
import SubjectFeeSchedule from "./SchedulePayment/SubjectFeeSchedule";
import GenerateStudentCourseInvoice from "../../../Offers/GenerateInvoice/GenerateStudentCourseInvoice";
const paymentScheduleColumn = [
  {
    title: "InvId",
    dataIndex: "invId",
    width: 150,
    align: "center",
  },
  {
    title: "Inv No",
    dataIndex: "invNo",
    width: 150,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 150,
    align: "center",
  },
  {
    title: "Inv Start Date",
    dataIndex: "invStartDate",
    width: 150,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 250,
    align: "center",
  },
  {
    title: "Fees",
    dataIndex: "fees",
    width: 150,
    align: "center",
  },
  {
    title: "Fee Paid",
    dataIndex: "feePaid",
    width: 150,
    align: "center",
  },
  {
    title: "Accrued Fee",
    dataIndex: "accruedFee",
    width: 150,
    align: "center",
  },
  {
    title: "Paid Duration",
    dataIndex: "paidDuration",
    width: 150,
    align: "center",
  },
  {
    title: "Commission",
    dataIndex: "commission",
    width: 100,
    align: "center",
  },
  {
    title: "Inv Credit",
    dataIndex: "invCredit",
    width: 100,
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 350,
    align: "center",
  },
];
const paidColumn = [
  {
    title: "Tran No.",
    dataIndex: "tranNo",
    width: 250,
    align: "center",
  },
  {
    title: "InvId",
    dataIndex: "invId",
    width: 250,
    align: "center",
  },
  {
    title: "Receipt No",
    dataIndex: "receiptNo",
    width: 250,
    align: "center",
  },
  {
    title: "Student Paid",
    dataIndex: "studentPaid",
    width: 250,
    align: "center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    width: 100,
    align: "center",
  },
  {
    title: "Bank Deposited",
    dataIndex: "bankDeposited",
    width: 100,
    align: "center",
  },
  {
    title: "Deposit Date",
    dataIndex: "depositDate",
    width: 250,
    align: "center",
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentMode",
    width: 250,
    align: "center",
  },
  {
    title: "Scholarship",
    dataIndex: "scholarship",
    width: 250,
    align: "center",
  },
  {
    title: "Refunded",
    dataIndex: "refunded",
    width: 250,
    align: "center",
  },
  {
    title: "Agent Bonus",
    dataIndex: "agentBonus",
    width: 100,
    align: "center",
  },
  {
    title: "Bad Debt",
    dataIndex: "badDebt",
    width: 100,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const paymentScheduleDataSrc = [
  {
    id: 1,
    invId: "259",
    invNo: "1264",
    dueDate: "12 Apr 21",
    invStartDate: "12 Apr 21",
    agentName: "Opera Education Group Pty Ltd",
    fees: "900.00",
    feePaid: "900.00",
    accruedFee: "900.00",
    paidDuration: "3 Week",
    commission: "0%+No GST = 0.00",
    invCredit: "0.00",
    description: "Remarks: autogenerated",
  },
];
const paidDataSrc = [
  {
    id: 1,
    tranNo: "120",
    invId: "259",
    receiptNo: "1127",
    studentPaid: "900.00",
    paidDate: "12-04-2021",
    bankDeposited: "900.00",
    depositDate: "",
    paymentMode: "Bank Deposit",
    scholarship: "0.00",
    refunded: "0.00",
    agentBonus: "0.00+No GST =0",
    badDebt: "0.00",
    remarks: "Last updated by: anu @ 05/09/2023",
  },
];

const PaymentSummary = ({ formData }) => {
  const [
    isUpdatePaymentScheduleModalVisible,
    setIsUpdatePaymentScheduleModalVisible,
  ] = useState(false);
  const [
    isSchedulePaymentInfoModalVisible,
    setIsSchedulePaymentInfoModalVisible,
  ] = useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isPayScheduleFeeModalVisible, setIsPayScheduleFeeModalVisible] =
    useState(false);
  const [isInvoiceCreditModalVisible, setIsInvoiceCreditModalVisible] =
    useState(false);
  const [isModifyTransactionModalVisible, setIsModifyTransactionModalVisible] =
    useState(false);
  const [
    isAddPaymentScheduleModalVisible,
    setIsAddPaymentScheduleModalVisible,
  ] = useState(false);
  const [isRefundPaymentModalVisible, setIsRefundPaymentModalVisible] =
    useState(false);
  const [
    isReverseTransactionModalVisible,
    setIsReverseTransactionModalVisible,
  ] = useState(false);
  const [isProcessCommissionModalVisible, setIsProcessCommissionModalVisible] =
    useState(false);
  const [
    isSubjectFeeScheduleModalVisible,
    setIsSubjectFeeScheduleModalVisible,
  ] = useState(false);
  const [isGenerateInvoiceModalVisible, setIsGenerateInvoiceModalVisible] =
    useState(false);
  let paymentScheduleData = paymentScheduleDataSrc.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      invId: dataObj?.invId,
      invNo: dataObj?.invNo,
      dueDate: dataObj?.dueDate,
      invStartDate: dataObj?.invStartDate,
      agentName: dataObj?.agentName,
      fees: dataObj?.fees,
      feePaid: dataObj?.feePaid,
      accruedFee: dataObj?.accruedFee,
      paidDuration: dataObj?.paidDuration,
      commission: dataObj?.commission,
      invCredit: dataObj?.invCredit,
      description: dataObj?.description,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "200px" }}
        >
          <Tooltip title="Modify Payment Schedule">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdatePaymentScheduleModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Pay Schedule Fee">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsPayScheduleFeeModalVisible(true);
                }}
              >
                <AiOutlineDollar />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Transaction Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSchedulePaymentInfoModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Generate Schedule Invoice">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Agent Invoice">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Invoice Credit">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsInvoiceCreditModalVisible(true);
                }}
              >
                <AiOutlineFileAdd />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  let paidData = paidDataSrc.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      tranNo: dataObj?.tranNo,
      invId: dataObj?.invId,
      receiptNo: dataObj?.receiptNo,
      studentPaid: dataObj?.studentPaid,
      paidDate: dataObj?.paidDate,
      bankDeposited: dataObj?.bankDeposited,
      depositDate: dataObj?.depositDate,
      paymentMode: dataObj?.paymentMode,
      scholarship: dataObj?.scholarship,
      refunded: dataObj?.refunded,
      agentBonus: dataObj?.agentBonus,
      badDebt: dataObj?.badDebt,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "200px" }}
        >
          <Tooltip title="Modify Transaction">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsModifyTransactionModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          {/* <Tooltip title="Refund Payment">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsRefundPaymentModalVisible(true);
                }}
              >
                <RiRefund2Line />
              </div>
            </a>
          </Tooltip> */}
          <Tooltip title="Generate Schedule Receipt">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Agent Receipt">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Reverse Transaction">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsReverseTransactionModalVisible(true);
                }}
              >
                <HiArrowUturnUp />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Send Email">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Transaction">
            <Popconfirm
              title="Are you sure to delete this selected transaction?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fxRJsb">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem 0" }} />
      </div>
    );
  };
  return (
    <div className="grid grid-cols-1 gap-4 mt-8">
      <div className="flex flex-col gap-4">
        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Student & Course Info
          </span>
          {detailFun("Agency Name", "Opera Education Group Pty Ltd")}
          {detailFun("Course Duration", "12-04-2021 / 18-06-2021 (10 Weeks)")}
          {detailFun("Status", "Enrolled")}
          {detailFun("Total Course Fee", "$2,900.00")}
          {detailFun("Total Fee Paid", "$900.00")}
          {detailFun("Total Course Fee Balance Due", "$2,000.00")}
          {detailFun("Course Miscellaneous Fee Due", "$0.00")}
          {detailFun("Invoiced Due Amount", "$2,000.00")}
          {detailFun("Total Miscellaneous Fee Due", "$0.00")}
        </div>

        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Payment schedule(s) for student
          </span>
          <PageHeader>
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <Pagename>Payment Schedule</Pagename>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <AddButton
                  handleClick={() => {
                    setIsAddPaymentScheduleModalVisible(true);
                  }}
                >
                  Add
                </AddButton>
                <ButtonElement
                  handleClick={() => {
                    setIsProcessCommissionModalVisible(true);
                  }}
                  name="Process Commission"
                />
                <ButtonElement
                  handleClick={() => {
                    setIsSubjectFeeScheduleModalVisible(true);
                  }}
                  name="Subject Fee Schedule"
                />
                <ButtonElement
                  handleClick={() => {
                    setIsSubjectFeeScheduleModalVisible(true);
                  }}
                  name="Subject Fee Schedule"
                />
                <ButtonElement
                  handleClick={() => {
                    setIsGenerateInvoiceModalVisible(true);
                  }}
                  name="Combine Invoices"
                />
              </div>
            </div>
          </PageHeader>
          <Table
            scroll={{
              x: 0,
            }}
            bordered
            dataSource={paymentScheduleData}
            columns={paymentScheduleColumn}
          />
        </div>
        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Paid payment list for student
          </span>
          <Table
            scroll={{
              x: 0,
            }}
            bordered
            dataSource={paidData}
            columns={paidColumn}
          />
        </div>
      </div>

      {isUpdatePaymentScheduleModalVisible && (
        <UpdatePaymentSchedule
          isUpdatePaymentScheduleModalVisible={
            isUpdatePaymentScheduleModalVisible
          }
          setIsUpdatePaymentScheduleModalVisible={
            setIsUpdatePaymentScheduleModalVisible
          }
        />
      )}
      {isSchedulePaymentInfoModalVisible && (
        <SchedulePaymentInfo
          isSchedulePaymentInfoModalVisible={isSchedulePaymentInfoModalVisible}
          setIsSchedulePaymentInfoModalVisible={
            setIsSchedulePaymentInfoModalVisible
          }
        />
      )}
      {isPayScheduleFeeModalVisible && (
        <PayScheduleFee
          isPayScheduleFeeModalVisible={isPayScheduleFeeModalVisible}
          setIsPayScheduleFeeModalVisible={setIsPayScheduleFeeModalVisible}
        />
      )}
      {isInvoiceCreditModalVisible && (
        <InvoiceCredit
          isInvoiceCreditModalVisible={isInvoiceCreditModalVisible}
          setIsInvoiceCreditModalVisible={setIsInvoiceCreditModalVisible}
        />
      )}
      {isModifyTransactionModalVisible && (
        <ModifyTransaction
          isModifyTransactionModalVisible={isModifyTransactionModalVisible}
          setIsModifyTransactionModalVisible={
            setIsModifyTransactionModalVisible
          }
        />
      )}
      {/* {isRefundPaymentModalVisible && (
        <RefundPayment
          isRefundPaymentModalVisible={isRefundPaymentModalVisible}
          setIsRefundPaymentModalVisible={setIsRefundPaymentModalVisible}
        />
      )} */}
      {isReverseTransactionModalVisible && (
        <ReverseTransaction
          isReverseTransactionModalVisible={isReverseTransactionModalVisible}
          setIsReverseTransactionModalVisible={
            setIsReverseTransactionModalVisible
          }
        />
      )}
      {isAddPaymentScheduleModalVisible && (
        <AddPaymentSchedule
          isAddPaymentScheduleModalVisible={isAddPaymentScheduleModalVisible}
          setIsAddPaymentScheduleModalVisible={
            setIsAddPaymentScheduleModalVisible
          }
        />
      )}
      {isSubjectFeeScheduleModalVisible && (
        <SubjectFeeSchedule
          isSubjectFeeScheduleModalVisible={isSubjectFeeScheduleModalVisible}
          setIsSubjectFeeScheduleModalVisible={
            setIsSubjectFeeScheduleModalVisible
          }
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isProcessCommissionModalVisible && (
        <ProcessCommission
          isProcessCommissionModalVisible={isProcessCommissionModalVisible}
          setIsProcessCommissionModalVisible={
            setIsProcessCommissionModalVisible
          }
        />
      )}
      {isGenerateInvoiceModalVisible && (
        <GenerateStudentCourseInvoice
          isGenerateStudentCourseInvoiceModalVisible={
            isGenerateInvoiceModalVisible
          }
          setIsGenerateStudentCourseInvoiceModalVisible={
            setIsGenerateInvoiceModalVisible
          }
        />
      )}
    </div>
  );
};

export default PaymentSummary;
