import React from "react";
import { DatePicker, Form } from "antd";

const DatepickerElement = ({
  name,
  label = "",
  placeholder = "",
  fieldWidth = "fit-content",
  hasRule = false,
  showTime = false,
  format = "DD-MM-YYYY",
}) => {
  return (
    <Form.Item
      style={{
        margin: "0",
        width: fieldWidth,
      }}
      name={name}
      label={label}
      rules={[
        {
          required: hasRule,
          message: `${label ? label : name} is required`,
        },
      ]}
    >
      <DatePicker
        showTime={showTime}
        format={format}
        style={{ width: fieldWidth }}
        placeholder={placeholder}
        // format="DD-MM-YYYY"
      />
    </Form.Item>
  );
};

export default DatepickerElement;
