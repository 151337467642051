import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import AddInterventionStrategy from "./AddInterventionStrategy";

const columns = [
  {
    title: "Intervention Type",
    dataIndex: "interventionType",
    width: 100,
    align: "center",
  },
  {
    title: "Strategy",
    dataIndex: "strategy",
    width: 200,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const InterventionStrategyData = [
  {
    id: "1",
    interventionType: "Academic",
    strategy: "extra class",
  },
  {
    id: "2",
    interventionType: "Academic",
    strategy: "Meeting with Student",
  },
  {
    id: "2",
    interventionType: "Attendance",
    strategy: "	Warning Letter",
  },
];

const InterventionStrategy = () => {
  const [
    isAddInterventionStrategyModalVisible,
    setIsAddInterventionStrategyModalVisible,
  ] = useState(false);
  const [
    isUpdateInterventionStrategyModalVisible,
    setIsUpdateInterventionStrategyModalVisible,
  ] = useState(false);

  const data = InterventionStrategyData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      interventionType: dataObj?.interventionType,
      strategy: dataObj?.strategy,
      actions: (
        <Space size="middle">
          <Tooltip title="Delete Intervention Strategy">
            <Popconfirm
              title="Are you sure to delete this intervention strategy?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Intervention Type & Strategy Setup</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddInterventionStrategyModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddInterventionStrategyModalVisible && (
        <AddInterventionStrategy
          isAddInterventionStrategyModalVisible={
            isAddInterventionStrategyModalVisible
          }
          setIsAddInterventionStrategyModalVisible={
            setIsAddInterventionStrategyModalVisible
          }
        />
      )}
    </div>
  );
};

export default InterventionStrategy;
