import React from "react";
import {
  ButtonElement,
  DatepickerElement,
  DrawerElement,
  InputFieldElement,
  SelectField,
} from "../../../../components/input";
import { Form } from "antd";
import InputNumberField from "../../../../components/inputNumberField/InputNumberField";

export default function AddManageVenue() {
  const stateList = [
    {
      label: "NSW",
      value: 1,
    },
    {
      label: "NT",
      value: 2,
    },
  ];
  return (
    <DrawerElement title="Add Venue" keyType="addVenue">
      <Form
        layout="vertical"
        className="grid grid-cols-2 gap-4 mx-4 items-center"
      >
        <InputNumberField
          name="venue_code"
          label="Venue code "
          placeholder="Venue code"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="venue_name"
          placeholder="Venue Name"
          label="Venue Name/Description"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="property_name"
          placeholder="Property Name"
          label="Building/Property Name"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="unit_details"
          placeholder="Unit Details"
          label="Flat/Unit Details"
          fieldWidth="100%"
        />
        <InputNumberField
          name="street_number"
          label="Street Number"
          placeholder="Street Number"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="street_name"
          placeholder="Street Name"
          label="Street Name"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="suburb"
          placeholder="Suburb"
          label="Suburb"
          fieldWidth="100%"
        />
        <span>Country: Austalia</span>
        <SelectField
          name="state"
          placeholder="Select State"
          label="Select State"
          options={stateList}
          fieldWidth="100%"
        />
        <InputNumberField
          name="postcode"
          label="PostCode"
          placeholder="PostCode"
          fieldWidth="100%"
        />
        <InputNumberField
          name="contact_no"
          label="Contact No"
          placeholder="Contact No"
          fieldWidth="100%"
        />
        <InputNumberField
          name="abn"
          label="ABN"
          placeholder="ABN"
          fieldWidth="100%"
        />
        <DatepickerElement
          name="from_date"
          placeholder="DD/MM/YYYY"
          label="Venue Effective From Date"
          fieldWidth="100%"
        />
        <DatepickerElement
          name="to_date"
          placeholder="DD/MM/YYYY"
          label="Venue Effective To Date"
          fieldWidth="100%"
        />
        <ButtonElement name="Add Venue" />
      </Form>
    </DrawerElement>
  );
}
