import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddPaymentMode = ({
  isAddPaymentModeModalVisible,
  setIsAddPaymentModeModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddPaymentModeSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddPaymentMode_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddPaymentModeModalVisible(false);
  };
  return (
    <Modal
      title="Add Payment Mode"
      open={isAddPaymentModeModalVisible}
      onCancel={() => {
        setIsAddPaymentModeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddPaymentModeSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Payment Mode Name"
          name={"paymentModeName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter payment mode name!",
            },
          ]}
        >
          <Input placeholder="Payment Mode Name" />
        </Form.Item>
        <Form.Item
          label="Payment Mode Details"
          name={"paymentModeDetails"}
          style={{ width: "100%" }}
        >
          <TextArea placeholder="Write something..." />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            name={"referenceRequired"}
            valuePropName="checked"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter",
              },
            ]}
          >
            <Checkbox>Reference Required</Checkbox>
          </Form.Item>
          <Form.Item
            label="Bank Account Number"
            name={"bankAccountNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a bank account number!",
              },
            ]}
          >
            <Input placeholder="Bank Account Number" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Payment Mode"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPaymentMode;
