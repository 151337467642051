import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
import { AiOutlinePlus } from "react-icons/ai";
import AddPurchasingContractScheduleId from "./AddPurchasingContractScheduleId";
const { Option } = Select;
const { TextArea } = Input;

const AddTrainingPlan = ({
  isAddTrainingPlanModalVisible,
  setIsAddTrainingPlanModalVisible,
}) => {
  const [isAddEmployerModalVisible, setIsAddEmployerModalVisible] =
    useState(false);
  const [
    isAddPurchasingContractScheduleIdModalVisible,
    setIsAddPurchasingContractScheduleIdModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddTrainingPlanSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_VACCINATION_DOSE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddTrainingPlanModalVisible(false);
  };
  return (
    <Drawer
      title="Add Training Plan"
      open={isAddTrainingPlanModalVisible}
      onClose={() => {
        setIsAddTrainingPlanModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddTrainingPlanSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course"
            name={"course"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a exit type!",
              },
            ]}
          >
            <Select defaultValue={"Select Course"}>
              <Option>EAP: English for Academic Purposes</Option>
              <Option>EAP: English for Academic Purposes - Offshore</Option>
              <Option>EAP: English for Academic Purposes - Other</Option>
              <Option>GE: General English</Option>
              <Option>GE-OS: General English - Offshore</Option>
              <Option>GE-Other: General English - Other</Option>
              <Option>IELTS: IELTS Prepration</Option>
            </Select>
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Employer"
              name={"employer"}
              style={{ width: "100%" }}
            >
              <Select placeholder="Select a Employer"></Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddEmployerModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Training Contract ID"
            name={"trainingContractId"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a training contract ID!",
              },
            ]}
          >
            <Input placeholder="Training Contract ID" />
          </Form.Item>

          <Form.Item
            label="Contract Date"
            name={"contractDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a contract date!",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Contract Type"
            name={"contractType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a contract type!",
              },
            ]}
          >
            <Select placeholder="Contract Type">
              <Option>Full Time</Option>
              <Option>Part Time</Option>
              <Option>School-based</Option>
            </Select>
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Purchasing Contract Schedule ID"
              name={"purchasingContractScheduleId"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a purchasing contract schedule ID!",
                },
              ]}
            >
              <Select placeholder="Select Purchasing Contract Schedule ID">
                <Option>CON023JB002</Option>
                <Option>CON023JB003</Option>
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddPurchasingContractScheduleIdModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Apprenticeship ID"
            name={"apprenticeshipId"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a apprenticeship ID!",
            //   },
            // ]}
          >
            <Input placeholder="Apprenticeship ID" />
          </Form.Item>

          <Form.Item
            label="Apprenticeship Name"
            name={"apprenticeshipName"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick a apprenticeship name!",
            //   },
            // ]}
          >
            <Input placeholder={"Apprenticeship Name"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Supervisor"
            name={"supervisor"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a Supervisor!",
              },
            ]}
          >
            <Input placeholder="Supervisor" />
          </Form.Item>

          <Form.Item
            label="Status"
            name={"status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a contract date!",
              },
            ]}
          >
            <Select placeholder="Select Status">
              <Option value="PROPOSED">Proposed</Option>
              <Option value="APPROVED">Approved</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="State"
            name={"state"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a training contract ID!",
              },
            ]}
          >
            <Select placeholder="Select a State">
              <Option value="ACT">ACT</Option>
              <Option value="NSW">NSW</Option>
              <Option value="NT">NT</Option>
              <Option value="QLD">QLD</Option>
              <Option value="SA">SA</Option>
              <Option value="TAS">TAS</Option>
              <Option value="VIC">VIC</Option>
              <Option value="WA">WA</Option>
              <Option value="OTHER">Other - Overseas</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Funding Source"
            name={"fundingSource"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a Funding Source!",
              },
            ]}
          >
            <Select placeholder={"Select a Funding Source"}>
              <Option>ACT: AAP</Option>
              <Option>ACT: ACE</Option>
              <Option>ACT: FFD</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Venue Code"
            name={"venueCode"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a Venue Code!",
            //   },
            // ]}
          >
            <Input placeholder="Select Venue Code" />
          </Form.Item>

          <Form.Item
            label="Australian Apprenticeship Centre(AAC)"
            name={"australianApprenticeshipCentre"}
            style={{ width: "100%" }}
          >
            <Input placeholder={"Australian Apprenticeship Centre(AAC)"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Notes" name={"notes"} style={{ width: "100%" }}>
            <TextArea placeholder="Write a note" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Training Plan"
          >
            Add Training Plan
          </ButtonElement>
        </Form.Item>
      </Form>
      {isAddPurchasingContractScheduleIdModalVisible && (
        <AddPurchasingContractScheduleId
          isAddPurchasingContractScheduleIdModalVisible={
            isAddPurchasingContractScheduleIdModalVisible
          }
          setIsAddPurchasingContractScheduleIdModalVisible={
            setIsAddPurchasingContractScheduleIdModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default AddTrainingPlan;
