import { Card, Divider } from "antd";
import React from "react";

const StaffProfile = () => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fxRJsb">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      {detailFun("Username", "alex.freitag")}
      {detailFun("College Email", "alex@opera.nsw.edu.au")}
      {detailFun("Mobile", "9659865996")}
    </Card>
  );
};

export default StaffProfile;
