import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const ModifyTransaction = ({
  isModifyTransactionModalVisible,
  setIsModifyTransactionModalVisible,
}) => {
  const [modifyTransactionData, setModifyTransactionData] = useState({
    receiptNo: "NO_NEW_RECEIPT",
    flagInfo: "TUTION_FEE_PAID",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ModifyTransactionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ModifyTransaction_REQUEST", payload: formData });
    // form.resetFields();
    setIsModifyTransactionModalVisible(false);
  };
  return (
    <Drawer
      title="Modify Transaction"
      open={isModifyTransactionModalVisible}
      onClose={() => {
        setIsModifyTransactionModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={ModifyTransactionSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {modifyTransactionData.receiptNo === "NO_NEW_RECEIPT" && (
            <Form.Item
              label="Receipt No"
              name={"receiptNo"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter receipt number!",
                },
              ]}
            >
              <Select
                placeholder="Receipt No"
                onChange={(e) => {
                  setModifyTransactionData((prevData) => {
                    return {
                      ...prevData,
                      receiptNo: e,
                    };
                  });
                }}
              >
                <Option value="1130">1130</Option>
                <Option value="1128">1128</Option>
                <Option value="NEW_RECEIPT">New Receipt</Option>
              </Select>
            </Form.Item>
          )}

          {modifyTransactionData.receiptNo === "NEW_RECEIPT" && (
            <Form.Item
              label={
                <div className="fxRJsb">
                  {" "}
                  Receipt No{" "}
                  <ButtonElement
                    size="small"
                    handleClick={(e) => {
                      setModifyTransactionData((prevData) => {
                        return {
                          ...prevData,
                          receiptNo: "NO_NEW_RECEIPT",
                        };
                      });
                    }}
                    name="Link Existing Receipt"
                  />
                </div>
              }
              name={"receiptNo"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter receipt number!",
                },
              ]}
            >
              <Input placeholder="Receipt No" />
            </Form.Item>
          )}
          <Form.Item
            label="Flag Info"
            name={"flagInfo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter",
              },
            ]}
          >
            <Select
              placeholder="Receipt No"
              onChange={(e) => {
                setModifyTransactionData((prevData) => {
                  return {
                    ...prevData,
                    flagInfo: e,
                  };
                });
              }}
              disabled
            >
              <Option value="TUTION_FEE_PAID">Tution Fee Paid</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Transaction No" style={{ width: "100%" }}>
            121
          </Form.Item>
          <Form.Item label="Total Schedule Fee " style={{ width: "100%" }}>
            666.67
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Transaction Amount" style={{ width: "100%" }}>
            666.67
          </Form.Item>
          <Form.Item
            label="Payment Date"
            name={"paymentDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a payment date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Bank Deposit Date"
            name={"bankDepositDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="Payment Mode"
            name={"PaymentMode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a payment mode!",
              },
            ]}
          >
            <Select placeholder="Payment mode" defaultValue={"BANK_DEPOSIT"}>
              <Option value="BANK_DEPOSIT">Bank Deposit</Option>
              <Option value="CREDIT_CARD">Credit Card</Option>
              <Option value="OTHER">Other</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Agent Bonus"
              name={"agentBonus"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter receipt number!",
                },
              ]}
            >
              <Input type="number" placeholder="Receipt No" />
            </Form.Item>
            <Form.Item
              name={"agentBonus"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter receipt number!",
                },
              ]}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>GST</Radio>
                <Radio value={false}>No GST</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item
            label="Agent Bonus Invoice No"
            style={{ width: "100%" }}
          ></Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Agent Bonus Remarks"
            name={"agentBonusRemarks"}
            style={{ width: "100%" }}
          >
            <TextArea placeholder="Write something..." />
          </Form.Item>
          <Form.Item
            label={"Bonus Paid Date"}
            name={"bonusPaidDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Bad Debt"
            name={"badDebt"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Bad Debt" />
          </Form.Item>
          <Form.Item
            style={{ width: "100%", alignSelf: "flex-end" }}
            name={"isCalculateCommissionAfterAgentBonus"}
            valuePropName="checked"
          >
            <Checkbox>Calculate commission after agent bonus</Checkbox>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Bank Deposited"
            name={"bankDeposited"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Bank Deposited" />
          </Form.Item>
          <Form.Item
            style={{ width: "100%", alignSelf: "flex-end" }}
            name={"isReceiptSent"}
            valuePropName="checked"
          >
            <Checkbox>Receipt Sent</Checkbox>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label={"Receipt sent on"}
            name={"receiptSentOn"}
            style={{ width: "100%" }}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Remarks "
            name={"remarks "}
            style={{ width: "100%" }}
          >
            <TextArea placeholder="Write something..." />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Modify Transaction"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ModifyTransaction;
