import React from "react";
import {
  ButtonElement,
  ModalElement,
  SelectField,
  TextField,
} from "../../../../components/input";
import { Form } from "antd";
import InputNumberField from "../../../../components/inputNumberField/InputNumberField";

export default function AddContractSchedule() {
  const stateList = [
    {
      label: "All",
      value: 1,
    },
    {
      label: "ACT",
      value: 2,
    },
  ];

  const fundingList = [
    {
      label: "ACT AAP",
      value: 1,
    },

    {
      label: "ACT ACE",
      value: 2,
    },
  ];

  return (
    <ModalElement
      title="Add New Contract Schedule"
      keyType="addContractSchedule"
    >
      <Form layout="vertical" className="flex flex-col gap-4">
        <span>Contract code:Test</span>
        <SelectField
          name="state"
          placeholder="Select State"
          label="State"
          options={stateList}
          fieldWidth="100%"
        />
        <SelectField
          name="funding_source"
          placeholder="Select Funding Source"
          label="Funding Source"
          options={fundingList}
          fieldWidth="100%"
        />
        <InputNumberField
          name="schedule_code"
          placeholder="Schedule Code"
          label="Schedule Code"
          fieldWidth="100%"
        />
        <TextField
          name="schedule_description"
          label="Schedule Description"
          placeholder="Schedule Description"
          fieldWidth="100%"
        />
        <ButtonElement name="Add Contract Schedule" bottonWidth="100%" />
      </Form>
    </ModalElement>
  );
}
