import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Table,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const SubjectFeeSchedule = ({
  isSubjectFeeScheduleModalVisible,
  setIsSubjectFeeScheduleModalVisible,
}) => {
  const [subjectFeeScheduleData, setSubjectFeeScheduleData] = useState({
    transactionProcessingType: "INVOICED_AMOUNT",
    addLatePaymentFee: false,
    applySurcharge: false,
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const SubjectFeeScheduleSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "SubjectFeeSchedule_REQUEST", payload: formData });
    // form.resetFields();
    setIsSubjectFeeScheduleModalVisible(false);
  };
  const [payFees, setPayFees] = useState([
    {
      isChecked: false,
      term: "1",
      subjectCode: "GE-A2-A 1",
      subjectName: "GE Elementary",
      studyPeriod: "12-04-2021 / 18-06-2021",
      invoiceStartDate: "12-04-2021",
      dueDate: "12-04-2021",
      fee: "0.00",
    },
  ]);
  const handleIsCheckedChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].isChecked = e.target.checked;
    setPayFees(list);
  };
  const handleInvoiceStartDateChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].invoiceStartDate = e;
    setPayFees(list);
  };
  const handleDueDateChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].dueDate = e;
    setPayFees(list);
  };
  const handleFeeChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].fee = e.target.checked;
    setPayFees(list);
  };

  return (
    <Drawer
      title="Fee Schedule by Subject Enrolment"
      open={isSubjectFeeScheduleModalVisible}
      onClose={() => {
        setIsSubjectFeeScheduleModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={SubjectFeeScheduleSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Select Semester"
            name={"selectSemester"}
            style={{ width: "100%" }}
          >
            <Select
              defaultValue={"INVOICED_AMOUNT"}
              onChange={(e) => {
                setSubjectFeeScheduleData((prevData) => {
                  return {
                    ...prevData,
                    transactionProcessingType: e.target.value,
                  };
                });
              }}
            >
              <Option value={"2022"}>2022</Option>
              <Option value={"2021"}>2023</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Invoice Type"
            name={"invoiceType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a invoice type",
              },
            ]}
          >
            <Radio.Group
              defaultValue={"One Invoice For All Subject(s)"}
              onChange={(e) => {
                subjectFeeScheduleData((prevData) => {
                  return {
                    ...prevData,
                    invoiceType: e.target.value,
                  };
                });
              }}
            >
              <Radio value={"One Invoice For All Subject(s)"}>
                One Invoice For All Subject(s)
              </Radio>
              <Radio value={"Separate Invoice Number for each Subject"}>
                Separate Invoice Number for each Subject
              </Radio>
              <Radio
                value={
                  "Separate Installment for each Subject with one Invoice Number"
                }
              >
                Separate Installment for each Subject with one Invoice Number
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Subject Enrolment list
          </span>
          <div
            style={{
              backgroundColor: "#eeeeee55",
              borderRadius: "0.5rem",
              padding: "0.5rem",
            }}
          >
            {payFees.map((singleQualification, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{
                        justifyContent: "space-between",
                        gap: "0.5rem",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Form.Item
                        style={{
                          alignSelf: "flex-end",
                        }}
                        name={"isPayFee"}
                      >
                        <Checkbox
                          name="isPayFee"
                          value={payFees[index].isChecked}
                          onChange={(e) => {
                            handleIsCheckedChange(e, index);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Term"
                        style={{
                          width: "100%",
                          alignSelf: "flex-end",
                        }}
                      >
                        {payFees[index].term}
                      </Form.Item>
                      <Form.Item
                        label="Subject Code"
                        style={{
                          width: "100%",
                          alignSelf: "flex-end",
                        }}
                      >
                        {payFees[index].subjectCode}
                      </Form.Item>
                      <Form.Item
                        label="Subject Name"
                        style={{
                          width: "100%",
                          alignSelf: "flex-end",
                        }}
                      >
                        {payFees[index].subjectName}
                      </Form.Item>
                      <Form.Item
                        label="Study Period"
                        style={{
                          width: "100%",
                          alignSelf: "flex-end",
                        }}
                      >
                        {payFees[index].studyPeriod}
                      </Form.Item>

                      <Form.Item
                        label="Invoice Start Date"
                        style={{ width: "100%", alignSelf: "flex-end" }}
                      >
                        <Input
                          name={"invoiceStartDate"}
                          placeholder="invoiceStartDate"
                          value={payFees[index].invoiceStartDate}
                          onChange={(e) => {
                            handleInvoiceStartDateChange(e, index);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Due Date"
                        style={{ width: "100%", alignSelf: "flex-end" }}
                      >
                        <Input
                          name={"dueDate"}
                          placeholder="dueDate"
                          value={payFees[index].dueDate}
                          onChange={(e) => {
                            handleDueDateChange(e, index);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Fee"
                        style={{ width: "100%", alignSelf: "flex-end" }}
                      >
                        <Input
                          name={"fee"}
                          placeholder="fee"
                          value={payFees[index].fee}
                          onChange={(e) => {
                            handleFeeChange(e, index);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Schedule Info
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Invoice No"
              name={"invoiceNo"}
              style={{ width: "100%" }}
            >
              <Input placeholder="Invoice No" />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Agent Commission"
                name={"agentCommission"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a commission percentage!",
                  },
                ]}
              >
                <Input type="number" placeholder="" />
              </Form.Item>
              <Form.Item
                name={"isGst"}
                style={{ width: "100%", alignSelf: "flex-end" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a commission percentage!",
                  },
                ]}
              >
                <Radio.Group defaultValue={"NO_GST"}>
                  <Radio value={"GST"}>GST</Radio>
                  <Radio value={"NO_GST"}>No GST</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <Form.Item label="Remarks" name={"remarks"} style={{ width: "100%" }}>
            <TextArea placeholder="Write something..." />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Process Transaction"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default SubjectFeeSchedule;
