import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddServiceProviderFacility = ({
  isAddServiceProviderFacilityModalVisible,
  setIsAddServiceProviderFacilityModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [availabelFacility, setAvailabelFacility] = useState([
    {
      isFacilityChecked: false,
      facilityName: "Document Transfer",
      serviceProviderPrice: "10.00",
      stdPrice: "10.00",
      gstInc: false,
      comment: "",
    },
    {
      isFacilityChecked: false,
      facilityName: "Food Delivary",
      serviceProviderPrice: "80.00",
      stdPrice: "80.00",
      gstInc: false,
      comment: "",
    },
  ]);
  const handleIsFacilityCheckedChange = (e, index) => {
    console.log(e, index);
    const list = [...availabelFacility];
    list[index].isFacilityChecked = e.target.checked;
    setAvailabelFacility(list);
  };
  const handleFacilityNameChange = (e, index) => {
    console.log(e, index);
    const list = [...availabelFacility];
    list[index].facilityName = e.target.value;
    setAvailabelFacility(list);
  };
  const handleserviceProviderPriceChange = (e, index) => {
    console.log(e, index);
    const list = [...availabelFacility];
    list[index].serviceProviderPrice = e.target.value;
    setAvailabelFacility(list);
  };
  const handleStdPriceChange = (e, index) => {
    console.log(e, index);
    const list = [...availabelFacility];
    list[index].serviceProviderPrice = e.target.value;
    setAvailabelFacility(list);
  };
  const handleGstIncChange = (e, index) => {
    console.log(e, index);
    const list = [...availabelFacility];
    list[index].gstInc = e.target.value;
    setAvailabelFacility(list);
  };
  const handleCommentChange = (e, index) => {
    console.log(e, index);
    const list = [...availabelFacility];
    list[index].comment = e.target.value;
    setAvailabelFacility(list);
  };

  const AddServiceProviderFacilitySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddServiceProviderFacility_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddServiceProviderFacilityModalVisible(false);
  };
  return (
    <Drawer
      title="Add Facility"
      open={isAddServiceProviderFacilityModalVisible}
      onClose={() => {
        setIsAddServiceProviderFacilityModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddServiceProviderFacilitySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Provider"
            name={"serviceProvider"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a service provider!",
              },
            ]}
          >
            <Select placeholder="Service Provider">
              <Option value="UPS">UPS</Option>
              <Option value="DHL">DHL</Option>
              <Option value="AUSTRALIAN_EXPRESS">Australian Express</Option>
              <Option value="ABC Homestay">ABC Homestay</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Service Name"
            name={"serviceName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a service name !",
              },
            ]}
          >
            <Select placeholder="Service Name">
              <Option value="AIRPORT_PICKUP">Airport Pickup</Option>
              <Option value="ACCOMODATION">Accomodation</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Category"
            name={"serviceCategory"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a service category",
              },
            ]}
          >
            <Select placeholder="Service Category">
              <Option value="TRANSPORT">Transport</Option>
              <Option value="CARGO">Cargo</Option>
            </Select>
          </Form.Item>
        </div>
        <>
          <div
            style={{
              backgroundColor: "#eeeeee55",
              borderRadius: "0.5rem",
              padding: "0.5rem",
            }}
          >
            <h3 className="font-semibold text-sm mb-2">Available Facility:</h3>
            {availabelFacility.map((singleQualification, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{
                        justifyContent: "space-between",
                        gap: "0.5rem",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Form.Item valuePropName="checked" className="self-end">
                        <Checkbox
                          name={"isFacilityChecked"}
                          placeholder="From Date"
                          value={availabelFacility[index].isFacilityChecked}
                          onChange={(e) => {
                            handleIsFacilityCheckedChange(e, index);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Facility Name"
                        style={{
                          width: "100%",
                        }}
                      >
                        {availabelFacility[index].facilityName}
                      </Form.Item>
                      <Form.Item
                        label="Service Provider Price"
                        style={{ width: "100%" }}
                      >
                        <Input
                          name={"serviceProviderPrice"}
                          placeholder="Service Provider Price"
                          value={availabelFacility[index].serviceProviderPrice}
                          onChange={(e) => {
                            handleserviceProviderPriceChange(e, index);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label="Std Price" style={{ width: "100%" }}>
                        <Input
                          name={"stdPrice"}
                          placeholder="Std Price"
                          value={availabelFacility[index].stdPrice}
                          onChange={(e) => {
                            handleStdPriceChange(e, index);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label="GST Inc." style={{ width: "100%" }}>
                        <Radio.Group
                          name={"gstInc"}
                          value={availabelFacility[index].gstInc}
                          onChange={(e) => {
                            handleGstIncChange(e, index);
                          }}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="Comment" style={{ width: "100%" }}>
                        <Input
                          name={"comment"}
                          placeholder="Comment"
                          value={availabelFacility[index].comment}
                          onChange={(e) => {
                            handleCommentChange(e, index);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Divider
                    orientationMargin="0"
                    style={{ margin: "0", padding: "0", color: "white" }}
                  />
                </div>
              );
            })}
          </div>
        </>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Facility"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddServiceProviderFacility;
