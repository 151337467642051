import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import AddButton from "../../../../components/button/AddButton";
import AddExitInterview from "./AddExitInterview";

const columns = [
  {
    title: "Entered Time",
    dataIndex: "enteredTime",
    width: 250,
    align: "center",
  },
  {
    title: "Exit Type",
    dataIndex: "exitType",
    width: 150,
    align: "center",
  },
  {
    title: "Course Info",
    dataIndex: "courseInfo",
    width: 150,
    align: "center",
  },
  {
    title: "Reason",
    dataIndex: "reason",
    width: 100,
    align: "center",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    width: 100,
    align: "center",
  },
  {
    title: "New Provider",
    dataIndex: "newProvider",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const vaccineDetailsData = [
  {
    id: 1,
    enteredTime: "20-08-2023 8:19:29 PM",
    exitType: "Provider",
    courseInfo: "",
    reason: "Withdrawn from course",
    comment: "Due to medical emergency",
    newProvider: "Provider",
  },
];

const Interview = () => {
  const [isAddInterviewModalVisible, setIsAddInterviewModalVisible] =
    useState(false);
  const [isUpdateInterviewModalVisible, setIsUpdateInterviewModalVisible] =
    useState(false);
  const [
    isAddVaccinationDoseModalVisible,
    setIsAddVaccinationDoseModalVisible,
  ] = useState(false);
  const data = vaccineDetailsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      enteredTime: dataObj?.enteredTime,
      exitType: dataObj?.exitType,
      courseInfo: dataObj?.courseInfo,
      reason: dataObj?.reason,
      comment: dataObj?.comment,
      newProvider: dataObj?.newProvider,
      actions: (
        <Space size="middle">
          <Tooltip title="Delete Entry">
            <Popconfirm
              title="Are you sure to delete selected entry?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Student Exit Interview Details</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddInterviewModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddInterviewModalVisible && (
        <AddExitInterview
          isAddExitInterviewModalVisible={isAddInterviewModalVisible}
          setIsAddExitInterviewModalVisible={setIsAddInterviewModalVisible}
        />
      )}
    </Card>
  );
};

export default Interview;
