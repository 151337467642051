import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import AddButton from "../../../../components/button/AddButton";
import AddVaccinationDetail from "./AddVaccinationDetail";
import AddVaccinationDose from "./VaccinationDose/AddVaccinationDose";
import UpdateVaccinationDetail from "./UpdateVaccinationDetail";

const columns = [
  {
    title: "Vaccination Type",
    dataIndex: "vaccinationType",
    width: 250,
    align: "center",
  },
  {
    title: "Document Type",
    dataIndex: "documentType",
    width: 150,
    align: "center",
  },
  {
    title: "Document Number",
    dataIndex: "documentNumber",
    width: 150,
    align: "center",
  },
  {
    title: "Issued Date",
    dataIndex: "issuedDate",
    width: 100,
    align: "center",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
    width: 100,
    align: "center",
  },
  {
    title: "Issued By",
    dataIndex: "issuedBy",
    width: 100,
    align: "center",
  },
  {
    title: "Issued Country",
    dataIndex: "issuedCountry",
    width: 100,
    align: "center",
  },
  {
    title: "Verified By",
    dataIndex: "verifiedBy",
    width: 100,
    align: "center",
  },
  {
    title: "Verified Date",
    dataIndex: "verifiedDate",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const vaccineDetailsData = [
  {
    id: 1,
    vaccinationType: "Covid-19",
    documentType: "Certificate",
    documentNumber: "84564",
    issuedDate: "19/05/2022",
    expiryDate: "19/05/2023",
    issuedBy: "Abhinav Sharma",
    issuedCountry: "Australia",
    verifiedBy: "Manish Shrestha",
    verifiedDate: "16-09-2023",
  },
];

const StudentVaccinationDetails = () => {
  const [
    isAddVaccinationDetailModalVisible,
    setIsAddVaccinationDetailModalVisible,
  ] = useState(false);
  const [
    isUpdateVaccinationDetailModalVisible,
    setIsUpdateVaccinationDetailModalVisible,
  ] = useState(false);
  const [
    isAddVaccinationDoseModalVisible,
    setIsAddVaccinationDoseModalVisible,
  ] = useState(false);
  const data = vaccineDetailsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      vaccinationType: dataObj?.vaccinationType,
      documentType: dataObj?.documentType,
      documentNumber: dataObj?.documentNumber,
      issuedDate: dataObj?.issuedDate,
      expiryDate: dataObj?.expiryDate,
      issuedBy: dataObj?.issuedBy,
      issuedCountry: dataObj?.issuedCountry,
      verifiedBy: dataObj?.verifiedBy,
      verifiedDate: dataObj?.verifiedDate,
      actions: (
        <Space size="middle">
          <Tooltip title="Add Vaccination Dose">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsAddVaccinationDoseModalVisible(true);
                }}
              >
                <AiOutlinePlus />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Update Teacher Matrix">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateVaccinationDetailModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete selected teacher matrix?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Vaccination Detail</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddVaccinationDetailModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddVaccinationDetailModalVisible && (
        <AddVaccinationDetail
          isAddVaccinationDetailModalVisible={
            isAddVaccinationDetailModalVisible
          }
          setIsAddVaccinationDetailModalVisible={
            setIsAddVaccinationDetailModalVisible
          }
        />
      )}
      {isAddVaccinationDoseModalVisible && (
        <AddVaccinationDose
          isAddVaccinationDoseModalVisible={isAddVaccinationDoseModalVisible}
          setIsAddVaccinationDoseModalVisible={
            setIsAddVaccinationDoseModalVisible
          }
        />
      )}
      {isUpdateVaccinationDetailModalVisible && (
        <UpdateVaccinationDetail
          isUpdateVaccinationDetailModalVisible={
            isUpdateVaccinationDetailModalVisible
          }
          setIsUpdateVaccinationDetailModalVisible={
            setIsUpdateVaccinationDetailModalVisible
          }
        />
      )}
    </Card>
  );
};

export default StudentVaccinationDetails;
