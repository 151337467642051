import { Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const DrawerElement = ({ children, title, keyType }) => {
  const dispatch = useDispatch();
  const { openDrawer, drawerType } = useSelector(
    (store) => store.DrawerReducer
  );

  const handleCloseDrawer = () => {
    dispatch({ type: "FETCH_CLOSE_DRAWER" });
  };

  return (
    <Drawer
      footer={null}
      // width={"90vw"}
      title={title}
      placement="right"
      onClose={handleCloseDrawer}
      open={openDrawer && keyType?.toLowerCase() === drawerType?.toLowerCase()}
    >
      {children}
    </Drawer>
  );
};

export default DrawerElement;
