import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFilePdf,
  AiOutlinePlus,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddStudentScholarship from "./AddStudentScholarship";
import UpdateStudentScholarship from "./UpdateStudentScholarship";

const columns = [
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 250,
    align: "center",
  },
  {
    title: "Scholarship Amount",
    dataIndex: "scholarshipAmount",
    width: 150,
    align: "center",
  },
  {
    title: "Used Amount",
    dataIndex: "usedAmount",
    width: 150,
    align: "center",
  },
  {
    title: "Recorded On",
    dataIndex: "recordedOn",
    width: 100,
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 100,
    align: "center",
  },
];

const StudentScholarshipData = [
  {
    id: 1,
    courseName: "GE : General English (Course Attempt : 1)",
    scholarshipAmount: "10",
    usedAmount: "0",
    recordedOn: "07-09-2023",
    description: "",
  },
];

const StudentScholarship = () => {
  const [
    isAddStudentScholarshipModalVisible,
    setIsAddStudentScholarshipModalVisible,
  ] = useState(false);
  const [
    isUpdateStudentScholarshipModalVisible,
    setIsUpdateStudentScholarshipModalVisible,
  ] = useState(false);

  const data = StudentScholarshipData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      courseName: dataObj?.courseName,
      scholarshipAmount: dataObj?.scholarshipAmount,
      usedAmount: dataObj?.usedAmount,
      recordedOn: dataObj?.recordedOn,
      description: dataObj?.description,
      actions: (
        <Space size="middle">
          <Tooltip title="Update">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStudentScholarshipModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete this Refund Info">
            <Popconfirm
              title="Are you sure to delete this refund info?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Student Scholarship</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddStudentScholarshipModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 0,
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddStudentScholarshipModalVisible && (
        <AddStudentScholarship
          isAddStudentScholarshipModalVisible={
            isAddStudentScholarshipModalVisible
          }
          setIsAddStudentScholarshipModalVisible={
            setIsAddStudentScholarshipModalVisible
          }
        />
      )}
      {isUpdateStudentScholarshipModalVisible && (
        <UpdateStudentScholarship
          isUpdateStudentScholarshipModalVisible={
            isUpdateStudentScholarshipModalVisible
          }
          setIsUpdateStudentScholarshipModalVisible={
            setIsUpdateStudentScholarshipModalVisible
          }
        />
      )}
    </Card>
  );
};

export default StudentScholarship;
