import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const GeneratePermanentStudntId = ({
  isGeneratePermanentStudntIdModalVisible,
  setIsGeneratePermanentStudntIdModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const GeneratePermanentStudntIdSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_PROVIDER_REQUEST", payload: formData });
    // form.resetFields();
    setIsGeneratePermanentStudntIdModalVisible(false);
  };
  return (
    <Modal
      title="Generate Permanent Studnt ID"
      open={isGeneratePermanentStudntIdModalVisible}
      onCancel={() => {
        setIsGeneratePermanentStudntIdModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={GeneratePermanentStudntIdSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Application" style={{ width: "100%" }}>
            19
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Recommended student ID (Student ID format legend: IDYYYY0000)"
            name={"recommendedStudentId"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a student ID!",
              },
            ]}
          >
            <Input placeholder="Student ID" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Generate Permanent Studnt ID"
          >
            Generate Permanent Studnt ID
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GeneratePermanentStudntId;
