import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";

import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import PageHeader from "../../../../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../../../../components/input";
import AddButton from "../../../../../../components/button/AddButton";
import AddStudentSubjectEnrollment from "./AddStudentSubjectEnrollment/AddStudentSubjectEnrollment";

const columns = [
  {
    title: "Semester/Term",
    dataIndex: "semester",
    width: 250,
    align: "center",
  },
  {
    title: "Course Stage",
    dataIndex: "courseStage",
    width: 250,
    align: "center",
  },
  {
    title: "Subject/Module",
    dataIndex: "subject",
    width: 250,
    align: "center",
  },
  {
    title: "Batch",
    dataIndex: "batch",
    width: 250,
    align: "center",
  },
  {
    title: "Study Period",
    dataIndex: "studyPeriod",
    width: 250,
    align: "center",
  },
  {
    title: "Final Outcome",
    dataIndex: "finalOutcome",
    width: 250,
    align: "center",
  },
  {
    title: "Grade",
    dataIndex: "grade",
    width: 250,
    align: "center",
  },
  {
    title: "Marks(%)",
    dataIndex: "marks",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    semester: "2021 (Term 1)",
    courseStage: "1",
    subject: "GE-A2-A: GE Elementary (Attempt 1)",
    batch: "No Batch",
    studyPeriod: "12/04/2021 - 18/06/2021",
    finalOutcome: "Enrolled",
    grade: "Enrolled",
    marks: "Not Marked",
  },
];

const StudentSubjectEnrollment = () => {
  const [
    isAddStudentSubjectEnrollmentModalVisible,
    setIsAddStudentSubjectEnrollmentModalVisible,
  ] = useState(false);
  const [
    isUpdateStudentSubjectEnrollmentModalVisible,
    setIsUpdateStudentSubjectEnrollmentModalVisible,
  ] = useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      key: dataObj.id,
      semester: dataObj?.semester,
      courseStage: dataObj?.courseStage,
      subject: dataObj?.subject,
      batch: dataObj?.batch,
      studyPeriod: dataObj?.studyPeriod,
      finalOutcome: dataObj?.finalOutcome,
      grade: dataObj?.grade,
      marks: dataObj?.marks,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Teacher Matrix">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStudentSubjectEnrollmentModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete selected teacher matrix?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Student Subject Enrolment</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddStudentSubjectEnrollmentModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      {isAddStudentSubjectEnrollmentModalVisible && (
        <AddStudentSubjectEnrollment
          isAddStudentSubjectEnrollmentModalVisible={
            isAddStudentSubjectEnrollmentModalVisible
          }
          setIsAddStudentSubjectEnrollmentModalVisible={
            setIsAddStudentSubjectEnrollmentModalVisible
          }
        />
      )}
      {/* {isUpdateStudentSubjectEnrollmentModalVisible && (
        <UpdateStudentSubjectEnrollment
          isUpdateStudentSubjectEnrollmentModalVisible={
            isUpdateStudentSubjectEnrollmentModalVisible
          }
          setIsUpdateStudentSubjectEnrollmentModalVisible={
            setIsUpdateStudentSubjectEnrollmentModalVisible
          }
        />
      )} */}
    </Card>
  );
};

export default StudentSubjectEnrollment;
