import React from "react";
import { Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const ShortCourseApplicantCurrentStreetAddress = ({
  applicantData,
  setApplicantData,
  current,
  setCurrent,
}) => {
  const [form] = Form.useForm();

  const addShortCourseApplicantCurrentStreetAddressHandler = () => {
    setCurrent(2);
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addShortCourseApplicantCurrentStreetAddressHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["haveOverseasPermanentAddress"],
            value: applicantData?.haveOverseasPermanentAddress,
          },
          {
            name: ["havePostalAddress"],
            value: applicantData?.havePostalAddress,
          },
          { name: ["country"], value: "AUSTRALIA" },
          {
            name: ["buildingPropertyName"],
            value: applicantData?.currentAddress?.buildingPropertyName,
          },
          {
            name: ["flatUnitDetails"],
            value: applicantData?.currentAddress?.flatUnitDetails,
          },
          {
            name: ["streetNumber"],
            value: applicantData?.currentAddress?.streetNumber,
          },
          {
            name: ["streetName"],
            value: applicantData?.currentAddress?.streetName,
          },
          { name: ["suburb"], value: applicantData?.currentAddress?.suburb },
          { name: ["state"], value: applicantData?.currentAddress?.state },
          { name: ["state"], value: applicantData?.currentAddress?.state },
          {
            name: ["postcode"],
            value: applicantData?.currentAddress?.postcode,
          },
          {
            name: ["homePhone"],
            value: applicantData?.currentAddress?.homePhone,
          },
          {
            name: ["workPhone"],
            value: applicantData?.currentAddress?.workPhone,
          },
          {
            name: ["mobilePhone"],
            value: applicantData?.currentAddress?.mobilePhone,
          },
          {
            name: ["countryPostal"],
            value: applicantData?.postalAddress?.country,
          },
          {
            name: ["buildingPropertyNamePostal"],
            value: applicantData?.postalAddress?.buildingPropertyName,
          },
          {
            name: ["flatUnitDetailsPostal"],
            value: applicantData?.postalAddress?.flatUnitDetails,
          },
          {
            name: ["streetNumberPostal"],
            value: applicantData?.postalAddress?.streetNumber,
          },
          {
            name: ["postalDeliveryInformation"],
            value: applicantData?.postalAddress?.postalDeliveryInformation,
          },
          {
            name: ["streetNamePostal"],
            value: applicantData?.postalAddress?.streetName,
          },
          {
            name: ["suburbPostal"],
            value: applicantData?.postalAddress?.suburb,
          },
          { name: ["statePostal"], value: applicantData?.postalAddress?.state },
          { name: ["statePostal"], value: applicantData?.postalAddress?.state },
          {
            name: ["postcodePostal"],
            value: applicantData?.postalAddress?.postcode,
          },
          {
            name: ["homePhonePostal"],
            value: applicantData?.postalAddress?.homePhone,
          },
          {
            name: ["workPhonePostal"],
            value: applicantData?.postalAddress?.workPhone,
          },
          {
            name: ["mobilePhonePostal"],
            value: applicantData?.postalAddress?.mobilePhone,
          },
          {
            name: ["countryPermanent"],
            value: applicantData?.overseasPermanentAddress?.country,
          },
          {
            name: ["buildingPropertyNamePermanent"],
            value:
              applicantData?.overseasPermanentAddress?.buildingPropertyName,
          },
          {
            name: ["flatUnitDetailsPermanent"],
            value: applicantData?.overseasPermanentAddress?.flatUnitDetails,
          },
          {
            name: ["streetNumberPermanent"],
            value: applicantData?.overseasPermanentAddress?.streetNumber,
          },
          {
            name: ["streetNamePermanent"],
            value: applicantData?.overseasPermanentAddress?.streetName,
          },
          {
            name: ["suburbPermanent"],
            value: applicantData?.overseasPermanentAddress?.suburb,
          },
          {
            name: ["statePermanent"],
            value: applicantData?.overseasPermanentAddress?.state,
          },
          {
            name: ["statePermanent"],
            value: applicantData?.overseasPermanentAddress?.state,
          },
          {
            name: ["postcodePermanent"],
            value: applicantData?.overseasPermanentAddress?.postcode,
          },
          {
            name: ["homePhonePermanent"],
            value: applicantData?.overseasPermanentAddress?.homePhone,
          },
          {
            name: ["workPhonePermanent"],
            value: applicantData?.overseasPermanentAddress?.workPhone,
          },
          {
            name: ["mobilePhonePermanent"],
            value: applicantData?.overseasPermanentAddress?.mobilePhone,
          },
        ]}
      >
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Current Street Address
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a country!",
                },
              ]}
            >
              <Select
                placeholder="Country"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        country: e,
                      },
                    };
                  });
                }}
                disabled
              >
                <Option value="AUSTRALIA">Australia</Option>
                <Option value="NEPAL">Nepal</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Building/Property Name"
              name={"buildingPropertyName"}
              style={{ width: "100%", alignSelf: "flex-end" }}
            >
              <Input
                placeholder="Building/Property Name"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        buildingPropertyName: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Flat/Unit Details"
              name={"flatUnitDetails"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Flat/Unit Details"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        flatUnitDetails: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Street Number"
              name={"streetNumber"}
              style={{ width: "100%", alignSelf: "flex-end" }}
              rules={[
                {
                  required: true,
                  message: "Please enter street number!",
                },
              ]}
            >
              <Input
                placeholder="Street Number (e.g. 205 or Lot 118)"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        streetNumber: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Street Name"
              name={"streetName"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Street Name"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        streetName: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="City/Town/Suburb"
              name={"suburb"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a city, town or suburb!",
                },
              ]}
            >
              <Input
                placeholder="City, Town or Suburb"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        suburb: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            {applicantData?.currentAddress?.country?.toLowerCase() ===
            "australia" ? (
              <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
                <Select
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        currentAddress: {
                          ...prevData.currentAddress,
                          state: e,
                        },
                      };
                    });
                  }}
                >
                  <Option value="NSW|New South Wales">
                    NSW|New South Wales
                  </Option>
                  <Option value="QLD|Queensland">QLD|Queensland</Option>
                  <Option value="SA|South Australia">SA|South Australia</Option>
                  <Option value="TAS|Tasmania">TAS|Tasmania</Option>
                  <Option value="VIC|Victoria">VIC|Victoria</Option>
                  <Option value="WA|Western Australia">
                    WA|Western Australia
                  </Option>
                  <Option value="ACT|Australian Capital Territory">
                    ACT|Australian Capital Territory
                  </Option>
                  <Option value="NT|Northern Territory">
                    NT|Northern Territory
                  </Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
                <Input
                  placeholder="State"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        currentAddress: {
                          ...prevData.currentAddress,
                          state: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Postcode"
              name={"postcode"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a post code!",
                },
              ]}
            >
              <Input
                placeholder="Postcode"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        postcode: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Home Phone"
              name={"homePhone"}
              style={{ width: "100%" }}
              rules={[
                {
                  pattern: /^[\d]{9,11}$/,
                  message: "Phone number should contain 9 to 11 numbers",
                },
              ]}
            >
              <Input
                placeholder="Home Phone"
                type="number"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        homePhone: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Work Phone"
              name={"workPhone"}
              style={{ width: "100%" }}
              rules={[
                {
                  pattern: /^[\d]{9,11}$/,
                  message: "Phone number should contain 9 to 11 numbers",
                },
              ]}
            >
              <Input
                placeholder="Work Phone"
                type="number"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        workPhone: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Mobile Phone"
              name={"mobilePhone"}
              style={{ width: "100%" }}
              rules={[
                {
                  pattern: /^[\d]{9,11}$/,

                  message: "Phone number should contain 9 to 11 numbers",
                },
              ]}
            >
              <Input
                placeholder="Mobile Phone"
                type="number"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      currentAddress: {
                        ...prevData.currentAddress,
                        mobilePhone: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              name={"havePostalAddress"}
              valuePropName="checked"
              style={{ width: "100%" }}
            >
              <Checkbox
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      havePostalAddress: e.target.checked,
                    };
                  });
                }}
              >
                Do you have a Postal Address?(if different to your current
                street address)
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={"haveOverseasPermanentAddress"}
              valuePropName="checked"
              style={{ width: "100%" }}
            >
              <Checkbox
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      haveOverseasPermanentAddress: e.target.checked,
                    };
                  });
                }}
              >
                Do you have an Overseas/Permanent Address?
              </Checkbox>
            </Form.Item>
          </div>
        </div>
        {applicantData?.havePostalAddress && (
          <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
            <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
              Postal Address
            </span>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Country"
                name={"countryPostal"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a country!",
                  },
                ]}
              >
                <Select
                  placeholder="Country"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          country: e,
                        },
                      };
                    });
                  }}
                >
                  <Option value="AUSTRALIA">Australia</Option>
                  <Option value="NEPAL">Nepal</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Building/Property Name"
                name={"buildingPropertyNamePostal"}
                style={{ width: "100%", alignSelf: "flex-end" }}
              >
                <Input
                  placeholder="Building/Property Name"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          buildingPropertyName: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Flat/Unit Details"
                name={"flatUnitDetailsPostal"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Flat/Unit Details"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          flatUnitDetails: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Street Number"
                name={"streetNumberPostal"}
                style={{ width: "100%", alignSelf: "flex-end" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter street number!",
                  },
                ]}
              >
                <Input
                  placeholder="Street Number (e.g. 205 or Lot 118)"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          streetNumber: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Postal delivery information (e.g. PO Box 254)"
              name={"postalDeliveryInformation"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Street Name"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      postalAddress: {
                        ...prevData.postalAddress,
                        postalDeliveryInformation: e.target.value,
                      },
                    };
                  });
                }}
              />
            </Form.Item>

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Street Name"
                name={"streetNamePostal"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Street Name"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          streetName: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="City/Town/Suburb"
                name={"suburbPostal"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a city, town or suburb!",
                  },
                ]}
              >
                <Input
                  placeholder="City, Town or Suburb"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          suburb: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {applicantData?.postalAddress?.country?.toLowerCase() ===
              "australia" ? (
                <Form.Item
                  label="State"
                  name={"statePostal"}
                  style={{ width: "100%" }}
                >
                  <Select
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          postalAddress: {
                            ...prevData.postalAddress,
                            state: e,
                          },
                        };
                      });
                    }}
                  >
                    <Option value="NSW|New South Wales">
                      NSW|New South Wales
                    </Option>
                    <Option value="QLD|Queensland">QLD|Queensland</Option>
                    <Option value="SA|South Australia">
                      SA|South Australia
                    </Option>
                    <Option value="TAS|Tasmania">TAS|Tasmania</Option>
                    <Option value="VIC|Victoria">VIC|Victoria</Option>
                    <Option value="WA|Western Australia">
                      WA|Western Australia
                    </Option>
                    <Option value="ACT|Australian Capital Territory">
                      ACT|Australian Capital Territory
                    </Option>
                    <Option value="NT|Northern Territory">
                      NT|Northern Territory
                    </Option>
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label="State"
                  name={"statePostal"}
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="State"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          postalAddress: {
                            ...prevData.postalAddress,
                            state: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="Postcode"
                name={"postcodePostal"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a post code!",
                  },
                ]}
              >
                <Input
                  placeholder="Postcode"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          postcode: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Home Phone"
                name={"homePhonePostal"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,

                    message: "Phone number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  placeholder="Home Phone"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          homePhone: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Work Phone"
                name={"workPhonePostal"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,

                    message: "Phone number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  placeholder="Work Phone"
                  type="number"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          workPhone: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Mobile Phone"
                name={"mobilePhonePostal"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,

                    message: "Phone number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  placeholder="Mobile Phone"
                  type="number"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        postalAddress: {
                          ...prevData.postalAddress,
                          mobilePhone: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
          </div>
        )}
        {applicantData?.haveOverseasPermanentAddress && (
          <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
            <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
              Overseas/Permanent Address
            </span>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Country"
                name={"countryPermanent"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a country!",
                  },
                ]}
              >
                <Select
                  placeholder="Country"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          country: e,
                        },
                      };
                    });
                  }}
                >
                  <Option value="AUSTRALIA">Australia</Option>
                  <Option value="NEPAL">Nepal</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Building/Property Name"
                name={"buildingPropertyNamePermanent"}
                style={{ width: "100%", alignSelf: "flex-end" }}
              >
                <Input
                  placeholder="Building/Property Name"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          buildingPropertyName: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Flat/Unit Details"
                name={"flatUnitDetailsPermanent"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Flat/Unit Details"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          flatUnitDetails: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Street Number"
                name={"streetNumberPermanent"}
                style={{ width: "100%", alignSelf: "flex-end" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter street number!",
                  },
                ]}
              >
                <Input
                  placeholder="Street Number (e.g. 205 or Lot 118)"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          streetNumber: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Street Name"
                name={"streetNamePermanent"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Street Name"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          streetName: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="City/Town/Suburb"
                name={"suburbPermanent"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a city, town or suburb!",
                  },
                ]}
              >
                <Input
                  placeholder="City, Town or Suburb"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          suburb: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {applicantData?.overseasPermanentAddress?.country?.toLowerCase() ===
              "australia" ? (
                <Form.Item
                  label="State"
                  name={"statePermanent"}
                  style={{ width: "100%" }}
                >
                  <Select
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          overseasPermanentAddress: {
                            ...prevData.overseasPermanentAddress,
                            state: e,
                          },
                        };
                      });
                    }}
                  >
                    <Option value="NSW|New South Wales">
                      NSW|New South Wales
                    </Option>
                    <Option value="QLD|Queensland">QLD|Queensland</Option>
                    <Option value="SA|South Australia">
                      SA|South Australia
                    </Option>
                    <Option value="TAS|Tasmania">TAS|Tasmania</Option>
                    <Option value="VIC|Victoria">VIC|Victoria</Option>
                    <Option value="WA|Western Australia">
                      WA|Western Australia
                    </Option>
                    <Option value="ACT|Australian Capital Territory">
                      ACT|Australian Capital Territory
                    </Option>
                    <Option value="NT|Northern Territory">
                      NT|Northern Territory
                    </Option>
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label="State"
                  name={"statePermanent"}
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="State"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          overseasPermanentAddress: {
                            ...prevData.overseasPermanentAddress,
                            state: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="Postcode"
                name={"postcodePermanent"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a post code!",
                  },
                ]}
              >
                <Input
                  placeholder="Postcode"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          postcode: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Home Phone"
                name={"homePhonePermanent"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,

                    message: "Phone number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  placeholder="Home Phone"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          homePhone: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Work Phone"
                name={"workPhonePermanent"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,

                    message: "Phone number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  placeholder="Work Phone"
                  type="number"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          workPhone: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Mobile Phone"
                name={"mobilePhonePermanent"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,

                    message: "Phone number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  placeholder="Mobile Phone"
                  type="number"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overseasPermanentAddress: {
                          ...prevData.overseasPermanentAddress,
                          mobilePhone: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
          </div>
        )}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {current === 1 && (
            <Form.Item>
              <ButtonElement
                type={"default"}
                handleClick={() => {
                  setCurrent(0);
                }}
                name="Back"
              >
                Back
              </ButtonElement>
            </Form.Item>
          )}
          <Form.Item>
            <ButtonElement type="primary" htmlType="submit" name="Next">
              Next
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ShortCourseApplicantCurrentStreetAddress;
