import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;

const ReverseTransaction = ({
  isReverseTransactionModalVisible,
  setIsReverseTransactionModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ReverseTransactionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ReverseTransaction_REQUEST", payload: formData });
    // form.resetFields();
    setIsReverseTransactionModalVisible(false);
  };
  return (
    <Modal
      title="Reverse Transaction"
      open={isReverseTransactionModalVisible}
      onCancel={() => {
        setIsReverseTransactionModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={ReverseTransactionSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Reverse Comment"
          name={"reverseComment"}
          rules={[
            {
              required: true,
              message: "Please enter a reverse comment!",
            },
          ]}
        >
          <Input placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Reverse Now"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReverseTransaction;
