import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddTransferPayment = ({
  isAddTransferPaymentModalVisible,
  setIsAddTransferPaymentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddTransferPaymentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddTransferPayment_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddTransferPaymentModalVisible(false);
  };
  return (
    <Drawer
      title="Transfer Payment"
      open={isAddTransferPaymentModalVisible}
      onClose={() => {
        setIsAddTransferPaymentModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddTransferPaymentSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            From Course
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Course name"
              name={"courseName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a course name ",
                },
              ]}
            >
              <Select placeholder="Course Name">
                <Option value="GE: General English-12-04-2021 - Enrolled">
                  GE: General English-12-04-2021 - Enrolled
                </Option>
                <Option value="IELTS: IELTS Preparation-12-04-2021 - Enrolled">
                  IELTS: IELTS Preparation-12-04-2021 - Enrolled
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Transaction No"
              name={"transactionNo"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a transaction number",
                },
              ]}
            >
              <Select placeholder="Transaction No">
                <Option value="120">120</Option>
              </Select>
            </Form.Item>
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Auto Update Agent Commission"
              name={"autoUpdateAgentCommission"}
              style={{ width: "100%" }}
            >
              <Radio.Group defaultValue={false}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Date of payment" style={{ width: "100%" }}>
              12-04-2021
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item label="Transaction amount" style={{ width: "100%" }}>
              $900.00
            </Form.Item>
            <Form.Item
              label="Available amount to transfer"
              style={{ width: "100%" }}
            >
              $790.00
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Commission payable(GST Inc.)"
              style={{ width: "100%" }}
            >
              $0.00
            </Form.Item>
            <Form.Item
              label="Commission paid amount (GST Inc.)"
              style={{ width: "100%" }}
            >
              $0.00
            </Form.Item>
          </div>
          <Form.Item label="Comment" name={"comment"} style={{ width: "100%" }}>
            <TextArea placeholder="Comment" />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            To Course
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="New course"
              name={"newcourse"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a new course",
                },
              ]}
            >
              <Select placeholder="New Course">
                <Option value="GE: General English-12-04-2021 - Enrolled">
                  GE: General English-12-04-2021 - Enrolled
                </Option>
                <Option value="IELTS: IELTS Preparation-12-04-2021 - Enrolled">
                  IELTS: IELTS Preparation-12-04-2021 - Enrolled
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Transfer date"
              name={"transferDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a transfer date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Transfer amount"
              name={"transferAmount"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a transfer amount",
                },
              ]}
            >
              <Input type="number" placeholder={"Transfer amount"} />
            </Form.Item>
            <Form.Item
              label="Transfer Type"
              name={"transferType"}
              style={{ width: "100%" }}
            >
              <Radio.Group defaultValue={"EXISTING_INVOICE"}>
                <Radio value={"EXISTING_INVOICE"}>Existing Invoice</Radio>
                <Radio value={"NEW_INVOICE"}>New Invoice</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Invoice Start Date"
              name={"invoiceStartDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please pick a invoice start date",
                },
              ]}
            >
              <DatePicker format={"DD-MM-YYYY"} />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label={
                  <div style={{ whiteSpace: "nowrap" }}>Paid Duration</div>
                }
                name={"paidDuration"}
                rules={[
                  {
                    required: true,
                    message: "Please enter a paid duration!",
                  },
                ]}
              >
                <Input type="number" placeholder="Paid duration" />
              </Form.Item>
              <Form.Item
                name={"durationType"}
                style={{ width: "100%", alignSelf: "flex-end" }}
              >
                <Select placeholder="Duration Type" defaultValue={"WEEK"}>
                  <Option value="WEEK">Week</Option>
                  <Option value="MONTH">Month</Option>
                  <Option value="YEAR">Year</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Invoice No"
              name={"invoiceNo"}
              style={{ width: "100%" }}
            >
              <Input placeholder={"Invoice No"} />
            </Form.Item>
            <Form.Item
              label="Recommended Commission Rate (%)"
              name={"recommendedCommissionRate"}
              style={{ width: "100%" }}
            >
              <Select defaultValue={"EXISTING_INVOICE"}>
                <Option value={"No commission found"}>
                  No commission found
                </Option>
              </Select>
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Agent Commission rate (%)"
              name={"agentCommissionRate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a agent Commission rate!",
                },
              ]}
            >
              <Input type="number" placeholder="Agent Commission Rate" />
            </Form.Item>
            <Form.Item
              name={"isGst"}
              style={{ width: "100%", alignSelf: "flex-end" }}
            >
              <Radio.Group defaultValue={"NO_GST"}>
                <Radio value={"GST"}>GST</Radio>
                <Radio value={"NO_GST"}>No GST</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <Form.Item label="Comment" name={"comment"} style={{ width: "100%" }}>
            <TextArea placeholder="Comment" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Transfer"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddTransferPayment;
