import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddLanguage = ({
  isAddLanguageModalVisible,
  setIsAddLanguageModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddLanguageSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddLanguage_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddLanguageModalVisible(false);
  };
  return (
    <Modal
      title="Add Language"
      open={isAddLanguageModalVisible}
      onCancel={() => {
        setIsAddLanguageModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddLanguageSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Language"
            name={"language"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a language!",
              },
            ]}
          >
            <Input placeholder="Language" />
          </Form.Item>
          <Form.Item
            label="ABS value"
            name={"abs"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter an  ABS value!",
              },
            ]}
          >
            <Input placeholder="ABS value" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Language"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLanguage;
