import {
  Card,
  DatePicker,
  Input,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileWord,
  AiOutlinePlus,
} from "react-icons/ai";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../../components/input";
import AddButton from "../../../../components/button/AddButton";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddIntervention from "./AddIntervention";
import UpdateIntervention from "./UpdateIntervention";
import { GrNotification } from "react-icons/gr";
import SelectAlert from "../../../../components/SelectAlert/SelectAlert";
const { Search } = Input;

const columns = [
  {
    title: "Case No",
    dataIndex: "caseNo",
    width: 250,
    align: "center",
  },
  {
    title: "Recorded Date",
    dataIndex: "recordedDate",
    width: 150,
    align: "center",
  },
  {
    title: "Campus Name",
    dataIndex: "campusName",
    width: 150,
    align: "center",
  },
  {
    title: "Course ID",
    dataIndex: "courseId",
    width: 100,
    align: "center",
  },
  {
    title: "Semester",
    dataIndex: "semester",
    width: 100,
    align: "center",
  },
  {
    title: "Student Id",
    dataIndex: "studentId",
    width: 100,
    align: "center",
  },
  {
    title: "Student Status",
    dataIndex: "studentStatus",
    width: 100,
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 100,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 100,
    align: "center",
  },
  {
    title: "Outcome Date",
    dataIndex: "outcomeDate",
    width: 100,
    align: "center",
  },
  {
    title: "Intervention Type",
    dataIndex: "interventionType",
    width: 100,
    align: "center",
  },
  {
    title: "Details",
    dataIndex: "details",
    width: 100,
    align: "center",
  },
  {
    title: "Strategy",
    dataIndex: "strategy",
    width: 100,
    align: "center",
  },
  {
    title: "Action Taken",
    dataIndex: "actionTaken",
    width: 100,
    align: "center",
  },
  {
    title: "Action To Prevent",
    dataIndex: "actionToPrevent",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "File Attachment",
    dataIndex: "fileAttachment",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];
const viewInterventionBy = [
  {
    label: "Student Id",
    value: "studentId",
  },
  {
    label: "Case No.",
    value: "caseNo",
  },
  {
    label: "Course ID",
    value: "courseId",
  },
  {
    label: "Intervention Type",
    value: "interventionType",
  },
  {
    label: "Record Date Between Dates",
    value: "recordDateBetweenDates",
  },
  {
    label: "Due Date Between Dates",
    value: "dueDateBetweenDates",
  },
  {
    label: "Outcome Date Between Dates",
    value: "outcomeDateBetweenDates",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Semester",
    value: "semester",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const vaccineDetailsData = [
  {
    id: 1,
    caseNo: "1",
    recordedDate: "Sunday, 20 August 2023 3:01 PM",
    campusName: "OEGPL campus",
    courseId: "GE - 1",
    semester: "2021",
    studentId: "Test003",
    studentStatus: "Enrolled",
    name: "Meshed Test",
    dueDate: "25-08-2023",
    outcomeDate: "24-08-2023",
    interventionType: "Academic",
    details: "Neglignece in study",
    strategy: "extra class, Meeting with Student",
    actionTaken:
      "- 20-08-2023: Detentation (Escalated To 'Not Escalated') lodged by 'anu'",
    actionToPrevent:
      "- 20-08-2023: Extra classes (Escalated To 'Not Escalated') lodged by 'anu'",
    status: "Case Open",
    fileAttachment: "DetentionFile.jpg",
  },
];

const Intervention = () => {
  const [isAddInterventionModalVisible, setIsAddInterventionModalVisible] =
    useState(false);
  const [
    isUpdateInterventionModalVisible,
    setIsUpdateInterventionModalVisible,
  ] = useState(false);
  const [
    isAddVaccinationDoseModalVisible,
    setIsAddVaccinationDoseModalVisible,
  ] = useState(false);
  const [viewIntervention, setViewIntervention] = useState("studentId");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const data = vaccineDetailsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      caseNo: dataObj?.caseNo,
      recordedDate: dataObj?.recordedDate,
      campusName: dataObj?.campusName,
      courseId: dataObj?.courseId,
      semester: dataObj?.semester,
      studentId: dataObj?.studentId,
      studentStatus: dataObj?.studentStatus,
      name: dataObj?.name,
      dueDate: dataObj?.dueDate,
      outcomeDate: dataObj?.outcomeDate,
      interventionType: dataObj?.interventionType,
      details: dataObj?.details,
      strategy: dataObj?.strategy,
      actionTaken: dataObj?.actionTaken,
      actionToPrevent: dataObj?.actionToPrevent,
      status: dataObj?.status,
      fileAttachment: dataObj?.fileAttachment,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Teacher Matrix">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateInterventionModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Notify Student about this intervention">
            <a>
              <div className="bordered" onClick={() => {}}>
                <GrNotification />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate intervention details in Word file">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFileWord />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete selected teacher matrix?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Intervention Register</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddInterventionModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Intervention By</div>
            <SelectField
              name="viewInterventionBy"
              placeholder="View Intervention By"
              options={viewInterventionBy}
              handleChange={(e) => {
                setViewIntervention(e);
              }}
              fieldWidth="10rem"
            />
          </div>
          {(viewIntervention === "studentId" ||
            viewIntervention === "caseNo") && (
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Search: </div>
              <Search
                style={{ borderRadius: "3px", width: "100%" }}
                className="search"
                placeholder={`Search Student.`}
                allowClear
                size="Medium"
                //   value={searchValue}
                onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   onSearch(e.target.value);
                }}
              />
            </div>
          )}
          {viewIntervention === "courseId" && (
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <SelectField
                name="viewCourseBy"
                placeholder="View Course By"
                options={selectCourse}
                fieldWidth="22rem"
              />
            </div>
          )}
          {viewIntervention === "interventionType" && (
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <SelectField
                name="interventionType"
                placeholder="Intervention Type"
                options={[
                  { label: "Academic", value: "ACADEMIC" },
                  { label: "Attendance", value: "ATTENDANCE" },
                ]}
                fieldWidth="14rem"
              />
            </div>
          )}
          {(viewIntervention === "recordDateBetweenDates" ||
            viewIntervention === "outcomeDateBetweenDates" ||
            viewIntervention === "dueDateBetweenDates") && (
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>From: </div>
              <DatePicker format={"DD-MM-YYYY"} />
              <div>To: </div>
              <DatePicker format={"DD-MM-YYYY"} />
            </div>
          )}
          {viewIntervention === "status" && (
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <SelectField
                name="status"
                placeholder="Status"
                options={[
                  { label: "Case Open", value: "CASE_OPEN" },
                  { label: "Case Closed", value: "CASE_CLOSED" },
                  {
                    label: "Case Unresolved - Cancelled",
                    value: "CASE_UNRESOLVED",
                  },
                ]}
                fieldWidth="14rem"
              />
            </div>
          )}
          {viewIntervention === "semester" && (
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <SelectField
                name="Semester"
                placeholder="Semester"
                options={[]}
                fieldWidth="14rem"
              />
            </div>
          )}
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        {selectedRowKeys.length > 0 && selectedVisible && (
          <SelectAlert
            length={selectedRowKeys.length}
            type={"success"}
            closable={true}
            selectAction={
              <ButtonElement
                size="small"
                buttonType="primary"
                onClick={(e) => {}}
                name="Download CSV"
              >
                Download CSV
              </ButtonElement>
            }
          />
        )}
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddInterventionModalVisible && (
        <AddIntervention
          isAddInterventionModalVisible={isAddInterventionModalVisible}
          setIsAddInterventionModalVisible={setIsAddInterventionModalVisible}
        />
      )}

      {isUpdateInterventionModalVisible && (
        <UpdateIntervention
          isUpdateInterventionModalVisible={isUpdateInterventionModalVisible}
          setIsUpdateInterventionModalVisible={
            setIsUpdateInterventionModalVisible
          }
        />
      )}
    </Card>
  );
};

export default Intervention;
