import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Accounts from "../Accounts";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import PageHeader from "../../../components/PageHeader/PageHeader";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import UpdateProviderPayment from "./UpdateProviderPayment";
const { Search } = Input;

const columns = [
  {
    title: "Transaction No",
    dataIndex: "transactionNo",
    width: 100,
    align: "center",
  },
  {
    title: "Student Id",
    dataIndex: "studentId",
    width: 250,
    align: "center",
  },
  {
    title: "Student Name",
    dataIndex: "studentName",
    width: 200,
    align: "center",
  },
  {
    title: "DoB",
    dataIndex: "dob",
    width: 150,
    align: "center",
  },
  {
    title: "Student Due",
    dataIndex: "studentDue",
    width: 150,
    align: "center",
  },
  {
    title: "Student GST",
    dataIndex: "studentGst",
    width: 150,
    align: "center",
  },
  {
    title: "Student Paid",
    dataIndex: "studentPaid",
    width: 50,
    align: "center",
  },

  {
    title: "Refunded",
    dataIndex: "refunded",
    width: 150,
    align: "center",
  },
  {
    title: "Service Start Date",
    dataIndex: "serviceStartDate",
    width: 150,
    align: "center",
  },
  {
    title: "Provider",
    dataIndex: "provider",
    width: 200,
    align: "center",
  },
  {
    title: "Provider Amount",
    dataIndex: "providerAmount",
    width: 150,
    align: "center",
  },
  {
    title: "Provider GST",
    dataIndex: "providerGst",
    width: 150,
    align: "center",
  },
  {
    title: "Paid To Provider",
    dataIndex: "paidToProvider",
    width: 50,
    align: "center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    width: 250,
    align: "center",
  },
  {
    title: "Mode",
    dataIndex: "mode",
    width: 100,
    align: "center",
  },
  {
    title: "Reference",
    dataIndex: "reference",
    width: 150,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 200,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const invoiceData = [
  {
    id: "1",
    transactionNo: "1",
    studentId: "ID20210001",
    studentName: "John Citizen",
    dob: "24-01-1991",
    studentDue: "Student Due",
    studentGst: "45.45",
    studentPaid: "Yes",
    refunded: "0",
    serviceStartDate: "12-04-2021",
    provider: "Test Provider",
    providerAmount: "500.00",
    providerGst: "0.00",
    paidToProvider: "Yes",
    paidDate: "28-04-2021",
    mode: "Credit Card",
    reference: "",
    remarks: "Paid",
  },
];

const ProviderPayment = () => {
  const [searchBy, setSearchBy] = useState("AIRPORT_PICKUP");
  const [
    isUpdateProviderPaymentModalVisible,
    setIsUpdateProviderPaymentModalVisible,
  ] = useState(false);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let data = invoiceData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      transactionNo: dataObj?.transactionNo,
      studentId: dataObj?.studentId,
      studentName: dataObj?.studentName,
      dob: dataObj?.dob,
      studentDue: dataObj?.studentDue,
      studentGst: dataObj?.studentGst,
      studentPaid: dataObj?.studentPaid,
      refunded: dataObj?.refunded,
      serviceStartDate: dataObj?.serviceStartDate,
      provider: dataObj?.provider,
      providerAmount: dataObj?.providerAmount,
      providerGst: dataObj?.providerGst,
      paidToProvider: dataObj?.paidToProvider,
      paidDate: dataObj?.paidDate,
      mode: dataObj?.mode,
      reference: dataObj?.reference,
      remarks: dataObj?.remarks,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Update">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateProviderPaymentModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Accounts tab={"/provider-payment"}>
      <ComponentBody>
        <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Payment Type:</div>
            <SelectField
              name="paymentType"
              placeholder="Payment Type"
              value={searchBy}
              options={[
                {
                  label: "Airport Pickup",
                  value: "AIRPORT_PICKUP",
                },
                {
                  label: "OHSC",
                  value: "ohsc",
                },
              ]}
              handleChange={(e) => {
                setSearchBy(e);
              }}
              fieldWidth="12rem"
            />
          </div>
          {searchBy !== "ohsc" && (
            <>
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Service Category:</div>
                <SelectField
                  name="transactionType"
                  placeholder="Transaction Type"
                  options={[
                    {
                      label: "Transport",
                      value: "transport",
                    },
                  ]}
                  fieldWidth="12rem"
                />
              </div>
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select Facility:</div>
                <SelectField
                  name="transactionType"
                  placeholder="Transaction Type"
                  options={[
                    {
                      label: "Bud",
                      value: "bus",
                    },
                    {
                      label: "Taxi",
                      value: "taxi",
                    },
                  ]}
                  fieldWidth="12rem"
                />
              </div>{" "}
            </>
          )}
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Service Start From:</div>
            <DatePicker format={"DD-MM-YYYY"} />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Service Start To:</div>
            <DatePicker format={"DD-MM-YYYY"} />
          </div>

          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Transaction Type:</div>
            <SelectField
              name="transactionType"
              placeholder="Transaction Type"
              options={[
                {
                  label: "All Transaction",
                  value: "allTransaction",
                },
                {
                  label: "Provider Paid Only",
                  value: "providerPaidOnly",
                },
                {
                  label: "Provider Unpaid Only",
                  value: "providerUnpaidOnly",
                },
              ]}
              fieldWidth="12rem"
            />
          </div>

          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Student First Name / Last Name / Student ID / Offer ID </div>
            <Search
              style={{ borderRadius: "3px", width: "100%" }}
              className="search"
              placeholder={`Search Student.`}
              allowClear
              size="Medium"
              //   value={searchValue}
              onChange={(e) => {
                //   setSearchValue(e.target.value);
                //   onSearch(e.target.value);
              }}
            />
          </div>

          <ButtonElement name="View Schedules" />
        </div>
      </ComponentBody>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Provider Allocation / Payment</Pagename>
            <div
              style={{ display: "flex", justifyContent: "flex-start" }}
            ></div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {selectedRowKeys.length > 0 && selectedVisible && (
            <SelectAlert
              length={selectedRowKeys.length}
              type={"success"}
              closable={true}
              selectAction={
                <div className="fxRJs">
                  <ButtonElement
                    size="small"
                    buttonType="primary"
                    onClick={(e) => {}}
                    name="Export to Excel"
                  />
                </div>
              }
            />
          )}
          <Table
            scroll={{
              x: 0,
            }}
            bordered
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isUpdateProviderPaymentModalVisible && (
        <UpdateProviderPayment
          isUpdateProviderPaymentModalVisible={
            isUpdateProviderPaymentModalVisible
          }
          setIsUpdateProviderPaymentModalVisible={
            setIsUpdateProviderPaymentModalVisible
          }
        />
      )}
    </Accounts>
  );
};

export default ProviderPayment;
