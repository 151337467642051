import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import { ButtonElement } from "../../../../components/input";
import moment from "moment";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;

const ApplicantSpecificInformation = ({
  applicantData,
  setApplicantData,
  current,
  setCurrent,
  qualification,
  setQualification,
  employment,
  setEmployment,
}) => {
  const removeQualificationdInput = (index) => {
    const list = [...qualification];
    list.splice(index, 1);
    setQualification(list);
  };
  const addQualificationInput = () => {
    setQualification([
      ...qualification,
      {
        qualificationLevel: "",
        qualificationName: "",
        institution: "",
        country: "",
        yearsOfCompletion: "",
      },
    ]);
  };
  const handleQualificationLevelChange = (e, index) => {
    console.log(e, index);
    const list = [...qualification];
    list[index].qualificationLevel = e.target.value;
    setQualification(list);
  };
  const handleQualificationChange = (e, index) => {
    console.log(e, index);
    const list = [...qualification];
    list[index].qualificationName = e.target.value;
    setQualification(list);
  };
  const handleInstitutionChange = (e, index) => {
    console.log(e, index);
    const list = [...qualification];
    list[index].institution = e.target.value;
    setQualification(list);
  };
  const handleCountryChange = (e, index) => {
    console.log(e, index);
    const list = [...qualification];
    list[index].country = e;
    setQualification(list);
  };
  const handleYearsOfCompletionChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...qualification];
    list[index].yearsOfCompletion = moment.utc(e).local().format("YYYY");
    setQualification(list);
  };

  const removeEmploymentdInput = (index) => {
    const list = [...employment];
    list.splice(index, 1);
    setEmployment(list);
  };
  const addEmploymentInput = () => {
    setEmployment([
      ...employment,
      {
        employer: "",
        occupation: "",
        durationFrom: "",
        durationFrom: "",
        durationTo: "",
        duties: "",
      },
    ]);
  };
  const handleEmployerChange = (e, index) => {
    console.log(e, index);
    const list = [...employment];
    list[index].employer = e.target.value;
    setEmployment(list);
  };
  const handleOccupationChange = (e, index) => {
    console.log(e, index);
    const list = [...employment];
    list[index].occupation = e.target.value;
    setEmployment(list);
  };
  const handleDurationFromChange = (e, index) => {
    console.log(e, index);
    const list = [...employment];
    list[index].durationFrom = moment.utc(e).local().format("DD-MM-YYYY");
    setEmployment(list);
  };
  const handleDurationToChange = (e, index) => {
    console.log(e, index);
    const list = [...employment];
    list[index].durationTo = moment.utc(e).local().format("DD-MM-YYYY");
    setEmployment(list);
  };
  const handleDutiesChange = (e, index) => {
    const list = [...employment];
    list[index].duties = e.target.value;
    setEmployment(list);
  };
  const [form] = Form.useForm();

  const addApplicantSpecificInformationHandler = () => {
    setCurrent(3);
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addApplicantSpecificInformationHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["isAustralianAboriginalOrStraitIslander"],
            value: applicantData?.isAustralianAboriginalOrStraitIslander,
          },
          {
            name: ["isEnglishMainLanguage"],
            value: applicantData?.isEnglishMainLanguage,
          },
          {
            name: ["languageSpokenAtHome"],
            value: applicantData?.languageSpokenAtHome,
          },
          {
            name: ["wasPreviousLanguageOfInstruction"],
            value: applicantData?.wasPreviousLanguageOfInstruction,
          },
          {
            name: ["haveCompletedEnglishLanguageProficiencyTest"],
            value: applicantData?.haveCompletedEnglishLanguageProficiencyTest,
          },
          { name: ["testType"], value: applicantData?.testType },
          { name: ["when"], value: applicantData?.when },
          { name: ["listening"], value: applicantData?.Listening },
          { name: ["reading"], value: applicantData?.reading },
          { name: ["writing"], value: applicantData?.writing },
          { name: ["speaking"], value: applicantData?.speaking },
          { name: ["overall"], value: applicantData?.overall },
          {
            name: ["highestCompletedSchoolLevel"],
            value: applicantData?.highestCompletedSchoolLevel,
          },
          {
            name: ["areStillInSecondarySchool"],
            value: applicantData?.areStillInSecondarySchool,
          },
          {
            name: ["wouldAddPreviousQualifications"],
            value: applicantData?.wouldAddPreviousQualifications,
          },
          {
            name: ["highestCompletedSchoolLevel"],
            value: applicantData?.highestCompletedSchoolLevel,
          },
          {
            name: ["wouldAddEmploymentHistory"],
            value: applicantData?.wouldAddEmploymentHistory,
          },
        ]}
      >
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Language and Cultural Diversity
          </span>

          <Form.Item
            label="Are you of Australian Aboriginal or Torres Strait Islander origin?
              (For persons of both Australian Aboriginal and Torres Strait Islander
              origin, select the last item.)"
            name={"isAustralianAboriginalOrStraitIslander"}
            style={{ width: "100%" }}
          >
            <Select
              placeholder="Australian Aboriginal or Torres Strait Islander"
              defaultValue={"NO"}
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    isAustralianAboriginalOrStraitIslander: e,
                  };
                });
              }}
            >
              <Option value="PREFER_NOT_TO_SAY">
                Not stated / Prefer not to say
              </Option>
              <Option value="NO">NO</Option>
              <Option value="YES_ABORIGINAL">Yes, Only Aboriginal</Option>
              <Option value="YES_TORRES_STRAIT_ISLANDER">
                Yes, Torres Strait Islander
              </Option>
              <Option value="YES_ABORIGINAL_TORRES_STRAIT_ISLANDER">
                Yes, Both Aboriginal and Torres Strait Islander
              </Option>
            </Select>
          </Form.Item>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Is English your main language?"
              name={"isEnglishMainLanguage"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      isEnglishMainLanguage: e.target.value,
                      languageSpokenAtHome:
                        e.target.value === true ? "English" : null,
                    };
                  });
                }}
              >
                <Radio value={true}> Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="If No, what is your language spoken at home?"
              name={"languageSpokenAtHome"}
              style={{ width: "100%", alignSelf: "flex-end" }}
            >
              <Select
                placeholder="Language spoken at home"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      languageSpokenAtHome: e,
                    };
                  });
                }}
                disabled={
                  applicantData?.languageSpokenAtHome?.toLowerCase() ===
                  "english"
                }
              >
                <Option value="English">English</Option>
                <Option value="Nepali">Nepali</Option>
              </Select>
            </Form.Item>
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Was English the language of instruction in
              previous secondary or tertiary studies?
              "
              name={"wasPreviousLanguageOfInstruction"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      wasPreviousLanguageOfInstruction: e.target.value,
                    };
                  });
                }}
              >
                <Radio value={true}> Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Have you completed a test of English Language Proficiency?"
              name={"haveCompletedEnglishLanguageProficiencyTest"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      haveCompletedEnglishLanguageProficiencyTest:
                        e.target.value,
                    };
                  });
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          {applicantData?.haveCompletedEnglishLanguageProficiencyTest && (
            <>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="If Yes, what test did you sit?"
                  name={"testType"}
                  style={{ width: "100%" }}
                >
                  <Select
                    placeholder="Test Type"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          testType: e,
                        };
                      });
                    }}
                  >
                    <Option value="IELTS">IELTS</Option>
                    <Option value="PTE">PTE</Option>
                    <Option value="TOEFL">tOEFL</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="When" name={"when"} style={{ width: "100%" }}>
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          when: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Score"
                  name={"listening"}
                  style={{ width: "100%", alignSelf: "flex-end" }}
                >
                  <Input
                    placeholder="Listening"
                    type="number"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          listening: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={"reading"}
                  style={{ width: "100%", alignSelf: "flex-end" }}
                >
                  <Input
                    placeholder="Reading"
                    type="number"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          reading: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  name={"writing"}
                  style={{ width: "100%", alignSelf: "flex-end" }}
                >
                  <Input
                    placeholder="Reading"
                    type="number"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          writing: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={"speaking"}
                  style={{ width: "100%", alignSelf: "flex-end" }}
                >
                  <Input
                    placeholder="Speaking"
                    type="number"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          speaking: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item
                label={"Overall"}
                name={"overall"}
                style={{ width: "100%", alignSelf: "flex-end" }}
              >
                <Input
                  placeholder="Overall"
                  type="number"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        overall: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </>
          )}
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Schooling
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="What is your highest completed school level?"
              name={"highestCompletedSchoolLevel"}
              style={{ width: "100%" }}
            >
              <Select
                placeholder="Highest Completed School Level"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      highestCompletedSchoolLevel: e,
                      areStillInSecondarySchool:
                        e === "02_DID_NOT_GO_TO_SCHOOL" ? "DISABLE" : false,
                    };
                  });
                }}
              >
                <Option value="02_DID_NOT_GO_TO_SCHOOL">
                  02-Did not go to school
                </Option>
                <Option value="08_YEAR_8_OR_BELOW">08-Year 8 or below</Option>
                <Option value="09_YEAR_9_OR_EQUIVALENT">
                  09-Year 9 or equivalent
                </Option>
                <Option value="10_COMPLETED_YEAR_10">
                  10-Completed year 10
                </Option>
                <Option value="11_COMPLETED_YEAR_11">
                  11-Completed year 11
                </Option>
                <Option value="12_COMPLETED_YEAR_12">
                  12-Completed year 12
                </Option>
                <Option value="@@_NOT_SPECIFIED">@@-Not Specified</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={"Are you still attending secondary school?"}
              name={"areStillInSecondarySchool"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                placeholder="Overall"
                type="number"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      areStillInSecondarySchool: e.target.value,
                    };
                  });
                }}
                disabled={
                  applicantData?.areStillInSecondarySchool === "DISABLED"
                }
              >
                <Radio value={true}> Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item
            name={"wouldAddPreviousQualifications"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    wouldAddPreviousQualifications: e.target.checked,
                  };
                });
              }}
            >
              Yes! I would like to add Previous Qualifications Achieved
            </Checkbox>
          </Form.Item>
          {applicantData?.wouldAddPreviousQualifications && (
            <>
              <div
                style={{
                  backgroundColor: "#eeeeee55",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                }}
              >
                {qualification.map((singleQualification, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="flexRowWithoutStyle"
                          style={{
                            justifyContent: "space-between",
                            gap: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Form.Item
                            label="Qualification Level"
                            style={{
                              width: "100%",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter qualification!",
                              },
                            ]}
                          >
                            <Input
                              name={"qualificationLevel"}
                              value={qualification[index].qualificationLevel}
                              placeholder="qualification"
                              onChange={(e) => {
                                handleQualificationLevelChange(e, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Qualification Name"
                            style={{
                              width: "100%",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter qualification!",
                              },
                            ]}
                          >
                            <Input
                              name={"qualificationName"}
                              value={qualification[index].qualificationName}
                              placeholder="qualification"
                              onChange={(e) => {
                                handleQualificationChange(e, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Institution"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter institution!",
                              },
                            ]}
                          >
                            <Input
                              name={"School/Institution Name"}
                              placeholder="institution"
                              value={qualification[index].institution}
                              onChange={(e) => {
                                handleInstitutionChange(e, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="State/Country"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter country!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Country"
                              value={qualification[index].country}
                              onChange={(e) => {
                                handleCountryChange(e.target.value, index);
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Year Completed"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter Completion year!",
                              },
                            ]}
                          >
                            <DatePicker
                              picker="year"
                              name={"yearsOfCompletion"}
                              value={
                                qualification[index].yearsOfCompletion
                                  ? moment
                                      .utc(
                                        qualification[index].yearsOfCompletion
                                      )
                                      .local()
                                  : ""
                              }
                              placeholder="Completion Year"
                              onChange={(e) => {
                                handleYearsOfCompletionChange(e, index);
                              }}
                            />
                          </Form.Item>
                        </div>
                        <Button
                          className="fxRAc"
                          color="red"
                          onClick={() => removeQualificationdInput(index)}
                          style={{
                            padding: "0",
                            justifyContent: "center",
                            borderRadius: "50%",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </div>
                    </div>
                  );
                })}
                <Button
                  block
                  type="dashed"
                  onClick={addQualificationInput}
                  style={{ marginBottom: "1rem" }}
                >
                  <PlusOutlined />
                  Add Qualification
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Employment
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Which best describes your current employment status?"
              name={"highestCompletedSchoolLevel"}
              style={{ width: "100%" }}
            >
              <Select
                placeholder="Highest Completed School Level"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      highestCompletedSchoolLevel: e,
                      areStillInSecondarySchool:
                        e === "02_DID_NOT_GO_TO_SCHOOL" ? "DISABLE" : false,
                    };
                  });
                }}
              >
                <Option value="01_FULL_TIME_EMPLOYEE">
                  01-Full-time employee
                </Option>
                <Option value="02_PART_TIME_EMPLOYEE">
                  02-Part-time employee
                </Option>
                <Option value="03_SELF_EMPLOYED_NOT_EMPLOYING_OTHERS">
                  03-Self-employed - not employing others
                </Option>
                <Option value="04_SELF_EMPLOYED_EMPLOYING_OTHERS">
                  04-Self-employed - employing others
                </Option>
                <Option value="05_EMPLOYED_UPAID_WORKER_IN_FAMILY_BUSINESS">
                  05-Employed - unpaid worker in family business
                </Option>
                <Option value="06_Unemployed_SEEKING_FULL_TIME_WORK">
                  06-Unemployed - Seeking full-time work
                </Option>
                <Option value="07_Unemployed_SEEKING_PART_TIME_WORK">
                  07-Unemployed - Seeking part-time work
                </Option>
                <Option value="08_Unemployed_NOT_SEEKING_EMPLOYMENT">
                  08-Unemployed - Not seeking employment
                </Option>
                <Option value="@@_NOT_SPECIFIED">@@-Not Specified</Option>
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            name={"wouldAddEmploymentHistory"}
            valuePropName={"checked"}
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    wouldAddEmploymentHistory: e.target.checked,
                  };
                });
              }}
            >
              I would like to add my employment history
            </Checkbox>
          </Form.Item>
          {applicantData?.wouldAddEmploymentHistory && (
            <>
              <div
                style={{
                  backgroundColor: "#eeeeee55",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                }}
              >
                {employment.map((singleQualification, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="flexRowWithoutStyle"
                          style={{
                            justifyContent: "space-between",
                            gap: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Form.Item
                            label="Employer"
                            style={{
                              width: "100%",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter an employer!",
                              },
                            ]}
                          >
                            <Input
                              name={"employer"}
                              value={employment[index].employer}
                              placeholder="Employer"
                              onChange={(e) => {
                                handleEmployerChange(e, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Occupation"
                            style={{
                              width: "100%",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter an occupation!",
                              },
                            ]}
                          >
                            <Input
                              name={"occupation"}
                              value={employment[index].occupation}
                              placeholder="Occupation"
                              onChange={(e) => {
                                handleOccupationChange(e, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Duration From"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please pick start date of employment!",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"DD-MM-YYYY"}
                              name={"durationFrom"}
                              placeholder="Duration From"
                              value={
                                employment[index].durationFrom
                                  ? moment(
                                      `${employment[index].durationFrom}`,
                                      "DD-MM-YYYY"
                                    )
                                      .utc()
                                      .local()
                                  : ""
                              }
                              onChange={(e) => {
                                handleDurationFromChange(e, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Duration To"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please pick end date of employment!",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"DD-MM-YYYY"}
                              placeholder="Duration To"
                              value={
                                employment[index].durationTo
                                  ? moment(
                                      `${employment[index].durationTo}`,
                                      "DD-MM-YYYY"
                                    ).local()
                                  : ""
                              }
                              onChange={(e) => {
                                handleDurationToChange(e, index);
                              }}
                            />
                          </Form.Item>

                          <Form.Item label="Duties" style={{ width: "100%" }}>
                            <TextArea
                              name={"duties"}
                              value={employment[index].duties}
                              placeholder="Completion Year"
                              onChange={(e) => {
                                handleDutiesChange(e, index);
                              }}
                            />
                          </Form.Item>
                        </div>
                        <Button
                          className="fxRAc"
                          color="red"
                          onClick={() => removeEmploymentdInput(index)}
                          style={{
                            padding: "0",
                            justifyContent: "center",
                            borderRadius: "50%",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </div>
                    </div>
                  );
                })}
                <Button
                  block
                  type="dashed"
                  onClick={addEmploymentInput}
                  style={{ marginBottom: "1rem" }}
                >
                  <PlusOutlined />
                  Add Employment
                </Button>
              </div>
            </>
          )}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {current === 2 && (
            <Form.Item>
              <ButtonElement
                type={"default"}
                handleClick={() => {
                  setCurrent(1);
                }}
                name="Back"
              >
                Back
              </ButtonElement>
            </Form.Item>
          )}
          <Form.Item>
            <ButtonElement type="primary" htmlType="submit" name="Next">
              Next
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ApplicantSpecificInformation;
