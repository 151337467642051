import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Select,
} from "antd";
import { ButtonElement } from "../../../../components/input";
import moment from "moment";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlinePlus } from "react-icons/ai";
import AddService from "../../../Student/StudentDetails/StudentCourse/Service/AddService";
const { Option } = Select;
const { TextArea } = Input;

const ApplicantServicesInformation = ({
  applicantData,
  setApplicantData,
  current,
  setCurrent,
  disability,
  setDisability,
}) => {
  const [isAddServiceModalVisible, setIsAddServiceModalVisible] =
    useState(false);
  const [form] = Form.useForm();

  const addApplicantServicesInformationHandler = () => {
    setCurrent(5);
  };
  const handleDisabilityChange = (e, index) => {
    console.log(e, index);
    const list = [...disability];
    list[index].disability = e.target.value;
    setDisability(list);
  };
  const handleDurationFromChange = (e, index) => {
    console.log(e, index);
    const list = [...disability];
    list[index].fromDate = moment.utc(e).local().format("DD-MM-YYYY");
    setDisability(list);
  };
  const handleDurationToChange = (e, index) => {
    console.log(e, index);
    const list = [...disability];
    list[index].toDate = moment.utc(e).local().format("DD-MM-YYYY");
    setDisability(list);
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addApplicantServicesInformationHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          { name: ["aboutUs"], value: applicantData?.aboutUs },
          { name: ["agentName"], value: applicantData?.agentName },
          { name: ["aboutUsSpecify"], value: applicantData?.aboutUsSpecify },
          {
            name: ["agentMailingAddress"],
            value: applicantData?.agentMailingAddress,
          },
          { name: ["agentCountry"], value: applicantData?.agentCountry },
          { name: ["service"], value: applicantData?.service },
          {
            name: ["wouldLikeToArrangeOshc"],
            value: applicantData?.wouldLikeToArrangeOshc,
          },
          { name: ["OSHCProvider"], value: applicantData?.OSHCProvider },
          { name: ["oshcType"], value: applicantData?.oshcType },
          {
            name: ["durationInMonths"],
            value: applicantData?.durationInMonths,
          },
          { name: ["appliedOshcFee"], value: applicantData?.appliedOshcFee },
          { name: ["oshcStartDate"], value: applicantData?.oshcStartDate },
          { name: ["haveDisability"], value: applicantData?.haveDisability },
          {
            name: ["wouldReceiveAdviceOnSupportServices"],
            value: applicantData?.wouldReceiveAdviceOnSupportServices,
          },
          {
            name: ["emergencyContactName"],
            value: applicantData?.emergencyContactName,
          },
          {
            name: ["emergencyRelationship"],
            value: applicantData?.emergencyRelationship,
          },
          {
            name: ["emergancyPersonAddress"],
            value: applicantData?.emergancyPersonAddress,
          },
          { name: ["emergencyPhone"], value: applicantData?.emergencyPhone },
          { name: ["emergencyEmail"], value: applicantData?.emergencyEmail },
          { name: ["accountManager"], value: applicantData?.accountManager },
          { name: ["admissionStaff"], value: applicantData?.admissionStaff },
          {
            name: ["agreeToTermsAndConditions"],
            value: applicantData?.agreeToTermsAndConditions,
          },
        ]}
      >
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            How did you hear about us?
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="How did you hear about us?"
              name={"aboutUs"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      aboutUs: e.target.value,
                    };
                  });
                }}
              >
                <Radio value={"PRESS_AND_PRINT_MEDIA"}>
                  Press & Print media
                </Radio>
                <Radio value={"INTERNET"}>Internet</Radio>
                <Radio value={"AGENT"}>Agent</Radio>
                <Radio value={"FRIEND"}>Friend</Radio>
                <Radio value={"OTHER"}>Other</Radio>
              </Radio.Group>
            </Form.Item>
            {applicantData?.aboutUs === "AGENT" ? (
              <Form.Item
                label="Agent Name"
                name={"agentName"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Agent Name"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        agentName: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Please specify"
                name={"aboutUsSpecify"}
                style={{ width: "100%" }}
              >
                <Input
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        aboutUsSpecify: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            )}
          </div>
          {applicantData?.aboutUs === "AGENT" && (
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <Form.Item
                label="E-Mail / Mailing Address"
                name={"agentMailingAddress"}
                style={{ width: "100%" }}
              >
                <TextArea
                  placeholder="Write mailing address or email"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        agentMailingAddress: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Country"
                name={"agentCountry"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter COE number!",
                //   },
                // ]}
              >
                <Select
                  placeholder="Country"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        agentCountry: e,
                      };
                    });
                  }}
                >
                  <Option value="Australia">Australia</Option>
                  <Option value="Nepal">Nepal</Option>
                </Select>
              </Form.Item>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Additional Service Request
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.3rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Service"
                name={"service"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a service!",
                  },
                ]}
              >
                <Checkbox.Group
                  defaultValue={"AIRPORT_PICKUP"}
                  placeholder="Offer Id"
                  onChange={(e) => {
                    setApplicantData((prevData) => {
                      return {
                        ...prevData,
                        service: e.target.value,
                      };
                    });
                  }}
                >
                  <Checkbox value="AIRPORT_PICKUP">Airport Pickup</Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddServiceModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
          </div>
          <Form.Item
            name={"wouldLikeToArrangeOshc"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    wouldLikeToArrangeOshc: e.target.checked,
                  };
                });
              }}
            >
              Would you like us to arrange Overseas Student Health Cover?
            </Checkbox>
          </Form.Item>
          {applicantData?.wouldLikeToArrangeOshc && (
            <>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="OSHC Provider"
                  name={"OSHCProvider"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select a OSHC provider!",
                  //   },
                  // ]}
                >
                  <Select
                    placeholder="OSHC Provider"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          OSHCProvider: e,
                        };
                      });
                    }}
                  >
                    <Option value="Medibank Private">Medibank Private</Option>
                  </Select>
                </Form.Item>
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="OSHC Type"
                  name={"oshcType"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select OSHC type!",
                  //   },
                  // ]}
                >
                  <Select
                    placeholder="OSHC Type"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          oshcType: e,
                        };
                      });
                    }}
                  >
                    <Option value="FAMILY">Family</Option>
                    <Option value="INDIVIDUAL">Individual</Option>
                  </Select>{" "}
                </Form.Item>
                <Form.Item
                  label="Cover Duration (in months)"
                  name={"durationInMonths"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter a duration !",
                  //   },
                  // ]}
                >
                  <Input
                    type="number"
                    placeholder="Duration"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          durationInMonths: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Applied OSHC Fee (OSHC Fee: 0)"
                  name={"appliedOshcFee"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter a applied OSHC fee !",
                  //   },
                  // ]}
                >
                  <Input
                    type="number"
                    placeholder="Duration"
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          appliedOshcFee: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="OSHC Start Date"
                  name={"oshcStartDate"}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    onChange={(e) => {
                      setApplicantData((prevData) => {
                        return {
                          ...prevData,
                          oshcStartDate: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Disability
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Do you consider yourself to have a disability, impairment or long-term condition?"
              name={"haveDisability"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      haveDisability: e.target.value,
                    };
                  });
                }}
              >
                <Radio value={"YES"}> Yes</Radio>
                <Radio value={"NO"}>No</Radio>
                <Radio value={"NOT_STATED"}>Not Stated/Prefer Not to Say</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                "Would you like to receive advice on support services, equipment and facilities which may assist you?"
              }
              name={"wouldReceiveAdviceOnSupportServices"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      wouldReceiveAdviceOnSupportServices: e.target.value,
                    };
                  });
                }}
              >
                <Radio value={true}> Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          {applicantData?.haveDisability === "YES" && (
            <>
              <div
                style={{
                  backgroundColor: "#eeeeee55",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                }}
              >
                {disability.map((singleQualification, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="flexRowWithoutStyle"
                          style={{
                            justifyContent: "space-between",
                            gap: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Form.Item
                            style={{
                              width: "100%",
                            }}
                          >
                            {disability[index].disability}
                          </Form.Item>
                          <Form.Item
                            label="From Date"
                            style={{ width: "100%" }}
                          >
                            <DatePicker
                              format={"DD-MM-YYYY"}
                              name={"fromDate"}
                              placeholder="From Date"
                              value={
                                disability[index].fromDate
                                  ? moment(
                                      `${disability[index].fromDate}`,
                                      "DD-MM-YYYY"
                                    )
                                      .utc()
                                      .local()
                                  : ""
                              }
                              onChange={(e) => {
                                handleDurationFromChange(e, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item label="To Date" style={{ width: "100%" }}>
                            <DatePicker
                              format={"DD-MM-YYYY"}
                              placeholder="To Date"
                              value={
                                disability[index].toDate
                                  ? moment(
                                      `${disability[index].toDate}`,
                                      "DD-MM-YYYY"
                                    ).local()
                                  : ""
                              }
                              onChange={(e) => {
                                handleDurationToChange(e, index);
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <Divider
                        orientationMargin="0"
                        style={{ margin: "0", padding: "0", color: "white" }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Emergency Contact
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Contact Name"
              name={"emergencyContactName"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Contact Name"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      emergencyContactName: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Relationship"
              name={"emergencyRelationship"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Relationship"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      emergencyRelationship: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: "1rem",
            }}
          >
            <Form.Item
              label="Address"
              name={"emergancyPersonAddress"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write a address"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      emergancyPersonAddress: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Phone"
              name={"emergencyPhone"}
              style={{ width: "100%" }}
              rules={[
                {
                  pattern: /^[\d]{9,11}$/,
                  message: "Contact number should contain 9 to 11 numbers",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Phone"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      emergencyPhone: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            label="Email"
            name={"emergencyEmail"}
            style={{ width: "100%" }}
            rules={[
              {
                type: "email",
                message: "Contact number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    emergencyEmail: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Other Application Details
          </span>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Account Manager"
              name={"accountManager"}
              style={{ width: "100%" }}
            >
              <Select
                placeholder="Account Manager"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      accountManager: e,
                    };
                  });
                }}
              >
                <Option value="Mr Kadam Roka">Mr Kadam Roka</Option>
                <Option value="Mrs Ranju Thapa">Mrs Ranju Thapa</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Admission Staff"
              name={"admissionStaff"}
              style={{ width: "100%" }}
            >
              <Select
                placeholder="Relationship"
                onChange={(e) => {
                  setApplicantData((prevData) => {
                    return {
                      ...prevData,
                      admissionStaff: e,
                    };
                  });
                }}
              >
                <Option value="Mr Kadam Roka">Mr Kadam Roka</Option>
                <Option value="Mrs Ranju Thapa">Mrs Ranju Thapa</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name={"agreeToTermsAndConditions"}
          valuePropName="checked"
          style={{ width: "100%" }}
        >
          <Checkbox
            onChange={(e) => {
              setApplicantData((prevData) => {
                return {
                  ...prevData,
                  agreeToTermsAndConditions: e.target.checked,
                };
              });
            }}
          >
            I agree to the terms and conditions
          </Checkbox>
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {current === 4 && (
            <Form.Item>
              <ButtonElement
                type={"default"}
                handleClick={() => {
                  setCurrent(3);
                }}
                name="Back"
              >
                Back
              </ButtonElement>
            </Form.Item>
          )}
          <Form.Item>
            <ButtonElement type="primary" htmlType="submit" name="Next">
              Next
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
      {isAddServiceModalVisible && (
        <AddService
          isAddServiceModalVisible={isAddServiceModalVisible}
          setIsAddServiceModalVisible={setIsAddServiceModalVisible}
        />
      )}
    </div>
  );
};

export default ApplicantServicesInformation;
