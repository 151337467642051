import React from "react";
import { Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const ShortCourseApplicantCourseInformation = ({
  applicantCourseData,
  setApplicantCourseData,
  current,
  setCurrent,
  addApplicatnHandler,
}) => {
  const [form] = Form.useForm();

  const addShortCourseApplicantCourseInformationHandler = () => {
    addApplicatnHandler();
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addShortCourseApplicantCourseInformationHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          { name: ["agent"], value: applicantCourseData?.agent },
          { name: ["agentContact"], value: applicantCourseData?.agentContact },
          { name: ["intake"], value: applicantCourseData?.intake },
          {
            name: ["numberOfWeeks"],
            value: applicantCourseData?.numberOfWeeks,
          },
          { name: ["course"], value: applicantCourseData?.course },
          { name: ["startDate"], value: applicantCourseData?.startDate },
          {
            name: ["courseActualFee"],
            value: applicantCourseData?.courseActualFee,
          },
          {
            name: ["enrollmentFee"],
            value: applicantCourseData?.enrollmentFee,
          },
          { name: ["upfrontFee"], value: applicantCourseData?.upfrontFee },
          { name: ["materialFee"], value: applicantCourseData?.materialFee },
          { name: ["status"], value: applicantCourseData?.status },
          {
            name: ["applicationRequest"],
            value: applicantCourseData?.applicationRequest,
          },
          {
            name: ["areApplyingForAdvancedStanding"],
            value: applicantCourseData?.areApplyingForAdvancedStanding,
          },
          {
            name: ["applyingForAdvancedStandingSpecify"],
            value: applicantCourseData?.applyingForAdvancedStandingSpecify,
          },
          {
            name: ["specialCondition"],
            value: applicantCourseData?.specialCondition,
          },
          {
            name: ["holidayStartDate"],
            value: applicantCourseData?.holidayStartDate,
          },
          {
            name: ["holidayEndDate"],
            value: applicantCourseData?.holidayEndDate,
          },
          {
            name: ["studentOrientationDate"],
            value: applicantCourseData?.studentOrientationDate,
          },
          {
            name: ["totalHolidayWeeks"],
            value: applicantCourseData?.totalHolidayWeeks,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Apply Under Agent?"
            name={"agent"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select an agent",
            //   },
            // ]}
          >
            <Select
              placeholder="Agent"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    agent: e,
                  };
                });
              }}
            >
              <Option value="ABC Consultancy and Visa Services">
                ABC Consultancy and Visa Services
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Agent Contact"
            name={"agentContact"}
            style={{ width: "100%" }}

            //   rules={[
            //     {
            //       required: true,
            //       message: "Please enter agent contact!",
            //     },
            //   ]}
          >
            <Select
              placeholder="Agent Contact"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    agentContact: e,
                  };
                });
              }}
            >
              <Option value="Rahul Panday (0125854854)">
                Rahul Panday (0125854854)
              </Option>
            </Select>{" "}
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Intake"
            name={"intake"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a intake!",
            //   },
            // ]}
          >
            <Select
              placeholder="Intake"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    intake: e,
                  };
                });
              }}
            >
              <Option value="JANUARY">January</Option>
              <Option value="FEBRUARY">February</Option>
              <Option value="MARCH">March</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Number of weeks"
            name={"numberOfWeeks"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter number of weeks!",
            //   },
            // ]}
          >
            <Input
              type="number"
              placeholder="Number of weeks"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    numberOfWeeks: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course"
            name={"course"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course !",
              },
            ]}
          >
            <Select
              placeholder="Select Course"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    course: e,
                  };
                });
              }}
            >
              <Option value="EAP: English for Academic Purposes">
                EAP: English for Academic Purposes
              </Option>
              <Option value="EAP: English for Academic Purposes - Offshore">
                EAP: English for Academic Purposes - Offshore
              </Option>
              <Option value="EAP: English for Academic Purposes - Other">
                EAP: English for Academic Purposes - Other
              </Option>
              <Option value="GE: General English">GE: General English</Option>
              <Option value="GE-OS: General English - Offshore">
                GE-OS: General English - Offshore
              </Option>
              <Option value="GE-Other: General English - Other">
                GE-Other: General English - Other
              </Option>
              <Option value="IELTS: IELTS Prepration">
                IELTS: IELTS Prepration
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Preferred study period (start-finish)"
            name={"startDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a start date !",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    startDate: e,
                  };
                });
              }}
            >
              <Option value="02-01-2023">02-01-2023/02-01-2024</Option>
              <Option value="09-01-2023">09-01-2023/09-01-2024</Option>
              <Option value="16-01-2023">16-01-2023/16-01-2024</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label={`Course Actual Fee (Course Normal Fee: $ 220.00)`}
          name={"courseActualFee"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter course actual fee!",
            },
          ]}
        >
          <Input
            type="number"
            placeholder="Course Actual Fee"
            onChange={(e) => {
              setApplicantCourseData((prevData) => {
                return {
                  ...prevData,
                  courseActualFee: e.target.value,
                };
              });
            }}
          />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Enrollment Fee"
            name={"enrollmentFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a enrollment fee!",
              },
            ]}
          >
            <Select
              placeholder="Enrollment Fee"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    enrollmentFee: e,
                  };
                });
              }}
              defaultValue={"NO_ENROL_FEE"}
            >
              <Option value="200">200</Option>
              <Option value="300">300</Option>
              <Option value="NO_ENROL_FEE">No enrol fee</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Upfront Fee"
            name={"upfrontFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a upfront fee!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Upfront Fee"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    upfrontFee: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Material Fee"
            name={"materialFee"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a material fee!",
            //   },
            // ]}
          >
            <Input
              type="number"
              placeholder="Material Fee"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    materialFee: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Status"
            name={"status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a status !",
              },
            ]}
          >
            <Select
              placeholder="Status"
              defaultValue={"NEW_APPLICATION_REQUEST"}
              disabled={true}
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    status: "NEW_APPLICATION_REQUEST",
                  };
                });
              }}
            >
              <Option value="AGENT_APPLY">Agent Apply</Option>
              <Option value="CANCELLED">Cancelled</Option>
              <Option value="COMPLETED">Completed</Option>
              <Option value="CONVERTED">Converted</Option>
              <Option value="CURRENT_STUDENT">Current Student</Option>
              <Option value="DEFERRED">Deferred</Option>
              <Option value="DID_NOT_COMMENCE">Did not Commence</Option>
              <Option value="ENROLLED">Enrolled</Option>
              <Option value="EXPIRED_OFFER">Expired Offer</Option>
              <Option value="FINISHED">Finished</Option>
              <Option value="GRADUATED">Graduated</Option>
              <Option value="NEW_APPLICATION_REQUEST">
                New Application Request
              </Option>
              <Option value="NEW_COURSE_REQUEST">New Course Request</Option>
              <Option value="OFFERED">Offered</Option>
              <Option value="PLACED">Placed</Option>
              <Option value="REPORTED">Reported</Option>
              <Option value="TRANSITIONED">Transitioned</Option>
              <Option value="VIC_COMPLETION_OF-LOWER_LEVEL_OF_PROGRAM">
                VIC-Completion of lower level of program
              </Option>
              <Option value="VIC_IN_TRAINING">VIC-In training</Option>
              <Option value="VIC_NESTED_PROGRAM">VIC-Nested program</Option>
              <Option value="VIC_NOT_YET_STARTED">VIC-Not yet started</Option>
              <Option value="VIC_PROGRAM_COMPLETED_ISSUED">
                VIC-Program completed (issued)
              </Option>
              <Option value="VIC_PROGRAM_COMPLETED_NOT_ISSUED">
                VIC-Program completed (not issued)
              </Option>
              <Option value="VIC_STREAMED_PROGRAM">VIC-Streamed program</Option>
              <Option value="VIC_STUDENT_SUCCESSFULLY_ACHEIVED_INTENDED_OUTCOME">
                VIC-Student successfully acheived intended outcome
              </Option>
              <Option value="VIC_SUPERSEDED_PROGRAM">
                VIC-Superseded program
              </Option>
              <Option value="VIC_WITHDRAWN_APPARENT">
                VIC-Withdrawn - Apparent
              </Option>
              <Option value="VIC_WITHDRAWN_DEFERRED">
                VIC-Withdrawn - Deferred
              </Option>
              <Option value="VIC_WITHDRAWN_OFFICIAL">
                VIC-Withdrawn - Official
              </Option>
              <Option value="WITHDRAWN">Withdrawn</Option>
            </Select>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Study Reason:"
            name={"studyReason"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a status !",
              },
            ]}
          >
            <Select
              placeholder="Study Reason"
              defaultValue={"@@-Not specified"}
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    studyReason: e,
                  };
                });
              }}
            >
              <Option value="01-To get a job (Job related)">
                01-To get a job (Job related)
              </Option>
              <Option value="02-To develop my existing business (Job related">
                02-To develop my existing business (Job related)
              </Option>
              <Option value="03-To start my own business (Job related">
                03-To start my own business (Job related)
              </Option>
              <Option value="04-To try for a different career (Job related">
                04-To try for a different career (Job related)
              </Option>
              <Option value="05-To get a better job or promotion (Job related">
                05-To get a better job or promotion (Job related)
              </Option>
              <Option value="06-It was a requirement of my job (Job related">
                06-It was a requirement of my job (Job related)
              </Option>
              <Option value="07-I wanted extra skills for my job (Job related">
                07-I wanted extra skills for my job (Job related)
              </Option>
              <Option value="08-To get into another course of study (Further study">
                08-To get into another course of study (Further study)
              </Option>
              <Option value="11-Other reasons (Other">
                11-Other reasons (Other)
              </Option>
              <Option value="12-For personal interest or self-development (Other">
                12-For personal interest or self-development (Other)
              </Option>
              <Option value="13-To get skills for community/voluntary work (Other">
                13-To get skills for community/voluntary work (Other)
              </Option>
              <Option value="@@-Not specified">@@-Not specified</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Are you applying for advanced standing/ credit?"
            name={"areApplyingForAdvancedStanding"}
            style={{ width: "100%" }}
          >
            <Radio.Group
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    areApplyingForAdvancedStanding: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {applicantCourseData?.areApplyingForAdvancedStanding && (
            <Form.Item
              label="Please Specify"
              name={"applyingForAdvancedStandingSpecify"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write something"
                onChange={(e) => {
                  setApplicantCourseData((prevData) => {
                    return {
                      ...prevData,
                      applyingForAdvancedStandingSpecify: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Special Condition"
            name={"specialCondition"}
            style={{ width: "100%" }}
          >
            <TextArea
              placeholder="Write about special conditions"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    specialCondition: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Holiday Start Date"
            name={"holidayStartDate"}
            style={{ width: "100%" }}
          >
            <DatePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    holidayStartDate: e,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Holiday End Date"
            name={"holidayEndDate"}
            style={{ width: "100%" }}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    holidayEndDate: e,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Student Orientation Date"
            name={"studentOrientationDate"}
            style={{ width: "100%" }}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    studentOrientationDate: e,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Total Holiday Weeks"
            name={"totalHolidayWeeks"}
            style={{ width: "100%" }}
          >
            <Input
              type="number"
              placeholder="Total Holiday Weeks"
              onChange={(e) => {
                setApplicantCourseData((prevData) => {
                  return {
                    ...prevData,
                    totalHolidayWeeks: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {current === 2 && (
            <Form.Item>
              <ButtonElement
                type={"default"}
                handleClick={() => {
                  setCurrent(1);
                }}
                name="Back"
              >
                Back
              </ButtonElement>
            </Form.Item>
          )}
          <Form.Item>
            <ButtonElement
              type="primary"
              htmlType="submit"
              name="Submit Application"
            >
              Submit
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ShortCourseApplicantCourseInformation;
