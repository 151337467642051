import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddLanguage from "./AddLanguage";

const columns = [
  {
    title: "Language",
    dataIndex: "language",
    width: 250,
    align: "center",
  },
  {
    title: "ABS value",
    dataIndex: "abs",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const languageListData = [
  {
    id: "1",
    language: "Aboriginal English",
    abs: "8998",
  },
  {
    id: "2",
    language: "Acehnese",
    abs: "6513",
  },
  {
    id: "3",
    language: "Acholi",
    abs: "6513",
  },
  {
    id: "4",
    language: "Adnymathanha",
    abs: "8901",
  },
];

const LanguageList = () => {
  const [isAddLanguageModalVisible, setIsAddLanguageModalVisible] =
    useState(false);

  const data = languageListData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      language: dataObj?.language,
      abs: dataObj?.abs,
      actions: (
        <Space size="middle">
          <Tooltip title="Delete Language">
            <Popconfirm
              title="Are you sure to delete this language?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Language List</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddLanguageModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddLanguageModalVisible && (
        <AddLanguage
          isAddLanguageModalVisible={isAddLanguageModalVisible}
          setIsAddLanguageModalVisible={setIsAddLanguageModalVisible}
        />
      )}
    </div>
  );
};

export default LanguageList;
