import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../../../components/input";
import { AiOutlineEdit } from "react-icons/ai";
import OfferChecklistComment from "./OfferChecklistComment";

const columns = [
  {
    title: "Checklist",
    dataIndex: "checklist",
    width: 250,
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: 350,
    align: "center",
  },
  {
    title: "Checked By",
    dataIndex: "checkedBy",
    width: 150,
    align: "center",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 150,
    align: "center",
  },
];

const OfferChecklistData = [
  {
    id: 1,
    checklist: "Academic Document",
    description: "All Academic Documents Submitted",
    checkedBy: "Anu",
    comments: "Received",
  },
  {
    id: 2,
    checklist: "English requirement",
    description: "English requirement satisfied",
    checkedBy: "Anu",
    comments: "not received",
  },
];

const OfferChecklist = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [
    isOfferChecklistCommentModalVisible,
    setIsOfferChecklistCommentModalVisible,
  ] = useState(false);
  const data = OfferChecklistData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      checklist: dataObj?.checklist,
      description: dataObj?.description,
      checkedBy: dataObj?.checkedBy,
      comments: dataObj?.comments,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Staff">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsOfferChecklistCommentModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });

  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
  };
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Offer Checklist</Pagename>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: () => {},
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isOfferChecklistCommentModalVisible && (
        <OfferChecklistComment
          isOfferChecklistCommentModalVisible={
            isOfferChecklistCommentModalVisible
          }
          setIsOfferChecklistCommentModalVisible={
            setIsOfferChecklistCommentModalVisible
          }
        />
      )}
    </Card>
  );
};

export default OfferChecklist;
