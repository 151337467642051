import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../../Assets/logo/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StaffDiary from "../../Staff/StaffDetails/StaffDiary/StaffDiary";
import StaffDocuments from "../../Staff/StaffDetails/StaffDocuments/StaffDocuments";
import { ButtonElement } from "../../../components/input";
import Sanction from "../StudentDetails/Sanction/Sanction";
import DocumentChecklist from "../StudentDetails/DocumentChecklist/DocumentChecklist";
import PaymentSummary from "./PaymentSummary/PaymentSummary";
import SendComposedMail from "../../Mailbox/SendComposedMail";
import AddInitialPayment from "./InitialPayment/AddInitialPayment";
import MiscellaneousPayment from "./MiscellaneousPayment/MiscellaneousPayment";
import AgentCommission from "./AgentCommission/AgentCommission";
import RefundHistory from "./RefundHistory/RefundHistory";
import StudentScholarship from "./StudentScholarship/StudentScholarship";
import TransferPayment from "./TransferPayment/TransferPayment";

const StudentPaymentDetails = ({
  leadData,
  record,
  isStudentPaymentDetailsModalVisible,
  setIsStudentPaymentDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return { staffState: state.StaffReducer };
  });

  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [
    isStartInitialPaymentModalVisible,
    setIsStartInitialPaymentModalVisible,
  ] = useState(false);

  const [current, setCurrent] = useState("paymentSummary");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const staffTabList = [
    {
      key: "paymentSummary",
      label: "Payment Summary",
    },
    {
      key: "miscellaneousPayment",
      label: "Miscellaneous Payment",
    },
    {
      key: "agentCommission",
      label: "Agent Commission",
    },
    {
      key: "refundHistory",
      label: "Refund History",
    },
    {
      key: "studentScholarship",
      label: "Student Scholarship",
    },
    {
      key: "transferPayment",
      label: "Transfer Payment",
    },
    {
      key: "diary",
      label: "Diary",
    },
    {
      key: "documentChecklist",
      label: "Document Checklist",
    },
    {
      key: "sanction",
      label: "Sanction",
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  const navigate = useNavigate();
  console.log("record from lead details");
  console.log("record from lead store");
  console.log("store.staffState?.leadById");
  return (
    <Drawer
      title="Student Payment Details"
      open={isStudentPaymentDetailsModalVisible}
      onClose={() => {
        setIsStudentPaymentDetailsModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Card style={{ borderRadius: "3px" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "inherit",
          }}
        >
          <Image
            src={client}
            className="clientImage"
            height={160}
            width={160}
          />
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
              width: "80%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`Sabin Khadka (ID: SD001)`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlinePhone
                  style={{
                    fontSize: "20",
                  }}
                />
                0417456836
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <GoLocation
                  style={{
                    fontSize: "16",
                  }}
                />
                1/544 Willoughby Road Willoughby NSW Australia, 2068
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlineMail
                  style={{
                    fontSize: "16",
                  }}
                />{" "}
                awesomesabinkhadka@gmail.com
              </span>
            </div>
            {/* <div>
                  <div>
                    <span className="profileTitle">Campaign:</span>
                    <span>
                      Further Details Here
                    </span>
                  </div>
                </div> */}
            <div
              className="fxRAc"
              style={{
                gap: "0.5rem",
                position: "absolute",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>

              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
              <Tooltip title="Initial Payment">
                <ButtonElement
                  type="primary"
                  handleClick={() => {
                    setIsStartInitialPaymentModalVisible(true);
                  }}
                  name="Start Initial Payment"
                />
              </Tooltip>
              <Tooltip title="Generate Statement of Account">
                <ButtonElement
                  type="primary"
                  onClick={() => {}}
                  name="Statement of Account"
                />
              </Tooltip>
              <Tooltip title="Generate Payment Schedule Invoice">
                <ButtonElement
                  type="primary"
                  onClick={() => {}}
                  name="Invoice"
                />
              </Tooltip>
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            ></div>
          </div>
        </div>
        <Tabs
          defaultActiveKey="profile"
          items={staffTabList}
          activeTabKey={current}
          onChange={(key) => {
            console.log("current key", key);
            setCurrent(key);
          }}
        />

        {current === "paymentSummary" && (
          <>
            <PaymentSummary />
          </>
        )}
        {current === "miscellaneousPayment" && (
          <>
            <MiscellaneousPayment />
          </>
        )}
        {current === "refundHistory" && (
          <>
            <RefundHistory />
          </>
        )}
        {current === "transferPayment" && (
          <>
            <TransferPayment />
          </>
        )}
        {current === "studentScholarship" && (
          <>
            <StudentScholarship />
          </>
        )}
        {current === "agentCommission" && (
          <>
            <AgentCommission />
          </>
        )}
        {current === "documentChecklist" && (
          <>
            <DocumentChecklist />
          </>
        )}

        {current === "diary" && (
          <>
            <StaffDiary />
          </>
        )}
        {current === "sanction" && (
          <>
            <Sanction />
          </>
        )}
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isStartInitialPaymentModalVisible && (
        <AddInitialPayment
          isStartInitialPaymentModalVisible={isStartInitialPaymentModalVisible}
          setIsStartInitialPaymentModalVisible={
            setIsStartInitialPaymentModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default StudentPaymentDetails;
