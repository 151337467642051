import React from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

const ModalElement = ({ children, keyType, title }) => {
  const dispatch = useDispatch();
  const { openModal, modalType } = useSelector((store) => store?.DrawerReducer);
  console.log("checking checking", openModal, modalType, keyType);

  const handleOk = () => {
    dispatch({ type: "FETCH_CLOSE_MODAL" });
  };
  const handleCancel = () => {
    dispatch({ type: "FETCH_CLOSE_MODAL" });
  };
  return (
    <Modal
      title={title}
      open={openModal && keyType?.toLowerCase() === modalType?.toLowerCase()}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{
        zIndex: "1000000 !important",
      }}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default ModalElement;
