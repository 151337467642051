import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";

import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFilePdf,
  AiOutlinePlus,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import { TbReport } from "react-icons/tb";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../../components/input";
import AddButton from "../../../../../components/button/AddButton";

const columns = [
  {
    title: "Transaction No",
    dataIndex: "transactionNo",
    width: 250,
    align: "center",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    width: 150,
    align: "center",
  },
  {
    title: "Student Id",
    dataIndex: "studentId",
    width: 150,
    align: "center",
  },
  {
    title: "Course Id",
    dataIndex: "courseId",
    width: 150,
    align: "center",
  },
  {
    title: "Course Attempt",
    dataIndex: "courseAttempt",
    width: 150,
    align: "center",
  },
  {
    title: "Due Amount",
    dataIndex: "dueAmount",
    width: 100,
    align: "center",
  },
  {
    title: "Paid Amount",
    dataIndex: "paidAmount",
    width: 100,
    align: "center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    width: 100,
    align: "center",
  },
  {
    title: "Commission Payable",
    dataIndex: "commissionPayable",
    width: 100,
    align: "center",
  },
  {
    title: "GST",
    dataIndex: "gst",
    width: 100,
    align: "center",
  },
  {
    title: "Commission Paid",
    dataIndex: "commissionPaid",
    width: 100,
    align: "center",
  },
  {
    title: "Commission Paid Date",
    dataIndex: "commissionPaidDate",
    width: 100,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 100,
    align: "center",
  },
];

const PaymentHistoryData = [
  {
    transactionNo: "89",
    invoiceNo: "1",
    studentId: "SD2023001",
    courseId: "IT003",
    courseAttempt: "1",
    dueAmount: "3000",
    paidAmount: "3000.00",
    paidDate: "11-05-2022",
    commissionPayable: "900",
    gst: "90",
    commissionPaid: "990",
    commissionPaidDate: "11-05-2022",
    remarks: "Initial payment by agent. Commission deducted",
  },
];

const PaymentHistory = () => {
  const [isAddPaymentHistoryModalVisible, setIsAddPaymentHistoryModalVisible] =
    useState(false);
  const [
    isUpdatePaymentHistoryModalVisible,
    setIsUpdatePaymentHistoryModalVisible,
  ] = useState(false);

  const data = PaymentHistoryData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      transactionNo: dataObj?.transactionNo,
      invoiceNo: dataObj?.invoiceNo,
      studentId: dataObj?.studentId,
      courseId: dataObj?.courseId,
      courseAttempt: dataObj?.courseAttempt,
      dueAmount: dataObj?.dueAmount,
      paidAmount: dataObj?.paidAmount,
      paidDate: dataObj?.paidDate,
      commissionPayable: dataObj?.commissionPayable,
      gst: dataObj?.gst,
      commissionPaid: dataObj?.commissionPaid,
      commissionPaidDate: dataObj?.commissionPaidDate,
      remarks: dataObj?.remarks,
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Payment History</Pagename>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Year:</div>
            <SelectField
              name="year"
              placeholder="Year"
              options={[]}
              handleChange={(e) => {}}
              fieldWidth="8rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Type:</div>
            <SelectField
              name="year"
              placeholder="View Type"
              options={[
                {
                  label: "Auto Deducted",
                  value: "AUTO_DEDUCTED",
                },
                {
                  label: "Incoiced Commission",
                  value: "INVOICED_COMMISSION",
                },
                {
                  label: "Other Type",
                  value: "OTHER_TYPE",
                },
              ]}
              handleChange={(e) => {}}
              fieldWidth="12rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 0,
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {/* {isAddPaymentHistoryModalVisible && (
        <AddPaymentHistory
          isAddPaymentHistoryModalVisible={isAddPaymentHistoryModalVisible}
          setIsAddPaymentHistoryModalVisible={
            setIsAddPaymentHistoryModalVisible
          }
        />
      )}
      {isUpdatePaymentHistoryModalVisible && (
        <UpdatePaymentHistory
          isUpdatePaymentHistoryModalVisible={
            isUpdatePaymentHistoryModalVisible
          }
          setIsUpdatePaymentHistoryModalVisible={
            setIsUpdatePaymentHistoryModalVisible
          }
        />
      )} */}
    </Card>
  );
};

export default PaymentHistory;
