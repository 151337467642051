import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePlus,
  AiOutlineUpload,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import AddEmployer from "./AddEmployer";
import UpdateEmployer from "./UpdateEmployer";
import EmployerDetails from "./EmployerDetails/EmployerDetails";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: 100,
    align: "center",
  },
  {
    title: "Contact Person",
    dataIndex: "contactPerson",
    width: 150,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 200,
    align: "center",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    width: 100,
    align: "center",
  },
  {
    title: "Industry",
    dataIndex: "industry",
    width: 200,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 100,
    align: "center",
  },
];

const ManageEmployerData = [
  {
    id: "1",
    name: "ABC Corporations",
    contactPerson: "Mike Pearson",
    email: "abc@gmail.com.au",
    phone: "0156565666",
    industry: "27",
    status: "ACTIVE",
  },
  {
    id: "2",
    name: "DC Plumbing Services",
    contactPerson: "Jhon Doe",
    email: "dcplumbing@gmail.com",
    phone: "0265656955",
    industry: "44",
    status: "ACTIVE",
  },
];

const ManageEmployer = () => {
  const [isAddEmployerModalVisible, setIsAddEmployerModalVisible] =
    useState(false);
  const [isUpdateEmployerModalVisible, setIsUpdateEmployerModalVisible] =
    useState(false);
  const [isEmployerDetailsModalVisible, setIsEmployerDetailsModalVisible] =
    useState(false);

  const data = ManageEmployerData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      name: dataObj?.name,
      contactPerson: dataObj?.contactPerson,
      email: dataObj?.email,
      phone: dataObj?.phone,
      industry: dataObj?.industry,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Staff Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsEmployerDetailsModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Update Employer Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateEmployerModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>

          {/* <Tooltip title="Send Email">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineMail />
              </div>
            </a>
          </Tooltip> */}
          <Tooltip title="Delete Employer">
            <Popconfirm
              title="Are you sure to delete this employer?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Employer</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddEmployerModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isEmployerDetailsModalVisible && (
        <EmployerDetails
          fromAddTeacher={false}
          isEmployerDetailsModalVisible={isEmployerDetailsModalVisible}
          setIsEmployerDetailsModalVisible={setIsEmployerDetailsModalVisible}
        />
      )}
      {isAddEmployerModalVisible && (
        <AddEmployer
          isAddEmployerModalVisible={isAddEmployerModalVisible}
          setIsAddEmployerModalVisible={setIsAddEmployerModalVisible}
        />
      )}
      {isUpdateEmployerModalVisible && (
        <UpdateEmployer
          isUpdateEmployerModalVisible={isUpdateEmployerModalVisible}
          setIsUpdateEmployerModalVisible={setIsUpdateEmployerModalVisible}
        />
      )}
    </div>
  );
};

export default ManageEmployer;
