import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Table,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;
const toBeAssignedColumns = [
  {
    title: "User Id",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 150,
    align: "center",
  },
];
const assignedColumns = [
  {
    title: "User Id",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Course",
    dataIndex: "course",
    width: 150,
    align: "center",
  },
  {
    title: "Access to Everyone?",
    dataIndex: "firstName",
    width: 200,
    align: "center",
  },
];
const AssignAccessToEvent = ({
  isAssignAccessToEventModalVisible,
  setIsAssignAccessToEventModalVisible,
}) => {
  const [assginAccessToEventData, setAssginAccessToEventData] = useState({
    assignAccessTo: "CLASS",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const assignAccessToEventSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ASSIGN_ACCESS_TO_EVENT_REQUEST", payload: formData });
    // form.resetFields();
    setIsAssignAccessToEventModalVisible(false);
  };
  return (
    <Drawer
      title="Assign Access to Event"
      open={isAssignAccessToEventModalVisible}
      onClose={() => {
        setIsAssignAccessToEventModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={assignAccessToEventSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Assign Access To"
            name={"assignAccessTo"}
            style={{ width: "100%" }}
          >
            <Select
              placeholder="Assign Access To"
              defaultValue={"CLASS"}
              onChange={(e) => {
                setAssginAccessToEventData((prevData) => {
                  return {
                    assignAccessTo: e,
                  };
                });
              }}
            >
              <Option value="CLASS">Class</Option>
              <Option value="COURSE">Course</Option>
              <Option value="USER">User</Option>
              <Option value="ROLE">Role</Option>
            </Select>
          </Form.Item>

          {assginAccessToEventData.assignAccessTo === "CLASS" && (
            <Form.Item
              label="Semester"
              name={"semester"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select a semester !",
              //   },
              // ]}
            >
              <Select placeholder="Semester" defaultValue={"2023"}>
                <Option value="2023">2023</Option>
                <Option value="2024">2024</Option>
                <Option value="2025">2025</Option>
                <Option value="2026">2026</Option>
                <Option value="2027">2027</Option>
              </Select>
            </Form.Item>
          )}
          {assginAccessToEventData.assignAccessTo === "COURSE" && (
            <Form.Item
              label="Course"
              name={"course"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a course !",
                },
              ]}
            >
              <Select>
                <Option>EAP: English for Academic Purposes</Option>
                <Option>EAP: English for Academic Purposes - Offshore</Option>
                <Option>EAP: English for Academic Purposes - Other</Option>
                <Option>GE: General English</Option>
                <Option>GE-OS: General English - Offshore</Option>
                <Option>GE-Other: General English - Other</Option>
                <Option>IELTS: IELTS Prepration</Option>
              </Select>
            </Form.Item>
          )}
          {assginAccessToEventData.assignAccessTo === "USER" && (
            <Form.Item
              label="Role"
              name={"role"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a role !",
                },
              ]}
            >
              <Select>Teacher</Select>
              <Select>Student</Select>
              <Select>Super Admin</Select>
            </Form.Item>
          )}
        </div>
        {assginAccessToEventData.assignAccessTo === "CLASS" && (
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item label="Term" name={"term"} style={{ width: "100%" }}>
              <Select placeholder="Term" defaultValue={"1"}>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Subject"
              name={"subject"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a subject",
                },
              ]}
            >
              <Select placeholder="Subject">
                <Option>EAP: English for Academic Purposes</Option>
                <Option>GE: General English </Option>
              </Select>
            </Form.Item>
          </div>
        )}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {assginAccessToEventData.assignAccessTo === "CLASS" && (
            <Form.Item
              label="Class"
              name={"class"}
              rules={[
                {
                  required: true,
                  message: "Please enter !",
                },
              ]}
            >
              <Select placeholder="Class">
                <Option>02012023A: (1-1)</Option>
                <Option>02012023b: (1-1)</Option>
              </Select>
            </Form.Item>
          )}
          {assginAccessToEventData.assignAccessTo !== "ROLE" && (
            <Form.Item
              label="Assign to Whole Group?"
              name={"assignToWholeGroup"}
              style={{ width: "100%" }}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          )}
        </div>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table
            // dataSource={toBeAssignedData}
            bordered={true}
            columns={toBeAssignedColumns}
            pagination={false}
          />
          <Table
            // dataSource={assignedData}
            bordered={true}
            columns={assignedColumns}
            pagination={false}
          />
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Assign Access"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AssignAccessToEvent;
