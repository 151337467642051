import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddOhscType from "./AddOhscType";
import { ButtonElement } from "../../../../components/input";
import AddOhscProviderName from "./AddOhscProviderName";
const { Option } = Select;

const UpdateOhscProvider = ({
  isUpdateOhscProviderModalVisible,
  setIsUpdateOhscProviderModalVisible,
}) => {
  const [isAddProviderModalVisible, setIsAddProviderModalVisible] =
    useState(false);
  const [isAddOhscTypeModalVisible, setIsAddOhscTypeModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateOhscProviderSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateOhscProvider_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateOhscProviderModalVisible(false);
  };
  return (
    <Modal
      title="Update Ohsc Provider Info"
      open={isUpdateOhscProviderModalVisible}
      onCancel={() => {
        setIsUpdateOhscProviderModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={UpdateOhscProviderSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        {/* <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Provider Name"
              name={"providerName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a provider name!",
                },
              ]}
            >
              <Select placeholder="Provider Name">
                <Option value={"Medibank Private"}>Medibank Private</Option>
                <Option value={"AHM"}>AHM</Option>
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddProviderModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Select OSHC type"
              name={"OSHC Type"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select OSHC type!",
                },
              ]}
            >
              <Select placeholder="OSHC Type">
                <Option value={"FAMILY"}>Family</Option>
                <Option value={"SINGLE"}>Single</Option>
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddOhscTypeModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="OSHC duration (in Months)"
            name={"ohscDuration"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter OHSC duration in months!",
              },
            ]}
          >
            <Input type="number" placeholder="OSHC Duration" />
          </Form.Item>
          <Form.Item
            label="Valid From"
            name={"validFrom"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a OHSC valid from date",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div> */}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {/* <Form.Item
            label="Valid To"
            name={"validTo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a OHSC valid to date",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item> */}
          <Form.Item
            label="OSHC Fee"
            name={"ohscFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a OSHC fee!",
              },
            ]}
          >
            <Input type="number" placeholder="OSHC Fee" />
          </Form.Item>
        </div>
        <Form.Item>
          <ButtonElement block type="primary" htmlType="submit" name="Update" />
        </Form.Item>
      </Form>
      {/* {isAddProviderModalVisible && (
        <AddOhscProviderName
          isAddProviderModalVisible={isAddProviderModalVisible}
          setIsAddProviderModalVisible={setIsAddProviderModalVisible}
        />
      )}
      {isAddOhscTypeModalVisible && (
        <AddOhscType
          isAddOhscTypeModalVisible={isAddOhscTypeModalVisible}
          setIsAddOhscTypeModalVisible={setIsAddOhscTypeModalVisible}
        />
      )} */}
    </Modal>
  );
};

export default UpdateOhscProvider;
