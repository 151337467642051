import React from "react";
import {
  ButtonElement,
  CheckboxElement,
  DrawerElement,
  RadioElement,
  SelectField,
} from "../../../components/input";
import { Form } from "antd";

export default function AddCourseCalanderType() {
  const calenderList = [
    {
      label: "Calender Set1",
      value: 1,
    },
    {
      label: "Calender Set2",
      value: 2,
    },
  ];

  const yearList = [
    {
      label: 2022,
      value: 1,
    },
    {
      label: 2023,
      value: 2,
    },
  ];
  const courseList = [
    {
      label: "EILCOS",
      value: 1,
    },
  ];
  const courseStatus = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 2,
    },
  ];

  const courseSelectionList = [
    {
      label: "All Course",
      value: 1,
    },
    {
      label: "Selected Course",
      value: 2,
    },
  ];

  const courseIdList = [
    {
      label: "EAP: English for Academic Purposes	",
      value: 1,
    },
    {
      label: "EAP-OS: English for Academic Purposes - Offshore",
      value: 2,
    },
    {
      label: "EAP-Other: English for Academic Purposes - Other	",
      value: 3,
    },
    {
      label: "GE: General English",
      value: 4,
    },
    {
      label: "GE-OS: General English - Offshore	",
      value: 5,
    },
    {
      label: "GE-Other: General English - Other  ",
      value: 6,
    },
  ];

  return (
    <DrawerElement
      title="Add Calender Type to Course"
      keyType="addCalanderTypeToCourse"
    >
      <Form layout="vertical" className="grid grid-cols-2 gap-4 mx-4">
        <SelectField
          name="calender_type"
          label="Calender Type"
          placeholder="Select Calender Type"
          options={calenderList}
          fieldWidth="100%"
        />
        <SelectField
          name="year"
          placeholder="Select Year"
          label="Select Year"
          options={yearList}
          fieldWidth="100%"
        />
        <SelectField
          name="course_type"
          placeholder="Selec Course Type"
          label="Select Course Type"
          options={courseList}
          fieldWidth="100%"
        />
        <RadioElement
          name="course_status"
          label="Course Status"
          options={courseStatus}
          fieldWidth="100%"
        />
        <RadioElement
          name="course"
          label="Course Selection"
          options={courseSelectionList}
          fieldWidth="100%"
        />
        <div className="col-span-2">
          <CheckboxElement
            name="course_id"
            label="Course Id"
            options={courseIdList}
            classNameStyle="grid gird-cols-2 gap-3"
            fieldWidth="100%"
          />
        </div>
        <ButtonElement name="Setup" />
      </Form>
    </DrawerElement>
  );
}
