import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddCountry = ({
  isAddCountryModalVisible,
  setIsAddCountryModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddCountrySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_COUNTRY_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddCountryModalVisible(false);
  };
  return (
    <Modal
      title="Add Country"
      open={isAddCountryModalVisible}
      onCancel={() => {
        setIsAddCountryModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddCountrySubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Country"
            name={"country"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a country name!",
              },
            ]}
          >
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item
            label="Nationality"
            name={"nationality"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a nationality of the country!",
              },
            ]}
          >
            <Input placeholder="Nationality" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Country Code"
            name={"countryCode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter",
              },
            ]}
          >
            <Input placeholder="Country Code" />
          </Form.Item>
          <Form.Item
            label="Region"
            name={"region"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a region",
              },
            ]}
          >
            <Select placeholder="Region">
              <Option value="Americas">Americas</Option>
              <Option value="North Africa and the Middle East">
                North Africa and the Middle East
              </Option>
              <Option value="North-East Asia">North-East Asia</Option>
              <Option value="North-West Europe">North-West Europe</Option>
              <Option value="Not Specified">Not Specified</Option>
              <Option value="Ocenia and Antarctica">
                Ocenia and Antarctica
              </Option>
              <Option value="South-East Asia">South-East Asia</Option>
              <Option value="Southern and Central Asia">
                Southern and Central Asia
              </Option>
              <Option value="Southern and Eastern Europe">
                Southern and Eastern Europe
              </Option>
              <Option value="Sub-Saharan Africa">Sub-Saharan Africa</Option>
            </Select>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Country Level"
            name={"countryLevel"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a country level",
              },
            ]}
          >
            <Select placeholder="Country Level">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="ABS value"
            name={"abs"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter an ABS value!",
              },
            ]}
          >
            <Input placeholder="ABS" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Country"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCountry;
