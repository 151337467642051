import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import { ButtonElement } from "../../../../components/input";
import AddServiceFacility from "./ServiceFacility/AddServiceFacility";
import AddServiceCategory from "./ServiceCategory/ServiceCategory";
const { Option } = Select;

const AddFacility = ({
  isAddFacilityModalVisible,
  setIsAddFacilityModalVisible,
}) => {
  const [
    isAddServiceFacilityModalVisible,
    setIsAddServiceFacilityModalVisible,
  ] = useState(false);
  const [
    isAddServiceFacilityCategoryModalVisible,
    setIsAddServiceFacilityCategoryModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddFacilitySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddFacility_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddFacilityModalVisible(false);
  };
  return (
    <Drawer
      title="Add Facility"
      open={isAddFacilityModalVisible}
      onClose={() => {
        setIsAddFacilityModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddFacilitySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Service Name"
              name={"serviceName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a service name!",
                },
              ]}
            >
              <Select placeholder="Service Name">
                <Option value="AIRPORT_PICKUP">Airport Pickup</Option>
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddServiceFacilityModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Category Name"
              name={"categoryName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a category name!",
                },
              ]}
            >
              <Input placeholder="Category Name" />
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddServiceFacilityCategoryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Facility Name"
            name={"facilityName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a facility name",
              },
            ]}
          >
            <Input placeholder="Facility Name" />
          </Form.Item>
          <Form.Item
            label="Facility Price Type"
            name={"facilityPriceType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter facility price type",
              },
            ]}
          >
            <Select placeholder="Facility Price Type">
              <Option value="PER_SERVICE">Per Service</Option>
              <Option value="PER_WEEK">Per Week</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="Student Price"
          name={"studentPrice"}
          rules={[
            {
              required: true,
              message: "Please enter a student price!",
            },
          ]}
        >
          <Input number placeholder="Student Price" />
        </Form.Item>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Facility"
          />
        </Form.Item>
      </Form>
      {isAddServiceFacilityModalVisible && (
        <AddServiceFacility
          isAddServiceFacilityModalVisible={isAddServiceFacilityModalVisible}
          setIsAddServiceFacilityModalVisible={
            setIsAddServiceFacilityModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default AddFacility;
