import { Card, Divider } from "antd";
import React from "react";

const ApplicantProfile = () => {
  const detailFun = (title, value, disableDivider) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fxRJsb">
          {title}:<span>{value}</span>
        </div>
        {!disableDivider && (
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
        )}
      </div>
    );
  };
  return (
    <div
      className="fxRJsb"
      style={{ marginTop: "1rem", gap: "0.5rem", alignItems: "flex-start" }}
    >
      <Card
        className="applicantDetails"
        bordered={true}
        style={{
          borderRadius: "3px",
          width: "100%",
        }}
      >
        <Divider
          orientationMargin="0"
          style={{
            marginBottom: "0.5rem",
            color: "#999",
            fontSize: "14px",
          }}
        >
          PERSONAL INFORMATION
        </Divider>

        {detailFun("Full name", "Mr Oscar Javier Romero Espitia")}
        {detailFun("Nickname", "Sabin")}
        {detailFun("Applicant type", "Overseas Student in Australia")}
        {detailFun("Gender", "Male")}
        {detailFun(
          "Date of Birth",
          "Wednesday, 24 August 1977 (Age 45 yr. 11 m.)"
        )}
        {detailFun("College Email", "alex@opera.nsw.edu.au")}
        {detailFun("Origin", "Overseas Student in Australia")}
        {detailFun("Nationality", "Colombian")}
        {detailFun("Birthplace, Country of birth", "Ibague, Colombia")}
        {detailFun("Passport No", "AS054192")}
        {detailFun("Expiry date", "Monday, 22 December 2025")}
        {detailFun("Visa type", "Student Visa")}
        {detailFun("Visa Number", "Not Provided")}
        {detailFun("Visa Expiry Date", "Not Provided")}
        {detailFun("Account Manager", "Anu Rani Regmi")}
        {detailFun("Admission Staff", "Rajiv Shrestha")}
        {detailFun(
          "Which BEST describes your current employment status?",
          "Not Specified",
          true
        )}
        <Divider
          orientationMargin="0"
          style={{ marginBottom: "0.5rem", color: "#999", fontSize: "14px" }}
        >
          CULTURAL DIVERSITY
        </Divider>
        {detailFun(
          "Are you of Australian Aboriginal or Torres Strait Islander origin?",
          "No, Neither Aboriginal nor Torres Strait Islander",
          true
        )}
        <Divider
          orientationMargin="0"
          style={{ marginBottom: "0.5rem", color: "#999", fontSize: "14px" }}
        >
          SCHOOLING
        </Divider>
        {detailFun(
          "What is your highest COMPLETED school level?",
          "Not Specified"
        )}
        {detailFun("Are you still attending secondary school?", "No")}
      </Card>
      <Card
        className="applicantDetails"
        style={{
          borderRadius: "3px",
          width: "100%",
        }}
      >
        <Divider
          orientationMargin="0"
          style={{ marginBottom: "0.5rem", color: "#999", fontSize: "14px" }}
        >
          LANGUAGE PROFICIENCY
        </Divider>
        {detailFun("First language", "Spanish")}
        {detailFun(
          "English was the language of instruction in previous secondary or tertiary studies?",
          "No"
        )}
        {detailFun(
          "Current Street Address (Primary Address)",
          "22 Milfred StreetHurlstone Park NSWAustralia,2193"
        )}
        {detailFun("Phone/WorkPhone", "-")}
        {detailFun("Fax", "-")}
        {detailFun("Mobile", "-")}
        {detailFun("0478290611", "-")}
        {detailFun(
          "Overseas/Permanent Address",
          "22 Milfred StreetHurlstone Park NSWAustralia,2193"
        )}
        {detailFun("Phone/WorkPhone", "-")}
        {detailFun("Fax", "-")}
        {detailFun("Mobile", "-")}
        {detailFun("0478290611", "-", true)}
        <Divider
          orientationMargin="0"
          style={{ marginBottom: "0.5rem", color: "#999", fontSize: "14px" }}
        >
          DISABILITY INFORMATION
        </Divider>
        {detailFun("Disability", "No", true)}
        <Divider
          orientationMargin="0"
          style={{ marginBottom: "0.5rem", color: "#999", fontSize: "14px" }}
        >
          EMERGENCY/GUARDIAN CONTACT DETAIL
        </Divider>
        {detailFun("Contact Type", "Emergency")}
        {detailFun("Contact Name", "TBA")}
        {detailFun("Relationship", "TBA")}
        {detailFun("Address", "TBA")}
        {detailFun("Phone", "TBA")}
        {detailFun("Email", "TBA")}
      </Card>
    </div>
  );
};

export default ApplicantProfile;
