import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const { Option } = Select;
const { TextArea } = Input;

const AddStudentCourse = ({
  isAddStudentCourseModalVisible,
  setIsAddStudentCourseModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addStudentCourseSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_STUDENT_COURSE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddStudentCourseModalVisible(false);
  };
  return (
    <Drawer
      title="Add Student Course"
      open={isAddStudentCourseModalVisible}
      onClose={() => {
        setIsAddStudentCourseModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={addStudentCourseSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item name={"avetmissFundingReported"} valuePropName="checked">
              <Checkbox>AVETMISS Funding Reported</Checkbox>
            </Form.Item>

            <Form.Item name={"certificateIssued"} valuePropName="checked">
              <Checkbox>Certificate Issued</Checkbox>
            </Form.Item>
          </div>
          <Form.Item
            label="Module Enrolment?"
            name={"moduleEnrolment"}
            style={{ width: "100%" }}
          >
            <Radio.Group>
              <Radio value={"YES"}>Yes</Radio>
              <Radio value={"NO"}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Offer ID"
            name={"offerId"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter offer id!",
              },
            ]}
          >
            <Select defaultValue={"NEW_OFFER_ID"} placeholder="Offer Id">
              <Option value="NEW_OFFER_ID">New Offer ID</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Course"
            name={"course"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course !",
              },
            ]}
          >
            <Select placeholder="Select Course">
              <Option>EAP: English for Academic Purposes</Option>
              <Option>EAP: English for Academic Purposes - Offshore</Option>
              <Option>EAP: English for Academic Purposes - Other</Option>
              <Option>GE: General English</Option>
              <Option>GE-OS: General English - Offshore</Option>
              <Option>GE-Other: General English - Other</Option>
              <Option>IELTS: IELTS Prepration</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Results Calculation Method"
            name={"resultCalculationMethod"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select result calculation method",
            //   },
            // ]}
          >
            <Select placeholder="Result Calculation Method">
              <Option>Subject Based</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Agent"
            name={"agent"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select an agent",
            //   },
            // ]}
          >
            <Select placeholder="Agent">
              <Option>ABC Consultancy and Visa Services</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="Agent Contact"
          name={"agentContact"}
          //   rules={[
          //     {
          //       required: true,
          //       message: "Please enter agent contact!",
          //     },
          //   ]}
        >
          <Select placeholder="Agent Contact">
            <Option>Rahul Panday (0125854854)</Option>
          </Select>{" "}
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Admission Staff"
            name={"admissionStaff"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select admission staff!",
            //   },
            // ]}
          >
            <Select placeholder="Admission Staff">
              <Option>Prabin Panday</Option>
            </Select>{" "}
          </Form.Item>
          <Form.Item
            label="Placement Manager"
            name={"placementManager"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select placement manager !",
            //   },
            // ]}
          >
            <Select placeholder="Admission Staff">
              <Option>Brian Lara</Option>
            </Select>{" "}
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course Manager"
            name={"courseManager"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a course manager !",
            //   },
            // ]}
          >
            <Select placeholder="Course Manager">
              <Option>Danial Bravo</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Intake"
            name={"intake"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a intake!",
            //   },
            // ]}
          >
            <Select placeholder="Intake">
              <Option>January</Option>
              <Option>February</Option>
              <Option>March</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Start Date"
            name={"startDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick a start date !",
            //   },
            // ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="Finish Date"
            name={"finishDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick a finish date!",
            //   },
            // ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Offer issued Date"
            name={"offerIssuedDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick a offer issued date!",
            //   },
            // ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="COE Number"
            name={"coeNo"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter COE number!",
            //   },
            // ]}
          >
            <Input placeholder="COE number" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course Template"
            name={"courseTemplate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a course template!",
            //   },
            // ]}
          >
            <Select>
              <Option>English for Academic Purposes</Option>
              <Option>English for Academic Purposes - Offshore</Option>
              <Option>English for Academic Purposes - Other</Option>
              <Option>General English</Option>
              <Option>General English - Offshore</Option>
              <Option>General English - Other</Option>
              <Option>IELTS Prepration</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Number of weeks"
            name={"numberOfWeeks"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter number of weeks!",
            //   },
            // ]}
          >
            <Input type="number" placeholder="Number of weeks" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Promotion"
            name={"promotion"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a promotion!",
              },
            ]}
          >
            <Select placeholder="Promotion">
              <Option>Summer Promotion</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={`Tuition Charged Fee (Standard Fee: AUD 160.00)`}
            name={"tutionChargedFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter tution charged fee!",
              },
            ]}
          >
            <Input type="number" placeholder="Tution Charged Fee" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Enrollment Fee (in AUD)"
            name={"enrollmentFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a enrollment fee!",
              },
            ]}
          >
            <Input placeholder="Enrollment Fee" />
          </Form.Item>
          <Form.Item
            label="Upfront Fee (in AUD)"
            name={"upfrontFee"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a upfront fee!",
            //   },
            // ]}
          >
            <Input type="number" placeholder="Upfront Fee" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Material Fee (in AUD)"
            name={"materialFee"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a material fee!",
            //   },
            // ]}
          >
            <Input placeholder="Material Fee" />
          </Form.Item>
          <Form.Item
            label="Status"
            name={"status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a status !",
              },
            ]}
          >
            <Select placeholder="Status">
              <Option value="AGENT_APPLY">Agent Apply</Option>
              <Option value="CANCELLED">Cancelled</Option>
              <Option value="COMPLETED">Completed</Option>
              <Option value="CONVERTED">Converted</Option>
              <Option value="CURRENT_STUDENT">Current Student</Option>
              <Option value="DEFERRED">Deferred</Option>
              <Option value="DID_NOT_COMMENCE">Did not Commence</Option>
              <Option value="ENROLLED">Enrolled</Option>
              <Option value="EXPIRED_OFFER">Expired Offer</Option>
              <Option value="FINISHED">Finished</Option>
              <Option value="GRADUATED">Graduated</Option>
              <Option value="NEW_APPLICATION_REQUEST">
                New Application Request
              </Option>
              <Option value="NEW_COURSE_REQUEST">New Course Request</Option>
              <Option value="OFFERED">Offered</Option>
              <Option value="PLACED">Placed</Option>
              <Option value="REPORTED">Reported</Option>
              <Option value="TRANSITIONED">Transitioned</Option>
              <Option value="VIC_COMPLETION_OF-LOWER_LEVEL_OF_PROGRAM">
                VIC-Completion of lower level of program
              </Option>
              <Option value="VIC_IN_TRAINING">VIC-In training</Option>
              <Option value="VIC_NESTED_PROGRAM">VIC-Nested program</Option>
              <Option value="VIC_NOT_YET_STARTED">VIC-Not yet started</Option>
              <Option value="VIC_PROGRAM_COMPLETED_ISSUED">
                VIC-Program completed (issued)
              </Option>
              <Option value="VIC_PROGRAM_COMPLETED_NOT_ISSUED">
                VIC-Program completed (not issued)
              </Option>
              <Option value="VIC_STREAMED_PROGRAM">VIC-Streamed program</Option>
              <Option value="VIC_STUDENT_SUCCESSFULLY_ACHEIVED_INTENDED_OUTCOME">
                VIC-Student successfully acheived intended outcome
              </Option>
              <Option value="VIC_SUPERSEDED_PROGRAM">
                VIC-Superseded program
              </Option>
              <Option value="VIC_WITHDRAWN_APPARENT">
                VIC-Withdrawn - Apparent
              </Option>
              <Option value="VIC_WITHDRAWN_DEFERRED">
                VIC-Withdrawn - Deferred
              </Option>
              <Option value="VIC_WITHDRAWN_OFFICIAL">
                VIC-Withdrawn - Official
              </Option>
              <Option value="WITHDRAWN">Withdrawn</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Application Request"
            name={"applicationRequest"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter application request!",
            //   },
            // ]}
          >
            <TextArea placeholder="Write about application request" />
          </Form.Item>
          <Form.Item
            label="Credit Transfer Request"
            name={"creditTransferRequest"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter credit transfer request!",
            //   },
            // ]}
          >
            <TextArea placeholder="Write about credit transfer request" />
          </Form.Item>
        </div>

        <Form.Item
          label="Special Condition"
          name={"specialCondition"}
          style={{ width: "100%" }}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please enter special condition!",
          //   },
          // ]}
        >
          <ReactQuill
            placeholder="Write about special condition"
            theme="snow"
            style={{ height: "250px", marginBottom: "4rem" }}
          />{" "}
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Holiday Start Date"
            name={"holidayStartDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="Holiday End Date"
            name={"holidayEndDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Student Orientation Date"
            name={"studentOrientationDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Total Holiday Weeks"
            name={"totalHolidayWeeks"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Total Holiday Weeks" />
          </Form.Item>
        </div>
        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Student Course"
          >
            Add Student Course
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddStudentCourse;
