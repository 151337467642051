import { Button, Card, Form, Input, Modal, Select, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import OrganizationalSettings from "../../../components/OrganizationalSettings/OrganizationalSettings";
import StudentIdFormat from "./StudentIdFormat/StudentIdFormat";
import InterventionStrategy from "./InterventionStrategy/InterventionStrategy";
import SystemFieldsSetup from "./SystemFieldsSetup/SystemFieldsSetup";

const staffTabList = [
  {
    key: "studentIdFormat",
    label: "Student ID Format",
  },
  {
    key: "interventionStrategy",
    label: "Intervention Strategy",
  },
  {
    key: "systemFieldsSetup",
    label: "System Fields Setup",
  },
];
const StudentSettings = () => {
  const [current, setCurrent] = useState("studentIdFormat");

  return (
    <OrganizationalSettings>
      <div style={{ margin: "0.5rem 0 0 0.5rem" }}>
        <h3 className="font-medium text-base text-black">Student Settings</h3>
        <div className="bg-white rounded px-4 py-2">
          <Tabs
            defaultActiveKey="profile"
            items={staffTabList}
            activeTabKey={current}
            onChange={(key) => {
              console.log("current key", key);
              setCurrent(key);
            }}
          />
          {current === "studentIdFormat" && (
            <>
              <StudentIdFormat />
            </>
          )}
          {current === "interventionStrategy" && (
            <>
              <InterventionStrategy />
            </>
          )}
          {current === "systemFieldsSetup" && (
            <>
              <SystemFieldsSetup />
            </>
          )}
        </div>
      </div>
    </OrganizationalSettings>
  );
};

export default StudentSettings;
