import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddServiceProvider = ({
  isAddServiceProviderModalVisible,
  setIsAddServiceProviderModalVisible,
}) => {
  const [serviceProviderData, setServiceProviderData] = useState({
    country: "AUSTRALIA",
    state: null,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddServiceProviderSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddServiceProvider_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddServiceProviderModalVisible(false);
  };
  return (
    <Drawer
      title="Add Service Provider"
      open={isAddServiceProviderModalVisible}
      onClose={() => {
        setIsAddServiceProviderModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddServiceProviderSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Provider Company Name"
            name={"serviceProviderCompanyName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter service provider company name:!",
              },
            ]}
          >
            <Input placeholder="Service Provider Company Name" />
          </Form.Item>
          <Form.Item
            label="Contact Name"
            name={"contactName"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Contact Name" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Country"
            name={"country"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a country",
              },
            ]}
          >
            <Select
              placeholder="Country"
              onChange={(e) => {
                setServiceProviderData((prevData) => {
                  return {
                    ...prevData,
                    country: e,
                  };
                });
              }}
            >
              <Option value="AUSTRALIA">Australia</Option>
              <Option value="NEPAL">Nepal</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Company Address"
            name={"companyAddress"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter company address",
              },
            ]}
          >
            <Input placeholder="Company Address:" />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Company Suburb"
            name={"companySuburb"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter company suburb!",
              },
            ]}
          >
            <Input placeholder="Company Suburb" />
          </Form.Item>
          {serviceProviderData?.country?.toLowerCase() === "australia" ? (
            <Form.Item
              label="Company State"
              name={"companyState"}
              style={{ width: "100%" }}
            >
              <Select
                onChange={(e) => {
                  setServiceProviderData((prevData) => {
                    return {
                      ...prevData,
                      state: e,
                    };
                  });
                }}
              >
                <Option value="NSW|New South Wales">NSW|New South Wales</Option>
                <Option value="QLD|Queensland">QLD|Queensland</Option>
                <Option value="SA|South Australia">SA|South Australia</Option>
                <Option value="TAS|Tasmania">TAS|Tasmania</Option>
                <Option value="VIC|Victoria">VIC|Victoria</Option>
                <Option value="WA|Western Australia">
                  WA|Western Australia
                </Option>
                <Option value="ACT|Australian Capital Territory">
                  ACT|Australian Capital Territory
                </Option>
                <Option value="NT|Northern Territory">
                  NT|Northern Territory
                </Option>
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Company State"
              name={"companyState"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Company State"
                onChange={(e) => {
                  setServiceProviderData((prevData) => {
                    return {
                      ...prevData,
                      state: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Postcode"
            name={"postcode"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Postcode" />
          </Form.Item>
          <Form.Item
            label="Company Phone"
            name={"companyPhone"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a company phone!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Company Phone" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Mobile"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Mobile" />
          </Form.Item>
          <Form.Item
            label="Company Fax"
            name={"companyFax"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Company Fax" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Website"
            name={"website"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "url",
                message: "Please enter a Website!",
              },
            ]}
          >
            <Input placeholder="www.company.com" />
          </Form.Item>
        </div>
        <Form.Item label="Comment" name={"comment"} style={{ width: "100%" }}>
          <TextArea placeholder="Write something..." />
        </Form.Item>
        <Form.Item
          label="Internal Comment"
          name={"internalComment"}
          style={{ width: "100%" }}
        >
          <TextArea placeholder="Write something..." />
        </Form.Item>
        <Form.Item
          label="Other Details"
          name={"otherDetails"}
          style={{ width: "100%" }}
        >
          <TextArea placeholder="Write something..." />
        </Form.Item>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Service Provider"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddServiceProvider;
