import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../../components/input";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineDollar,
  AiOutlineDownload,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFilePdf,
  AiOutlineHistory,
  AiOutlinePlus,
  AiTwotoneEye,
} from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import AddButton from "../../../../components/button/AddButton";
import AddStudentCourse from "./AddStudentCourse/AddStudentCourse";
import UpdateStudentCourse from "./AddStudentCourse/UpdateStudentCourse";
import { MdOutlineLocalOffer, MdOutlineSubject } from "react-icons/md";
import UpdateSelectedOfferInfo from "./UpdateSelectedOfferInfo";
import EnrollSubjectForCourse from "./EnrollSubjectForCourse/EnrollSubjectForCourse";

const columns = [
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 250,
    align: "center",
  },
  {
    title: "Agent",
    dataIndex: "agent",
    width: 150,
    align: "center",
  },
  {
    title: "Offer",
    dataIndex: "offer",
    width: 150,
    align: "center",
  },
  {
    title: "Offer Issued Date",
    dataIndex: "offerIssuedDate",
    width: 150,
    align: "center",
  },
  {
    title: "Intake",
    dataIndex: "intake",
    width: 100,
    align: "center",
  },
  {
    title: "Start Date",
    dataIndex: "StartDate",
    width: 100,
    align: "center",
  },
  {
    title: "Finish Date",
    dataIndex: "finishDate",
    width: 100,
    align: "center",
  },
  {
    title: "Tution Charged Fee",
    dataIndex: "tutionChargedFee",
    width: 100,
    align: "center",
  },
  {
    title: "Placement Manager",
    dataIndex: "placementManager",
    width: 100,
    align: "center",
  },
  {
    title: "Course Manager",
    dataIndex: "courseManager",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
  {
    title: "Offer",
    dataIndex: "offerActions",
    width: 350,
    align: "center",
  },
];

const vaccineDetailsData = [
  {
    id: 1,
    courseName: "GE: General English",
    agent: "Opera Education Group Pty Ltd",
    offer: "4",
    offerIssuedDate: "09/08/2023",
    intake: "January",
    StartDate: "12/04/2021",
    finishDate: "18/06/2021",
    tutionChargedFee: "2900.00",
    placementManager: "Rajiv Shrestha",
    courseManager: "Niraj Pradhan",
    status: "Enrolled",
  },
];

const StudentCourse = () => {
  const [isAddStudentCourseModalVisible, setIsAddStudentCourseModalVisible] =
    useState(false);
  const [
    isEnrollSubjectForCourseModalVisible,
    setIsEnrollSubjectForCourseModalVisible,
  ] = useState(false);
  const [
    isUpdateStudentCourseModalVisible,
    setIsUpdateStudentCourseModalVisible,
  ] = useState(false);
  const [
    isUpdateSelectedOfferInfoModalVisible,
    setIsUpdateSelectedOfferInfoModalVisible,
  ] = useState(false);
  const [isViewCoeHistoryModalVisible, setIsViewCoeHistoryModalVisible] =
    useState(false);

  const data = vaccineDetailsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      courseName: dataObj?.courseName,
      agent: dataObj?.agent,
      offer: dataObj?.offer,
      offerIssuedDate: dataObj?.offerIssuedDate,
      intake: dataObj?.intake,
      StartDate: dataObj?.StartDate,
      finishDate: dataObj?.finishDate,
      tutionChargedFee: dataObj?.tutionChargedFee,
      placementManager: dataObj?.placementManager,
      courseManager: dataObj?.courseManager,
      status: dataObj?.status,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "150px" }}
        >
          <Tooltip title="Update Selected Course">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStudentCourseModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Edit Selected offer added service info">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateSelectedOfferInfoModalVisible(true);
                }}
              >
                <MdOutlineLocalOffer />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Enroll Subject for this course?">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsEnrollSubjectForCourseModalVisible(true);
                }}
              >
                <MdOutlineSubject />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="View COE History">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsViewCoeHistoryModalVisible(true);
                }}
              >
                <AiTwotoneEye />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete selected teacher matrix?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
      offerActions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "250px" }}
        >
          <Tooltip title="View Offer Letter Before Approve">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Approve Offer Letter">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineCheck />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Download Offer Letter Before Approval">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineDownload />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Student Invoice">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Agent Invoice">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineDollar />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="View/Edit Upfront Fee Schedule">
            <a>
              <div className="bordered" onClick={() => {}}>
                <TbMoneybag />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="View/Edit Upfront Fee Schedule">
            <a>
              <div className="bordered" onClick={() => {}}>
                <TbMoneybag />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Course Log">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineHistory />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Student Course</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddStudentCourseModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddStudentCourseModalVisible && (
        <AddStudentCourse
          isAddStudentCourseModalVisible={isAddStudentCourseModalVisible}
          setIsAddStudentCourseModalVisible={setIsAddStudentCourseModalVisible}
        />
      )}
      {isUpdateStudentCourseModalVisible && (
        <UpdateStudentCourse
          isUpdateStudentCourseModalVisible={isUpdateStudentCourseModalVisible}
          setIsUpdateStudentCourseModalVisible={
            setIsUpdateStudentCourseModalVisible
          }
        />
      )}
      {isUpdateSelectedOfferInfoModalVisible && (
        <UpdateSelectedOfferInfo
          isUpdateSelectedOfferInfoModalVisible={
            isUpdateSelectedOfferInfoModalVisible
          }
          setIsUpdateSelectedOfferInfoModalVisible={
            setIsUpdateSelectedOfferInfoModalVisible
          }
        />
      )}
      {isEnrollSubjectForCourseModalVisible && (
        <EnrollSubjectForCourse
          isEnrollSubjectForCouseModalVisible={
            isEnrollSubjectForCourseModalVisible
          }
          setIsEnrollSubjectForCouseModalVisible={
            setIsEnrollSubjectForCourseModalVisible
          }
        />
      )}
    </Card>
  );
};

export default StudentCourse;
