import { Alert } from "antd";
import React from "react";

const SelectAlert = ({
  length = 0,
  type = "success",
  closable = true,
  afterCloseHandler = () => {},
  selectAction = <></>,
}) => {
  return (
    <Alert
      message={`Selected ${length} items.`}
      type={type}
      closable={closable}
      afterClose={afterCloseHandler}
      action={selectAction}
      style={{ borderRadius: "3px" }}
    />
  );
};

export default SelectAlert;
