import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";

import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineUpload,
} from "react-icons/ai";
import PageHeader from "../../../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../../../components/input";
import AddButton from "../../../../../../components/button/AddButton";
import AddAdvancedStanding from "./AddAdvancedStanding";

const columns = [
  {
    title: "Course",
    dataIndex: "course",
    width: 250,
    align: "center",
  },
  {
    title: "Student Course Template",
    dataIndex: "studentCourseTemplate",
    width: 250,
    align: "center",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    width: 250,
    align: "center",
  },
  {
    title: "Unit",
    dataIndex: "unit",
    width: 250,
    align: "center",
  },
  {
    title: "Is Approved",
    dataIndex: "isApproved",
    width: 100,
    align: "center",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    course: "",
    studentCourseTemplate: "",
    subject: "",
    unit: "",
    isApproved: "",
    comment: "",
  },
];
const selectCourse = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "GE: General English-12-04-2021-Enrolled",
    value: "geEnrolled",
  },
  {
    label: "IELTS: IELTS Preparation-02-01-2023-Enrolled",
    value: "ieltsEnrolled",
  },
];

const AdvancedStanding = () => {
  const [
    isAddAdvancedStandingModalVisible,
    setIsAddAdvancedStandingModalVisible,
  ] = useState(false);
  const [isUploadCertificateModalVisible, setIsUploadCertificateModalVisible] =
    useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      key: dataObj.id,
      course: dataObj?.course,
      studentCourseTemplate: dataObj?.studentCourseTemplate,
      subject: dataObj?.subject,
      unit: dataObj?.unit,
      isApproved: dataObj?.isApproved,
      comment: dataObj?.comment,
      actions: (
        <Space size="middle">
          {/* <Tooltip title="Upload Certificate">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUploadCertificateModalVisible(true);
                }}
              >
                <AiOutlineUpload />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete selected certificate?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip> */}
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Subject/Unit Advanced Standing</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddAdvancedStandingModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      {isAddAdvancedStandingModalVisible && (
        <AddAdvancedStanding
          isAddAdvancedStandingModalVisible={isAddAdvancedStandingModalVisible}
          setIsAddAdvancedStandingModalVisible={
            setIsAddAdvancedStandingModalVisible
          }
        />
      )}
      {/* {isUploadCertificateModalVisible && (
        <UploadCertificate
          isUploadCertificateModalVisible={isUploadCertificateModalVisible}
          setIsUploadCertificateModalVisible={
            setIsUploadCertificateModalVisible
          }
        />
      )} */}
    </Card>
  );
};

export default AdvancedStanding;
