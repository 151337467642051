import React from "react";
import { Button, Checkbox, Drawer, Form, Input, Select, Steps } from "antd";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const StaffSystemAccessInfo = ({
  staffData,
  setStaffData,
  current,
  setCurrent,
  addStaffHandler,
  fromAddTeacher,
}) => {
  const [form] = Form.useForm();

  const addStaffSystemAccessHandler = () => {
    addStaffHandler();
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addStaffSystemAccessHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["userName"],
            value: staffData?.userName,
          },
          {
            name: ["password"],
            value: staffData?.password,
          },
          {
            name: ["confirmPassword"],
            value: staffData?.confirmPassword,
          },
        ]}
      >
        <Form.Item
          label="Username"
          name={"userName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter username!",
            },
          ]}
        >
          <Input
            placeholder="Username"
            onChange={(e) => {
              setStaffData((prevData) => {
                return {
                  ...prevData,
                  userName: e.target.value,
                };
              });
            }}
          />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Password"
            name={"password"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password!"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    password: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name={"confirmPassword"}
            dependencies={["password"]}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please confirm the password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Password and confirm password do not match!")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Confirm Password!"
              onChange={(e) => {
                setStaffData((prevData) => {
                  return {
                    ...prevData,
                    confirmPassword: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {current === 2 && (
            <Form.Item>
              <Button
                onClick={() => {
                  setCurrent(1);
                }}
                style={{ borderRadius: "3px" }}
              >
                Back
              </Button>
            </Form.Item>
          )}
          <Form.Item>
            <ButtonElement
              type="primary"
              htmlType="submit"
              name={fromAddTeacher ? "Add Teacher" : "Add Staff"}
            >
              {fromAddTeacher ? "Add Teacher" : "Add Staff"}
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default StaffSystemAccessInfo;
