import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Accounts from "../Accounts";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFileExcel,
  AiOutlineFilePdf,
} from "react-icons/ai";
import PageHeader from "../../../components/PageHeader/PageHeader";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import ViewOrProcessPayments from "./ViewOrProcessPayments";
const { Search } = Input;

const columns = [
  {
    title: "Agent ID",
    dataIndex: "agentId",
    width: 50,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 250,
    align: "center",
  },
  {
    title: "Person",
    dataIndex: "person",
    width: 200,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 250,
    align: "center",
  },
  {
    title: "User Name",
    dataIndex: "userName",
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    width: 200,
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    width: 300,
    align: "center",
  },
];

const agentData = [
  {
    id: "1",
    agentId: "24",
    agentName: "The Next Education & Migration Services Pty Ltd.",
    person: "Kabir Kandel",
    email: "admission2@thenext.com.au",
    userName: "agent1",
    status: "Active",
    totalAmount: "$755.57",
  },
  {
    id: "2",
    agentId: "45",
    agentName: "Tilicho Consulting Group Pty Ltd.",
    person: "Prakash Panthee",
    email: "info@tilicho.com.au",
    userName: "agent2",
    status: "Provisional",
    totalAmount: "$1,540.00",
  },
];

const BulkAgentPaymentComponent = () => {
  const [isAddCommissionModalVisible, setIsAddCommissionModalVisible] =
    useState(false);
  const [
    isViewOrProcessPaymentsModalVisible,
    setIsViewOrProcessPaymentsModalVisible,
  ] = useState(false);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [searchBy, setSearchBy] = useState("viewAndApproveCommissionAvailable");
  const [dateOption, setDateOption] = useState("tillDate");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let data = agentData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      agentId: dataObj?.agentId,
      agentName: dataObj?.agentName,
      person: dataObj?.person,
      email: dataObj?.email,
      userName: dataObj?.userName,
      status: dataObj?.status,
      totalAmount: dataObj?.totalAmount,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="View and/or Process Payments">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsViewOrProcessPaymentsModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          {searchBy === "viewAndApproveCommissionAvailable" && (
            <Tooltip title="Generate Remittance Advice">
              <a>
                <div className="bordered" onClick={() => {}}>
                  <AiOutlineFilePdf />
                </div>
              </a>
            </Tooltip>
          )}
          <Tooltip title="Export to MS Excel">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFileExcel />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <>
      <ComponentBody>
        <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>View Option:</div>
            <SelectField
              name="status"
              placeholder="Status"
              value={searchBy}
              options={[
                {
                  label: "View and Approve Commission Available",
                  value: "viewAndApproveCommissionAvailable",
                },
                {
                  label: "Process and Pay Commission",
                  value: "processAndPayCommission",
                },
              ]}
              fieldWidth="12rem"
              handleChange={(e) => {
                setSearchBy(e);
              }}
            />
          </div>
          {searchBy === "viewAndApproveCommissionAvailable" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Search By:</div>
              <SelectField
                name="searchBy"
                placeholder="Search By"
                options={[
                  {
                    label: "Full Payment Schedule Paid By DueDate",
                    value: "fullPaymentSchedulePaidByDueDate",
                  },
                  {
                    label: "Partial Payment Schedule Paid By DueDate",
                    value: "partialPaymentSchedulePaidByDueDate",
                  },
                  {
                    label: "Full Payment Schedule Paid By DueDate",
                    value: "fullPaymentSchedulePaidByDueDate",
                  },
                  {
                    label: "Full Payment Schedule Paid By PaidDate",
                    value: "fullPaymentSchedulePaidByPaidDate",
                  },
                  {
                    label: "Partial Payment Schedule Paid By PaidDate",
                    value: "partialPaymentSchedulePaidByPaidDate",
                  },
                ]}
                fieldWidth="12rem"
              />
            </div>
          )}
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Date Option:</div>
            <SelectField
              name="dateOption"
              placeholder="Date Option"
              value={dateOption}
              options={[
                {
                  label: "Till Date",
                  value: "tillDate",
                },
                {
                  label: "Between Two Dates",
                  value: "betweenTwoDates",
                },
                {
                  label: "Till Paid Date",
                  value: "tillPaidDate",
                },
                {
                  label: "Between Two Paid Dates",
                  value: "betweenTwoPaidDates",
                },
                {
                  label: "All Approved",
                  value: "allApproved",
                },
              ]}
              fieldWidth="12rem"
              handleChange={(e) => {
                setDateOption(e);
              }}
            />
          </div>

          {(dateOption === "betweenTwoDates" ||
            dateOption === "betweenTwoPaidDates") && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>From date:</div>
              <DatePicker format={"DD-MM-YYYY"} />
            </div>
          )}
          {dateOption !== "allApproved" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>To date:</div>
              <DatePicker format={"DD-MM-YYYY"} />
            </div>
          )}

          <ButtonElement name="View" />
        </div>
      </ComponentBody>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>
              Bulk Approve and Process Commission Payments for Agents
            </Pagename>
            <div
              style={{ display: "flex", justifyContent: "flex-start" }}
            ></div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {selectedRowKeys.length > 0 && selectedVisible && (
            <SelectAlert
              length={selectedRowKeys.length}
              type={"success"}
              closable={true}
              selectAction={
                <div className="fxRAc">
                  {searchBy === "viewAndApproveCommissionAvailable" && (
                    <ButtonElement
                      size="small"
                      buttonType="primary"
                      onClick={(e) => {}}
                      name="Bulk Approve"
                    />
                  )}
                  <ButtonElement
                    size="small"
                    buttonType="primary"
                    onClick={(e) => {}}
                    name="Export to MS Excel (Bulk)"
                  />
                </div>
              }
            />
          )}
          <Table
            bordered
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isViewOrProcessPaymentsModalVisible && (
        <ViewOrProcessPayments
          searchBy={searchBy}
          isViewOrProcessPaymentsModalVisible={
            isViewOrProcessPaymentsModalVisible
          }
          setIsViewOrProcessPaymentsModalVisible={
            setIsViewOrProcessPaymentsModalVisible
          }
        />
      )}
    </>
  );
};

export default BulkAgentPaymentComponent;
