import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Accounts from "../Accounts";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import PageHeader from "../../../components/PageHeader/PageHeader";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
const { Search } = Input;

const columns = [
  {
    title: "Origin",
    dataIndex: "origin",
    width: 100,
    align: "center",
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 250,
    align: "center",
  },
  {
    title: "Commission (%)",
    dataIndex: "commission",
    width: 150,
    align: "center",
  },
  {
    title: "is GST?",
    dataIndex: "isGst",
    width: 50,
    align: "center",
  },
  {
    title: "From Date",
    dataIndex: "fromDate",
    width: 150,
    align: "center",
  },
  {
    title: "To Date",
    dataIndex: "toDate",
    width: 150,
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const selectAgentStatus = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "New Application Request",
    value: "newApplicationRequest",
  },
  {
    label: "Principal Agent",
    value: "principalAgent",
  },
  {
    label: "Provisional",
    value: "Provisional",
  },
  {
    label: "All",
    value: "all",
  },
];

const agentData = [
  {
    id: "1",
    origin: "All",
    courseName: "EAP: English for Acadamic Purposes (EAP)",
    commission: "25",
    isGst: "YES",
    fromDate: "08-04-2023",
    toDate: "09-04-2024",
  },
  {
    id: "2",
    origin: "Onshore",
    courseName: "IELTS: IELTS Advanced",
    commission: "25",
    isGst: "YES",
    fromDate: "08-04-2023",
    toDate: "09-04-2024",
  },
];

const BulkUpdateCommission = () => {
  const [isAddCommissionModalVisible, setIsAddCommissionModalVisible] =
    useState(false);
  const [isUpdateCommissionModalVisible, setIsUpdateCommissionModalVisible] =
    useState(false);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let data = agentData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      origin: dataObj?.origin,
      courseName: dataObj?.courseName,
      commission: dataObj?.commission,
      isGst: dataObj?.isGst,
      fromDate: dataObj?.fromDate,
      toDate: dataObj?.toDate,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Update Commission">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateCommissionModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Staff">
            <Popconfirm
              title="Are you sure to delete this selected commission?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Accounts tab={"/bulk-update-commission"}>
      <ComponentBody>
        <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Agent Status:</div>
            <SelectField
              name="status"
              placeholder="Status"
              options={selectAgentStatus}
              fieldWidth="12rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Agent Origin:</div>
            <SelectField
              name="agentOrigin"
              placeholder="Agent Origin"
              options={[
                {
                  label: "Offshore",
                  value: "offshore",
                },
                {
                  label: "Onshore",
                  value: "onshore",
                },
                {
                  label: "Not Specified",
                  value: "notSpecified",
                },
              ]}
              fieldWidth="12rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Agency name:</div>
            <SelectField
              name="agencyName"
              placeholder="Agency name"
              options={[
                {
                  label: "360 Life Experience",
                  value: "360 Life Experience",
                },
                {
                  label: "AA Plus Education Group",
                  value: "AA Plus Education Group",
                },
              ]}
              fieldWidth="12rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Course Type:</div>
            <SelectField
              name="selectPosition"
              placeholder="Position"
              options={[
                {
                  label: "ELICOS",
                  value: "elicos",
                },
              ]}
              fieldWidth="12rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Year:</div>
            <DatePicker picker="year" format={"YYYY"} />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Intake Date:</div>
            <SelectField
              name="selectPosition"
              placeholder="Intake Date"
              options={[]}
              fieldWidth="12rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Semester:</div>
            <SelectField
              name="selectPosition"
              placeholder="Semester"
              options={[
                {
                  label: "2023",
                  value: "2023",
                },
                {
                  label: "2024",
                  value: "2024",
                },
                {
                  label: "2025",
                  value: "2025",
                },
                {
                  label: "All Semester",
                  value: "allSemester",
                },
              ]}
              fieldWidth="12rem"
            />
          </div>
          <ButtonElement name="View Commission" />
        </div>
      </ComponentBody>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Update Agent Commission</Pagename>
            <div
              style={{ display: "flex", justifyContent: "flex-start" }}
            ></div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {selectedRowKeys.length > 0 && selectedVisible && (
            <SelectAlert
              length={selectedRowKeys.length}
              type={"success"}
              closable={true}
              selectAction={
                <ButtonElement
                  size="small"
                  buttonType="primary"
                  onClick={(e) => {}}
                  name="Update Commission"
                />
              }
            />
          )}
          <Table
            bordered
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
    </Accounts>
  );
};

export default BulkUpdateCommission;
