import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import { DatePicker, Input, Popconfirm, Space, Table, Tooltip } from "antd";
import Accounts from "../Accounts";
import Student from "../../Student/Student";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../components/input";
import StudentDetails from "../../Student/StudentDetails/StudentDetails";
import StudentPaymentDetails from "../../Student/StudentPaymentDetails/StudentPaymentDetails";
import { MdPayment } from "react-icons/md";
const { Search } = Input;
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 100,
    align: "center",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    width: 100,
    align: "center",
  },
  {
    title: "Origin",
    dataIndex: "origin",
    width: 250,
    align: "center",
  },
  {
    title: "DOB",
    dataIndex: "dob",
    width: 200,
    align: "center",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    width: 150,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 200,
    align: "center",
  },
  {
    title: "College Email",
    dataIndex: "onshoreFee",
    width: 200,
    align: "center",
  },
  {
    title: "User Name",
    dataIndex: "userName",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const searchStudentBy = [
  {
    label: "ID/First/Last",
    value: "idFirstLast",
  },
  {
    label: "Student ID",
    value: "studentId",
  },
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Student Name (First Name or Last Name)",
    value: "studentNameFirstNameOrLastName",
  },
  {
    label: "Preferren Name",
    value: "preferredName",
  },
  {
    label: "Date of Birth",
    value: "dateOfBirth",
  },
  {
    label: "Student Origin",
    value: "studentOrigin",
  },
  {
    label: "Student Nationality",
    value: "studentNationality",
  },
  {
    label: "Course",
    value: "course",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Course Start Date",
    value: "courseStartDate",
  },
  {
    label: "No UserName",
    value: "noUserName",
  },
  {
    label: "Offer No",
    value: "offerNo",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "College Email",
    value: "collegeEmail",
  },
  {
    label: "Mobile",
    value: "mobile",
  },
  {
    label: "CoE No",
    value: "coeNo",
  },
  {
    label: "Tac Reference Number",
    value: "tacReferenceNumber",
  },
  {
    label: "Employer",
    value: "employer",
  },
];

const studentOrigin = [
  {
    label: "Overseas Student (Offshore)",
    value: "overseasStudentOffshore",
  },
  {
    label: "Overseas Student in Australia (Onshore)",
    value: "overseasStudentInAustaliaOnshore",
  },
  {
    label: "Resident Student (Domestic)",
    value: "residentStudent",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const selectStudentType = [
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Current Student",
    value: "currentStudent",
  },
  {
    label: "Enrolled",
    value: "enrolled",
  },
  {
    label: "Expired Offer",
    value: "expiredOffer",
  },
  {
    label: "Finished",
    value: "finished",
  },
  {
    label: "New Application Request",
    value: "newApplicationRequest",
  },
  {
    label: "New Course Request",
    value: "newCourseRequest",
  },
  {
    label: "Offered",
    value: "offered",
  },
  {
    label: "Withdrawn",
    value: "withdrawn",
  },
];

const studentData = [
  {
    id: "SD001",
    name: "Sabin Khadka",
    gender: "M",
    origin: "Overseas Student in Australia",
    dob: "21/07/2000",
    mobile: "0452 627 246	",
    email: "awesomesabinkhadka@gmail.com",
    onshoreFee: "OPSD001@opera.nsw.edu.au",
    userName: "SD001",
  },
  {
    id: "SD002",
    name: "Zezhong Chen",
    gender: "M",
    origin: "Overseas Student",
    dob: "23/03/2002",
    mobile: "+8617759063970",
    email: "3642860236@qq.com",
    onshoreFee: "OPS0001638@opera.nsw.edu.au	",
    userName: "SD002",
  },
];

const StudentPayment = () => {
  const [searchBy, setSearchBy] = useState("idFirstLast");
  const [isStudentDetailsModalVisible, setIsStudentDetailsModalVisible] =
    useState(false);
  const [
    isStudentPaymentDetailsModalVisible,
    setIsStudentPaymentDetailsModalVisible,
  ] = useState(false);
  let data = studentData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      name: dataObj?.name,
      gender: dataObj?.gender,
      origin: dataObj?.origin,
      dob: dataObj?.dob,
      mobile: dataObj?.mobile,
      email: dataObj?.email,
      onshoreFee: dataObj?.onshoreFee,
      userName: dataObj?.userName,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Student Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStudentDetailsModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Payment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStudentPaymentDetailsModalVisible(true);
                }}
              >
                <MdPayment />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Student">
            <Popconfirm
              title="Are you sure to delete this selected student?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Accounts tab={"/student-payment"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Student Management</Pagename>
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Search by</div>
              <SelectField
                name="searchStudentBy"
                placeholder="Search Student By"
                options={searchStudentBy}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="12rem"
              />
            </div>
            {searchBy === "dateOfBirth" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <DatePicker format={"DD-MM-YYYY"} style={{ width: "12rem" }} />
              </div>
            )}
            {searchBy === "studentOrigin" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <SelectField
                  name="studentOrigin"
                  placeholder="Student Origin"
                  options={studentOrigin}
                  handleChange={(e) => {}}
                  fieldWidth="19rem"
                />
              </div>
            )}
            {searchBy === "studentNationality" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <SelectField
                  name="studentNationality"
                  placeholder="Student Nationality"
                  options={[
                    { label: "Australian", value: "Australian" },
                    { label: "Nepalese", value: "Nepalese" },
                  ]}
                  handleChange={(e) => {}}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {searchBy === "course" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <SelectField
                  name="course"
                  placeholder="Course"
                  options={selectCourse}
                  handleChange={(e) => {}}
                  fieldWidth="20rem"
                />
                <SelectField
                  name="studentType"
                  placeholder="Student Type"
                  options={selectStudentType}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {searchBy === "status" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <SelectField
                  name="studentType"
                  placeholder="Student Type"
                  options={selectStudentType}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {searchBy === "agent" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <SelectField
                  name="agent"
                  placeholder="Agents"
                  options={[]}
                  handleChange={(e) => {}}
                  fieldWidth="20rem"
                />
              </div>
            )}
            {searchBy === "courseStartDate" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <SelectField
                  name="course"
                  placeholder="Course"
                  options={selectCourse}
                  handleChange={(e) => {}}
                  fieldWidth="20rem"
                />
                <DatePicker format={"DD-MM-YYYY"} style={{ width: "12rem" }} />
              </div>
            )}
            {(searchBy === "idFirstLast" ||
              searchBy === "studentId" ||
              searchBy === "firstName" ||
              searchBy === "lastName" ||
              searchBy === "studentNameFirstNameOrLastName" ||
              searchBy === "preferredName" ||
              searchBy === "offerNo" ||
              searchBy === "email" ||
              searchBy === "collegeEmail" ||
              searchBy === "mobile" ||
              searchBy === "coeNo" ||
              searchBy === "tacReferenceNumber" ||
              searchBy === "employer") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <Search
                  style={{ borderRadius: "3px", width: "100%" }}
                  className="search"
                  placeholder={`Search Student.`}
                  allowClear
                  size="Medium"
                  //   value={searchValue}
                  onChange={(e) => {
                    //   setSearchValue(e.target.value);
                    //   onSearch(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "2rem",
          }}
        >
          <Table
            size="small"
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isStudentDetailsModalVisible && (
        <StudentDetails
          isStudentDetailsModalVisible={isStudentDetailsModalVisible}
          setIsStudentDetailsModalVisible={setIsStudentDetailsModalVisible}
        />
      )}
      {isStudentPaymentDetailsModalVisible && (
        <StudentPaymentDetails
          isStudentPaymentDetailsModalVisible={
            isStudentPaymentDetailsModalVisible
          }
          setIsStudentPaymentDetailsModalVisible={
            setIsStudentPaymentDetailsModalVisible
          }
        />
      )}
    </Accounts>
  );
};

export default StudentPayment;
