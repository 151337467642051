import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import AddButton from "../../../../components/button/AddButton";
import AddSanction from "./AddSanction";
import UpdateSanction from "./UpdateSanction";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    width: 250,
    align: "center",
  },
  {
    title: "Message",
    dataIndex: "message",
    width: 150,
    align: "center",
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    width: 150,
    align: "center",
  },
  {
    title: "Updated Date",
    dataIndex: "updatedDate",
    width: 150,
    align: "center",
  },
  {
    title: "Show Message to Student",
    dataIndex: "showMessageToStudent",
    width: 100,
    align: "center",
  },
  {
    title: "Disable Student Portal From",
    dataIndex: "disableStudentPortalFrom",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const vaccineDetailsData = [
  {
    id: 1,
    type: "Payment",
    message: "Sanctioned due to inappropriate activities",
    updatedBy: "Anu Rani Regmi (Anu)",
    updatedDate: "20-08-2023",
    showMessageToStudent: "YES",
    disableStudentPortalFrom: "20-08-2023",
    status: "ACTIVE",
  },
];

const Sanction = () => {
  const [isAddSanctionModalVisible, setIsAddSanctionModalVisible] =
    useState(false);
  const [isUpdateSanctionModalVisible, setIsUpdateSanctionModalVisible] =
    useState(false);

  const data = vaccineDetailsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      type: dataObj?.type,
      message: dataObj?.message,
      updatedBy: dataObj?.updatedBy,
      updatedDate: dataObj?.updatedDate,
      showMessageToStudent: dataObj?.showMessageToStudent,
      disableStudentPortalFrom: dataObj?.disableStudentPortalFrom,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Sanction Detail">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateSanctionModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Sanction">
            <Popconfirm
              title="Are you sure to delete selected sanction?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Apply Student Sanction</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddSanctionModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddSanctionModalVisible && (
        <AddSanction
          isAddSanctionModalVisible={isAddSanctionModalVisible}
          setIsAddSanctionModalVisible={setIsAddSanctionModalVisible}
        />
      )}
      {isUpdateSanctionModalVisible && (
        <UpdateSanction
          isUpdateSanctionModalVisible={isUpdateSanctionModalVisible}
          setIsUpdateSanctionModalVisible={setIsUpdateSanctionModalVisible}
        />
      )}
    </Card>
  );
};

export default Sanction;
