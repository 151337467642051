import { Form } from "antd";
import React, { useState } from "react";
import { ButtonElement, InputFieldElement } from "../../../components/input";
import InputNumberField from "../../../components/inputNumberField/InputNumberField";

export default function ToilBankComponent({
  setFormData,
  setCurrent,
  formData,
}) {
  const [submitType, setSubmitType] = useState("");
  const handleSubmitForm = (values) => {
    setFormData((prev) => {
      return {
        ...prev,
        toid_act: values?.toid_act,
        toid_nsw: values?.toid_nsw,
        toid_nt: values?.toid_nt,
        toid_qld: values?.toid_qld,
        toid_sa: values?.toid_sa,
        toid_tas: values?.toid_tas,
        toid_vic: values?.toid_vic,
        toid_wa: values?.toil_wa,

        bank_name: values?.bank_name,
        branch: values?.branch,
        account_name: values?.account_name,
        account_number: values?.account_number,
        bsb: values?.bsb,
        swift_code: values?.swift_code,
      };
    });
    if (submitType === "back") {
      setCurrent((prev) => prev - 1);
    } else if (submitType === "next") {
      setCurrent((prev) => prev + 1);
    }
  };
  return (
    <Form
      initialValues={{ ...formData }}
      onFinish={handleSubmitForm}
      layout="vertical"
      className="flex flex-col gap-4"
    >
      <div className="relative border grid grid-cols-1 lg:grid-cols-2 gap-4 border-[#00798a50] rounded-[0.5rem] p-4 mt-3 ">
        <span className="absolute text-[1rem] left-4 top-[-0.8rem] px-2 bg-white text-[#00798a]">
          AVETMISS Training Organisation Identifier (TOID) for State
        </span>
        <InputFieldElement
          name="toid_act"
          label="TOID for ACT"
          placeholder="TOID for ACT"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="toid_nsw"
          label="TOID for NSW"
          placeholder="TOID for NSW"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="toid_nt"
          label="TOID for NT"
          placeholder="TOID for NT"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="toid_qld"
          label="TOID for QLD"
          placeholder="TOID for QLD"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="toid_sa"
          label="TOID for SA"
          placeholder="TOID for SA"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="toid_tas"
          label="TOID for TAS"
          placeholder="TOID for TAS"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="toid_vic"
          label="TOID for VIC"
          placeholder="TOID for VIC"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="toid_wa"
          label="TOID for WA"
          placeholder="TOID for WA"
          fieldWidth="100%"
        />
      </div>
      <div className="relative border grid grid-cols-1 lg:grid-cols-2 gap-4 border-[#00798a50] rounded-[0.5rem] p-4 mt-3 ">
        <span className="absolute text-[1rem] left-4 top-[-0.8rem] px-2 bg-white text-[#00798a]">
          Bank Details
        </span>
        <InputFieldElement
          name="bank_name"
          label="Bank Name"
          placeholder="Bank Name"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="branch"
          label="Branch"
          placeholder="Branch"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="account_name"
          label="Account name"
          placeholder="Account Name"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="account_number"
          label="Account Number"
          placeholder="Account Number"
          fieldWidth="100%"
        />
        <InputNumberField
          name="bsb"
          label="BSB"
          placeholder="BSB"
          fieldWidth="100%"
        />
        <InputNumberField
          name="swift_code"
          label="Swift Code"
          placeholder="Swift Code"
          fieldWidth="100%"
        />
      </div>
      <div className="col-span-2 flex flex-row gap-2">
        <ButtonElement
          htmlType="submit"
          handleClick={() => setSubmitType("back")}
          name="Back"
        />
        <ButtonElement
          htmlType="submit"
          handleClick={() => setSubmitType("next")}
          name="Next"
        />
      </div>
    </Form>
  );
}
