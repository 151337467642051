import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
import UpfrontFeeSummary from "./UpfrontFeeSummary";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
const { Option } = Select;

const columns = [
  {
    title: "Start Date",
    dataIndex: "startDate",
    width: 250,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 250,
    align: "center",
  },
  {
    title: "Installment Amount",
    dataIndex: "installmentAmount",
    width: 250,
    align: "center",
  },
  {
    title: "Paid Duration",
    dataIndex: "paidDuration",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const scheduleData = [
  {
    id: 1,
    startDate: "22/08/2023",
    dueDate: "11/12/2023",
    installmentAmount: "1200.00",
    paidDuration: "10 Weeks",
  },
  {
    id: 2,
    startDate: "31/10/2023",
    dueDate: "19/02/2024",
    installmentAmount: "10 Weeks",
    paidDuration: "",
  },
];

const UpdateUpfrontFeeSchedule = ({
  isUpdateUpfrontFeeScheduleModalVisible,
  setIsUpdateUpfrontFeeScheduleModalVisible,
}) => {
  const [isUpdateScheduleModalVisible, setIsUpdateScheduleModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateUpfrontFeeScheduleSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "Subject Enrollment StudentSubjectEnrollment_REQUEST",
      payload: formData,
    });
    // form.resetFields();
    setIsUpdateUpfrontFeeScheduleModalVisible(false);
  };
  let data = scheduleData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      startDate: dataObj?.startDate,
      dueDate: dataObj?.dueDate,
      installmentAmount: dataObj?.installmentAmount,
      paidDuration: dataObj?.paidDuration,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Update Upfront Fee Schedule">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateScheduleModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Delete Upfront Fee Schedule">
            <Popconfirm
              title="Are you sure to delete this selected Staff?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Drawer
      title="Update Upfront Fee"
      open={isUpdateUpfrontFeeScheduleModalVisible}
      onClose={() => {
        setIsUpdateUpfrontFeeScheduleModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
          }}
        >
          <Form
            layout="vertical"
            onFinish={UpdateUpfrontFeeScheduleSubmitHandler}
            colon={true}
            form={form}
            style={{ width: "100%" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Fee Type"
                name={"feeType"}
                style={{ width: "100%" }}
              >
                <Radio.Group defaultValue={"TUTION_FEE"}>
                  <Radio value={"TUTION_FEE"}>Tuition Fee</Radio>
                  <Radio value={"MISCELLANEOUS_FEE"}>Miscellaneous Fee</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Invoice Start Date"
                name={"invoiceStartDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick invoice start date!",
                  },
                ]}
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Invoice Due Date"
                name={"invoiceDueDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick invoice due date!",
                  },
                ]}
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
              <Form.Item
                label="No. of Instalment"
                name={"noOfInstallment"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter number of installments",
                  },
                ]}
              >
                <Input type="number" placeholder="No. of Instalment" />
              </Form.Item>
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Form.Item
                  label="Frequency"
                  name={"frequency"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a frequency!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Frequency" />
                </Form.Item>
                <Form.Item
                  name={"frequencyType"}
                  style={{ width: "100%", alignSelf: "flex-end" }}
                >
                  <Select placeholder="Frequency Type" defaultValue={"WEEK"}>
                    <Option value="Day">Day</Option>
                    <Option value="WEEK">Week</Option>
                    <Option value="MONTH">Month</Option>
                    <Option value="YEAR">YEAR</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                label="Activity Start Date"
                name={"activityStartDate"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please pick activity start date !",
                //   },
                // ]}
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Activity Finish Date"
                name={"activityFinishDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick a activity finish date!",
                  },
                ]}
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
              <Form.Item
                label="Venue/Training Location"
                name={"venue"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a venue/training Location !",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Funding Source State"
                name={"fundingSourceState"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please pick a funding source state!",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
              <Form.Item
                label="Funding Source NAT"
                name={"fundingSourceNat"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a venue/training Location !",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
            </div>

            <Form.Item>
              <ButtonElement
                block={true}
                type="primary"
                htmlType="submit"
                name="Update Upfront Fee"
              >
                Generate Schedule
              </ButtonElement>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <UpfrontFeeSummary
            detail={{
              name: "Anish Banjara",
            }}
          />
        </div>
      </div>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table dataSource={data} columns={columns} pagination={false} />
      </div>
    </Drawer>
  );
};

export default UpdateUpfrontFeeSchedule;
