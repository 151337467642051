import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddInterventionType from "./InterventionType/AddInterventionType";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddInterventionStrategy = ({
  isAddInterventionStrategyModalVisible,
  setIsAddInterventionStrategyModalVisible,
}) => {
  const [
    isAddInterventionTypeModalVisible,
    setIsAddInterventionTypeModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddInterventionStrategySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddInterventionStrategy_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddInterventionStrategyModalVisible(false);
  };
  return (
    <Drawer
      title="Add Intervention Strategy"
      open={isAddInterventionStrategyModalVisible}
      onClose={() => {
        setIsAddInterventionStrategyModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddInterventionStrategySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Intervention Type"
            name={"interventionType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a intervention type !",
              },
            ]}
          >
            <Select placeholder="Intervention Type">
              <Option value="ACADEMIC">Academic</Option>
              <Option value="ATTENDANCE">Attendance</Option>
            </Select>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddInterventionTypeModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>
        <Form.Item
          label="Strategy"
          name={"strategy"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a strategy !",
            },
          ]}
        >
          <Input placeholder="Strategy" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Intervention Strategy"
          />
        </Form.Item>
      </Form>
      {isAddInterventionTypeModalVisible && (
        <AddInterventionType
          isAddInterventionTypeModalVisible={isAddInterventionTypeModalVisible}
          setIsAddInterventionTypeModalVisible={
            setIsAddInterventionTypeModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default AddInterventionStrategy;
