import React, { useState } from "react";
import OrganizationalSettings from "../../../components/OrganizationalSettings/OrganizationalSettings";
import { Tabs } from "antd";
import ManageSemester from "./ManageSemester";
import ManageSemesterDivision from "./ManageSemesterDivision";
import CourseCalenderType from "./CourseCalenderType";

export default function CalenderSetting() {
  const [current, setCurrent] = useState("manageSemester");
  const settingList = [
    {
      label: "Manage Semester",
      key: "manageSemester",
    },
    {
      label: "Manage Semester Divisions",
      key: "manageSemesterDivisions",
    },
    {
      label: "Course Calender Type",
      key: "courseCalenderType",
    },
  ];
  return (
    <OrganizationalSettings>
      <div className="mx-4 mt-[2px] bg-white h-full p-4 flex flex-col gap-4">
        <div>
          <span className="text-[1.2rem] font-semibold">Calender Settings</span>
          <Tabs
            animated={{
              inkBar: false,
              tabPane: true,
            }}
            items={settingList}
            onChange={(e) => {
              setCurrent(e);
            }}
          />
        </div>
        {current === "manageSemester" && <ManageSemester />}
        {current === "manageSemesterDivisions" && <ManageSemesterDivision />}
        {current === "courseCalenderType" && <CourseCalenderType />}
      </div>
    </OrganizationalSettings>
  );
}
