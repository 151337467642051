import { Button, Card, Form, Input, Modal, Select, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import OrganizationalSettings from "../../../components/OrganizationalSettings/OrganizationalSettings";
import OshcInfo from "./OshcInfo/OshcInfo";
import ServiceSetup from "./ServiceSetup/ServiceSetup";
import ServiceProviderSetup from "./ServiceProviderSetup/ServiceProviderSetup";
import ServiceProviderFacility from "./ServiceProviderFacility/ServiceProviderFacility";

const staffTabList = [
  {
    key: "oshcInfo",
    label: "OSHC Info",
  },
  {
    key: "serviceSetup",
    label: "Service Setup",
  },
  {
    key: "serviceProviderSetup",
    label: "Service Provider Setup",
  },
  {
    key: "serviceProviderFacility",
    label: "Service Provider Facility",
  },
];
const ServiceSettings = () => {
  const [current, setCurrent] = useState("oshcInfo");

  return (
    <OrganizationalSettings>
      <div style={{ margin: "0.5rem 0 0 0.5rem" }}>
        <h3 className="font-medium text-base text-black">Service Settings</h3>
        <div className="bg-white rounded px-4 py-2">
          <Tabs
            defaultActiveKey="profile"
            items={staffTabList}
            activeTabKey={current}
            onChange={(key) => {
              console.log("current key", key);
              setCurrent(key);
            }}
          />
          {current === "oshcInfo" && (
            <>
              <OshcInfo />
            </>
          )}
          {current === "serviceSetup" && (
            <>
              <ServiceSetup />
            </>
          )}
          {current === "serviceProviderSetup" && (
            <>
              <ServiceProviderSetup />
            </>
          )}
          {current === "serviceProviderFacility" && (
            <>
              <ServiceProviderFacility />
            </>
          )}
        </div>
      </div>
    </OrganizationalSettings>
  );
};

export default ServiceSettings;
