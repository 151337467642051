import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const UpdateEnrolmentFeeSetup = ({
  isUpdateEnrolmentFeeSetupModalVisible,
  setIsUpdateEnrolmentFeeSetupModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateEnrolmentFeeSetupSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateEnrolmentFeeSetup_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateEnrolmentFeeSetupModalVisible(false);
  };
  return (
    <Modal
      title="Update Enrolment Fee Setup"
      open={isUpdateEnrolmentFeeSetupModalVisible}
      onCancel={() => {
        setIsUpdateEnrolmentFeeSetupModalVisible(false);
      }}
      footer={null}
      //   width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateEnrolmentFeeSetupSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {/* <Form.Item
            label="Enrolment Fee"
            name={"enrolmentFee"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter an enrolment fee!",
              },
            ]}
          >
            <Input type="number" placeholder="Enrolment Fee" />
          </Form.Item> */}
          <Form.Item
            label="Valid To"
            name={"Valid To"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick valid due date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        {/* <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Select Course type"
            name={"CourseType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course type",
              },
            ]}
          >
            <Select placeholder="Course Type">
              <Option value="ELICOS">ELICOS</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Course Selection"
            name={"courseSelection"}
            style={{ width: "100%" }}
          >
            <Radio.Group placeholder="Course Selection">
              <Radio value={"ALL_COURSES"}>All Courses</Radio>
              <Radio value={"SELECTED_COURSE"}>Selected Course</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Form.Item
          label="Select Origin"
          name={"selectOrigin"}
          valuePropName="checked"
        >
          <Checkbox.Group>
            <Checkbox value={"OVERSEAS_STUDENT_OFFSHORE"}>
              Overseas Student (Offshore)
            </Checkbox>
            <Checkbox value={"OVERSEAS_STUDENT_IN_AUSTRALIA_ONSHORE"}>
              Overseas Student in Australia (Onshore)
            </Checkbox>
            <Checkbox value={"RESIDENT_STUDENT_DOMESTIC"}>
              Resident Student (Domestic)
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label="Course" name={"course"} valuePropName="checked">
          <Checkbox.Group>
            <Checkbox className="w-1/3">
              EAP: English for Academic Purposes
            </Checkbox>
            <Checkbox className="w-1/3">
              EAP: English for Academic Purposes - Offshore
            </Checkbox>
            <Checkbox className="w-1/3">
              EAP: English for Academic Purposes - Other
            </Checkbox>
            <Checkbox className="w-1/3">GE: General English</Checkbox>
            <Checkbox className="w-1/3">
              GE-OS: General English - Offshore
            </Checkbox>
            <Checkbox className="w-1/3">
              GE-Other: General English - Other
            </Checkbox>
            <Checkbox className="w-1/3">IELTS: IELTS Prepration</Checkbox>
          </Checkbox.Group>
        </Form.Item> */}
        <Form.Item>
          <ButtonElement block type="primary" htmlType="submit" name="Update" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateEnrolmentFeeSetup;
