import React from "react";
import { DatePicker, Form, Input, Radio, Select } from "antd";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const ShortCourseApplicantPersonalInformation = ({
  applicantData,
  setApplicantData,
  current,
  setCurrent,
}) => {
  const [form] = Form.useForm();

  const addShortCourseApplicantPersonalInformationHandler = () => {
    setCurrent(1);
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={addShortCourseApplicantPersonalInformationHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          { name: ["title"], value: applicantData?.title },
          { name: ["firstName"], value: applicantData?.firstName },
          { name: ["middleName"], value: applicantData?.middleName },
          { name: ["lastName"], value: applicantData?.lastName },
          { name: ["preferredName"], value: applicantData?.preferredName },
          { name: ["gender"], value: applicantData?.gender },
          { name: ["dateOfBirth"], value: applicantData?.dateOfBirth },
          { name: ["email"], value: applicantData?.email },
          {
            name: ["birthplace"],
            value: applicantData?.birthplace,
          },
          { name: ["countryOfBirth"], value: applicantData?.countryOfBirth },
          { name: ["nationality"], value: applicantData?.nationality },
          { name: ["passportNo"], value: applicantData?.passportNo },
          { name: ["expiryDate"], value: applicantData?.expiryDate },
          { name: ["visaStatus"], value: applicantData?.visaStatus },
          { name: ["visaNumber"], value: applicantData?.visaNumber },
          { name: ["visaExpiryDate"], value: applicantData?.visaExpiryDate },
          { name: ["residencyStatus"], value: applicantData?.residencyStatus },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Full Name" name={"title"} style={{ width: "100%" }}>
            <Select
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    title: e,
                  };
                });
              }}
              placeholder="Title"
            >
              <Option value="Mr">Mr</Option>
              <Option value="Miss">Miss</Option>
              <Option value="Mrs">Mrs</Option>
              <Option value="Ms">Ms</Option>
              <Option value="Dr">Dr</Option>
              <Option value="Rev">Rev</Option>
              <Option value="Hon">Hon</Option>
              <Option value="">Not Specified</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={"firstName"}
            style={{ width: "100%", alignSelf: "flex-end" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              placeholder="First Name"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    firstName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name={"middleName"}
            style={{ width: "100%", alignSelf: "flex-end" }}
            rules={[
              {
                required: true,
                message: "Please enter middle name!",
              },
            ]}
          >
            <Input
              placeholder="Middle Name"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    middleName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name={"lastName"}
            style={{ width: "100%", alignSelf: "flex-end" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              placeholder="Last Name"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    lastName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Preferred Name"
            name={"preferredName"}
            style={{ width: "100%" }}
          >
            <Input
              placeholder="Preferred Name"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    preferredName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Gender"
            name={"gender"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a gender!",
              },
            ]}
          >
            <Radio.Group
              defaultValue={"MALE"}
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    gender: e.target.value,
                  };
                });
              }}
            >
              <Radio value={"MALE"}>Male</Radio>
              <Radio value={"FEMALE"}>Female</Radio>
              <Radio value={"OTHER"}>Other/Not specified</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Date of Birth"
            name={"dateOfBirth"}
            style={{ width: "100%" }}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              placeholder="Date of Birth"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    dateOfBirth: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              type="email"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Birthplace"
            name={"birthplace"}
            style={{ width: "100%" }}
          >
            <Input
              placeholder="Birthplace"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    birthplace: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Country of Birth"
            name={"countryOfBirth"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select country of birth!",
              },
            ]}
          >
            <Select
              placeholder="Country of Birth"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    countryOfBirth: e,
                  };
                });
              }}
            >
              <Option value="AUSTRALIA">Australia</Option>
              <Option value="NEPAL">Nepal</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Nationality"
            name={"nationality"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select Nationality!",
              },
            ]}
          >
            <Select
              placeholder="Nationality"
              onChange={(e) => {
                setApplicantData((prevData) => {
                  return {
                    ...prevData,
                    nationality: e,
                  };
                });
              }}
            >
              <Option value="AUSTRALIAN">Australian</Option>
              <Option value="NEPALESE">Nepalese</Option>
            </Select>
          </Form.Item>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Form.Item>
            <ButtonElement type="primary" htmlType="submit" name="Next">
              Next
            </ButtonElement>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ShortCourseApplicantPersonalInformation;
