import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { Api } from "../Helpers/BaseUrlProvider";
// import { AllApi, findAsyncToken, ImageApi } from "./AllApi";

// import { navigate } from "../constants/RootNavigation";
// import { errorConsole } from "../functions/HelperFuntion";
// import { CustomAlert } from "../functions/HelperFunction";
// import AsyncStorage from "@react-native-async-storage/async-storage";

export const AllEpic = (action$) =>
  action$.pipe(
    ofType("ADMIN_AUTHENTICATION_REQUEST"),
    mergeMap((action) =>
      from(Api(action)).pipe(
        mergeMap((response) => {
          console.log(response, "allepicsss");
          //   if (action.type_data.navigateTo != null) {
          //     navigate(action.type_data.navigateTo, action.type_data.params);
          //   }
          if (action.type_data.successInternalState) {
            action.type_data.successInternalState(response.data);
          }
          return of({
            type: action.type_data.success,
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          if (action.type_data.failureInternalState) {
            action.type_data.failureInternalState(err?.response?.data);
          }
          return of({ type: action.type_data.failure, payload: err.response });
        })
      )
    )
  );
