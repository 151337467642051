import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import React from "react";
import { ButtonElement } from "../../../../components/input";
import { useDispatch } from "react-redux";
import AddCourseSite from "./AddCourseSite";

export default function CourseSite() {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "active",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: () => (
        <div>
          <Tooltip placement="top" title="Delete Record">
            <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
          </Tooltip>{" "}
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      name: "Test",
      active: "Yes",
    },
    {
      id: 2,
      name: "Test",
      active: "Yes",
    },
    {
      id: 3,
      name: "Test",
      active: "Yes",
    },
  ];

  const handleAddCourseSite = (value) => {
    dispatch({ type: "FETCH_OPEN_MODAL", payload: value });
  };

  return (
    <div className="flex flex-col gap-4 my-4 gap-4">
      <ButtonElement
        handleClick={() => handleAddCourseSite("addCourseSite")}
        Icon={<PlusOutlined className="text-sm" />}
        name="Course Site"
      />
      <Table dataSource={data} columns={columns} />
      <AddCourseSite />
    </div>
  );
}
