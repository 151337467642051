import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdateCourseDeferral = ({
  isUpdateCourseDeferralModalVisible,
  setIsUpdateCourseDeferralModalVisible,
}) => {
  const [courseDeferData, setCourseDeferData] = useState({
    isUpdateCourseFinishDate: false,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateCourseDeferralSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_COURSE_DEFERRAL_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateCourseDeferralModalVisible(false);
  };
  return (
    <Drawer
      title="Update Course Deferral"
      open={isUpdateCourseDeferralModalVisible}
      onClose={() => {
        setIsUpdateCourseDeferralModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateCourseDeferralSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {/* <Form.Item
            label="Course "
            name={"course"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course !",
              },
            ]}
          >
            <Select>
              <Option> Mr Alexander Freitag(alex.freitag)</Option>
              <Option>Ms Anu Rani Regmi(Anu)</Option>
              <Option>Mr Benjamin Fairley(benjamin.fairley)</Option>
              <Option>Mr Gaurab Thapa(gaurab.thapa)</Option>
              <Option>Mr James McDonald(james.mcdonald)</Option>
              <Option>Mr Kadam Roka(kadam.roka)</Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Type"
            name={"type"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a type!",
              },
            ]}
          >
            <Select>
              <Option value="APPROVED_LEAVE">Approved Leave - AL</Option>
              <Option value="APPROVED_HOLIDAY">Approved Holiday - AH</Option>
              <Option value="DEFERRAL">Deferral - DF</Option>
              <Option value="SUSPENSION">Suspension - SP</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Reason "
            name={"reason"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a reason!",
              },
            ]}
          >
            <Select>
              <Option value="CHANGE_IN_PERSONAL_CIRCUMSTANCES">
                Change in personal circumstances
              </Option>
              <Option value="ELICOS_HOLIDAY_WEEK">ELICOS holiday week</Option>
              <Option value="VISITING_COUNTRY">Visiting Country</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Request Date"
            name={"requestDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a request date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Deferral Start Date"
            name={"deferralStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a deferral start date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="New Start Date"
            name={"newStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a new start date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Deferral Finish Date"
            name={"deferralStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a deferral finish date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            name={"approvedStatus"}
            valuePropName="checked"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a approved status !",
              },
            ]}
          >
            <Checkbox>Is Approved?</Checkbox>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            name={"isUpdateCourseFinishDate"}
            valuePropName="checked"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a approved status !",
              },
            ]}
          >
            <Checkbox
              onChange={(e) => {
                setCourseDeferData((prevData) => {
                  return {
                    ...prevData,
                    isUpdateCourseFinishDate: e.target.checked,
                  };
                });
              }}
            >
              Select to update course finish date
            </Checkbox>
          </Form.Item>
          {courseDeferData.isUpdateCourseFinishDate && (
            <Form.Item
              label="Course Finish Date"
              name={"courseFinishDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please pick a course finish date!",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          )}
        </div>
        <Form.Item
          label="DHA Notified Date"
          name={"dnaNotifiedDate"}
          style={{ width: "100%" }}
          //   rules={[
          //     {
          //       required: true,
          //       message: "Please pick a DHA notified date!",
          //     },
          //   ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item label="Comments" name={"Comments"} style={{ width: "100%" }}>
          <TextArea placeholder="Write a Comment" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Course Deferral"
          >
            Update Course Deferral
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateCourseDeferral;
