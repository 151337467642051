import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../../../../Assets/logo/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StaffDocuments from "../../../../Staff/StaffDetails/StaffDocuments/StaffDocuments";
import EmployerProfile from "./EmployerProfile/EmployerProfile";
import MultipleContacts from "./MultipleContacts/MultipleContacts";
import SendComposedMail from "../../../../Mailbox/SendComposedMail";

const EmployerDetails = ({
  leadData,
  record,
  fromAddTeacher,
  isEmployerDetailsModalVisible,
  setIsEmployerDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return { staffState: state.StaffReducer };
  });

  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  const [current, setCurrent] = useState("profile");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const staffTabList = [
    {
      key: "profile",
      label: "Profile",
    },
    {
      key: "multipleContacts",
      label: "Multiple Contacts",
    },
    {
      key: "documents",
      label: "Documents",
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  const navigate = useNavigate();
  console.log("record from lead details");
  console.log("record from lead store");
  console.log("store.staffState?.leadById");
  return (
    <Drawer
      title="Employer Details"
      open={isEmployerDetailsModalVisible}
      onClose={() => {
        setIsEmployerDetailsModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Card style={{ borderRadius: "3px" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "inherit",
          }}
        >
          <Image
            src={client}
            className="clientImage"
            height={160}
            width={160}
          />
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
              width: "80%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`DC Plumbing Services`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlinePhone
                  style={{
                    fontSize: "20",
                  }}
                />
                0265656955
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <GoLocation
                  style={{
                    fontSize: "16",
                  }}
                />
                1/544 Willoughby Road Willoughby NSW Australia, 2068
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlineMail
                  style={{
                    fontSize: "16",
                  }}
                />{" "}
                dcplumbing@gmail.com
              </span>
            </div>
            {/* <div>
                <div>
                  <span className="profileTitle">Campaign:</span>
                  <span>
                    Further Details Here
                  </span>
                </div>
              </div> */}
            <div
              className="fxRAc"
              style={{
                gap: "0.5rem",
                position: "absolute",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>

              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>

              {/*
                <Button
                  type="primary"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                  }}
                >
                  Status Update
                </Button>
                */}
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            ></div>
          </div>
        </div>
        <Tabs
          defaultActiveKey="profile"
          items={staffTabList}
          activeTabKey={current}
          onChange={(key) => {
            console.log("current key", key);
            setCurrent(key);
          }}
        />
        {current === "profile" && (
          <>
            <EmployerProfile />
          </>
        )}
        {current === "multipleContacts" && (
          <>
            <MultipleContacts />
          </>
        )}

        {current === "documents" && (
          <>
            <StaffDocuments />
          </>
        )}
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: ["dcplumbing@gmail.com"],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default EmployerDetails;
