import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Pagename from "../../../components/Pagename/pagename";
import AddButton from "../../../components/button/AddButton";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import { SelectField } from "../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineDollar,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFilePdf,
} from "react-icons/ai";
import Offers from "../Offers";
import { BsDownload } from "react-icons/bs";
import { TiTickOutline } from "react-icons/ti";
import ApplicantDetails from "./ApplicantDetails/ApplicantDetails";
import { MdOutlinePending, MdPending } from "react-icons/md";
import ChooseCourseApplication from "./ChooseCourseApplication";
const { Search } = Input;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Date Applied",
    dataIndex: "dateApplied",
    width: 150,
    align: "center",
  },
  {
    title: "Applicant Name",
    dataIndex: "applicantName",
    width: 200,
    align: "center",
  },
  {
    title: "Agent",
    dataIndex: "agent",
    width: 250,
    align: "center",
  },
  {
    title: "Course Applied",
    dataIndex: "courseApplied",
    width: 250,
    align: "center",
  },
  {
    title: "Course Applied",
    dataIndex: "courseApplied",
    width: 250,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "CoE",
    dataIndex: "coe",
    width: 100,
    align: "center",
  },
  {
    title: "Student ID",
    dataIndex: "studentId",
    width: 100,
    align: "center",
  },
  {
    title: "Reserve Id",
    dataIndex: "reserveId",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 350,
    align: "center",
  },
];
const searchApplicantsBy = [
  {
    label: "ID/First/Last",
    value: "idFirstLast",
  },
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Course",
    value: "course",
  },
  {
    label: "Date Applied",
    value: "dateApplied",
  },
  {
    label: "Date of Birth",
    value: "dateOfBirth",
  },
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Middle Name",
    value: "middleName",
  },
  {
    label: "Student Name (First and/or Last Name)",
    value: "studentNameFirstNameOrLastName",
  },
  {
    label: "Student Origin",
    value: "studentOrigin",
  },
  {
    label: "Nationality",
    value: "applicantNationality",
  },
  {
    label: "Nick Name",
    value: "nickName",
  },
  {
    label: "Offer ID",
    value: "offerId",
  },
  {
    label: "Start Semester",
    value: "startSemester",
  },
  {
    label: "Admissioin Staff",
    value: "admissionStaff",
  },
  {
    label: "Account Manager",
    value: "accountManager",
  },
  {
    label: "Reserve Student ID",
    value: "reserveStudentId",
  },
  {
    label: "Student Under Agent",
    value: "StudentUnderAgent",
  },
  {
    label: "Tac Reference Number",
    value: "tacReferenceNumber",
  },
];

const applicantData = [
  {
    id: 1,
    dateApplied: "21-08-2023",
    applicantName: "Oscar Javier Romero Espitia",
    agent: "Empire Int'l Education and Visa Services",
    courseApplied: "	GE: General English",
    courseApplied: "02-10-2023 - 29-12-2023",
    status: "Offered",
    coe: "",
    studentId: "SD001",
    reserveId: "001",
  },
  {
    id: 2,
    dateApplied: "21-08-2023",
    applicantName: "Sangita Khadka",
    agent: "Oz Connect Education and Visa Services",
    courseApplied: "	GE: General English",
    courseApplied: "07-08-2023 - 15-09-2023",
    status: "Offered",
    coe: "",
    studentId: "SD002",
    reserveId: "002",
  },
];

const applicationStatus = [
  {
    label: "All (Excluding Not Submitted)",
    value: "all",
  },
  {
    label: "Unapproved",
    value: "UNAPPROVED",
  },
  {
    label: "Converted",
    value: "converted",
  },
  {
    label: "Pending",
    value: "panding",
  },
  {
    label: "Reconsider",
    value: "reconsider",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Offered",
    value: "offered",
  },
  {
    label: "Not Submitted",
    value: "offered",
  },
];
const studentOrigin = [
  {
    label: "Overseas Student (Offshore)",
    value: "overseasStudentOffshore",
  },
  {
    label: "Overseas Student in Australia (Onshore)",
    value: "overseasStudentInAustaliaOnshore",
  },
  {
    label: "Resident Student (Domestic)",
    value: "residentStudent",
  },
];
const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const ViewApplicantList = () => {
  const [searchBy, setSearchBy] = useState("idFirstLast");
  const [teacherStatus, setTeacherStatus] = useState("ACTIVE");

  const [isUpdateStaffModalVisible, setIsUpdateStaffModalVisible] =
    useState(false);
  const [isApplicantDetailsModalVisible, setIsApplicantDetailsModalVisible] =
    useState(false);
  const [
    isChooseCourseApplicationModalVisible,
    setIsChooseCourseApplicationModalVisible,
  ] = useState(false);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  let data = applicantData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      dateApplied: dataObj?.dateApplied,
      applicantName: dataObj?.applicantName,
      agent: dataObj?.agent,
      courseApplied: dataObj?.courseApplied,
      courseApplied: dataObj?.courseApplied,
      status: dataObj?.status,
      coe: dataObj?.coe,
      studentId: dataObj?.studentId,
      reserveId: dataObj?.reserveId,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "12vw" }}
        >
          <Tooltip title="Update Applicant Detail">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStaffModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Teacher Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsApplicantDetailsModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Download Offer Letter">
            <a>
              <div className="bordered" onClick={() => {}}>
                <BsDownload />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Re Approve the offer letter">
            <a>
              <div className="bordered" onClick={() => {}}>
                <TiTickOutline />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Teacher">
            <Popconfirm
              title="Are you sure to delete this selected teacher?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Generate Student Invoice">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Generate Agent Payment Invoice">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineDollar />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Mark Pending Offer Letter">
            <a>
              <div className="bordered" onClick={() => {}}>
                <MdOutlinePending />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Offers tab={"/offers"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Applicant List</Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <AddButton
                handleClick={() => {
                  setIsChooseCourseApplicationModalVisible(true);
                }}
              >
                Add
              </AddButton>
            </div>
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Select application status</div>
              <SelectField
                name="applicationStatus"
                placeholder="Select Application Status"
                options={applicationStatus}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="8rem"
              />
            </div>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Search by</div>
              <SelectField
                defaultValue="idFirstLast"
                name="searchApplicantsBy"
                placeholder="Search Applicants By"
                options={searchApplicantsBy}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="12rem"
              />
            </div>
            {(searchBy === "dateOfBirth" ||
              searchBy === "dateApplied" ||
              searchBy === "courseStartDate") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <DatePicker format={"DD-MM-YYYY"} style={{ width: "12rem" }} />
              </div>
            )}
            {searchBy === "studentOrigin" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="studentOrigin"
                  placeholder="Student Origin"
                  options={studentOrigin}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {searchBy === "applicantNationality" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="applicantNationality"
                  placeholder="Applicant Nationality"
                  options={[
                    { label: "Australian", value: "Australian" },
                    { label: "Nepalese", value: "Nepalese" },
                  ]}
                  handleChange={(e) => {}}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {searchBy === "course" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="course"
                  placeholder="Course"
                  options={selectCourse}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {searchBy === "startSemester" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="studentType"
                  placeholder="Start Semester"
                  options={[]}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {(searchBy === "admissionStaff" ||
              searchBy === "accountManager") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="studentType"
                  placeholder="Staff"
                  options={[]}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {(searchBy === "agent" || searchBy === "StudentUnderAgent") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="agent"
                  placeholder="Agents"
                  options={[]}
                  handleChange={(e) => {}}
                  fieldWidth="10rem"
                />
              </div>
            )}

            {(searchBy === "idFirstLast" ||
              searchBy === "firstName" ||
              searchBy === "lastName" ||
              searchBy === "middleName" ||
              searchBy === "studentNameFirstNameOrLastName" ||
              searchBy === "nickName" ||
              searchBy === "offerId" ||
              searchBy === "reserveStudentId" ||
              searchBy === "tacReferenceNumber") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <Search
                  style={{ borderRadius: "3px", width: "100%" }}
                  className="search"
                  placeholder={`Search Applicant.`}
                  allowClear
                  size="Medium"
                  //   value={searchValue}
                  onChange={(e) => {
                    //   setSearchValue(e.target.value);
                    //   onSearch(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table dataSource={data} columns={columns} pagination={false} />
        </div>
      </ComponentBody>
      {isApplicantDetailsModalVisible && (
        <ApplicantDetails
          isApplicantDetailsModalVisible={isApplicantDetailsModalVisible}
          setIsApplicantDetailsModalVisible={setIsApplicantDetailsModalVisible}
        />
      )}
      {isChooseCourseApplicationModalVisible && (
        <ChooseCourseApplication
          isChooseCourseApplicationModalVisible={
            isChooseCourseApplicationModalVisible
          }
          setIsChooseCourseApplicationModalVisible={
            setIsChooseCourseApplicationModalVisible
          }
        />
      )}
    </Offers>
  );
};

export default ViewApplicantList;
