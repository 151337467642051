import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Accounts from "../Accounts";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineOrderedList,
} from "react-icons/ai";
import PageHeader from "../../../components/PageHeader/PageHeader";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import AddBankDepositDate from "./AddBankDepositDate";
import AddButton from "../../../components/button/AddButton";
import ReconcileBankDepositDetails from "./ReconcileBankDepositDetails/ReconcileBankDepositDetails";
const { Search } = Input;

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    width: 100,
    align: "center",
  },
  {
    title: "Deposit Date",
    dataIndex: "depositDate",
    width: 250,
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const agentData = [
  {
    id: "1",
    type: "Fees",
    depositDate: "04 Dec 2022",
    amount: "344",
  },
];

const BankReconciliation = () => {
  const [
    isAddBankDepositDateModalVisible,
    setIsAddBankDepositDateModalVisible,
  ] = useState(false);
  const [
    isReconcileBankDepositDetailsModalVisible,
    setIsReconcileBankDepositDetailsModalVisible,
  ] = useState(false);

  let data = agentData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      type: dataObj?.type,
      depositDate: dataObj?.depositDate,
      amount: dataObj?.amount,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Reconcile Bank Deposit Amount">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsReconcileBankDepositDetailsModalVisible(true);
                }}
              >
                <AiOutlineOrderedList />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete bank deposit detail">
            <Popconfirm
              title="Are you sure to delete selected bank deposit detail?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Accounts tab={"/bank-reconciliation"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Bank deposit date update</Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <AddButton
                handleClick={() => {
                  setIsAddBankDepositDateModalVisible(true);
                }}
              >
                Add
              </AddButton>
            </div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table
            bordered
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isAddBankDepositDateModalVisible && (
        <AddBankDepositDate
          isAddBankDepositDateModalVisible={isAddBankDepositDateModalVisible}
          setIsAddBankDepositDateModalVisible={
            setIsAddBankDepositDateModalVisible
          }
        />
      )}
      {isReconcileBankDepositDetailsModalVisible && (
        <ReconcileBankDepositDetails
          isReconcileBankDepositDetailsModalVisible={
            isReconcileBankDepositDetailsModalVisible
          }
          setIsReconcileBankDepositDetailsModalVisible={
            setIsReconcileBankDepositDetailsModalVisible
          }
        />
      )}
    </Accounts>
  );
};

export default BankReconciliation;
