import { Card, Divider } from "antd";
import React from "react";

const EmployerProfile = () => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fxRJsb">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <div className="grid grid-cols-1 gap-4 mt-8">
      <div className="flex flex-col gap-4">
        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Employer Info
          </span>
          {detailFun("Trading Name", "alex.freitag")}
          {detailFun("Contact Person", "alex@opera.nsw.edu.au")}
          {detailFun("ABN", "9659865996")}
          {detailFun("Industry", "Adult, Community and Other Education (82)")}
          {detailFun(
            "Company Address",
            "1/544 Willoughby Road Willoughby NSW Australia, 2068"
          )}
          {detailFun("Company Suburb", "NSW")}
          {detailFun("Country", "Australia")}
          {detailFun("Company State", "New South Wales")}
          {detailFun("Postcode", "33265")}
          {detailFun("Phone", "015656569")}
          {detailFun("Mobile", "9659865996")}
          {detailFun("Fax", "654664")}
          {detailFun("Email", "dcplumbing@gmail.com")}
          {detailFun("Status", "ACTIVE")}
        </div>
      </div>
    </div>
  );
};

export default EmployerProfile;
