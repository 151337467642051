import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddService = ({
  isAddServiceModalVisible,
  setIsAddServiceModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addServiceSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_SERVICE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddServiceModalVisible(false);
  };
  return (
    <Drawer
      title="Add Service"
      open={isAddServiceModalVisible}
      onClose={() => {
        setIsAddServiceModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={addServiceSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Category"
            name={"serviceCategory"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a service category !",
              },
            ]}
          >
            <Select
              defaultValue={"TRANSPORT"}
              placeholder="Select service category"
            >
              <Option value="TRANSPORT">Transport</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Facility"
            name={"facility"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a facility!",
            //   },
            // ]}
          >
            <Select placeholder="Facility">
              <Option>Bus</Option>
              <Option>Taxi</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Service Provider"
            name={"serviceProvider"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a service provider!",
            //   },
            // ]}
          >
            <Select placeholder="Agent">
              <Option>ABC Tours and Travels</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Price (in AUD)"
            name={"price"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a price!",
            //   },
            // ]}
          >
            <Input type="number" placeholder="price" />
          </Form.Item>
          <Form.Item
            label="Service Pickup Date"
            name={"servicePickupDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter service pickup date!",
            //   }
            // ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Pickup Time"
            name={"servicePickupTime"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter service pickup Time!",
            //   }
            // ]}
          >
            <TimePicker />
          </Form.Item>
          <Form.Item
            label="Service From"
            name={"serviceFrom"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select service from!",
            //   }
            // ]}
          >
            <Select placeholder="Service From"></Select>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service To"
            name={"serviceTo"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter service to!",
            //   },
            // ]}
          >
            <TextArea placeholder="Write service to" />
          </Form.Item>
          <Form.Item
            label="Service Comment"
            name={"serviceComment"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter service comment!",
            //   },
            // ]}
          >
            <TextArea placeholder="Write about the service" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Service"
          >
            Add Service
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddService;
