import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddEnrolmentFeeSetup from "./AddEnrolmentFeeSetup";
import UpdateEnrolmentFeeSetup from "./UpdateEnrolmentFeeSetup";

const columns = [
  {
    title: "Enrolment Fee",
    dataIndex: "enrolmentFee",
    width: 100,
    align: "center",
  },
  {
    title: "Course ID",
    dataIndex: "courseId",
    width: 200,
    align: "center",
  },
  {
    title: "Origin",
    dataIndex: "origin",
    width: 50,
    align: "center",
  },
  {
    title: "Valid To",
    dataIndex: "validTo",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const EnrolmentFeeSetupData = [
  {
    id: "1",
    enrolmentFee: "200.00",
    courseId: "EAP",
    origin: "Overseas Student",
    validTo: "30 Jul 2028",
  },
  {
    id: "2",
    enrolmentFee: "200.00",
    courseId: "GE",
    origin: "Resident Student",
    validTo: "30 Jul 2028",
  },
];

const EnrolmentFeeSetup = () => {
  const [
    isAddEnrolmentFeeSetupModalVisible,
    setIsAddEnrolmentFeeSetupModalVisible,
  ] = useState(false);
  const [
    isUpdateEnrolmentFeeSetupModalVisible,
    setIsUpdateEnrolmentFeeSetupModalVisible,
  ] = useState(false);

  const data = EnrolmentFeeSetupData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      enrolmentFee: dataObj?.enrolmentFee,
      courseId: dataObj?.courseId,
      origin: dataObj?.origin,
      validTo: dataObj?.validTo,
      actions: (
        <Space size="middle">
          <Tooltip title="Update EnrolmentFeeSetup">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateEnrolmentFeeSetupModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete EnrolmentFeeSetup">
            <Popconfirm
              title="Are you sure to delete this EnrolmentFeeSetup?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Enrolment Fee Setup</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddEnrolmentFeeSetupModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddEnrolmentFeeSetupModalVisible && (
        <AddEnrolmentFeeSetup
          isAddEnrolmentFeeSetupModalVisible={
            isAddEnrolmentFeeSetupModalVisible
          }
          setIsAddEnrolmentFeeSetupModalVisible={
            setIsAddEnrolmentFeeSetupModalVisible
          }
        />
      )}
      {isUpdateEnrolmentFeeSetupModalVisible && (
        <UpdateEnrolmentFeeSetup
          isUpdateEnrolmentFeeSetupModalVisible={
            isUpdateEnrolmentFeeSetupModalVisible
          }
          setIsUpdateEnrolmentFeeSetupModalVisible={
            setIsUpdateEnrolmentFeeSetupModalVisible
          }
        />
      )}
    </div>
  );
};

export default EnrolmentFeeSetup;
