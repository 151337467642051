import React, { useState } from "react";
import { Drawer, Table } from "antd";
import { useDispatch } from "react-redux";

const columns = [
  {
    title: "Transaction No",
    dataIndex: "transactionNo",
    width: 50,
    align: "center",
  },
  {
    title: "Receipt",
    dataIndex: "receipt",
    width: 50,
    align: "center",
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
    width: 250,
    align: "center",
  },
  {
    title: "Paid Amount",
    dataIndex: "paidAmount",
    width: 100,
    align: "center",
  },
  {
    title: "Deposited Amount",
    dataIndex: "depositedAmount",
    width: 200,
    align: "center",
  },
  {
    title: "Pay Mode",
    dataIndex: "payMode",
    width: 200,
    align: "center",
  },
  {
    title: "Cheque No.",
    dataIndex: "chequeNo",
    width: 100,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 200,
    align: "center",
  },
  {
    title: "Bank Dep. Date",
    dataIndex: "bankDepDate",
    width: 150,
    align: "center",
  },

  {
    title: "Refund Amount",
    dataIndex: "refundAmount",
    width: 150,
    align: "center",
  },
];

const agentData = [
  {
    id: "1",
    transactionNo: "120",
    receipt: "1127",
    paymentDate: "12-04-2021",
    paidAmount: "900.00",
    depositedAmount: "900.00",
    payMode: "Bank Deposit",
    chequeNo: "",
    remarks: "Paid",
    bankDepDate: "12-04-2021",
    refundAmount: "0",
  },
];

const SchedulePaymentInfo = ({
  searchBy,
  isSchedulePaymentInfoModalVisible,
  setIsSchedulePaymentInfoModalVisible,
}) => {
  const dispatch = useDispatch();

  let data = agentData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      transactionNo: dataObj?.transactionNo,
      receipt: dataObj?.receipt,
      paymentDate: dataObj?.paymentDate,
      paidAmount: dataObj?.paidAmount,
      depositedAmount: dataObj?.depositedAmount,
      payMode: dataObj?.payMode,
      chequeNo: dataObj?.chequeNo,
      remarks: dataObj?.remarks,
      bankDepDate: dataObj?.bankDepDate,
      refundAmount: dataObj?.refundAmount,
    };
  });

  return (
    <Drawer
      title="Process Payments"
      open={isSchedulePaymentInfoModalVisible}
      onClose={() => {
        setIsSchedulePaymentInfoModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 1200,
          }}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </div>
    </Drawer>
  );
};

export default SchedulePaymentInfo;
