import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const UpdateVaccinationDetail = ({
  isUpdateVaccinationDetailModalVisible,
  setIsUpdateVaccinationDetailModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const UpdateVaccinationDetailSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsUpdateVaccinationDetailModalVisible(false);
  };
  return (
    <Drawer
      title="Update Vaccination Detail"
      open={isUpdateVaccinationDetailModalVisible}
      onClose={() => {
        setIsUpdateVaccinationDetailModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateVaccinationDetailSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <Form.Item
          label="Vaccination Type"
          name={"vaccinationtype"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a vaccination type!",
            },
          ]}
        >
          <Select>
            <Option>COVID-19</Option>
            <Option>Influenza</Option>
            <Option>Rabies</Option>
            <Option>Tetanus</Option>
            <Option>Tuberculosis</Option>
          </Select>
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Proof of Vaccination"
            name={"proofOfVaccination"}
            style={{ width: "100%" }}
          >
            <Input type="file" />
          </Form.Item>
          <Form.Item
            label="Document Type"
            name={"documentType"}
            style={{ width: "100%" }}
          >
            <Select>
              <Option>Government Letter</Option>
              <Option>Doctor </Option>
              <Option>Certificate</Option>
              <Option>Statement</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Document Number"
            name={"documentNumber"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Document Number" />
          </Form.Item>
          <Form.Item
            label="Issued Date"
            name={"issuedDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Issued Date"
            name={"issuedDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Issued By"
            name={"issuedBy"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Issued By" />
          </Form.Item>
        </div>
        <Form.Item
          label="Issued Country"
          name={"issuedCountry"}
          style={{ width: "100%" }}
        >
          <Select placeholder="Issued Country"></Select>
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Vaccination Detail"
          >
            Update Vaccination Detail
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateVaccinationDetail;
