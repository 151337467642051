import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ButtonElement } from "../../../../components/input";
import { AiOutlinePlus } from "react-icons/ai";
import AddService from "./Service/AddService";
const { Option } = Select;
const { TextArea } = Input;

const UpdateSelectedOfferInfo = ({
  isUpdateSelectedOfferInfoModalVisible,
  setIsUpdateSelectedOfferInfoModalVisible,
}) => {
  const [isAddServiceModalVisible, setIsAddServiceModalVisible] =
    useState(false);
  const [aboutUs, setAboutUs] = useState("PRESS_AND_PRINT_MEDIA");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateSelectedOfferInfoSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UPDATE_STUDENT_COURSE_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateSelectedOfferInfoModalVisible(false);
  };
  return (
    <Drawer
      title="Update Offer Info"
      open={isUpdateSelectedOfferInfoModalVisible}
      onClose={() => {
        setIsUpdateSelectedOfferInfoModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateSelectedOfferInfoSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Service"
              name={"service"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a service!",
                },
              ]}
            >
              <Checkbox.Group
                defaultValue={"AIRPORT_PICKUP"}
                placeholder="Offer Id"
              >
                <Checkbox value="AIRPORT_PICKUP">Airport Pickup</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddServiceModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            name={"requestOHSC"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a facility!",
            //   },
            // ]}
          >
            <Checkbox>Request OHSC</Checkbox>
          </Form.Item>
          <Form.Item
            label="OSHC Provider"
            name={"OSHCProvider"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a OSHC provider!",
            //   },
            // ]}
          >
            <Select placeholder="OSHC Provider"></Select>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="OSHC Type"
            name={"oshcType"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select OSHC type!",
            //   },
            // ]}
          >
            <Select placeholder="OSHC Type"></Select>{" "}
          </Form.Item>
          <Form.Item
            label="Duration (in months)"
            name={"duration"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a duration !",
            //   },
            // ]}
          >
            <Input type="number" placeholder="Duration" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Applied OSHC Fee (OSHC Fee: 0)"
            name={"appliedOshcFee"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter a applied OSHC fee !",
            //   },
            // ]}
          >
            <Input type="number" placeholder="Duration" />
          </Form.Item>
          <Form.Item
            label="OSHC Start Date"
            name={"oshcStartDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a OSHC start date!",
            //   },
            // ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="How did you hear about us?"
            name={"aboutUs"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a about us !",
            //   },
            // ]}
          >
            <Radio.Group
              onChange={(e) => {
                setAboutUs(e.target.value);
              }}
            >
              <Radio value={"PRESS_AND_PRINT_MEDIA"}>Press & Print media</Radio>
              <Radio value={"INTERNET"}>Internet</Radio>
              <Radio value={"AGENT"}>Agent</Radio>
              <Radio value={"FRIEND"}>Friend</Radio>
              <Radio value={"OTHER"}>Other</Radio>
            </Radio.Group>
          </Form.Item>
          {aboutUs === "AGENT" ? (
            <Form.Item
              label="Agent Name"
              name={"agentName"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please pick a finish date!",
              //   },
              // ]}
            >
              <Input placeholder="Agent Name" />
            </Form.Item>
          ) : (
            <Form.Item
              label="Please specify"
              name={"pleaseSpecify"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please pick a finish date!",
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          )}
        </div>
        {aboutUs === "AGENT" && (
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="E-Mail / Mailing Address"
              name={"email"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please pick a E-Mail / Mailing Address!",
              //   },
              // ]}
            >
              <TextArea placeholder="Write mailing address or email" />
            </Form.Item>
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter COE number!",
              //   },
              // ]}
            >
              <Select placeholder="Country">
                <Option>Australia</Option>
                <Option>Nepal</Option>
              </Select>
            </Form.Item>
          </div>
        )}

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Offer Info"
          >
            Update Offer Info
          </ButtonElement>
        </Form.Item>
      </Form>
      {isAddServiceModalVisible && (
        <AddService
          isAddServiceModalVisible={isAddServiceModalVisible}
          setIsAddServiceModalVisible={setIsAddServiceModalVisible}
        />
      )}
    </Drawer>
  );
};

export default UpdateSelectedOfferInfo;
