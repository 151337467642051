import { Card, Divider } from "antd";
import React from "react";

const StudentProfile = () => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fxRJsb">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      {detailFun("Username", "Not Created")}
      {detailFun("Nickname", "Sabin")}
      {detailFun("College Email", "alex@opera.nsw.edu.au")}
      {detailFun("Gender", "Male")}
      {detailFun("Origin", "Overseas Student in Australia")}
      {detailFun("Nationality", "Australian")}
      {detailFun("Visa Type", "Student Visa")}
      {detailFun("Passport No", "123456")}
      {detailFun("Date of Birth", "21/05/2000")}
      {detailFun("Age", "23 yr. 2 m.")}
      {detailFun("Phone", "Not Provided")}
      {detailFun("Mobile", "9659865996")}
      {detailFun("Test Score", "")}
      {detailFun("English Test Type", "")}
      {detailFun("Account Manager", "")}
      {detailFun("Student Status", "ACTIVE")}
    </Card>
  );
};

export default StudentProfile;
