import React, { useState } from "react";
import { Button, Checkbox, Drawer, Form, Input, Select, Steps } from "antd";
import { ButtonElement } from "../../../../components/input";
import ShortCourseApplicantPersonalInformation from "./ShortCourseApplicantPersonalInformation";
import ShortCourseApplicantCurrentStreetAddress from "./ShortCourseApplicantCurrentStreetAddress";
import ShortCourseApplicantCourseInformation from "./ShortCourseApplicantCourseInformation";
const { Option } = Select;

const AddApplicantsForShortCourse = ({
  isAddApplicantsForShortCourseModalVisible,
  setIsAddApplicantsForShortCourseModalVisible,
  setIsChooseCourseApplicationModalVisible,
  record,
}) => {
  //   const dispatch = useDispatch();
  const [applicantData, setApplicantData] = useState({
    title: record?.title,
    firstName: record?.firstName,
    middleName: record?.middleName,
    lastName: record?.lastName,
    preferredName: record?.preferredName,
    gender: "MALE",
    dateOfBirth: record?.dateOfBirth,
    email: record?.email,
    beforeEachirthplace: record?.beforeEachirthplace,
    countryOfBirth: record?.countryOfBirth,
    nationality: record?.nationality,
    passportNo: record?.passportNo,
    expiryDate: record?.expiryDate,
    visaStatus: record?.visaStatus,
    visaNumber: record?.visaNumber,
    visaExpiryDate: record?.visaExpiryDate,
    residencyStatus: record?.residencyStatus,
    haveOverseasPermanentAddress: false,
    havePostalAddress: false,
    currentAddress: {
      country: "AUSTRALIA",
      buildingPropertyName: null,
      flatUnitDetails: null,
      streetNumber: null,
      streetName: null,
      suburb: null,
      state: null,
      state: null,
      postcode: null,
      homePhone: null,
      workPhone: null,
      mobilePhone: null,
    },
    postalAddress: {
      country: null,
      buildingPropertyName: null,
      flatUnitDetails: null,
      streetNumber: null,
      postalDeliveryInformation: null,
      streetName: null,
      suburb: null,
      state: null,
      state: null,
      postcode: null,
      homePhone: null,
      workPhone: null,
      mobilePhone: null,
    },
    overseasPermanentAddress: {
      country: null,
      buildingPropertyName: null,
      flatUnitDetails: null,
      streetNumber: null,
      streetName: null,
      suburb: null,
      state: null,
      state: null,
      postcode: null,
      homePhone: null,
      workPhone: null,
      mobilePhone: null,
    },
  });
  const [applicantCourseData, setApplicantCourseData] = useState({
    agent: null,
    agentContact: null,
    intake: null,
    numberOfWeeks: null,
    course: null,
    startDate: null,
    courseActualFee: null,
    enrollmentFee: null,
    upfrontFee: null,
    materialFee: null,
    status: "NEW_APPLICATION_REQUEST",
    applicationRequest: null,
    areApplyingForAdvancedStanding: false,
    applyingForAdvancedStandingSpecify: null,
    specialCondition: null,
    holidayStartDate: null,
    holidayEndDate: null,
    studentOrientationDate: null,
    totalHolidayWeeks: null,
  });

  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);

  const addApplicatnHandler = () => {
    // dispatch({ type: "ADD_APPLICANT_REQUEST", payload: { ...value } });
    form.resetFields();
    setIsAddApplicantsForShortCourseModalVisible(false);
    console.log("Will be added when api is provided");
  };
  const forms = [
    <ShortCourseApplicantPersonalInformation
      applicantData={applicantData}
      setApplicantData={setApplicantData}
      current={current}
      setCurrent={setCurrent}
    />,
    <ShortCourseApplicantCurrentStreetAddress
      applicantData={applicantData}
      setApplicantData={setApplicantData}
      current={current}
      setCurrent={setCurrent}
    />,

    <ShortCourseApplicantCourseInformation
      applicantCourseData={applicantCourseData}
      setApplicantCourseData={setApplicantCourseData}
      current={current}
      setCurrent={setCurrent}
      addApplicatnHandler={addApplicatnHandler}
    />,
  ];

  console.log("short course ApplicantData", applicantData);
  console.log("short course applicantCourseData", applicantCourseData);

  return (
    <Drawer
      title={
        <div className="fxRJsb">
          <span>Student Online Application </span>
          <h3>Application reference ID: 1360401983</h3>
          <ButtonElement
            name="Save and Exit"
            handleClick={() => {
              setIsAddApplicantsForShortCourseModalVisible(false);
            }}
          />
        </div>
      }
      open={isAddApplicantsForShortCourseModalVisible}
      onClose={() => {
        setIsAddApplicantsForShortCourseModalVisible(false);
        setIsChooseCourseApplicationModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Personal Information",
            },
            {
              title: "Address Information",
            },
            {
              title: "Course Information",
            },
          ]}
        />
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default AddApplicantsForShortCourse;
