import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { BiBlock } from "react-icons/bi";
import AddService from "../../../Student/StudentDetails/StudentCourse/Service/AddService";
import AddFacility from "./AddFacility";
import UpdateServiceFacility from "./ServiceFacility/UpdateServiceFacility";
import UpdateFacility from "./UpdateFacility";

const columns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
    width: 100,
    align: "center",
  },
  {
    title: "Date Type",
    dataIndex: "dateType",
    width: 200,
    align: "center",
  },
  {
    title: "Status (Service)",
    dataIndex: "serviceStatus",
    width: 50,
    align: "center",
  },
  {
    title: "Allow Student to choose Service Provider",
    dataIndex: "allowStudentToChooseServiceProvider",
    width: 50,
    align: "center",
  },
  {
    title: "Internal Only",
    dataIndex: "internalOnly",
    width: 50,
    align: "center",
  },
  {
    title: "Address Required",
    dataIndex: "addressRequired",
    width: 50,
    align: "center",
  },
  {
    title: "Category",
    dataIndex: "category",
    width: 50,
    align: "center",
  },
  {
    title: "Facility Name",
    dataIndex: "facilityName",
    width: 50,
    align: "center",
  },
  {
    title: "Status (Facility)",
    dataIndex: "facilityStatus",
    width: 50,
    align: "center",
  },
  {
    title: "Price Type",
    dataIndex: "priceType",
    width: 50,
    align: "center",
  },
  {
    title: "Student Price",
    dataIndex: "studentPrice",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const ServiceSetupData = [
  {
    id: "1",
    serviceName: "Airport Pickup",
    dateType: "Date Time",
    serviceStatus: "ACTIVE",
    allowStudentToChooseServiceProvider: "YES",
    internalOnly: "NO",
    addressRequired: "YES",
    category: "Transport",
    facilityName: "Bus",
    facilityStatus: "ACTIVE",
    priceType: "Per Service",
    studentPrice: "150.00",
  },
  {
    id: "1",
    serviceName: "Airport Pickup",
    dateType: "Date Time",
    serviceStatus: "ACTIVE",
    allowStudentToChooseServiceProvider: "YES",
    internalOnly: "NO",
    addressRequired: "YES",
    category: "Transport",
    facilityName: "Taxi",
    facilityStatus: "ACTIVE",
    priceType: "Per Service",
    studentPrice: "200.00",
  },
];

const ServiceSetup = () => {
  const [isAddFacilityModalVisible, setIsAddFacilityModalVisible] =
    useState(false);
  const [isUpdateFacilityModalVisible, setIsUpdateFacilityModalVisible] =
    useState(false);
  const [
    isUpdateServiceFacilityModalVisible,
    setIsUpdateServiceFacilityModalVisible,
  ] = useState(false);

  const data = ServiceSetupData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      serviceName: dataObj?.serviceName,
      dateType: dataObj?.dateType,
      serviceStatus: dataObj?.serviceStatus,
      allowStudentToChooseServiceProvider:
        dataObj?.allowStudentToChooseServiceProvider,
      internalOnly: dataObj?.internalOnly,
      addressRequired: dataObj?.addressRequired,
      category: dataObj?.category,
      facilityName: dataObj?.facilityName,
      facilityStatus: dataObj?.facilityStatus,
      priceType: dataObj?.priceType,
      studentPrice: dataObj?.studentPrice,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Service Category">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateServiceFacilityModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Update Facility">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateFacilityModalVisible(true);
                }}
              >
                <EditFilled />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Facility">
            <Popconfirm
              title="Are you sure to delete this Facility?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Activate Selected Facility">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlinePoweroff />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Deactivate Selected Facility">
            <a style={{ color: "red" }}>
              <div className="bordered" onClick={() => {}}>
                <BiBlock />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Facility Setup</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddFacilityModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 0,
          }}
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddFacilityModalVisible && (
        <AddFacility
          isAddFacilityModalVisible={isAddFacilityModalVisible}
          setIsAddFacilityModalVisible={setIsAddFacilityModalVisible}
        />
      )}
      {isUpdateServiceFacilityModalVisible && (
        <UpdateServiceFacility
          isUpdateServiceFacilityModalVisible={
            isUpdateServiceFacilityModalVisible
          }
          setIsUpdateServiceFacilityModalVisible={
            setIsUpdateServiceFacilityModalVisible
          }
        />
      )}
      {isUpdateFacilityModalVisible && (
        <UpdateFacility
          isUpdateFacilityModalVisible={isUpdateFacilityModalVisible}
          setIsUpdateFacilityModalVisible={setIsUpdateFacilityModalVisible}
        />
      )}
    </div>
  );
};

export default ServiceSetup;
