import { Button } from "antd";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

const AddButton = ({ children, handleClick = () => {} }) => {
  return (
    <Button
      type="primary"
      className="ant-primary-btn addbtn"
      icon={
        <AiOutlinePlus
          style={{
            fontSize: "19px",
          }}
        />
      }
      style={{
        padding: "4px 15px",
        borderRadius: "3px",
      }}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};

export default AddButton;
