import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdateStudentScholarship = ({
  isUpdateStudentScholarshipModalVisible,
  setIsUpdateStudentScholarshipModalVisible,
}) => {
  const [scholarshipData, setScholarshipData] = useState({
    scholarshipFor: "STUDENT",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateStudentScholarshipSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateStudentScholarship_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateStudentScholarshipModalVisible(false);
  };
  return (
    <Drawer
      title="Update Student Scholarship"
      open={isUpdateStudentScholarshipModalVisible}
      onClose={() => {
        setIsUpdateStudentScholarshipModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateStudentScholarshipSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Scholarship For"
            name={"scholarshipFor"}
            style={{ width: "100%" }}
          >
            <Radio.Group
              placeholder=""
              onChange={(e) => {
                setScholarshipData((prevData) => {
                  return {
                    ...prevData,
                    scholarshipFor: e.target.value,
                  };
                });
              }}
            >
              <Radio value={"STUDENT"}>Student</Radio>
              <Radio value={"COURSE"}>Course</Radio>
            </Radio.Group>
          </Form.Item>
          {scholarshipData.scholarshipFor === "STUDENT" && (
            <Form.Item
              label="Course"
              name={"course"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a course!",
                },
              ]}
            >
              <Select placeholder="course">
                <Option>GE: General English- 12-04-2021 - Enrolled</Option>
                <Option>IELTS: IELTS Preparation- 12-04-2021 - Enrolled</Option>
              </Select>
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Scholarship date"
            name={"ScholarshipDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick scholarship date",
              },
            ]}
          >
            <DatePicker placeholder="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="Scholarship amount"
            name={"scholarshipAmount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a scholarship amount",
              },
            ]}
          >
            <Input type="number" placeholder="Scholarship Amount" />
          </Form.Item>
        </div>
        <Form.Item label="Description" name={"description"}>
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Student Scholarship"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateStudentScholarship;
