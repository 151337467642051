import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import { Input, Popconfirm, Radio, Space, Table, Tooltip } from "antd";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../components/input";
import Accounts from "../Accounts";
import AddButton from "../../../components/button/AddButton";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineSetting,
} from "react-icons/ai";
import AddLedgerValue from "./LedgerValue/AddLedgerValue";
import UpdateLedgerValue from "./LedgerValue/UpdateLedgerValue";
import AddLedgerAccount from "./LedgerAccount/AddLedgerAccount";
import UpdateLedgerAccount from "./LedgerAccount/UpdateLedgerAccount";
import AddPaymentMode from "./SetupPaymentMode/AddPaymentMode";
import UpdatePaymentMode from "./SetupPaymentMode/UpdatePaymentMode";
const { Search } = Input;
const columns = [
  {
    title: "Course Code",
    dataIndex: "courseCode",
    width: 50,
    align: "center",
  },
  {
    title: "Fee Name",
    dataIndex: "feeName",
    width: 150,
    align: "center",
  },
  {
    title: "Student Origin",
    dataIndex: "studentOrigin",
    width: 200,
    align: "center",
  },
  {
    title: "Value",
    dataIndex: "value",
    width: 100,
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: 50,
    align: "center",
  },
  {
    title: "Is GST Inc?",
    dataIndex: "isGstInc",
    width: 50,
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: 250,
    align: "center",
  },
  {
    title: "Receivable A/C",
    dataIndex: "receivableAc",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];
const ledgerAccountColumns = [
  {
    title: "Account Type",
    dataIndex: "accountType",
    width: 100,
    align: "center",
  },
  {
    title: "Account Value",
    dataIndex: "accountValue",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];
const paymentModeColumns = [
  {
    title: "Mode Name",
    dataIndex: "modeName",
    width: 100,
    align: "center",
  },
  {
    title: "Details",
    dataIndex: "Details",
    width: 150,
    align: "center",
  },
  {
    title: "Default Mode",
    dataIndex: "defaultMode",
    width: 150,
    align: "center",
  },
  {
    title: "Reference Required",
    dataIndex: "referenceRequired",
    width: 150,
    align: "center",
  },
  {
    title: "Bank Account",
    dataIndex: "bankAccount",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const studentOrigin = [
  {
    label: "All Students",
    value: "allStudents",
  },
  {
    label: "Overseas Student in Australia",
    value: "overseasStudentInAustralia",
  },
  {
    label: "Overseas Student",
    value: "overseasStudent",
  },
  {
    label: "Resident Student",
    value: "residentStudent",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];
const ledgerValueData = [
  {
    id: "1",
    courseCode: "ELICOS",
    feeName: "Tuition Fee",
    studentOrigin: "All Students",
    value: "123",
    amount: "0.00",
    isGstInc: "Yes",
    description: "",
    receivableAc: "",
  },
  {
    id: "2",
    courseCode: "ELICOS",
    feeName: "Enrolment Fees",
    studentOrigin: "Overseas Student in Australia",
    value: "0",
    amount: "250.00",
    isGstInc: "Yes",
    description: "",
    receivableAc: "0185878787885",
  },
];
const ledgerAccountData = [
  {
    id: "1",
    accountType: "Bank Account",
    accountValue: "1234567",
  },
];
const paymentModedata = [
  {
    id: "1",
    modeName: "Credit Card",
    Details: "Credit Card",
    defaultMode: "Yes",
    referenceRequired: "Yes",
    bankAccount: "212345578875",
  },
  {
    id: "2",
    modeName: "Bank Deposit",
    Details: "Bank Deposit",
    defaultMode: "Yes",
    referenceRequired: "Yes",
    bankAccount: "986565115",
  },
];
const staffMailData = [
  {
    id: "13",
    title: "Mr",
    firstName: "Raju",
    lastName: "Manandhar 2",
    position: "Teacher",
    privateEmail: "manandhar44771@gmail.com",
    collegeEmail: "OPS00001015@opera.nsw.edu.au",
    mailStatus: "",
  },
];

const AccountsSetUp = () => {
  const [searchBy, setSearchBy] = useState("offerId");
  const [isAddLedgerValueModalVisible, setIsAddLedgerValueModalVisible] =
    useState(false);
  const [isUpdateLedgerValueModalVisible, setIsUpdateLedgerValueModalVisible] =
    useState(false);
  const [isAddLedgerAccountModalVisible, setIsAddLedgerAccountModalVisible] =
    useState(false);
  const [
    isUpdateLedgerAccountModalVisible,
    setIsUpdateLedgerAccountModalVisible,
  ] = useState(false);
  const [isAddPaymentModeModalVisible, setIsAddPaymentModeModalVisible] =
    useState(false);
  const [isUpdatePaymentModeModalVisible, setIsUpdatePaymentModeModalVisible] =
    useState(false);

  const [accountSetUpType, setAccountSetUpType] = useState("LEDGER_VALUE");
  let ledgerValData = ledgerValueData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      courseCode: dataObj?.courseCode,
      feeName: dataObj?.feeName,
      studentOrigin: dataObj?.studentOrigin,
      value: dataObj?.value,
      amount: dataObj?.amount,
      isGstInc: dataObj?.isGstInc,
      description: dataObj?.description,
      receivableAc: dataObj?.receivableAc,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Update Ledger Value">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateLedgerValueModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Ledger Value">
            <Popconfirm
              title="Are you sure to delete this selected ledger value?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  let ledgerAccdata = ledgerAccountData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      accountType: dataObj?.accountType,
      accountValue: dataObj?.accountValue,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Update Accunt Value">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateLedgerAccountModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Account Value">
            <Popconfirm
              title="Are you sure to delete this selected account value?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  let paymentModData = paymentModedata.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      modeName: dataObj?.modeName,
      Details: dataObj?.Details,
      defaultMode: dataObj?.defaultMode,
      referenceRequired: dataObj?.referenceRequired,
      bankAccount: dataObj?.bankAccount,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Update Payment Mode">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdatePaymentModeModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Set as Default">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineSetting />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Payment Mode">
            <Popconfirm
              title="Are you sure to delete this selected payment mode?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });

  return (
    <Accounts tab={"/accounts-set-up"}>
      {accountSetUpType === "LEDGER_VALUE" && (
        <ComponentBody>
          <div className="fxRJs" style={{ gap: "0.4rem", flexWrap: "wrap" }}>
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Fee Type:</div>
              <SelectField
                name="feeType"
                placeholder="Fee Type"
                options={[
                  {
                    label: "Tution Fee",
                    value: "TUTION_FEE",
                  },
                  {
                    label: "Miscellaneous Fee",
                    value: "MISCELLANEOUS_FEE",
                  },
                ]}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="12rem"
              />
            </div>
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Course Type:</div>
              <SelectField
                name="courseType"
                placeholder="Course Type"
                options={[
                  {
                    label: "ELICOS",
                    value: "elicos",
                  },
                  {
                    label: "No Course Type",
                    value: "noCourseType",
                  },
                ]}
                fieldWidth="9rem"
              />
            </div>
          </div>
        </ComponentBody>
      )}
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>
              {accountSetUpType === "LEDGER_VALUE"
                ? "Set-Up Payment Account Number (Ledger Value)"
                : accountSetUpType === "LEDGER_ACCOUNT"
                ? "Set-Up Payment Account Number (Ledger Value)"
                : "Set-Up Payment Mode (Method of Payment)"}
            </Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <AddButton
                handleClick={() => {
                  if (accountSetUpType === "LEDGER_VALUE") {
                    setIsAddLedgerValueModalVisible(true);
                  } else if (accountSetUpType === "LEDGER_ACCOUNT") {
                    setIsAddLedgerAccountModalVisible(true);
                  } else {
                    setIsAddPaymentModeModalVisible(true);
                  }
                }}
              >
                Add
              </AddButton>
            </div>
          </div>

          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Radio.Group
              defaultValue="LEDGER_VALUE"
              value={accountSetUpType}
              onChange={(e) => {
                setAccountSetUpType(e.target.value);
              }}
              style={{ width: "100%" }}
            >
              <Radio.Button
                value="LEDGER_VALUE"
                style={{ borderRadius: "3px" }}
              >
                Ledger Value
              </Radio.Button>
              <Radio.Button
                value="LEDGER_ACCOUNT"
                style={{ borderRadius: "3px" }}
              >
                Ledger Account
              </Radio.Button>
              <Radio.Button
                value="SETUP_PAYMENT_MODE"
                style={{ borderRadius: "3px" }}
              >
                Setup Payment Mode
              </Radio.Button>
            </Radio.Group>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {accountSetUpType === "LEDGER_VALUE" ? (
            <Table
              dataSource={ledgerValData}
              bordered={true}
              columns={columns}
              pagination={false}
            />
          ) : accountSetUpType === "LEDGER_ACCOUNT" ? (
            <Table
              dataSource={ledgerAccdata}
              bordered={true}
              columns={ledgerAccountColumns}
              pagination={false}
            />
          ) : (
            <Table
              dataSource={paymentModData}
              bordered={true}
              columns={paymentModeColumns}
              pagination={false}
            />
          )}
        </div>
      </ComponentBody>
      {isAddLedgerValueModalVisible && (
        <AddLedgerValue
          isAddLedgerValueModalVisible={isAddLedgerValueModalVisible}
          setIsAddLedgerValueModalVisible={setIsAddLedgerValueModalVisible}
        />
      )}
      {isUpdateLedgerValueModalVisible && (
        <UpdateLedgerValue
          isUpdateLedgerValueModalVisible={isUpdateLedgerValueModalVisible}
          setIsUpdateLedgerValueModalVisible={
            setIsUpdateLedgerValueModalVisible
          }
        />
      )}
      {isAddLedgerAccountModalVisible && (
        <AddLedgerAccount
          isAddLedgerAccountModalVisible={isAddLedgerAccountModalVisible}
          setIsAddLedgerAccountModalVisible={setIsAddLedgerAccountModalVisible}
        />
      )}
      {isUpdateLedgerAccountModalVisible && (
        <UpdateLedgerAccount
          isUpdateLedgerAccountModalVisible={isUpdateLedgerAccountModalVisible}
          setIsUpdateLedgerAccountModalVisible={
            setIsUpdateLedgerAccountModalVisible
          }
        />
      )}
      {isAddPaymentModeModalVisible && (
        <AddPaymentMode
          isAddPaymentModeModalVisible={isAddPaymentModeModalVisible}
          setIsAddPaymentModeModalVisible={setIsAddPaymentModeModalVisible}
        />
      )}
      {isUpdatePaymentModeModalVisible && (
        <UpdatePaymentMode
          isUpdatePaymentModeModalVisible={isUpdatePaymentModeModalVisible}
          setIsUpdatePaymentModeModalVisible={
            setIsUpdatePaymentModeModalVisible
          }
        />
      )}
    </Accounts>
  );
};

export default AccountsSetUp;
