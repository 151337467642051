import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddStaffProfessionalDevelopment = ({
  isAddStaffProfessionalDevelopmentModalVisible,
  setIsAddStaffProfessionalDevelopmentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const AddStaffProfessionalDevelopmentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsAddStaffProfessionalDevelopmentModalVisible(false);
  };
  return (
    <Drawer
      title="Add Professional Development Activity"
      open={isAddStaffProfessionalDevelopmentModalVisible}
      onClose={() => {
        setIsAddStaffProfessionalDevelopmentModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddStaffProfessionalDevelopmentSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Select Category"
            name={"selectCategory"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course!",
              },
            ]}
          >
            <Select>
              <Option>Conference</Option>
              <Option>Workshop</Option>
              <Option>Seminar</Option>
              <Option>Webinar</Option>
              <Option>Exhibition/Trade Shows</Option>
              <Option>Professional Reading</Option>
              <Option>Industry Involvement</Option>
              <Option>Training</Option>
              <Option>Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Event Name"
            name={"eventName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a event name!",
              },
            ]}
          >
            <Input placeholder="Event Name" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Organized By"
            name={"organizedBy"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a event organizer!",
              },
            ]}
          >
            <Input placeholder="Event Organizer" />
          </Form.Item>
          <Form.Item
            label="Activity Name"
            name={"activityName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a activity name!",
              },
            ]}
          >
            <Input placeholder="Event Organizer" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Event Started On"
            name={"eventStartedOn"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please pick a event started date!",
            //     },
            //   ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Event Ended On"
            name={"eventEndedOn"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please pick a event ended date!",
            //     },
            //   ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>

        <Form.Item
          label="CPD Points"
          name={"cpdPoints"}
          style={{ width: "100%" }}
        >
          <Input type="number" placeholder="CPD Points" />
        </Form.Item>
        <Form.Item label="Comments" name={"comments"} style={{ width: "100%" }}>
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add an Activity"
          >
            Add an Activity
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddStaffProfessionalDevelopment;
