import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;

const UpdateServiceFacility = ({
  isUpdateServiceFacilityModalVisible,
  setIsUpdateServiceFacilityModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateServiceFacilitySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateServiceFacility_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateServiceFacilityModalVisible(false);
  };
  return (
    <Drawer
      title="Update Service Facility"
      open={isUpdateServiceFacilityModalVisible}
      onClose={() => {
        setIsUpdateServiceFacilityModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateServiceFacilitySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Name"
            name={"serviceName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a service name!",
              },
            ]}
          >
            <Input placeholder="Service Name" />
          </Form.Item>
          <Form.Item
            label="Date Type"
            name={"dateType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a date type!",
              },
            ]}
          >
            <Select placeholder="Date Type">
              <Option value="DATE_ONLY">Date Only</Option>
              <Option value="DATE_Time">Date Time</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            name={"allowStudentToChooseServiceProvider"}
            style={{ width: "100%" }}
            valuePropName="checked"
          >
            <Checkbox>
              Allow student to choose service provider for this service
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={"internalOnly"}
            style={{ width: "100%" }}
            valuePropName="checked"
          >
            <Checkbox>Internal Only</Checkbox>
          </Form.Item>
        </div>
        <Form.Item
          name={"studentAddressIsRequired"}
          style={{ width: "100%" }}
          valuePropName="checked"
        >
          <Checkbox>Student Address is Required</Checkbox>
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Service Facility"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateServiceFacility;
