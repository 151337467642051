import { Form } from "antd";
import React from "react";
import {
  ButtonElement,
  InputFieldElement,
  SelectField,
} from "../../../components/input";
import InputNumberField from "../../../components/inputNumberField/InputNumberField";

export default function CompanyDetailsComponent({
  setCurrent,
  setFormData,
  formData,
}) {
  const organisationTypeList = [
    {
      label: "School Government",
      value: 1,
    },
    {
      label: "School Catholic",
      value: 2,
    },
  ];
  const industryTypeList = [
    {
      label: "Tertiary Education",
      value: 1,
    },
    {
      label: "PreSchool and School Education",
      value: 2,
    },
  ];

  const stateList = [
    {
      label: "NSW",
      value: 1,
    },
    {
      label: "NT",
      value: 2,
    },
  ];

  const handleSubmitValue = (values) => {
    setCurrent((prev) => prev + 1);
    setFormData((prev) => {
      return {
        ...prev,
        rto_code: values?.rto_code,
        cricos_code: values?.cricos_code,
        college_name: values?.college_name,
        legal_name: values?.legal_name,
        oganisation_type: values?.organisation_type,
        contact_person: values?.contact_person,
        abn: values?.abn,
        telephone: values?.telephone,
        fax: values?.fax,
        contact_email: values?.fax,
        it_email: values?.it_email,
        marketing_email: values?.marketing_email,
        academic_email: values?.academic_email,
        admissions_email: values?.admissions_email,
        accounts_email: values?.accounts_email,
        other_email: values?.other_email,
        college_url: values?.college_url,
        college_email_setup: values?.college_email_setup,
        type_of_industry: values?.type_of_industry,

        college_address: values?.college_address,
        college_suburb: values?.college_suburb,
        college_state: values?.college_state,
        college_postcode: values?.college_postcode,

        postal_address: values?.postal_address,
        postal_suburb: values?.postal_suburb,
        postal_state: values?.postal_state,
        postal_postcode: values?.postal_postcode,
      };
    });
  };
  return (
    <Form
      initialValues={{ ...formData }}
      onFinish={handleSubmitValue}
      layout="vertical"
      className="flex flex-col gap-4"
    >
      <div className="relative border grid grid-cols-1 lg:grid-cols-2 gap-4 border-[#00798a50] rounded-[0.5rem] p-4 mt-3 ">
        <span className="absolute text-[1rem] left-4 top-[-0.8rem] px-2 bg-white text-[#00798a]">
          General Information
        </span>
        <span className="col-span-2">Auto Id: 1</span>
        <InputFieldElement
          name="rto_code"
          placeholder="RTO code"
          label="RTO Code"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="cricos_code"
          placeholder="CRICOS Code"
          label="CRICOS Code"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="college_name"
          placeholder="College Name"
          label="College Name"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="legal_name"
          placeholder="Legal Name"
          label="Legal Name"
          fieldWidth="100%"
        />
        <div className="md:col-span-2">
          <SelectField
            name="oganisation_type"
            placeholder="Training organisation type"
            label="Training organisation type"
            options={organisationTypeList}
            fieldWidth="100%"
          />
        </div>
        <InputFieldElement
          name="contact_person"
          placeholder="Contact person"
          label="Contact person"
          fieldWidth="100%"
        />
        <InputNumberField
          name="abn"
          placeholder="ABN"
          label="ABN"
          fieldWidth="100%"
        />
        <InputNumberField
          name="telephone"
          placeholder="Telephone"
          label="Telephone"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="fax"
          placeholder="Fax"
          label="Fax"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="contact_email"
          placeholder="Contact Email"
          label="Contact Email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="it_email"
          placeholder="IT Email"
          label="IT Email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="marketing_email"
          placeholder="Marketing Email"
          label="Marketing Email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="academic_email"
          placeholder="Academic email"
          label="Academic email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="admissions_email"
          placeholder="Admissions email"
          label="Admissions email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="accounts_email"
          placeholder="Accounts Email"
          label="Accounts Email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="other_email"
          placeholder="Other Email"
          label="Other Email"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="college_url"
          placeholder="College URL"
          label="College URL"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="college_email_setup"
          placeholder="College Email Setup"
          label="College Email Setup"
          fieldWidth="100%"
        />
        <SelectField
          name="type_of_industry"
          placeholder="Type of Industry          "
          label="Type of Industry          "
          options={industryTypeList}
          fieldWidth="100%"
        />
      </div>
      <div className="relative border grid grid-cols-1 lg:grid-cols-2 gap-4 border-[#00798a50] rounded-[0.5rem] p-4 mt-3 ">
        <span className="absolute text-[1rem] left-4 top-[-0.8rem] px-2 bg-white text-[#00798a]">
          College Street Address
        </span>
        <InputFieldElement
          name="college_address"
          placeholder="Address"
          label="Address"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="college_suburb"
          placeholder="Suburb"
          label="Suburb"
          fieldWidth="100%"
        />
        <SelectField
          name="college_state"
          placeholder="State"
          label="State"
          options={stateList}
          fieldWidth="100%"
        />
        <InputNumberField
          name="college_postcode"
          placeholder="Postcode"
          label="Postcode"
          fieldWidth="100%"
        />
      </div>

      <div className="relative border grid grid-cols-1 lg:grid-cols-2 gap-4 border-[#00798a50] rounded-[0.5rem] p-4 mt-3 ">
        <span className="absolute text-[1rem] left-4 top-[-0.8rem] px-2 bg-white text-[#00798a]">
          College Postal Address
        </span>
        <InputFieldElement
          name="postal_address"
          placeholder="Address"
          label="Address"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="postal_suburb"
          placeholder="Suburb"
          label="Suburb"
          fieldWidth="100%"
        />
        <SelectField
          name="postal_state"
          placeholder="State"
          label="State"
          options={stateList}
          fieldWidth="100%"
        />
        <InputNumberField
          name="postal_postcode"
          placeholder="Postcode"
          label="Postcode"
          fieldWidth="100%"
        />
      </div>
      <ButtonElement htmlType="submit" name="Next" />
    </Form>
  );
}
