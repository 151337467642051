import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const UpdateFacility = ({
  isUpdateFacilityModalVisible,
  setIsUpdateFacilityModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateFacilitySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateFacility_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateFacilityModalVisible(false);
  };
  return (
    <Modal
      title="Update Facility"
      open={isUpdateFacilityModalVisible}
      onCancel={() => {
        setIsUpdateFacilityModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={UpdateFacilitySubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Facility Name"
          name={"facilityName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a facility name",
            },
          ]}
        >
          <Input placeholder="Facility Name" />
        </Form.Item>
        <Form.Item
          label="Student Price"
          name={"studentPrice"}
          rules={[
            {
              required: true,
              message: "Please enter a student price!",
            },
          ]}
        >
          <Input number placeholder="Student Price" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Facility"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateFacility;
