import React, { useState } from "react";
import { Button, Checkbox, Drawer, Form, Input, Select, Steps } from "antd";
import CoursePaymentInfo from "./CoursePaymentInfo";
import PaymentInfo from "./PaymentInfo";
import ScheduleInfo from "./ScheduleInfo";

const AddInitialPayment = ({
  isStartInitialPaymentModalVisible,
  setIsStartInitialPaymentModalVisible,
  setIsChooseCourseApplicationModalVisible,
  record,
}) => {
  //   const dispatch = useDispatch();
  const [initialPaymentData, setInitialPaymentData] = useState({
    course: null,
    commissionPeriod: null,
    commissionRate: null,
    paymentType: null,
    appliedCommission: null,
    isGst: null,
    invoiceDueDate: null,
    invoiceDueDate: null,

    upfrontFeeToPay: null,
    paymentDate: null,
    upfrontFeePaid: null,
    bankDepositDate: null,
    paidDuration: null,
    durationType: null,
    PaymentMode: null,
    agentCommissionIsDeducted: null,
    agentInvoiceNumber: null,
    provideAgentBonus: null,
    bonusAmount: null,
    isGst: false,
    calculateCommissionWithoutAgentBonus: null,
    isEnrolmentFee: false,
    enrolmentFee: null,
    isMaterialFee: false,
    materialFee: null,
    isOshcFee: false,
    OSHCProvider: null,
    oshcType: null,
    durationInMonths: null,
    appliedOshcFee: null,
    oshcStartDate: null,
    isOtherServiceFees: false,
    accomodation: null,
    airportPickup: null,
    guardianship: null,
    homePlacement: null,
    applySurcharge: false,
    surchargeAmount: null,
    isAddBadDebtAmount: false,
    badDebtAmount: null,
    invoiceNo: null,
    remarks: null,

    generateNextPaymentSchedule: true,
    amount: null,
    noOfInstallments: null,
    frequency: null,
    frequencyType: "WEEK",
    invoiceType: "SAME_INVOICE_NO",
  });

  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);

  const addInitialPaymentHandler = () => {
    // dispatch({ type: "ADD_INITIAL_PAYMENT_REQUEST", payload: { ...value } });
    form.resetFields();
    setIsStartInitialPaymentModalVisible(false);
    console.log("Will be added when api is provided");
  };
  const forms = [
    <CoursePaymentInfo
      initialPaymentData={initialPaymentData}
      setInitialPaymentData={setInitialPaymentData}
      current={current}
      setCurrent={setCurrent}
    />,
    <PaymentInfo
      initialPaymentData={initialPaymentData}
      setInitialPaymentData={setInitialPaymentData}
      current={current}
      setCurrent={setCurrent}
    />,
    <ScheduleInfo
      initialPaymentData={initialPaymentData}
      setInitialPaymentData={setInitialPaymentData}
      current={current}
      setCurrent={setCurrent}
      addInitialPaymentHandler={addInitialPaymentHandler}
    />,
  ];

  console.log("short course initialPaymentData", initialPaymentData);

  return (
    <Drawer
      title={"Student Initial Payment"}
      open={isStartInitialPaymentModalVisible}
      onClose={() => {
        setIsStartInitialPaymentModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Course Payment Info",
            },
            {
              title: "Payment Information",
            },
            {
              title: "Schedule Information",
            },
          ]}
        />
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default AddInitialPayment;
