import React, { useState } from "react";
import { ButtonElement, Pagename } from "../../../../components/input";
import { Divider, Popconfirm, Space, Table, Tooltip } from "antd";
import moment from "moment";
import {
  AiOutlineDelete,
  AiOutlineDollar,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFileAdd,
  AiOutlineFilePdf,
  AiOutlineMail,
} from "react-icons/ai";
import { HiArrowUturnUp } from "react-icons/hi2";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../components/button/AddButton";
import SelectAlert from "../../../../components/SelectAlert/SelectAlert";
import ModifyAgentCommission from "./ModifyAgentCommission";

const agentCommissionColumn = [
  {
    title: "Transaction No.",
    dataIndex: "transactionNo",
    width: 150,
    align: "center",
  },
  {
    title: "Agent Invoice",
    dataIndex: "agentInvoice",
    width: 150,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 150,
    align: "center",
  },
  {
    title: "Comm. Payable (Exc. GST)",
    dataIndex: "commPayable",
    width: 150,
    align: "center",
  },
  {
    title: "GST",
    dataIndex: "gst",
    width: 250,
    align: "center",
  },
  {
    title: "Comm. Paid",
    dataIndex: "commPaid",
    width: 150,
    align: "center",
  },
  {
    title: "Mode",
    dataIndex: "mode",
    width: 150,
    align: "center",
  },
  {
    title: "CHQ#",
    dataIndex: "chq",
    width: 150,
    align: "center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    width: 150,
    align: "center",
  },
  {
    title: "Comm To Refund",
    dataIndex: "commToRefund",
    width: 150,
    align: "center",
  },
  {
    title: "GST To Refund",
    dataIndex: "gstToRefund",
    width: 150,
    align: "center",
  },
  {
    title: "Refund Amount",
    dataIndex: "refundAmount",
    width: 150,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 350,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 150,
    align: "center",
  },
];
const agentBonusColumn = [
  {
    title: "Transaction No",
    dataIndex: "transactionNo",
    width: 250,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 250,
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "type",
    width: 250,
    align: "center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    width: 250,
    align: "center",
  },
  {
    title: "GST",
    dataIndex: "gst",
    width: 100,
    align: "center",
  },
  {
    title: "Bonus/Rebate Amount",
    dataIndex: "bonusRebateAmount",
    width: 100,
    align: "center",
  },
];

const AgentCommissionRecordData = [
  {
    transactionNo: "120",
    agentInvoice: "",
    agentName: "Opera Education Group Pty Ltd",
    commPayable: "0",
    gst: "0",
    commPaid: "0",
    mode: "",
    chq: "",
    paidDate: "",
    commToRefund: "0",
    gstToRefund: "0",
    refundAmount: "0",
    remarks: "Last updated by: Anu Rani Regmi (anu) on 07/09/2023",
  },
];

const agentBonusRecordData = [
  {
    id: 1,
    transactionNo: "120",
    agentName: "Opera Education Group Pty Ltd",
    type: "Tuition Fee",
    paidDate: "	12/09/2023",
    gst: "1",
    bonusRebateAmount: "10",
  },
];

const AgentCommission = ({ formData }) => {
  const [isModifyTransactionModalVisible, setIsModifyTransactionModalVisible] =
    useState(false);

  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let agentCommissionData = AgentCommissionRecordData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      transactionNo: dataObj?.transactionNo,
      agentInvoice: dataObj?.agentInvoice,
      agentName: dataObj?.agentName,
      commPayable: dataObj?.commPayable,
      gst: dataObj?.gst,
      commPaid: dataObj?.commPaid,
      mode: dataObj?.mode,
      chq: dataObj?.chq,
      paidDate: dataObj?.paidDate,
      commToRefund: dataObj?.commToRefund,
      gstToRefund: dataObj?.gstToRefund,
      refundAmount: dataObj?.refundAmount,
      remarks: dataObj?.remarks,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "50px" }}
        >
          <Tooltip title="Modify Transaction">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsModifyTransactionModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  let agentBonusData = agentBonusRecordData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      transactionNo: dataObj?.transactionNo,
      agentName: dataObj?.agentName,
      type: dataObj?.type,
      paidDate: dataObj?.paidDate,
      gst: dataObj?.gst,
      bonusRebateAmount: dataObj?.bonusRebateAmount,
    };
  });

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fxRJsb">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem 0" }} />
      </div>
    );
  };
  return (
    <div className="grid grid-cols-1 gap-4 mt-8">
      <div className="flex flex-col gap-4">
        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Student Agent Commission Information
          </span>

          {detailFun("Total student paid amount", "$890.00")}
          {detailFun("Total agent commission payable (GST Inc.)", "$0.00")}
          {detailFun("Total commission paid amount (GST Inc.)", "$0.00")}
          {detailFun("Total commission to refund (GST Inc.) ", "$0.00")}
        </div>

        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Agent commission entries
          </span>

          <div
            className="fxC"
            style={{
              marginTop: "1rem",
            }}
          >
            <Table
              scroll={{
                x: 0,
              }}
              bordered
              dataSource={agentCommissionData}
              columns={agentCommissionColumn}
              pagination={false}
            />
          </div>
        </div>
        <div className="flex h-full flex-col gap-2 pt-6  p-4 rounded relative border">
          <span className="absolute top-[-1rem]  left-4 px-2 py-1 font-semibold text-[#00798a]  bg-white rounded-[4px]">
            Agent Bonus/Rebate Details
          </span>

          <div
            className="fxC"
            style={{
              marginTop: "1rem",
            }}
          >
            <Table
              scroll={{
                x: 0,
              }}
              bordered
              dataSource={agentBonusData}
              columns={agentBonusColumn}
              pagination={false}
            />
          </div>
        </div>
      </div>
      {isModifyTransactionModalVisible && (
        <ModifyAgentCommission
          isModifyAgentCommissionModalVisible={isModifyTransactionModalVisible}
          setIsModifyAgentCommissionModalVisible={
            setIsModifyTransactionModalVisible
          }
        />
      )}
    </div>
  );
};

export default AgentCommission;
