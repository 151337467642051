import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { BiBlock } from "react-icons/bi";
import AddServiceProvider from "./AddServiceProvider";
import UpdateServiceProvider from "./UpdateServiceProvider";

const columns = [
  {
    title: "Company Name",
    dataIndex: "companyName",
    width: 200,
    align: "center",
  },
  {
    title: "Contact Name",
    dataIndex: "contactName",
    width: 150,
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "address",
    width: 200,
    align: "center",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    width: 100,
    align: "center",
  },
  {
    title: "Fax",
    dataIndex: "fax",
    width: 100,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 150,
    align: "center",
  },
  {
    title: "Website",
    dataIndex: "website",
    width: 150,
    align: "center",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    width: 200,
    align: "center",
  },
  {
    title: "Internal Comment",
    dataIndex: "internalComment",
    width: 200,
    align: "center",
  },
  {
    title: "Other Details",
    dataIndex: "otherDetails",
    width: 200,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const ServiceProviderSetupData = [
  {
    id: "1",
    companyName: "ABC Home Stay Service",
    contactName: "Prabal Gurung",
    address: "TBA Bourke - NSW Australia",
    phone: "9862652684",
    fax: "54936556",
    email: "abchomestay@gmail.com",
    website: "www.abchomestay.com",
    comment: "Homestay Service inner suburb",
    internalComment: "",
    otherDetails: "open@7:00 am",
    status: "Active",
  },
];

const ServiceProviderSetup = () => {
  const [
    isAddServiceProviderModalVisible,
    setIsAddServiceProviderModalVisible,
  ] = useState(false);
  const [
    isUpdateServiceProviderModalVisible,
    setIsUpdateServiceProviderModalVisible,
  ] = useState(false);
  const [isUpdateFacilityModalVisible, setIsUpdateFacilityModalVisible] =
    useState(false);
  const [
    isUpdateServiceFacilityModalVisible,
    setIsUpdateServiceFacilityModalVisible,
  ] = useState(false);

  const data = ServiceProviderSetupData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      companyName: dataObj?.companyName,
      contactName: dataObj?.contactName,
      address: dataObj?.address,
      phone: dataObj?.phone,
      fax: dataObj?.fax,
      email: dataObj?.email,
      website: dataObj?.website,
      comment: dataObj?.comment,
      internalComment: dataObj?.internalComment,
      otherDetails: dataObj?.otherDetails,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Service Provider Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateServiceProviderModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Delete Service Provider">
            <Popconfirm
              title="Are you sure to delete this Provider?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Activate Selected Selected Service Provider">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlinePoweroff />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Deactivate Selected Service Provider">
            <a style={{ color: "red" }}>
              <div className="bordered" onClick={() => {}}>
                <BiBlock />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Service Provider Setup</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddServiceProviderModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 0,
          }}
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddServiceProviderModalVisible && (
        <AddServiceProvider
          isAddServiceProviderModalVisible={isAddServiceProviderModalVisible}
          setIsAddServiceProviderModalVisible={
            setIsAddServiceProviderModalVisible
          }
        />
      )}
      {isUpdateServiceProviderModalVisible && (
        <UpdateServiceProvider
          isUpdateServiceProviderModalVisible={
            isUpdateServiceProviderModalVisible
          }
          setIsUpdateServiceProviderModalVisible={
            setIsUpdateServiceProviderModalVisible
          }
        />
      )}
    </div>
  );
};

export default ServiceProviderSetup;
