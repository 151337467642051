import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../../components/input";
const { Option } = Select;

const UploadCertificate = ({
  isUploadCertificateModalVisible,
  setIsUploadCertificateModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UploadCertificateSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_CERTIFICATE_REGISTER_REQUEST", payload: formData });
    // form.resetFields();
    setIsUploadCertificateModalVisible(false);
  };
  return (
    <Modal
      title="Upload Certificate"
      open={isUploadCertificateModalVisible}
      onCancel={() => {
        setIsUploadCertificateModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={UploadCertificateSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Certificate File"
          name={"certificateFile"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a file!",
            },
          ]}
        >
          <Input type="file" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Upload Certificate"
          >
            Upload Certificate
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadCertificate;
