import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Tabs,
} from "antd";
import StudentSubjectEnrollment from "./StudentSubjectEnrollment/StudentSubjectEnrollment";
import CertificateRegister from "./CertificateRegister/CertificateRegister";
import AdvancedStanding from "./AdvancedStanding/AdvancedStanding";
const { Option } = Select;

const EnrollSubjectForCourse = ({
  isEnrollSubjectForCouseModalVisible,
  setIsEnrollSubjectForCouseModalVisible,
}) => {
  const [current, setCurrent] = useState("studentSubjectEnrolment");
  const enrollCourseSubjectTabList = [
    {
      key: "studentSubjectEnrolment",
      label: "Student Subject Enrolment",
    },
    {
      key: "advancedStanding",
      label: "Advanced Standing",
    },
    {
      key: "certificateRegister",
      label: "Certificate Register",
    },
    // {
    //   key: "elicosCertificate",
    //   label: "ELICOS Certificate",
    // },
    // {
    //   key: "finishDate",
    //   label: "finishDate",
    // },
  ];
  return (
    <Drawer
      title="Student Subject Enrolment (set-up Study Plan)"
      open={isEnrollSubjectForCouseModalVisible}
      onClose={() => {
        setIsEnrollSubjectForCouseModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Card style={{ borderRadius: "3px" }} className="drawerStyle">
        <Tabs
          defaultActiveKey="profile"
          items={enrollCourseSubjectTabList}
          activeTabKey={current}
          onChange={(key) => {
            console.log("current key", key);
            setCurrent(key);
          }}
        />
        {current === "studentSubjectEnrolment" && (
          <>
            <StudentSubjectEnrollment />
          </>
        )}
        {current === "advancedStanding" && (
          <>
            <AdvancedStanding />
          </>
        )}
        {current === "certificateRegister" && (
          <>
            <CertificateRegister />
          </>
        )}
        {current === "elicosCertificate" && <></>}
        {current === "finishDate" && <></>}
      </Card>
    </Drawer>
  );
};

export default EnrollSubjectForCourse;
