import React from "react";
import {
  ButtonElement,
  InputFieldElement,
  ModalElement,
  RadioElement,
} from "../../../../components/input";
import { Form, InputNumber } from "antd";
import InputNumberField from "../../../../components/inputNumberField/InputNumberField";

export default function AddCourseSite() {
  const activeStatus = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 2,
    },
  ];
  return (
    <ModalElement title="Add Course Site" keyType="addCourseSite">
      <Form layout="vertical" className="flex flex-col gap-4">
        <InputNumberField
          name="coursesite_id"
          placeholder="Course Site Id"
          label="Course Site Id "
          fieldWidth="100%"
        />
        <InputFieldElement
          name="course_site_name"
          placeholder="Course Site Name"
          label="Course Site Name"
          fieldWidth="100%"
        />
        <RadioElement
          name="active "
          label="Active"
          options={activeStatus}
          fieldWidth="100%"
        />

        <ButtonElement name="Add Course Site" bottonWidth="100%" />
      </Form>
    </ModalElement>
  );
}
