import { Menu, Layout, Divider, Card, Tabs, Button, Form } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import SubscriptionPlan from "./SubscriptionPlan/SubscriptionPlan";
import UpdateCompanyProfile from "./UpdateCompanyProfile";
import { InputFieldElement } from "../../components/input";
const { Header, Sider, Content } = Layout;

const ProfileTabs = () => {
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
    };
  });
  const [record, setRecord] = useState({});
  const [current, setCurrent] = useState("companyProfile");

  const dispatch = useDispatch();

  const clientDetailsTabList = [
    {
      label: "Company Profile",
      key: "companyProfile",
    },
    {
      label: "Subscription",
      key: "subscriptionPlan",
    },
    {
      label: "Account & Security",
      key: "accountSecurity",
    },
  ];
  return (
    <div className="bg-white h-full px-2 md:px-4 flex flex-col gap-2">
      <Tabs
        size="large"
        animated={{
          inkBar: false,
          tabPane: true,
        }}
        items={clientDetailsTabList}
        onChange={(e) => {
          setCurrent(e);
        }}
      />

      {current === "companyProfile" && (
        <UpdateCompanyProfile profileRecord={store.loginState?.profile} />
      )}
      {current === "subscriptionPlan" && (
        <SubscriptionPlan profileRecord={store.loginState} />
      )}
      {current === "accountSecurity" && <AccountSecurity />}
    </div>
  );
};

export default ProfileTabs;

export const AccountSecurity = () => {
  return (
    <div className="mt-12 flex flex-row justify-center ">
      <Form
        className="flex flex-col gap-4 p-8 rounded-[0.5rem] border border-[#00798a50] w-[70%] bg-[#00798a08] relative"
        labelCol={{ span: 8 }}
        wrapperCol={{ offset: 1, span: 15 }}
      >
        <span className="absolute top-[-1rem] px-4  py-1 bg-white border border-[#00798a50] rounded-[4px]">
          Change Password
        </span>
        <InputFieldElement
          name="password"
          label="Old Password"
          placeholder="Old Password"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="new_password"
          label="New Password"
          placeholder="New Password"
          fieldWidth="100%"
        />
        <InputFieldElement
          name="confirm_password"
          label="Confrim Password"
          placeholder="Confirm Password"
          fieldWidth="100%"
        />
      </Form>
    </div>
  );
};
