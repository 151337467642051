import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddChecklist from "./AddChecklist";
import UpdateChecklist from "./UpdateChecklist";

const columns = [
  {
    title: "Check list name",
    dataIndex: "ChecklistName",
    width: 100,
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: 200,
    align: "center",
  },
  {
    title: "Mandatory",
    dataIndex: "mandatory",
    width: 50,
    align: "center",
  },
  {
    title: "Used For",
    dataIndex: "usedFor",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const ChecklistSetupData = [
  {
    id: "1",
    ChecklistName: "Acadamic Document",
    description: "Including transcript, character certificate",
    mandatory: "Yes",
    usedFor: "Offer",
  },
  {
    id: "2",
    ChecklistName: "Passport",
    description: "Including visa and NOC documents",
    mandatory: "No",
    usedFor: "Offer",
  },
];

const ChecklistSetup = () => {
  const [isAddChecklistModalVisible, setIsAddChecklistModalVisible] =
    useState(false);
  const [isUpdateChecklistModalVisible, setIsUpdateChecklistModalVisible] =
    useState(false);

  const data = ChecklistSetupData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      ChecklistName: dataObj?.ChecklistName,
      description: dataObj?.description,
      mandatory: dataObj?.mandatory,
      usedFor: dataObj?.usedFor,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Checklist">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateChecklistModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Checklist">
            <Popconfirm
              title="Are you sure to delete this checklist?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Checklist Setup</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddChecklistModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddChecklistModalVisible && (
        <AddChecklist
          isAddChecklistModalVisible={isAddChecklistModalVisible}
          setIsAddChecklistModalVisible={setIsAddChecklistModalVisible}
        />
      )}
      {isUpdateChecklistModalVisible && (
        <UpdateChecklist
          isUpdateChecklistModalVisible={isUpdateChecklistModalVisible}
          setIsUpdateChecklistModalVisible={setIsUpdateChecklistModalVisible}
        />
      )}
    </div>
  );
};

export default ChecklistSetup;
