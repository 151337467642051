import { Button, Card, Form, Input, Modal, Select, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import OrganizationalSettings from "../../../components/OrganizationalSettings/OrganizationalSettings";
import CourseType from "./CourseType/CourseType";
import EnrolmentFeeSetup from "./EnrolmentFeeSetup/EnrolmentFeeSetup";

const staffTabList = [
  {
    key: "courseType",
    label: "Course Type",
  },
  {
    key: "enrolmentFee",
    label: "Enrolment Fee",
  },
];
const CourseSettings = () => {
  const [current, setCurrent] = useState("courseType");

  return (
    <OrganizationalSettings>
      <div style={{ margin: "0.5rem 0 0 0.5rem" }}>
        <h3 className="font-medium text-base text-black">Course Settings</h3>
        <div className="bg-white rounded px-4 py-2">
          <Tabs
            defaultActiveKey="profile"
            items={staffTabList}
            activeTabKey={current}
            onChange={(key) => {
              console.log("current key", key);
              setCurrent(key);
            }}
          />
          {current === "courseType" && (
            <>
              <CourseType />
            </>
          )}
          {current === "enrolmentFee" && (
            <>
              <EnrolmentFeeSetup />
            </>
          )}
        </div>
      </div>
    </OrganizationalSettings>
  );
};

export default CourseSettings;
