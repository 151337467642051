import { Divider, Tag } from "antd";
import moment from "moment";
import React from "react";

const InitialPaymentSummary = ({ detail }) => {
  const caseDetails = (title, value) => {
    return (
      <div>
        <div className="profileTitle">{title}:</div>
        <div>{value}</div>
      </div>
    );
  };
  return (
    <div
      style={{
        borderLeft: "1px solid #f0f2f5",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
      }}
    >
      <div>
        <h4>STUDENT INFORMATION:</h4>
      </div>
      <Divider orientationMargin="0" style={{ margin: "0" }} />
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Student ID", "SD001")}
        </div>
        <div style={{ width: "100%" }}>
          {caseDetails("Student name", "Mr Meshed Khadka")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Course length", "2-01-2023 / 12-10-2023")}
        </div>
        <div style={{ width: "100%" }}>
          {caseDetails("Total tuition fees", "$8,640.00")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Course Duration", "40 week(s) 4day(s)")}
        </div>
        <div style={{ width: "100%" }}>
          {caseDetails("Agent name", "Oceania Education and Visa Services")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Total Received amount", "3000.00")}
        </div>
        <div style={{ width: "100%" }}>
          {caseDetails("Schedule Due Date(s)", "12-04-2021")}
        </div>
      </div>
      {/* <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Result Calculation Method", "Subject Based")}
        </div>
      </div> */}
    </div>
  );
};

export default InitialPaymentSummary;
