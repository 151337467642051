import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddRegisterActivity = ({
  isAddRegisterActivityModalVisible,
  setIsAddRegisterActivityModalVisible,
}) => {
  const [registerActivityData, setRegisterActivityData] = useState({
    userType: "STAFF",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddRegisterActivitySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddRegisterActivity_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddRegisterActivityModalVisible(false);
  };
  return (
    <Drawer
      title="AddRegisterActivity"
      open={isAddRegisterActivityModalVisible}
      onClose={() => {
        setIsAddRegisterActivityModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddRegisterActivitySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Date" style={{ width: "100%" }}>
            Wednesday, 13 September 2023{" "}
          </Form.Item>
          <div className="flex flex-row justify-between gap-4 w-full">
            <Form.Item
              label="Lodged Date"
              name={"lodgedDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please pick a lodged date!",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item label="Lodged by" style={{ width: "100%" }}>
              Ms Anu Rani Regmi (anu)
            </Form.Item>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="User type"
            name={"userType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a user type",
              },
            ]}
          >
            <Select
              placeholder="User Type"
              onChange={(e) => {
                setRegisterActivityData((prevData) => {
                  return {
                    ...prevData,
                    userType: e,
                  };
                });
              }}
            >
              <Option value="STAFF">Staff</Option>
              <Option value="STUDENT">Student</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Category"
            name={"category"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a category",
              },
            ]}
          >
            <Input placeholder="Category" />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            {registerActivityData.userType === "STAFF"
              ? "Staff Detail"
              : "Student Detail (#Please Enter Student ID#)"}
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Staff role"
              name={"staffRole"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a staff role",
                },
              ]}
            >
              <Select placeholder="User Type">
                <Option>Academic Manager</Option>
                <Option>
                  Chief Financial Officer/Manger of Operations College
                  Administrator
                </Option>
                <Option>Director of Studies (DOS)</Option>
                <Option>Marketing and Admin Support Marketing Assistant</Option>
                <Option>Marketing Manager</Option>
                <Option>Operations Manager</Option>
                <Option>Principal Executive Officer</Option>
                <Option>Student Service Officer</Option>
                <Option>System Administrator</Option>
                <Option>Teacher</Option>
                <Option>Teacher - Part time</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Requested By"
              name={"requestedBy"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a requested by",
                },
              ]}
            >
              <Select placeholder="Requested By">
                <Option> Mr Alexander Freitag(alex.freitag)</Option>
                <Option>Ms Anu Rani Regmi(Anu)</Option>
                <Option>Mr Benjamin Fairley(benjamin.fairley)</Option>
                <Option>Mr Gaurab Thapa(gaurab.thapa)</Option>
                <Option>Mr James McDonald(james.mcdonald)</Option>
                <Option>Mr Kadam Roka(kadam.roka)</Option>{" "}
              </Select>
            </Form.Item>
          </div>
        </div>

        <Form.Item label="Case Detail" name={"caseDetail"}>
          <TextArea placeholder="Write Something..." />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Escalated to"
            name={"escalatedTo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter escalated to!",
              },
            ]}
          >
            <Select placeholder="Escalated To">
              <Option>Not Escalated</Option>
              <Option> Mr Alexander Freitag(alex.freitag)</Option>
              <Option>Ms Anu Rani Regmi(Anu)</Option>
              <Option>Mr Benjamin Fairley(benjamin.fairley)</Option>
              <Option>Mr Gaurab Thapa(gaurab.thapa)</Option>
              <Option>Mr James McDonald(james.mcdonald)</Option>
              <Option>Mr Kadam Roka(kadam.roka)</Option>{" "}
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name={"status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a status!",
              },
            ]}
          >
            <Select placeholder="Status">
              <Option value="CASE_OPENED">Case Opened</Option>
              <Option value="CASE_CLOSED">Case Closed</Option>{" "}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="Action Taken"
          name={"actionTaken"}
          rules={[
            {
              required: true,
              message: "Please enter action taken!",
            },
          ]}
        >
          <TextArea placeholder="Write Something..." />
        </Form.Item>
        <Form.Item
          label="Action taken to prevent reoccurance"
          name={"actionTakenToPreventReoccurance"}
        >
          <TextArea placeholder="Write Something..." />
        </Form.Item>

        <Form.Item label={"File attachment 1"} name="fileAttachment1">
          <Input type="file" />
        </Form.Item>
        <Form.Item label={"File attachment 2"} name="fileAttachment2">
          <Input type="file" />
        </Form.Item>
        <Form.Item label={"File attachment 3"} name="fileAttachment3">
          <Input type="file" />
        </Form.Item>
        <Form.Item label={"File attachment 4"} name="fileAttachment4">
          <Input type="file" />
        </Form.Item>
        <Form.Item label={"File attachment 5"} name="fileAttachment5">
          <Input type="file" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Activity"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddRegisterActivity;
