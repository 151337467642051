import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Pagename from "../../../components/Pagename/pagename";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import { ButtonElement, SelectField } from "../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineDollar,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFilePdf,
} from "react-icons/ai";
import Offers from "../Offers";
import { BsDownload } from "react-icons/bs";
import { TiTickOutline } from "react-icons/ti";
import { MdOutlinePending, MdPending } from "react-icons/md";
import GenerateStudentCourseInvoice from "./GenerateStudentCourseInvoice";
const { Search } = Input;

const columns = [
  {
    title: "InvId",
    dataIndex: "invId",
    width: 50,
    align: "center",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    width: 150,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 200,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 250,
    align: "center",
  },
  {
    title: "Fees",
    dataIndex: "fees",
    width: 250,
    align: "center",
  },
  {
    title: "Fee Paid",
    dataIndex: "feePaid",
    width: 250,
    align: "center",
  },
  {
    title: "Paid Duration",
    dataIndex: "paidDuration",
    width: 100,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 350,
    align: "center",
  },
];
const mscColumns = [
  {
    title: "Trans No",
    dataIndex: "transNo",
    width: 50,
    align: "center",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    width: 150,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 200,
    align: "center",
  },
  {
    title: "Pay Type",
    dataIndex: "payType",
    width: 250,
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: 250,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 350,
    align: "center",
  },
];
const searchStudentBy = [
  {
    label: "student ID",
    value: "studentId",
  },
];

const applicantData = [
  {
    id: 1,
    invId: "36",
    invoiceNo: "1040",
    dueDate: "06 Oct 2022",
    agentName: "test agent 2",
    fees: "	$500.00",
    feePaid: "$500.00",
    paidDuration: "2 Week",
    remarks: "Paid",
  },
  {
    id: 2,
    invId: "37",
    invoiceNo: "1041",
    dueDate: "07 Oct 2022",
    agentName: "test agent 2",
    fees: "	$1,500.00",
    feePaid: "$0.00",
    paidDuration: "1 Week",
    remarks: "Need Urgent Payment",
  },
];
const miscellanousData = [
  {
    id: 1,
    transNo: "17",
    invoiceNo: "	1040",
    dueDate: "06 Oct 2022",
    payType: "Material Fee",
    amount: "$200.00",
    remarks: "-",
  },
  {
    id: 2,
    transNo: "18",
    invoiceNo: "	1040",
    dueDate: "06 Oct 2022",
    payType: "Enrolment",
    amount: "$100.00",
    remarks: "-",
  },
  {
    id: 3,
    transNo: "19",
    invoiceNo: "	1041",
    dueDate: "	07 Oct 2022",
    payType: "Material Fee",
    amount: "$300.00",
    remarks: "-",
  },
  {
    id: 3,
    transNo: "19",
    invoiceNo: "	1041",
    dueDate: "	07 Oct 2022",
    payType: "Enrolment",
    amount: "$100.00",
    remarks: "-",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const GenerateInvoice = () => {
  const [searchBy, setSearchBy] = useState("idFirstLast");
  const [teacherStatus, setTeacherStatus] = useState("ACTIVE");

  const [isUpdateStaffModalVisible, setIsUpdateStaffModalVisible] =
    useState(false);
  const [isGenerateInvoiceModalVisible, setIsGenerateInvoiceModalVisible] =
    useState(false);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  let data = applicantData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      invId: dataObj?.invId,
      invoiceNo: dataObj?.invoiceNo,
      dueDate: dataObj?.dueDate,
      agentName: dataObj?.agentName,
      fees: dataObj?.fees,
      feePaid: dataObj?.feePaid,
      paidDuration: dataObj?.paidDuration,
      remarks: dataObj?.remarks,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "12vw" }}
        >
          <Tooltip title="Update Applicant Detail">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStaffModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  let miscData = miscellanousData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      transNo: dataObj?.transNo,
      invoiceNo: dataObj?.invoiceNo,
      dueDate: dataObj?.dueDate,
      payType: dataObj?.payType,
      amount: dataObj?.amount,
      remarks: dataObj?.remarks,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "12vw" }}
        >
          <Tooltip title="Update Applicant Detail">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStaffModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Offers tab={"/generate-invoice"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Generate Invoice</Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <ButtonElement
                handleClick={() => {
                  setIsGenerateInvoiceModalVisible(true);
                }}
                name="Generate Invoice"
              >
                Generate Invoice
              </ButtonElement>
            </div>
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Search Student By</div>
              <SelectField
                name="searchStudentBy"
                placeholder="Select Student By"
                options={searchStudentBy}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="8rem"
              />
            </div>
            {searchBy === "studentId" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <Search
                  style={{ borderRadius: "3px", width: "100%" }}
                  className="search"
                  placeholder={`Search Student ID.`}
                  allowClear
                  size="Medium"
                  //   value={searchValue}
                  onChange={(e) => {
                    //   setSearchValue(e.target.value);
                    //   onSearch(e.target.value);
                  }}
                />
              </div>
            )}

            {searchBy === "course" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="course"
                  placeholder="Course"
                  options={selectCourse}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table
            dataSource={data}
            bordered={true}
            columns={columns}
            pagination={false}
          />
        </div>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table
            bordered={true}
            dataSource={miscData}
            columns={mscColumns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isGenerateInvoiceModalVisible && (
        <GenerateStudentCourseInvoice
        isGenerateStudentCourseInvoiceModalVisible={isGenerateInvoiceModalVisible}
        setIsGenerateStudentCourseInvoiceModalVisible={setIsGenerateInvoiceModalVisible}
        />
      )}
    </Offers>
  );
};

export default GenerateInvoice;
