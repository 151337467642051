import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { AllEpic } from "./myEpics";
import StaffReducer from "./Reducers/StaffReducer";
import { UserReducer } from "./Reducers/UserReducer";
import DrawerReducer from "./Reducers/DrawerReducer";
import SiderDemoReducer from "./Reducers/SiderDemoReducer";
export const rootReducer = combineReducers({
  StaffReducer,
  UserReducer,
  DrawerReducer,
  SiderDemoReducer,
});

export const rootEpic = combineEpics(AllEpic);
