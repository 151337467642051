import React from "react";
import { Form, Radio } from "antd";

const RadioElement = ({
  name,
  label = "",
  hasRule = false,
  size = "medium",
  options = [],
  handleChange = () => {},
  fieldWidth = "fit-content",
}) => {
  return (
    <Form.Item
      style={{ margin: 0, width: fieldWidth }}
      name={name}
      label={label}
      rules={[
        {
          required: hasRule,
          message: `${label ? label : name} is required`,
        },
      ]}
    >
      <Radio.Group onChange={handleChange} size={size} options={options} />
    </Form.Item>
  );
};

export default RadioElement;
