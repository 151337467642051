import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddEmployer = ({
  isAddEmployerModalVisible,
  setIsAddEmployerModalVisible,
}) => {
  const [employerData, setEmployerData] = useState({
    country: "AUSTRALIA",
    state: null,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddEmployerSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddEmployer_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddEmployerModalVisible(false);
  };
  return (
    <Drawer
      title="Add Employer"
      open={isAddEmployerModalVisible}
      onClose={() => {
        setIsAddEmployerModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddEmployerSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Employer Name"
            name={"employerName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter employer name!",
              },
            ]}
          >
            <Input placeholder="Employer Name" />
          </Form.Item>
          <Form.Item
            label="Trading Name"
            name={"tradingName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter trading name!",
              },
            ]}
          >
            <Input placeholder="Trading Name" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Contact Person"
            name={"contactPerson"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter contact person!",
              },
            ]}
          >
            <Input placeholder="Contact Person" />
          </Form.Item>
          <Form.Item
            label="ABN"
            name={"abn"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter ABN no.!",
              },
            ]}
          >
            <Input placeholder="ABN" />
          </Form.Item>
        </div>
        <Form.Item
          label="Industry"
          name={"industry"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select an industry!",
            },
          ]}
        >
          <Select placeholder="Industry">
            <Option>Accommodation (44)</Option>
            <Option>Administrative Services (72)</Option>
            <Option>Adult, Community and Other Education (82)</Option>
            <Option>Agriculture (01)</Option>
            <Option>
              Agriculture, Forestry and Fishing Support Services (05)
            </Option>{" "}
            <Option>Air and Space Transport (49)</Option>
            <Option>Aquaculture (02)</Option>
            <Option>Auxiliary Finance and Insurance Services (64)</Option>
            <Option>
              Basic Chemical and Chemical Product Manufacturing (18)
            </Option>
            <Option>Basic Material Wholesaling (33)</Option>
            <Option>Beverage and Tobacco Product Manufacturing (12)</Option>
            <Option>Broadcasting (except Internet) (56)</Option>{" "}
            <Option>
              Building Cleaning, Pest Control and Other Support Services (73)
            </Option>{" "}
            <Option>Building Construction (30)</Option>
            <Option>Coal Mining (06)</Option>
            <Option>Commission-Based Wholesaling (38)</Option>
            <Option>Computer System Design and Related Services (70)</Option>
            <Option>Construction Services (32)</Option>
            <Option>Creative and Performing Arts Activities (90)</Option>
            <Option>Defence (76)</Option>
            <Option>Electricity Supply (26)</Option>
            <Option>Exploration and Other Mining Support Services (10)</Option>
            <Option>Fabricated Metal Product Manufacturing (22)</Option>
            <Option>Finance (62)</Option>
            <Option>Fishing, Hunting and Trapping (04)</Option>
            <Option>Food and Beverage Services (45)</Option>
            <Option>Food Product Manufacturing (11)</Option>
            <Option> Food Retailing (41)</Option>
            <Option>Forestry and Logging (03)</Option>
            <Option>Fuel Retailing (40)</Option>
            <Option>Furniture and Other Manufacturing (25)</Option>
            <Option>Gambling Activities (92)</Option>
            <Option>Gas Supply (27)</Option>
            <Option>
              Grocery, Liquor and Tobacco Product Wholesaling (36)
            </Option>
            <Option>Heavy and Civil Engineering Construction (31)</Option>
            <Option>Heritage Activities (89)</Option>
            <Option>Hospitals (84)</Option>
            <Option>Insurance and Superannuation Funds (63)</Option>
            <Option>Internet Publishing and Broadcasting (57)</Option>
            <Option>
              Internet Service Providers, Web Search Portals and Data Processing
              Services (59)
            </Option>
            <Option>Library and Other Information Services (60)</Option>
            <Option>Machinery and Equipment Manufacturing (24)</Option>
            <Option>Machinery and Equipment Wholesaling (34)</Option>
            <Option>Medical and Other Health Care Services (85)</Option>
            <Option>Metal Ore Mining (08)</Option>
            <Option>Motion Picture and Sound Recording Activities (55)</Option>
            <Option>
              Motor Vehicle and Motor Vehicle Parts Retailing (39)
            </Option>
            <Option>
              Motor Vehicle and Motor Vehicle Parts Wholesaling (35)
            </Option>
            <Option>Non-Metallic Mineral Mining and Quarrying (09)</Option>
            <Option>Non-Metallic Mineral Product Manufacturing (20)</Option>
            <Option>
              Non-Store Retailing and Retail Commission-Based Buying and/or
              Selling (43)
            </Option>
            <Option>
              Not specified or Blank value for Funding Source Identifier-State
              is not LSG, PSG or SSG ()
            </Option>
            <Option>Oil and Gas Extraction (07)</Option>
            <Option>Other Goods Wholesaling (37)</Option>
            <Option>
              Other Non-Metallic Mineral Mining and Quarrying (99)
            </Option>
            <Option>Other Store-Based Retailing (42)</Option>
            <Option>Other Transport (50)</Option>
            <Option>Personal and Other Services (95)</Option>
            <Option>Petroleum and Coal Product Manufacturing (17)</Option>
            <Option>
              Polymer Product and Rubber Product Manufacturing (19)
            </Option>
            <Option>
              Postal and Courier Pick-up and Delivery Services (51)
            </Option>
            <Option>Preschool and School Education (80)</Option>
            <Option>Primary Metal and Metal Product Manufacturing (21)</Option>
            <Option>
              Printing (including the Reproduction of Recorded Media) (16)
            </Option>{" "}
            <Option>
              Private Households Employing Staff and Undifferentiated Goods- and
              Service-Producing Activities of Households for Own Use (96)
            </Option>
            <Option>
              Professional, Scientific and Technical Services (Except Computer
              System Design and Related Services) (69)
            </Option>{" "}
            <Option>Property Operators and Real Estate Services (67)</Option>
            <Option>Public Administration (75)</Option>
            <Option>Public Order, Safety and Regulatory Services (77)</Option>
            <Option>
              Publishing (except Internet and Music Publishing) (54)
            </Option>{" "}
            <Option>
              Pulp, Paper and Converted Paper Product Manufacturing (15)
            </Option>
            <Option>Rail Transport (47)</Option>
            <Option>
              Rental and Hiring Services (except Real Estate) (66)
            </Option>{" "}
            <Option>Repair and Maintenance (94)</Option>
            <Option>Residential Care Services (86)</Option>
            <Option>Road Transport (46)</Option>
            <Option>Social Assistance Services (87)</Option>
            <Option>Sports and Recreation Activities (91)</Option>
            <Option>Telecommunications Services (58)</Option>
            <Option>Tertiary Education (81)</Option>
            <Option>
              Textile, Leather, Clothing and Footwear Manufacturing (13)
            </Option>
            <Option>Transport Equipment Manufacturing (23)</Option>
            <Option>Transport Support Services (52)</Option>
            <Option>Warehousing and Storage Services (53)</Option>
            <Option>
              Waste Collection, Treatment and Disposal Services (29)
            </Option>
            <Option>Water Supply, Sewerage and Drainage Services (28)</Option>
            <Option>Water Transport (48)</Option>
            <Option>Wood Product Manufacturing (14)</Option>
          </Select>
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Company Address"
            name={"companyAddress"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter company address",
              },
            ]}
          >
            <Input placeholder="Company Address:" />
          </Form.Item>
          <Form.Item
            label="Company Suburb"
            name={"companySuburb"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter company suburb!",
              },
            ]}
          >
            <Input placeholder="Company Suburb" />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Country"
            name={"country"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a country",
              },
            ]}
          >
            <Select
              placeholder="Country"
              onChange={(e) => {
                setEmployerData((prevData) => {
                  return {
                    ...prevData,
                    country: e,
                  };
                });
              }}
            >
              <Option value="AUSTRALIA">Australia</Option>
              <Option value="NEPAL">Nepal</Option>
            </Select>
          </Form.Item>

          {employerData?.country?.toLowerCase() === "australia" ? (
            <Form.Item
              label="Company State"
              name={"companyState"}
              style={{ width: "100%" }}
            >
              <Select
                onChange={(e) => {
                  setEmployerData((prevData) => {
                    return {
                      ...prevData,
                      state: e,
                    };
                  });
                }}
              >
                <Option value="NSW|New South Wales">NSW|New South Wales</Option>
                <Option value="QLD|Queensland">QLD|Queensland</Option>
                <Option value="SA|South Australia">SA|South Australia</Option>
                <Option value="TAS|Tasmania">TAS|Tasmania</Option>
                <Option value="VIC|Victoria">VIC|Victoria</Option>
                <Option value="WA|Western Australia">
                  WA|Western Australia
                </Option>
                <Option value="ACT|Australian Capital Territory">
                  ACT|Australian Capital Territory
                </Option>
                <Option value="NT|Northern Territory">
                  NT|Northern Territory
                </Option>
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Company State"
              name={"companyState"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Company State"
                onChange={(e) => {
                  setEmployerData((prevData) => {
                    return {
                      ...prevData,
                      state: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Postcode"
            name={"postcode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a postcode!",
              },
            ]}
          >
            <Input placeholder="Postcode" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name={"phone"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a phone number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Company Phone" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Mobile"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Mobile" />
          </Form.Item>
          <Form.Item label="Fax" name={"fax"} style={{ width: "100%" }}>
            <Input placeholder="Fax" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Status"
            name={"Status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "url",
                message: "Please select a Status!",
              },
            ]}
          >
            <Select placeholder="Status">
              <Option value="ACTIVE">Active</Option>
              <Option value="INACTIVE">Inctive</Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Employer"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddEmployer;
