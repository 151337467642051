import React from "react";
import { Form, Select } from "antd";

const SelectField = ({
  defaultValue,
  name,
  label = "",
  hasRule = false,
  size = "medium",
  placeholder = "",
  options,
  fieldWidth = "fit-content",
  handleChange = () => {},
}) => {
  return (
    <Form.Item
      style={{
        margin: "0",
        width: fieldWidth,
      }}
      name={name}
      label={label}
      rules={[
        {
          required: hasRule,
          message: `${label ? label : name} is required`,
        },
      ]}
    >
      <Select
        defaultValue={defaultValue}
        style={{ maxWidth: fieldWidth, width: "100%" }}
        options={[...options]}
        onChange={handleChange}
        size={size}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default SelectField;
