import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Accounts from "../Accounts";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import PageHeader from "../../../components/PageHeader/PageHeader";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import AddSanction from "../../Student/StudentDetails/Sanction/AddSanction";
const { Search } = Input;

const columns = [
  {
    title: "Sanction Information",
    dataIndex: "sanctionInformation",
    width: 100,
    align: "center",
  },
  {
    title: "Student Id",
    dataIndex: "studentId",
    width: 250,
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 200,
    align: "center",
  },
  {
    title: "CourseID",
    dataIndex: "courseId",
    width: 150,
    align: "center",
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 150,
    align: "center",
  },
  {
    title: "Course Attempt",
    dataIndex: "courseAttempt",
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },

  {
    title: "Status",
    dataIndex: "status",
    width: 150,
    align: "center",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    width: 150,
    align: "center",
  },
  {
    title: "InvoiceNo",
    dataIndex: "InvoiceNo",
    width: 200,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 150,
    align: "center",
  },
  {
    title: "TuitionFee Amount",
    dataIndex: "tuitionFeeAmount",
    width: 150,
    align: "center",
  },
  {
    title: "Misc. Fee Amount",
    dataIndex: "miscFeeAmount",
    width: 50,
    align: "center",
  },
  {
    title: "Total Invoiced Amt",
    dataIndex: "totalInvoicedAmt",
    width: 250,
    align: "center",
  },
  {
    title: "Tuition Paid",
    dataIndex: "tuitionPaid",
    width: 100,
    align: "center",
  },
  {
    title: "MiscFee Paid",
    dataIndex: "miscFeePaid",
    width: 150,
    align: "center",
  },
  {
    title: "Total Paid Amt",
    dataIndex: "totalPaidAmt",
    width: 200,
    align: "center",
  },
  {
    title: "Invoice Credit",
    dataIndex: "InvoiceCredit",
    width: 200,
    align: "center",
  },
  {
    title: "totalRefunded",
    dataIndex: "totalRefunded",
    width: 200,
    align: "center",
  },
  {
    title: "Tuition Due",
    dataIndex: "tuitionDue",
    width: 200,
    align: "center",
  },
  {
    title: "Misc Due",
    dataIndex: "miscDue",
    width: 200,
    align: "center",
  },
  {
    title: "Total Due Amt",
    dataIndex: "totalDueAmt",
    width: 200,
    align: "center",
  },
];

const sanctionData = [
  {
    id: "1",
    sanctionInformation: "",
    studentId: "OPS0001563",
    name: "Prantanya Muangklang	",
    courseId: "GE",
    courseName: "General English",
    courseAttempt: "1",
    status: "Enrolled",
    status: "Boss Education & Visa",
    mobile: "0468859354",
    InvoiceNo: "1154",
    dueDate: "04-09-2023",
    tuitionFeeAmount: "960",
    miscFeeAmount: "0",
    totalInvoicedAmt: "960",
    tuitionPaid: "0",
    miscFeePaid: "0",
    totalPaidAmt: "0",
    InvoiceCredit: "0.00",
    totalRefunded: "0",
    tuitionDue: "960",
    miscDue: "0",
    totalDueAmt: "960",
  },
];

const BulkSanction = () => {
  const [isAddSanctionModalVisible, setIsAddSanctionModalVisible] =
    useState(false);
  const [searchBy, setSearchBy] = useState(false);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  let data = sanctionData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      sanctionInformation: dataObj?.sanctionInformation,
      studentId: dataObj?.studentId,
      name: dataObj?.name,
      courseId: dataObj?.courseId,
      courseName: dataObj?.courseName,
      courseAttempt: dataObj?.courseAttempt,
      status: dataObj?.status,
      status: dataObj?.status,
      mobile: dataObj?.mobile,
      InvoiceNo: dataObj?.InvoiceNo,
      dueDate: dataObj?.dueDate,
      tuitionFeeAmount: dataObj?.tuitionFeeAmount,
      miscFeeAmount: dataObj?.miscFeeAmount,
      totalInvoicedAmt: dataObj?.totalInvoicedAmt,
      tuitionPaid: dataObj?.tuitionPaid,
      miscFeePaid: dataObj?.miscFeePaid,
      totalPaidAmt: dataObj?.totalPaidAmt,
      InvoiceCredit: dataObj?.InvoiceCredit,
      totalRefunded: dataObj?.totalRefunded,
      tuitionDue: dataObj?.tuitionDue,
      miscDue: dataObj?.miscDue,
      totalDueAmt: dataObj?.totalDueAmt,
    };
  });
  return (
    <Accounts tab={"/bulk-sanction"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Student Bulk Sanction</Pagename>
          </div>
          <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Payment Type:</div>
              <SelectField
                name="paymentType"
                placeholder="Payment Type"
                value={searchBy}
                options={[
                  {
                    label: "Student payment due list before selected dates",
                    value: "studentPaymentDueListBeforeSelectedDates",
                  },
                  {
                    label:
                      "Fee Status: Invoiced payment detail between two selected dates",
                    value:
                      "feeStatusInvoicedPaymentDetailBetweenTwoSelectedDates",
                  },
                ]}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="12rem"
              />
            </div>

            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>From Date:</div>
              <DatePicker format={"DD-MM-YYYY"} />
            </div>
            {searchBy ===
              "feeStatusInvoicedPaymentDetailBetweenTwoSelectedDates" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>To Date:</div>
                <DatePicker format={"DD-MM-YYYY"} />
              </div>
            )}
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Is active sanction applied: </div>
              <SelectField
                defaultValue={"no"}
                name="studentOrigin"
                options={[
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  {
                    label: "No",
                    value: "no",
                  },
                ]}
                handleChange={(e) => {}}
                fieldWidth="5rem"
              />
            </div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {selectedRowKeys.length > 0 && selectedVisible && (
            <SelectAlert
              length={selectedRowKeys.length}
              type={"success"}
              closable={true}
              selectAction={
                <div className="fxRJs">
                  <ButtonElement
                    size="small"
                    buttonType="primary"
                    handleClick={(e) => {
                      setIsAddSanctionModalVisible(true);
                    }}
                    name="Apply Bulk Sanction"
                  />
                </div>
              }
            />
          )}
          <Table
            scroll={{
              x: 0,
            }}
            bordered
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isAddSanctionModalVisible && (
        <AddSanction
          isAddSanctionModalVisible={isAddSanctionModalVisible}
          setIsAddSanctionModalVisible={setIsAddSanctionModalVisible}
        />
      )}
    </Accounts>
  );
};

export default BulkSanction;
