import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../components/input";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import { AiOutlineEye } from "react-icons/ai";
import AddRemarks from "./AddRemarks";
import ProcessingAgentCommission from "./Process/ProcessingAgentCommission";
const { Option } = Select;
const columns = [
  {
    title: "Transaction No",
    dataIndex: "transactionNo",
    width: 50,
    align: "center",
  },
  {
    title: "Agent ID",
    dataIndex: "agentId",
    width: 50,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 250,
    align: "center",
  },
  {
    title: "Agent Status",
    dataIndex: "agentStatus",
    width: 100,
    align: "center",
  },
  {
    title: "Student Id",
    dataIndex: "studentId",
    width: 200,
    align: "center",
  },
  {
    title: "Student Name",
    dataIndex: "studentName",
    width: 200,
    align: "center",
  },
  {
    title: "Course Status",
    dataIndex: "courseStatus",
    width: 100,
    align: "center",
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 200,
    align: "center",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    width: 150,
    align: "center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    width: 150,
    align: "center",
  },
  {
    title: "Fees Due",
    dataIndex: "feesDue",
    width: 150,
    align: "center",
  },
  {
    title: "Transaction Amount",
    dataIndex: "transactionAmount",
    width: 150,
    align: "center",
  },

  {
    title: "Std Refunded",
    dataIndex: "stdRefunded",
    width: 200,
    align: "center",
  },
  {
    title: "Semester",
    dataIndex: "semester",
    width: 200,
    align: "center",
  },
  {
    title: "Commission Rate",
    dataIndex: "commissionRate",
    width: 200,
    align: "center",
  },
  {
    title: "Commission Payable",
    dataIndex: "commissionPayable",
    width: 200,
    align: "center",
  },
  {
    title: "GST",
    dataIndex: "gst",
    width: 200,
    align: "center",
  },
  {
    title: "Total Paid",
    dataIndex: "totalPaid",
    width: 200,
    align: "center",
  },
  {
    title: "Payable",
    dataIndex: "payable",
    width: 200,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 300,
    align: "center",
  },
];

const agentData = [
  {
    id: "1",
    transactionNo: "2",
    agentId: "2",
    agentName: "Asian Intl Education and Migration pty Ltd.",
    agentStatus: "Provisional",
    studentId: "ID20210001",
    studentName: "John Doe",
    courseStatus: "Finished",
    courseName: "GE : General English",
    dueDate: "03/05/2021",
    paidDate: "28/04/2021",
    feesDue: "666.67",
    transactionAmount: "666.67",
    stdRefunded: "400",
    semester: "",
    commissionRate: "20%+GST",
    commissionPayable: "53.334",
    gst: "5.3334",
    totalPaid: "0",
    payable: "58.67",
  },
];

const ViewOrProcessPayments = ({
  searchBy,
  isViewOrProcessPaymentsModalVisible,
  setIsViewOrProcessPaymentsModalVisible,
}) => {
  const [isAddRemarksModalVisible, setIsAddRemarksModalVisible] =
    useState(false);
  const [
    isProcessingAgentCommissionModalVisible,
    setIsProcessingAgentCommissionModalVisible,
  ] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  let data = agentData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      transactionNo: dataObj?.transactionNo,
      agentId: dataObj?.agentId,
      agentName: dataObj?.agentName,
      agentStatus: dataObj?.agentStatus,
      studentId: dataObj?.studentId,
      studentName: dataObj?.studentName,
      courseStatus: dataObj?.courseStatus,
      courseName: dataObj?.courseName,
      dueDate: dataObj?.dueDate,
      paidDate: dataObj?.paidDate,
      feesDue: dataObj?.feesDue,
      transactionAmount: dataObj?.transactionAmount,
      stdRefunded: dataObj?.stdRefunded,
      semester: dataObj?.semester,
      commissionRate: dataObj?.commissionRate,
      commissionPayable: dataObj?.commissionPayable,
      gst: dataObj?.gst,
      totalPaid: dataObj?.totalPaid,
      payable: dataObj?.payable,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="View and/or Process Payments">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsAddRemarksModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  return (
    <Drawer
      title="Process Payments"
      open={isViewOrProcessPaymentsModalVisible}
      onClose={() => {
        setIsViewOrProcessPaymentsModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        {selectedRowKeys.length > 0 && selectedVisible && (
          <SelectAlert
            length={selectedRowKeys.length}
            type={"success"}
            closable={true}
            selectAction={
              <ButtonElement
                size="small"
                buttonType="primary"
                handleClick={(e) => {
                  if (searchBy === "processAndPayCommission") {
                    setIsProcessingAgentCommissionModalVisible(true);
                  } else {
                  }
                }}
                name={`${
                  searchBy === "viewAndApproveCommissionAvailable"
                    ? "Approve"
                    : "Process"
                }`}
              />
            }
          />
        )}
        <Table
          scroll={{
            x: 1200,
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </div>
      {isAddRemarksModalVisible && (
        <AddRemarks
          isAddRemarksModalVisible={isAddRemarksModalVisible}
          setIsAddRemarksModalVisible={setIsAddRemarksModalVisible}
        />
      )}
      {isProcessingAgentCommissionModalVisible && (
        <ProcessingAgentCommission
          isProcessingAgentCommissionModalVisible={
            isProcessingAgentCommissionModalVisible
          }
          setIsProcessingAgentCommissionModalVisible={
            setIsProcessingAgentCommissionModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default ViewOrProcessPayments;
