import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddRegisterActivity from "./AddRegisterActivity";

const columns = [
  {
    title: "Date Generated",
    dataIndex: "dateGenerated",
    width: 50,
    align: "center",
  },
  {
    title: "Date Lodged",
    dataIndex: "lodgedDate",
    width: 50,
    align: "center",
  },
  {
    title: "Category",
    dataIndex: "category",
    width: 50,
    align: "center",
  },
  {
    title: "Requested By",
    dataIndex: "requestedBy",
    width: 50,
    align: "center",
  },
  {
    title: "Details",
    dataIndex: "details",
    width: 150,
    align: "center",
  },
  {
    title: "Action Taken",
    dataIndex: "actionTaken",
    width: 150,
    align: "center",
  },
  {
    title: "Action to Prevent Reoccurance",
    dataIndex: "actionToPreventReoccurance",
    width: 150,
    align: "center",
  },
  {
    title: "File Attachment",
    dataIndex: "fileAttachment",
    width: 50,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 100,
    align: "center",
  },
];

const ImprovementRegisterData = [
  {
    id: "1",
    dateGenerated: "12-09-2023",
    lodgedDate: "12-09-2023",
    category: "Library Facilities",
    requestedBy: "James",
    details: "Not enough books in the library eg English Advanced,",
    actionTaken: "Procure order dispatched",
    actionToPreventReoccurance: "Follow up daily",
    fileAttachment: "",
    status: "Case Opened",
  },
];

const ImprovementRegister = () => {
  const [
    isAddRegisterActivityModalVisible,
    setIsAddRegisterActivityModalVisible,
  ] = useState(false);
  const [isUpdateChecklistModalVisible, setIsUpdateChecklistModalVisible] =
    useState(false);

  const data = ImprovementRegisterData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      dateGenerated: dataObj?.dateGenerated,
      lodgedDate: dataObj?.lodgedDate,
      category: dataObj?.category,
      requestedBy: dataObj?.requestedBy,
      details: dataObj?.details,
      actionTaken: dataObj?.actionTaken,
      actionToPreventReoccurance: dataObj?.actionToPreventReoccurance,
      fileAttachment: dataObj?.fileAttachment,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Checklist">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateChecklistModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Checklist">
            <Popconfirm
              title="Are you sure to delete this checklist?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Register Activity Detail</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddRegisterActivityModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddRegisterActivityModalVisible && (
        <AddRegisterActivity
          isAddRegisterActivityModalVisible={isAddRegisterActivityModalVisible}
          setIsAddRegisterActivityModalVisible={
            setIsAddRegisterActivityModalVisible
          }
        />
      )}
      {/* {isUpdateChecklistModalVisible && (
        <UpdateChecklist
          isUpdateChecklistModalVisible={isUpdateChecklistModalVisible}
          setIsUpdateChecklistModalVisible={setIsUpdateChecklistModalVisible}
        />
      )} */}
    </div>
  );
};

export default ImprovementRegister;
