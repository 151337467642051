import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddEmployerContact from "./AddEmployerContact";
import PageHeader from "../../../../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../../../../components/input";
import AddButton from "../../../../../../components/button/AddButton";
import UpdateEmployerContact from "./UpdateEmployerContact";

const columns = [
  {
    title: "Person Name",
    dataIndex: "personName",
    width: 100,
    align: "center",
  },
  {
    title: "Designation",
    dataIndex: "designation",
    width: 200,
    align: "center",
  },
  {
    title: "Department",
    dataIndex: "department",
    width: 50,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 50,
    align: "center",
  },
  {
    title: "Telephone",
    dataIndex: "telephone",
    width: 50,
    align: "center",
  },
  {
    title: "Fax",
    dataIndex: "fax",
    width: 50,
    align: "center",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    width: 50,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const MultipleContactsData = [
  {
    id: "1",
    personName: "Mr John Doe",
    designation: "sales rep",
    department: "Marketing",
    email: "johndoe@gmail.com",
    telephone: "9898989995",
    fax: "1545659",
    mobile: "984565666",
    status: "Active",
  },
];

const MultipleContacts = () => {
  const [
    isAddEmployerContactModalVisible,
    setIsAddEmployerContactModalVisible,
  ] = useState(false);
  const [
    isUpdateEmployerContactModalVisible,
    setIsUpdateEmployerContactModalVisible,
  ] = useState(false);

  const data = MultipleContactsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      personName: dataObj?.personName,
      designation: dataObj?.designation,
      department: dataObj?.department,
      email: dataObj?.email,
      telephone: dataObj?.telephone,
      fax: dataObj?.fax,
      mobile: dataObj?.mobile,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Employer Contact">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateEmployerContactModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Employer Contact">
            <Popconfirm
              title="Are you sure to delete this Employer Contact?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Employer Multiple Contacts Registration</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddEmployerContactModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddEmployerContactModalVisible && (
        <AddEmployerContact
          isAddEmployerContactModalVisible={isAddEmployerContactModalVisible}
          setIsAddEmployerContactModalVisible={
            setIsAddEmployerContactModalVisible
          }
        />
      )}
      {isUpdateEmployerContactModalVisible && (
        <UpdateEmployerContact
          isUpdateEmployerContactModalVisible={
            isUpdateEmployerContactModalVisible
          }
          setIsUpdateEmployerContactModalVisible={
            setIsUpdateEmployerContactModalVisible
          }
        />
      )}
    </div>
  );
};

export default MultipleContacts;
