import React, { useState } from "react";
import { Divider, Select } from "antd";
import { ButtonElement } from "../../components/input";
import { useDispatch } from "react-redux";
import EditCompanyProfile from "./companyProfile/EditCompanyProfile";

const UpdateCompanyProfile = ({ profileRecord }) => {
  const dispatch = useDispatch();
  const handleEditCompanyProfile = (value) => {
    dispatch({ type: "FETCH_OPEN_DRAWER", payload: value });
  };
  return (
    <div className="flex flex-col h-[70vh] ">
      <span className="flex flex-row justify-end mr-2">
        <ButtonElement
          handleClick={() => handleEditCompanyProfile("editCompanyDetails")}
          name="Edit Company Profile"
        />
      </span>
      <div className="h-full overflow-y-scroll my-2 flex flex-col gap-4 custom-scroll">
        <div className="relative border grid grid-cols-1 lg:grid-cols-2 gap-2 rounded-[0.5rem] p-2 pt-4 mt-3 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            General Information
          </span>
          <span className="lg:col-span-2">{detailFun("Auto Id", 1)}</span>
          {detailFun("RTO Code", "NA")}
          {detailFun("CRICOS Code", "03900F")}
          <span className="lg:col-span-2">
            {detailFun("College Name", "Opera Education Group Pty Ltd")}
          </span>
          <span className="lg:col-span-2">
            {detailFun(
              "Training organisation type",
              "21 - School - Government"
            )}
          </span>{" "}
          {detailFun("Contact person", "Anu Regmi")}
          {detailFun("ABN", "21616701412")}
          {detailFun("Telephone", "02 8034 8856")}
          {detailFun("Fax", "NA")}
          {detailFun("Contact email", "aregmi@opera.nsw.edu.au")}
          {detailFun("IT email", "aregmi@opera.nsw.edu.au")}
          {detailFun("Marketing email", "marketing@opera.nsw.edu.au")}
          {detailFun("Academic email", "aregmi@opera.nsw.edu.au")}
          {detailFun("Admissions email", "admissions@opera.nsw.edu.au")}
          {detailFun("Accounts email", "aregmi@opera.nsw.edu.au")}
          {detailFun("Other email", "opera.edu.au@gmail.com")}
          {detailFun("College URL", "http://www.opera.nsw.edu.au")}
          <span className="lg:col-span-2">
            {detailFun("College Email Setup", "studentid +@ + opera.nsw.edu")}
          </span>
          <span className="lg:col-span-2">
            {detailFun(
              "Type of Industry",
              "Post and Pickup courier delivery service"
            )}
          </span>
        </div>

        <div className="relative border grid-cols-1 grid md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            AVETMISS Training Organisation Identifier (TOID) for State
          </span>
          {detailFun("TOID for ACT", "00000000NA")}
          {detailFun("TOID for NSW", "00000000NA")}
          {detailFun("TOID for NT", "00000000NA")}
          {detailFun("TOID for QLD", "00000000NA")}
          {detailFun("TOID for SA", "00000000NA")}
          {detailFun("TOID for TAS", "00000000NA")}
          {detailFun("TOID for VIC", "00000000NA")}
          {detailFun("TOID for WA", "00000000NA")}
        </div>
        <div className="relative border grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            College Street Address
          </span>
          {detailFun("Address", "Level 1/303 Pitt Street")}
          {detailFun("Suburb", "Sydney")}
          {detailFun("State", "NSW")}
          {detailFun("Postcode", "2000")}
        </div>
        <div className="relative border grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            College Postal Address
          </span>
          {detailFun("Address", "Level 1/303 Pitt Street")}
          {detailFun("Suburb", "Sydney")}
          {detailFun("State", "NSW")}
          {detailFun("Postcode", "2000")}
        </div>
        <div className="relative border grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white  text-[#00798a]">
            Bank Details
          </span>
          {detailFun("Bank name", "Commonwealth Bank of Australia")}
          {detailFun("Branch", "")}
          {detailFun("Account name", "Opera Education Group")}
          {detailFun("Account number", "")}
          {detailFun("BSB", "062185")}
          {detailFun("Swift Code", "")}
        </div>
      </div>
      <EditCompanyProfile />
    </div>
  );
};

export default UpdateCompanyProfile;

export const detailFun = (title, value) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 text-[gray]">
        {title}:<span className="text-black">{value}</span>
      </div>
      <Divider orientationMargin="0" className="my-2" />
    </div>
  );
};
