import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFilePdf,
  AiOutlinePlus,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { TbReport } from "react-icons/tb";
import AddTransferPayment from "./AddTransferPayment";

const columns = [
  {
    title: "Transaction No.",
    dataIndex: "transactionNo",
    width: 250,
    align: "center",
  },
  {
    title: "Receipt No",
    dataIndex: "receiptNo",
    width: 150,
    align: "center",
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
    width: 150,
    align: "center",
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    width: 100,
    align: "center",
  },
  {
    title: "Amount Deposited",
    dataIndex: "amountDeposited",
    width: 100,
    align: "center",
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentMode",
    width: 100,
    align: "center",
  },
  {
    title: "Bank Dep. Date",
    dataIndex: "bankDepDate",
    width: 100,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 100,
    align: "center",
  },
  {
    title: "Amount Refunded",
    dataIndex: "amountRefunded",
    width: 100,
    align: "center",
  },
];

const TransferPaymentData = [
  {
    id: 1,
    transactionNo: "120",
    receiptNo: "1127",
    paymentDate: "12-04-2021",
    amountPaid: "900.00",
    amountDeposited: "900.00",
    paymentMode: "Bank Deposit",
    bankDepDate: "12-04-2021",
    remarks: "Last updated by: anu @ 08/09/2023",
    amountRefunded: "10",
  },
];

const TransferPayment = () => {
  const [
    isAddTransferPaymentModalVisible,
    setIsAddTransferPaymentModalVisible,
  ] = useState(false);

  const data = TransferPaymentData.map((dataObj) => {
    return {
      key: dataObj?.id,
      transactionNo: dataObj?.transactionNo,
      receiptNo: dataObj?.receiptNo,
      paymentDate: dataObj?.paymentDate,
      amountPaid: dataObj?.amountPaid,
      amountDeposited: dataObj?.amountDeposited,
      paymentMode: dataObj?.paymentMode,
      bankDepDate: dataObj?.bankDepDate,
      remarks: dataObj?.remarks,
      amountRefunded: dataObj?.amountRefunded,
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Transfer Payment Between Courses</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddTransferPaymentModalVisible(true);
              }}
            >
              Transfer
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 0,
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddTransferPaymentModalVisible && (
        <AddTransferPayment
          isAddTransferPaymentModalVisible={isAddTransferPaymentModalVisible}
          setIsAddTransferPaymentModalVisible={
            setIsAddTransferPaymentModalVisible
          }
        />
      )}
     
    </Card>
  );
};

export default TransferPayment;
