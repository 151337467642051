import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const ProcessingAgentCommission = ({
  isProcessingAgentCommissionModalVisible,
  setIsProcessingAgentCommissionModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ProcessingAgentCommissionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ProcessingAgentCommission_REQUEST", payload: formData });
    // form.resetFields();
    setIsProcessingAgentCommissionModalVisible(false);
  };
  return (
    <Drawer
      title="Process Commission"
      open={isProcessingAgentCommissionModalVisible}
      onClose={() => {
        setIsProcessingAgentCommissionModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={ProcessingAgentCommissionSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Total amount payable" style={{ width: "100%" }}>
            AUD 53.334
          </Form.Item>
          <Form.Item label="GST amount" style={{ width: "100%" }}>
            AUD 5.33
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Total amount paid" style={{ width: "100%" }}>
            AUD 58.67
          </Form.Item>
          <Form.Item
            label="Invoice No"
            name={"invoiceNo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter invoice number",
              },
            ]}
          >
            <Input placeholder="Invoice No" />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Payment mode"
            name={"paymentMode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select payment mode!",
              },
            ]}
          >
            <Select placeholder="Payment mode" defaultValue={"BANK_DEPOSIT"}>
              <Option value="BANK_DEPOSIT">Bank Diposit</Option>
              <Option value="CREDIT_CARD">Credit Card</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Commission paid date"
            name={"commissionPaidDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a commission paid date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              placeholder="Commission paid date"
            />
          </Form.Item>
        </div>

        <Form.Item label="Remarks" name={"remarks"} style={{ width: "100%" }}>
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Process Commission"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ProcessingAgentCommission;
