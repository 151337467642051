import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import AddButton from "../../../../components/button/AddButton";
import AddTrainingPlan from "./AddTrainingPlan";
import UpdateTrainingPlan from "./UpdateTrainingPlan";
import { MdAssignmentAdd } from "react-icons/md";
import SelectAlert from "../../../../components/SelectAlert/SelectAlert";

const columns = [
  {
    title: "Employer",
    dataIndex: "employer",
    width: 250,
    align: "center",
  },
  {
    title: "Training Contract Id",
    dataIndex: "trainingContractId",
    width: 150,
    align: "center",
  },
  {
    title: "Contract Date",
    dataIndex: "contractDate",
    width: 150,
    align: "center",
  },
  {
    title: "Supervisor",
    dataIndex: "supervisor",
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Contract Type",
    dataIndex: "contractType",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const vaccineDetailsData = [
  {
    id: 1,
    employer: "Rajiv Shrestha",
    trainingContractId: "CON023JB001",
    contractDate: "20-08-2023",
    supervisor: "Prashant Tamang",
    status: "PROPOSED",
    contractType: "Full Time",
  },
];

const TrainingPlan = () => {
  const [isAddTrainingPlanModalVisible, setIsAddTrainingPlanModalVisible] =
    useState(false);
  const [
    isUpdateTrainingPlanModalVisible,
    setIsUpdateTrainingPlanModalVisible,
  ] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const data = vaccineDetailsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      employer: dataObj?.employer,
      trainingContractId: dataObj?.trainingContractId,
      contractDate: dataObj?.contractDate,
      supervisor: dataObj?.supervisor,
      status: dataObj?.status,
      contractType: dataObj?.contractType,
      actions: (
        <Space size="middle">
          <Tooltip title="Update student training plan detail">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateTrainingPlanModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Assign Module to training plan">
            <a>
              <div className="bordered" onClick={() => {}}>
                <MdAssignmentAdd />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete training plan">
            <Popconfirm
              title="Are you sure to delete selected student training plan?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Student Training Plan</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddTrainingPlanModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Course</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Course"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        {selectedRowKeys.length > 0 && selectedVisible && (
          <SelectAlert
            length={selectedRowKeys.length}
            type={"success"}
            closable={true}
            selectAction={
              <div className="fxRJs">
                <ButtonElement
                  size="small"
                  buttonType="primary"
                  onClick={(e) => {}}
                  name="Download CSV"
                >
                  Download CSV
                </ButtonElement>
                <ButtonElement
                  size="small"
                  buttonType="primary"
                  onClick={(e) => {}}
                  name="Download Word"
                >
                  Download Word
                </ButtonElement>
                <ButtonElement
                  size="small"
                  buttonType="primary"
                  onClick={(e) => {}}
                  name="Download PDF"
                >
                  Download PDF
                </ButtonElement>
              </div>
            }
          />
        )}
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddTrainingPlanModalVisible && (
        <AddTrainingPlan
          isAddTrainingPlanModalVisible={isAddTrainingPlanModalVisible}
          setIsAddTrainingPlanModalVisible={setIsAddTrainingPlanModalVisible}
        />
      )}
      {isUpdateTrainingPlanModalVisible && (
        <UpdateTrainingPlan
          isUpdateTrainingPlanModalVisible={isUpdateTrainingPlanModalVisible}
          setIsUpdateTrainingPlanModalVisible={
            setIsUpdateTrainingPlanModalVisible
          }
        />
      )}
    </Card>
  );
};

export default TrainingPlan;
