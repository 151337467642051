import React, { useState } from "react";

import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Accounts from "../Accounts";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {
  ComponentBody,
  Pagename,
  SelectField,
} from "../../../components/input";
import { MdPayment } from "react-icons/md";
import AgentPaymentDetails from "./AgentPaymentDetails/AgentPaymentDetails";
const { Search } = Input;
const columns = [
  {
    title: "Agent ID",
    dataIndex: "id",
    width: 100,
    align: "center",
  },
  {
    title: "Agent Name",
    dataIndex: "agentName",
    width: 200,
    align: "center",
  },
  {
    title: "Person",
    dataIndex: "person",
    width: 200,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 250,
    align: "center",
  },
  {
    title: "User Name",
    dataIndex: "userName",
    width: 200,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const searchAgentsBy = [
  {
    label: "All Agent",
    value: "allAgent",
  },
  {
    label: "Agent ID",
    value: "agentId",
  },
  {
    label: "Agnet Name",
    value: "agentName",
  },
  {
    label: "Status",
    value: "status",
  },
];

const AgentsData = [
  {
    id: "123",
    agentName: "360 Life Experience",
    person: "Viviana Andrea Corredor Cuellar",
    email: "	angela.help@360lifexperience.com",
    userName: "",
    status: "Active",
  },
  {
    id: "29",
    agentName: "3Bees Education and Visa Services",
    person: "Madhav Shrestha",
    email: "madhav@3beesgroup.com.au",
    userName: "",
    status: "Provisional",
  },
  {
    id: "29",
    agentName: "Anong Group Pty. Ltd.",
    person: "Aiden Parker",
    email: "info@wisevision.com.au",
    userName: "",
    status: "New Application Request",
  },
];

const AgentPayment = () => {
  const [agentStatus, setAgentStatus] = useState("ACTIVE");
  const [searchBy, setSearchBy] = useState("status");
  const [
    isAgentPaymentDetailsModalVisible,
    setIsAgentPaymentDetailsModalVisible,
  ] = useState(false);
  let data = AgentsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      agentName: dataObj?.agentName,
      person: dataObj?.person,
      email: dataObj?.email,
      userName: dataObj?.userName,
      status: dataObj?.status,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Agents Payment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsAgentPaymentDetailsModalVisible(true);
                }}
              >
                <MdPayment />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Accounts tab={"/agent-payment"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Agent Management</Pagename>
          </div>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Search by</div>
              <SelectField
                name="searchAgentsBy"
                placeholder="Search Agents By"
                options={searchAgentsBy}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="12rem"
              />
            </div>
            {searchBy === "status" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <Radio.Group
                  defaultValue="ACTIVE"
                  value={agentStatus}
                  onChange={(e) => {
                    setAgentStatus(e.target.value);
                  }}
                  style={{ width: "100%" }}
                >
                  <Radio.Button value="ACTIVE" style={{ borderRadius: "3px" }}>
                    Active
                  </Radio.Button>
                  <Radio.Button
                    value="INACTIVE"
                    style={{ borderRadius: "3px" }}
                  >
                    Inactive
                  </Radio.Button>
                </Radio.Group>
              </div>
            )}

            {(searchBy === "agentName" || searchBy === "agentId") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Search: </div>
                <Search
                  style={{ borderRadius: "3px", width: "100%" }}
                  className="search"
                  placeholder={`Search Agents.`}
                  allowClear
                  size="Medium"
                  //   value={searchValue}
                  onChange={(e) => {
                    //   setSearchValue(e.target.value);
                    //   onSearch(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "2rem",
          }}
        >
          <Table
            size="small"
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
      {isAgentPaymentDetailsModalVisible && (
        <AgentPaymentDetails
          isAgentPaymentDetailsModalVisible={isAgentPaymentDetailsModalVisible}
          setIsAgentPaymentDetailsModalVisible={
            setIsAgentPaymentDetailsModalVisible
          }
        />
      )}
    </Accounts>
  );
};

export default AgentPayment;
