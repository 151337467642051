import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Communication from "../Communication";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import AddButton from "../../../components/button/AddButton";
import SelectAlert from "../../../components/SelectAlert/SelectAlert";
import AddStaffDiary from "../../Staff/StaffDetails/StaffDiary/AddStaffDiary";
const { Search } = Input;

const columns = [
  {
    title: "Student ID",
    dataIndex: "studentId",
    width: 200,
    align: "center",
  },
  {
    title: "Student Name",
    dataIndex: "studentName",
    width: 150,
    align: "center",
  },
  {
    title: "Nick Name",
    dataIndex: "nickName",
    width: 150,
    align: "center",
  },
  {
    title: "Course ID",
    dataIndex: "courseId",
    width: 150,
    align: "center",
  },

  {
    title: "Course Attempt",
    dataIndex: "courseAttempt",
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 250,
    align: "center",
  },
];

const selectType = [
  {
    label: "Student Class List",
    value: "studentClassList",
  },
  {
    label: "Student Course",
    value: "studentcourse",
  },
  {
    label: "Student Course Between Dates",
    value: "studentCourseBetweenDates",
  },

  {
    label: "Subject Starting Between Dates",
    value: "subjectStartingBetweenDates",
  },
  {
    label: "Subject Census Date Between Dates",
    value: "subjectCensusDateBetweenDates",
  },
  {
    label: "Particular Student",
    value: "particularStudent",
  },
  {
    label: "Semester and Term",
    value: "semesterAndTerm",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const applicationStatus = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Withdrawn",
    value: "WITHDRAWN",
  },
  {
    label: "Unapproved",
    value: "UNAPPROVED",
  },
  {
    label: "New Course Request",
    value: "newCourseRequest",
  },
  {
    label: "New Application Request",
    value: "newApplicationRequest",
  },
  {
    label: "Pending",
    value: "panding",
  },
  {
    label: "Enrolled",
    value: "ENROLLED",
  },
  {
    label: "Converted",
    value: "converted",
  },
  {
    label: "Finished",
    value: "FINISHED",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    label: "Expired Offer",
    value: "expiredOffer",
  },

  {
    label: "Reconsider",
    value: "reconsider",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Offered",
    value: "offered",
  },
];

const eventData = [
  {
    id: 1,
    studentId: "ID20221031",
    studentName: "Smarika Nepal",
    nickName: "Smarika",
    courseId: "GE",
    courseAttempt: "1",
    status: "Current Student",
  },
  {
    id: 2,
    studentId: "OPS00001015",
    studentName: "Prasansha Hamal Adhikari",
    nickName: "Prasansha",
    courseId: "EAP",
    courseAttempt: "1",
    status: "Current Student",
  },
];

const CommunicationLog = () => {
  const [searchBy, setSearchBy] = useState("offerId");
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const [isAddStaffDiaryModalVisible, setIsAddStaffDiaryModalVisible] =
    useState(false);

  const data = eventData.map((dataObj) => {
    return {
      id: dataObj?.id,
      key: dataObj?.id,
      studentId: dataObj?.studentId,
      studentName: dataObj?.studentName,
      nickName: dataObj?.nickName,
      courseId: dataObj?.courseId,
      courseAttempt: dataObj?.courseAttempt,
      status: dataObj?.status,
    };
  });
  return (
    <Communication tab={"/communication-log"}>
      <ComponentBody>
        <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div>Select Type:</div>
            <SelectField
              name="selectType"
              placeholder="Select Type:"
              options={selectType}
              handleChange={(e) => {
                setSearchBy(e);
              }}
              fieldWidth="10rem"
            />
          </div>
          {searchBy !== "particularStudent" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select Course Type:</div>
              <SelectField
                name="courseType"
                placeholder="Course Type"
                options={[
                  {
                    label: "ELICOS",
                    value: "elicos",
                  },
                ]}
                fieldWidth="12rem"
              />
            </div>
          )}
          {(searchBy === "studentcourse" ||
            searchBy === "studentCourseBetweenDates" ||
            searchBy === "subjectStartingBetweenDates" ||
            searchBy === "subjectCensusDateBetweenDates") && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select Course Status:</div>
              <SelectField
                name="selectCourseStatus"
                placeholder="Select Course Status"
                options={[
                  {
                    label: "Active",
                    value: "active",
                  },
                  {
                    label: "Inactive",
                    value: "inactive",
                  },
                  {
                    label: "All",
                    value: "all",
                  },
                ]}
                fieldWidth="12rem"
              />
            </div>
          )}
          {(searchBy === "studentcourse" ||
            searchBy === "studentCourseBetweenDates" ||
            searchBy === "subjectStartingBetweenDates" ||
            searchBy === "subjectCensusDateBetweenDates") && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select Course:</div>
              <SelectField
                name="selectCourseStatus"
                placeholder="Select Course Status"
                options={selectCourse}
                fieldWidth="12rem"
              />
            </div>
          )}
          {(searchBy === "semesterAndTerm" ||
            searchBy === "studentClassList") && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select semester:</div>
              <SelectField
                name="selectSemester"
                placeholder="Select semester"
                options={[
                  {
                    label: "2023",
                    value: "2023",
                  },
                ]}
                fieldWidth="12rem"
              />
            </div>
          )}
          {(searchBy === "semesterAndTerm" ||
            searchBy === "studentClassList") && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select term:</div>
              <SelectField
                name="selectTerm"
                placeholder="Select Term"
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                ]}
                fieldWidth="12rem"
              />
            </div>
          )}
          {(searchBy === "subjectCensusDateBetweenDates" ||
            searchBy === "subjectStartingBetweenDates") && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Subject:</div>
              <SelectField
                name="subject"
                placeholder="Subject"
                options={[]}
                fieldWidth="12rem"
              />
            </div>
          )}
          {searchBy === "particularStudent" && (
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Student Id:</div>
              <Search
                style={{ borderRadius: "3px", width: "100%" }}
                className="search"
                placeholder={`Search student ID.`}
                allowClear
                size="Medium"
                //   value={searchValue}
                onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   onSearch(e.target.value);
                }}
              />
            </div>
          )}
          {searchBy === "studentClassList" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Specify Batch:</div>
              <Search
                style={{ borderRadius: "3px", width: "100%" }}
                className="search"
                placeholder={`Search Batch.`}
                allowClear
                size="Medium"
                //   value={searchValue}
                onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   onSearch(e.target.value);
                }}
              />
            </div>
          )}
          {searchBy === "studentClassList" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select Class:</div>
              <SelectField
                name="selectClass"
                placeholder="Select Class"
                options={[]}
                fieldWidth="12rem"
              />
            </div>
          )}
          {searchBy === "studentClassList" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Include student(s) already finished the class in last:</div>
              <SelectField
                name="selectClass"
                placeholder="Select Class"
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                ]}
                fieldWidth="12rem"
              />{" "}
              Weeks
            </div>
          )}

          {(searchBy === "studentcourse" ||
            searchBy === "studentCourseBetweenDates") && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select Status:</div>
              <SelectField
                name="selectStatus"
                placeholder="Select Status"
                options={applicationStatus}
                fieldWidth="12rem"
              />
            </div>
          )}
          {searchBy === "studentCourseBetweenDates" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Course Duration Between:</div>
              <DatePicker format={"DD-MM-YYYY"} /> and{" "}
              <DatePicker format={"DD-MM-YYYY"} />
            </div>
          )}

          {searchBy === "subjectStartingBetweenDates" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Subject Starting Between:</div>
              <DatePicker format={"DD-MM-YYYY"} /> and{" "}
              <DatePicker format={"DD-MM-YYYY"} />
            </div>
          )}
          {searchBy === "subjectCensusDateBetweenDates" && (
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Subject Census Between:</div>
              <DatePicker format={"DD-MM-YYYY"} /> and{" "}
              <DatePicker format={"DD-MM-YYYY"} />
            </div>
          )}

          <ButtonElement name="View Student List" />
        </div>
      </ComponentBody>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Communication Log/Diary - Bulk</Pagename>
            <div
              style={{ display: "flex", justifyContent: "flex-start" }}
            ></div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
            gap: "0.7rem",
          }}
        >
          {selectedRowKeys.length > 0 && selectedVisible && (
            <SelectAlert
              length={selectedRowKeys.length}
              type={"success"}
              closable={true}
              selectAction={
                <ButtonElement
                  size="small"
                  buttonType="primary"
                  handleClick={(e) => {
                    setIsAddStaffDiaryModalVisible(true);
                  }}
                  name="Add Communication Log (Diary)"
                />
              }
            />
          )}
          <Table
            bordered
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            columns={columns}
            dataSource={data}
          />
        </div>
      </ComponentBody>
      {isAddStaffDiaryModalVisible && (
        <AddStaffDiary
          isAddStaffDiaryModalVisible={isAddStaffDiaryModalVisible}
          setIsAddStaffDiaryModalVisible={setIsAddStaffDiaryModalVisible}
        />
      )}
    </Communication>
  );
};

export default CommunicationLog;
