import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import { TbReport } from "react-icons/tb";
import AddCreditBonusAllocation from "./AddCreditBonusAllocation";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../../components/button/AddButton";
import { Pagename } from "../../../../../components/input";
import UpdateCreditBonusAllocation from "./UpdateCreditBonusAllocation";

const columns = [
  {
    title: "Credit No",
    dataIndex: "creditNo",
    width: 250,
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "type",
    width: 150,
    align: "center",
  },
  {
    title: "Std Trans No",
    dataIndex: "stdTransNo",
    width: 150,
    align: "center",
  },
  {
    title: "Credit Amount",
    dataIndex: "creditAmount",
    width: 100,
    align: "center",
  },
  {
    title: "Pay Mode",
    dataIndex: "payMode",
    width: 100,
    align: "center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    width: 100,
    align: "center",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    width: 100,
    align: "center",
  },
  {
    title: "Credit Used",
    dataIndex: "creditUsed",
    width: 100,
    align: "center",
  },
  {
    title: "Credit Remained",
    dataIndex: "creditRemained",
    width: 100,
    align: "center",
  },
  {
    title: "Credit Used Information",
    dataIndex: "creditUsedInformation",
    width: 100,
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const CreditBonusAllocationData = [
  {
    id: 1,
    creditNo: "1",
    type: "Agent Credit",
    stdTransNo: "0",
    creditAmount: "Credit Amount",
    payMode: "Bank Deposit",
    paidDate: "10-09-2023",
    comment: "",
    creditUsed: "0.00",
    creditRemained: "1000.00",
    creditUsedInformation: "No record found",
  },
];

const CreditBonusAllocation = () => {
  const [
    isAddCreditBonusAllocationModalVisible,
    setIsAddCreditBonusAllocationModalVisible,
  ] = useState(false);
  const [
    isUpdateCreditBonusAllocationModalVisible,
    setIsUpdateCreditBonusAllocationModalVisible,
  ] = useState(false);

  const data = CreditBonusAllocationData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      creditNo: dataObj?.creditNo,
      type: dataObj?.type,
      stdTransNo: dataObj?.stdTransNo,
      creditAmount: dataObj?.creditAmount,
      payMode: dataObj?.payMode,
      paidDate: dataObj?.paidDate,
      comment: dataObj?.comment,
      creditUsed: dataObj?.creditUsed,
      creditRemained: dataObj?.creditRemained,
      creditUsedInformation: dataObj?.creditUsedInformation,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Info">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateCreditBonusAllocationModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Delete Entry">
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Agent Credit or Bonus Allocation</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddCreditBonusAllocationModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddCreditBonusAllocationModalVisible && (
        <AddCreditBonusAllocation
          isAddCreditBonusAllocationModalVisible={
            isAddCreditBonusAllocationModalVisible
          }
          setIsAddCreditBonusAllocationModalVisible={
            setIsAddCreditBonusAllocationModalVisible
          }
        />
      )}
      {isUpdateCreditBonusAllocationModalVisible && (
        <UpdateCreditBonusAllocation
          isUpdateCreditBonusAllocationModalVisible={
            isUpdateCreditBonusAllocationModalVisible
          }
          setIsUpdateCreditBonusAllocationModalVisible={
            setIsUpdateCreditBonusAllocationModalVisible
          }
        />
      )}
    </Card>
  );
};

export default CreditBonusAllocation;
