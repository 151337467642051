import { Card, Popconfirm, Radio, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import { BiBlock } from "react-icons/bi";
import AddServiceProviderFacility from "./AddServiceProviderFacility";
import UpdateServiceProviderFacility from "./UpdateServiceProviderFacility";

const columns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
    width: 200,
    align: "center",
  },
  {
    title: "Category Name",
    dataIndex: "categoryName",
    width: 150,
    align: "center",
  },
  {
    title: "Faclity Name",
    dataIndex: "faclityName",
    width: 200,
    align: "center",
  },
  {
    title: "Service Provider Price",
    dataIndex: "serviceProviderPrice",
    width: 100,
    align: "center",
  },
  {
    title: "Std Price",
    dataIndex: "stdPrice",
    width: 100,
    align: "center",
  },
  {
    title: "GST Inc.",
    dataIndex: "gstInc",
    width: 150,
    align: "center",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    width: 150,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 50,
    align: "center",
  },
];

const ServiceProviderFacilityData = [
  {
    id: "1",
    serviceName: "Airport Pickup",
    categoryName: "Transport",
    faclityName: "Bus",
    serviceProviderPrice: "20.00",
    stdPrice: "20.00",
    gstInc: "NO",
    comment: "",
    status: "ACTIVE",
  },
  {
    id: "2",
    serviceName: "Airport Pickup",
    categoryName: "Transport",
    faclityName: "Taxi",
    serviceProviderPrice: "50.00",
    stdPrice: "50.00",
    gstInc: "NO",
    comment: "",
    status: "ACTIVE",
  },
];

const ServiceProviderFacility = () => {
  const [serviceProviderFacilityStatus, setServiceProviderFacilityStatus] =
    useState("ALL");
  const [
    isAddServiceProviderFacilityModalVisible,
    setIsAddServiceProviderFacilityModalVisible,
  ] = useState(false);
  const [
    isUpdateServiceProviderFacilityModalVisible,
    setIsUpdateServiceProviderFacilityModalVisible,
  ] = useState(false);
  const [isUpdateFacilityModalVisible, setIsUpdateFacilityModalVisible] =
    useState(false);
  const [
    isUpdateServiceFacilityModalVisible,
    setIsUpdateServiceFacilityModalVisible,
  ] = useState(false);

  const data = ServiceProviderFacilityData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      serviceName: dataObj?.serviceName,
      categoryName: dataObj?.categoryName,
      faclityName: dataObj?.faclityName,
      serviceProviderPrice: dataObj?.serviceProviderPrice,
      stdPrice: dataObj?.stdPrice,
      gstInc: dataObj?.gstInc,
      comment: dataObj?.comment,
      status: dataObj?.status,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Data">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateServiceProviderFacilityModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Facility">
            <Popconfirm
              title="Are you sure to delete this Facility?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Activate Selected Facility">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlinePoweroff />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Deactivate Selected Facility">
            <a style={{ color: "red" }}>
              <div className="bordered" onClick={() => {}}>
                <BiBlock />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Service Provider Facilities Setup</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddServiceProviderFacilityModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Service Provider</div>
              <SelectField
                name="serviceProvider"
                placeholder="Service Provider"
                options={[
                  {
                    label: "UPS",
                    value: "UPS",
                  },
                  {
                    label: "DHL",
                    value: "DHL",
                  },
                  {
                    label: "Australian Express",
                    value: "AUSTRALIAN_EXPRESS",
                  },
                  {
                    label: "ABC Home Stay",
                    value: "ABC Home Stay",
                  },
                ]}
                fieldWidth="9rem"
              />
            </div>
            <Radio.Group
              defaultValue="ALL"
              value={serviceProviderFacilityStatus}
              onChange={(e) => {
                setServiceProviderFacilityStatus(e.target.value);
              }}
              style={{ width: "100%" }}
            >
              <Radio.Button value="ALL" style={{ borderRadius: "3px" }}>
                All
              </Radio.Button>
              <Radio.Button value="ACTIVE" style={{ borderRadius: "3px" }}>
                Active
              </Radio.Button>
              <Radio.Button value="INACTIVE" style={{ borderRadius: "3px" }}>
                Inactive
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{
            x: 0,
          }}
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddServiceProviderFacilityModalVisible && (
        <AddServiceProviderFacility
          isAddServiceProviderFacilityModalVisible={
            isAddServiceProviderFacilityModalVisible
          }
          setIsAddServiceProviderFacilityModalVisible={
            setIsAddServiceProviderFacilityModalVisible
          }
        />
      )}
      {isUpdateServiceProviderFacilityModalVisible && (
        <UpdateServiceProviderFacility
          isUpdateServiceProviderFacilityModalVisible={
            isUpdateServiceProviderFacilityModalVisible
          }
          setIsUpdateServiceProviderFacilityModalVisible={
            setIsUpdateServiceProviderFacilityModalVisible
          }
        />
      )}
    </div>
  );
};

export default ServiceProviderFacility;
