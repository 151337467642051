import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdatePaymentSchedule = ({
  isUpdatePaymentScheduleModalVisible,
  setIsUpdatePaymentScheduleModalVisible,
}) => {
  const [paymentScheduleData, setPaymentScheduleData] = useState({
    radio: "Update this schedule only",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdatePaymentScheduleSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdatePaymentSchedule_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdatePaymentScheduleModalVisible(false);
  };
  return (
    <Drawer
      title="Update Payment Schedule"
      open={isUpdatePaymentScheduleModalVisible}
      onClose={() => {
        setIsUpdatePaymentScheduleModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdatePaymentScheduleSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Invoice Start Date"
            name={"invoiceStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a invoice start date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a due date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Agent Name"
            name={"agentName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a agent name",
              },
            ]}
          >
            <Select
              placeholder="Agent Name"
              defaultValue={"Opera Education Group Pty Ltd"}
            >
              <Option value="Opera Education Group Pty Ltd">
                Opera Education Group Pty Ltd
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Invoice No"
            name={"invoiceNo"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Invoice No" />
          </Form.Item>
        </div>
        <Form.Item
          label="Amount For This Schedule (AUD)"
          name={"amountForThisSchedule"}
          rules={[
            {
              required: true,
              message: "Please enter amount for this schedule!",
            },
          ]}
        >
          <Input type="number" placeholder="" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            style={{ width: "100%" }}
            name={"invoiceSent"}
            valuePropName="checked"
          >
            <Checkbox>Invoice Sent</Checkbox>
          </Form.Item>
          <Form.Item
            label="Invoice Sent On"
            name={"invoiceSentOn"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter !",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <Form.Item label="Remarks" name={"remarks"}>
          <TextArea placeholder="Write something..." />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Frequency"
              name={"frequency"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a frequency!",
                },
              ]}
            >
              <Input type="number" placeholder="Frequency" />
            </Form.Item>
            <Form.Item
              name={"frequencyType"}
              style={{ width: "100%", alignSelf: "flex-end" }}
              rules={[
                {
                  required: true,
                  message: "Please select a frequency type!",
                },
              ]}
            >
              <Select placeholder="Frequency Type">
                <Option value="DAY">Day</Option>
                <Option value="WEEK">Week</Option>
                <Option value="MONTH">Month</Option>
                <Option value="Year">Year</Option>
              </Select>
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              label="Agent Commission"
              name={"agentCommission"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a commission percentage!",
                },
              ]}
            >
              <Input type="number" placeholder="" />
            </Form.Item>
            <Form.Item
              name={"isGst"}
              style={{ width: "100%", alignSelf: "flex-end" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a commission percentage!",
                },
              ]}
            >
              <Radio.Group defaultValue={"NO_GST"}>
                <Radio value={"GST"}>GST</Radio>
                <Radio value={"NO_GST"}>No GST</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        {paymentScheduleData.radio ===
          "Update all remaining unpaid schedules" && (
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Form.Item
              label="Total Due Amount"
              name={"totalDueAmount"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a total due amount!",
                },
              ]}
            >
              <Input type="number" placeholder="Total Due Amount" />
            </Form.Item>
            <Form.Item
              label="No. of Installment"
              name={"noOfInstallment"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter no. of installment!",
                },
              ]}
            >
              <Input type="number" placeholder="No. of Installment" />
            </Form.Item>
          </div>
        )}
        <Form.Item
          name={"radio"}
          style={{ width: "100%", alignSelf: "flex-end" }}
        >
          <Radio.Group
            value={paymentScheduleData}
            defaultValue={"Update this schedule only"}
            onChange={(e) => {
              setPaymentScheduleData((prevData) => {
                return {
                  radio: e.target.value,
                };
              });
            }}
          >
            <Radio value={"Update this schedule only"}>
              Update this schedule only
            </Radio>
            <Radio value={"Update all remaining unpaid schedules"}>
              Update all remaining unpaid schedules
            </Radio>
            <Radio value={"Delete this schedule only"}>
              Delete this schedule only
            </Radio>
            <Radio value={"Delete all remaining unpaid schedules"}>
              Delete all remaining unpaid schedules
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Payment Schedule"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdatePaymentSchedule;
