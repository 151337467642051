import { Table, Tooltip } from "antd";
import React from "react";
import { ButtonElement, SelectField } from "../../../../components/input";
import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import AddManageRoom from "./AddManageRoom";
import EditManageRoom from "./EditManageRoom";

export default function ManageRoom() {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Venue Code",
      dataIndex: "venue_code",
      align: "center",
    },
    {
      title: "Room Id",
      dataIndex: "room_id",
      align: "center",
    },
    {
      title: "Room Name",
      dataIndex: "room_name",
      align: "center",
    },
    {
      title: "Max Capacity	",
      dataIndex: "max_capacity",
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "active",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: () => (
        <div className="flex justify-center gap-2">
          <Tooltip placement="top" title="Manage Room">
            <EditOutlined
              onClick={() => handleAddEditRoom("editRoom")}
              className="text-[blue] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[blue] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000"
            />
          </Tooltip>{" "}
          <Tooltip placement="top" title="Delete Record">
            <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
          </Tooltip>{" "}
        </div>
      ),
    },
  ];

  const data = [
    {
      venue_code: "Opera_HO",
      room_id: "EAP(A/pm)	",
      room_name: "EAP(A/pm)",
      max_capacity: "18 seats",
      active: "Yes",
    },

    {
      venue_code: "Opera_HO",
      room_id: "EAP(A/pm)	",
      room_name: "EAP(A/pm)",
      max_capacity: "18 seats",
      active: "Yes",
    },
    {
      venue_code: "Opera_HO",
      room_id: "EAP(A/pm)	",
      room_name: "EAP(A/pm)",
      max_capacity: "18 seats",
      active: "Yes",
    },
    {
      venue_code: "Opera_HO",
      room_id: "EAP(A/pm)	",
      room_name: "EAP(A/pm)",
      max_capacity: "18 seats",
      active: "Yes",
    },
  ];

  const roomList = [
    {
      label: "Opera Head Office",
      value: 1,
    },
    {
      label: "All",
      value: 2,
    },
  ];

  const handleAddEditRoom = (value) => {
    dispatch({ type: "FETCH_OPEN_MODAL", payload: value });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <ButtonElement
          handleClick={() => handleAddEditRoom("addRoom")}
          Icon={<PlusOutlined className="text-sm" />}
          name="Room"
        />
        <SelectField
          name="view_room_by"
          label="View Room By"
          placeholder="Select View Room"
          options={roomList}
        />
      </div>
      <Table dataSource={data} columns={columns} />
      <AddManageRoom />
      <EditManageRoom />
    </div>
  );
}
