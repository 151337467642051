import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import { Input, Popconfirm, Radio, Space, Table, Tooltip } from "antd";
import Communication from "../Communication";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Pagename } from "../../../components/input";
import AddButton from "../../../components/button/AddButton";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import AddEvent from "./AddEvent";
import UpdateEvent from "./UpdateEvent";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import AssignAccessToEvent from "./AssignAccessToEvent";
const { Search } = Input;

const columns = [
  {
    title: "Event Heading",
    dataIndex: "eventHeading",
    width: 200,
    align: "center",
  },
  {
    title: "Posted Date",
    dataIndex: "postedDate",
    width: 150,
    align: "center",
  },
  {
    title: "Date Range",
    dataIndex: "dateRange",
    width: 150,
    align: "center",
  },
  {
    title: "Event Type",
    dataIndex: "eventType",
    width: 150,
    align: "center",
  },
  {
    title: "Announcement",
    dataIndex: "announcement",
    width: 250,
    align: "center",
  },
  {
    title: "Recurring Interval",
    dataIndex: "recurringInterval",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const eventData = [
  {
    id: 1,
    eventHeading: "Sports Carnival",
    postedDate: "30-08-2023",
    dateRange: "31-08-2023 / 01-09-2023",
    eventType: "News & Events",
    announcement: "",
    recurringInterval: "Once Only",
  },
  {
    id: 2,
    eventHeading: "Sports Carnival ",
    postedDate: "30-08-2023",
    dateRange: "31-08-2023 / 01-09-2023",
    eventType: "Reminder",
    announcement: "",
    recurringInterval: "Monthly on 1",
  },
];

const ManageNewsAndReminder = () => {
  const [isAddEventModalVisible, setIsAddEventModalVisible] = useState(false);
  const [isUpdateEventModalVisible, setIsUpdateEventModalVisible] =
    useState(false);
  const [
    isAssignAccessToEventModalVisible,
    setIsAssignAccessToEventModalVisible,
  ] = useState(false);
  const data = eventData.map((dataObj) => {
    return {
      id: dataObj?.id,
      key: dataObj?.id,
      eventHeading: dataObj?.eventHeading,
      postedDate: dataObj?.postedDate,
      dateRange: dataObj?.dateRange,
      eventType: dataObj?.eventType,
      announcement: dataObj?.announcement,
      recurringInterval: dataObj?.recurringInterval,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "150px" }}
        >
          <Tooltip title="Edit Comment">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateEventModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Assign Access to Event">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsAssignAccessToEventModalVisible(true);
                }}
              >
                <MdOutlineAssignmentTurnedIn />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Event">
            <Popconfirm
              title="Are you sure to delete the selected event?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Communication tab={"/manage-news-and-reminder"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Manage News & Events/Reminders</Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <AddButton
                handleClick={() => {
                  setIsAddEventModalVisible(true);
                }}
              >
                Add
              </AddButton>
            </div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table columns={columns} dataSource={data} />
        </div>
      </ComponentBody>
      {isAddEventModalVisible && (
        <AddEvent
          isAddEventModalVisible={isAddEventModalVisible}
          setIsAddEventModalVisible={setIsAddEventModalVisible}
        />
      )}
      {isUpdateEventModalVisible && (
        <UpdateEvent
          isUpdateEventModalVisible={isUpdateEventModalVisible}
          setIsUpdateEventModalVisible={setIsUpdateEventModalVisible}
        />
      )}
      {isAssignAccessToEventModalVisible && (
        <AssignAccessToEvent
          isAssignAccessToEventModalVisible={isAssignAccessToEventModalVisible}
          setIsAssignAccessToEventModalVisible={
            setIsAssignAccessToEventModalVisible
          }
        />
      )}
    </Communication>
  );
};

export default ManageNewsAndReminder;
