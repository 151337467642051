import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../components/button/AddButton";
import { Pagename } from "../../../../components/input";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePoweroff,
  AiOutlineUpload,
} from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import AddStaffProfessionalDevelopment from "./AddStaffProfessionalDevelopment";
import UpdateStaffProfessionalDevelopment from "./UpdateStaffProfessionalDevelopment";
import { GoVerified } from "react-icons/go";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: 250,
    align: "center",
  },
  {
    title: "Category",
    dataIndex: "category",
    width: 150,
    align: "center",
  },
  {
    title: "Event Name",
    dataIndex: "eventName",
    width: 100,
    align: "center",
  },
  {
    title: "Organized By",
    dataIndex: "organizedBy",
    width: 150,
    align: "center",
  },

  {
    title: "Activity Name",
    dataIndex: "activityName",
    width: 350,
    align: "center",
  },
  {
    title: "Event Date",
    dataIndex: "eventDate",
    width: 150,
    align: "center",
  },
  {
    title: "No. of Days",
    dataIndex: "noOfDays",
    width: 100,
    align: "center",
  },
  {
    title: "CPD Points",
    dataIndex: "cpdPoints",
    width: 100,
    align: "center",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    width: 100,
    align: "center",
  },
  {
    title: "Date Recorded",
    dataIndex: "dateRecorded",
    width: 100,
    align: "center",
  },
  {
    title: "File Attachment",
    dataIndex: "fileAttachment",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    name: "Alexander Freitag",
    category: "Conference",
    eventName: "Conference on Effective Learning Process",
    organizedBy: "Lions Club",
    activityName: "Guest Speaker",
    eventDate: "10-07-2023",
    noOfDays: "5",
    cpdPoints: "10",
    comments:
      "Discussion on Problem and Challenges on current learning process and its resolutions",
    dateRecorded: "10-08-2023",
    fileAttachment: "Report on Student's curriculam development",
  },
];

const StaffProfessionalDevelopment = () => {
  const [
    isAddStaffProfessionalDevelopmentModalVisible,
    setIsAddStaffProfessionalDevelopmentModalVisible,
  ] = useState(false);
  const [
    isUpdateStaffProfessionalDevelopmentModalVisible,
    setIsUpdateStaffProfessionalDevelopmentModalVisible,
  ] = useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      name: dataObj?.name,
      category: dataObj?.category,
      eventName: dataObj?.eventName,
      organizedBy: dataObj?.organizedBy,
      activityName: dataObj?.activityName,
      eventDate: dataObj?.eventDate,
      noOfDays: dataObj?.noOfDays,
      cpdPoints: dataObj?.cpdPoints,
      comments: dataObj?.comments,
      dateRecorded: dataObj?.dateRecorded,
      fileAttachment: dataObj?.fileAttachment,
      actions: (
        <Space
          size="middle"
          style={{ display: "flex", flexWrap: "wrap", width: "150px" }}
        >
          <Tooltip title="Update Professional Development Activity">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateStaffProfessionalDevelopmentModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Upload Professional Development Activit Evidience">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineUpload />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Verify Professional Development Activity">
            <a style={{ color: "red" }}>
              <div className="bordered" onClick={() => {}}>
                <GoVerified />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Professional Development Activity">
            <Popconfirm
              title="Are you sure to delete this professional development activity?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }} s>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Professional Development</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddStaffProfessionalDevelopmentModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          scroll={{ x: 0 }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddStaffProfessionalDevelopmentModalVisible && (
        <AddStaffProfessionalDevelopment
          isAddStaffProfessionalDevelopmentModalVisible={
            isAddStaffProfessionalDevelopmentModalVisible
          }
          setIsAddStaffProfessionalDevelopmentModalVisible={
            setIsAddStaffProfessionalDevelopmentModalVisible
          }
        />
      )}
      {isUpdateStaffProfessionalDevelopmentModalVisible && (
        <UpdateStaffProfessionalDevelopment
          isUpdateStaffProfessionalDevelopmentModalVisible={
            isUpdateStaffProfessionalDevelopmentModalVisible
          }
          setIsUpdateStaffProfessionalDevelopmentModalVisible={
            setIsUpdateStaffProfessionalDevelopmentModalVisible
          }
        />
      )}
    </Card>
  );
};

export default StaffProfessionalDevelopment;
