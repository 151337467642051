import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddOfferDocumentChecklist = ({
  isAddOfferDocumentChecklistModalVisible,
  setIsAddOfferDocumentChecklistModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddOfferDocumentChecklistSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddOfferDocumentChecklist_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddOfferDocumentChecklistModalVisible(false);
  };
  return (
    <Modal
      title="Add Offer Document Checklist"
      open={isAddOfferDocumentChecklistModalVisible}
      onCancel={() => {
        setIsAddOfferDocumentChecklistModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddOfferDocumentChecklistSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Document Name"
            name={"documentName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a document name!",
              },
            ]}
          >
            <Input placeholder="Document Name" />
          </Form.Item>
          <Form.Item
            label="Document Type"
            name={"Document Type"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a document type!",
              },
            ]}
          >
            <Select placeholder="Document Type">
              <Option value={"IN_APPLICATION"}>In Application</Option>
              <Option value={"POST_APPLICATION"}>Post Application</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Select Student Origin"
            name={"studentOrigin"}
            valuePropName="checked"
          >
            <Checkbox.Group>
              <Checkbox value={"OVERSEAS_STUDENT_OFFSHORE"}>
                Overseas Student (Offshore)
              </Checkbox>
              <Checkbox value={"OVERSEAS_STUDENT_IN_AUSTRALIA_ONSHORE"}>
                Overseas Student in Australia (Onshore)
              </Checkbox>
              <Checkbox value={"RESIDENT_STUDENT_DOMESTIC"}>
                Resident Student (Domestic)
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            className="w-full"
            name={"isCompulsory"}
            valuePropName="checked"
          >
            <Checkbox>Set Compulsory</Checkbox>
          </Form.Item>
          <Form.Item
            className="w-full"
            name={"isActive"}
            valuePropName="checked"
          >
            <Checkbox>Set Active</Checkbox>
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Offer Document Checklist"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOfferDocumentChecklist;
