import { lazy } from "react";

//staff page
const Staff = lazy(() => import("./Staff/Staff"));

//user page
const ManageUserAccount = lazy(() => import("./user/ManageUserAccount"));
const BulkCreateStudentUserAccount = lazy(() =>
  import("./user/BulkCreateStudentUserAccount")
);
const BulkStudentUserAccountManagement = lazy(() =>
  import("./user/BulkStudentUserAccountManagement")
);
const UserIpActivityTrack = lazy(() => import("./user/UserIpActivityTrack"));

//course page
const Subject = lazy(() => import("./Courses/Subject/Subject"));
const ViewCourses = lazy(() => import("./Courses/ViewCourses.js/ViewCourses"));
const CourseSubject = lazy(() =>
  import("./Courses/CourseSubject/CourseSubject")
);
const CourseTemplate = lazy(() =>
  import("./Courses/CourseTemplate/CourseTemplate")
);
const CourseUpfrontFee = lazy(() =>
  import("./Courses/CourseUpfrontFee/CourseUpfrontFee")
);
const CoursePromotion = lazy(() =>
  import("./Courses/CoursePromotion/CoursePromotion")
);
const IntakeDates = lazy(() => import("./Courses/IntakeDates/IntakeDates"));
const PublicHoliday = lazy(() =>
  import("./Courses/PublicHoliday/PublicHoliday")
);

//timetable page
const ManageClassTimetable = lazy(() =>
  import("./Timetable/ManageClassTimetable/ManageClassTimetable")
);
const ManageAssessmentGroup = lazy(() =>
  import("./Timetable/ManageAssessmentGroup/ManageAssessmentGroup")
);
// const TimetableReport = lazy(() =>
//   import("./Timetable/TimetableReport/TimetableReport")
// );
const ViewTimetable = lazy(() =>
  import("./Timetable/ViewTimetable/ViewTimetable")
);
// const ClassCapacityReport = lazy(() =>
//   import("./Timetable/ClassCapacityReport/ClassCapacityReport")
// );
const ReplacementTeacher = lazy(() =>
  import("./Timetable/ReplacementTeacher/ReplacementTeacher")
);
// const PrintAttendanceList = lazy(() =>
//   import("./Timetable/PrintAttendanceList/PrintAddtendanceList")
// );

//teacher
const ManageTeacher = lazy(() => import("./Teacher/ViewTeacher/ViewTeacher"));
const TeacherMatrix = lazy(() =>
  import("./Teacher/TeacherMatrix/TeacherMatrix")
);

//offers
const ViewApplicantList = lazy(() =>
  import("./Offers/ViewApplicantList/ViewApplicantList")
);
const ApplyOnlineByAdmin = lazy(() =>
  import("./Offers/ApplyOnlineByAdmin/ApplyOnlineByAdmin")
);
const ApplyShortCourse = lazy(() =>
  import("./Offers/ApplyShortCourse/ApplyShortCourse")
);
const GenerateInvoice = lazy(() =>
  import("./Offers/GenerateInvoice/GenerateInvoice")
);
const OfferMailingList = lazy(() =>
  import("./Offers/OfferMailingList/OfferMailingList")
);

//complience
const AddClassAttendance = lazy(() =>
  import(
    "./compliance/attendanceManagement/addClassAttendance/AddClassAttendance"
  )
);
const AttendanceSummary = lazy(() =>
  import(
    "./compliance/attendanceManagement/attendanceSummary/AttendanceSummary"
  )
);
const StudentAttendanceSummery = lazy(() =>
  import(
    "./compliance/attendanceManagement/attendanceSummary/StudentAttendanceSummery"
  )
);
const BulkAttendance = lazy(() =>
  import("./compliance/attendanceManagement/bulkAttendance/BulkAttendance")
);

const SetupAssessmentTask = lazy(() =>
  import(
    "./compliance/academicProgress/setupAssessmentTask/SetupAssessmentTask"
  )
);

const TaskEntry = lazy(() =>
  import("./compliance/academicProgress/taskEntry/TaskEntry")
);

const TaskResultEntry = lazy(() =>
  import("./compliance/academicProgress/taskResultEntry/TaskResultEntry")
);

const TransferResult = lazy(() =>
  import("./compliance/academicProgress/transferResult/TransferResult")
);

const TransferResultByUnit = lazy(() =>
  import(
    "./compliance/academicProgress/transferResultByUnit/TransferResultByUnit"
  )
);

const ElicosBulkResultEntry = lazy(() =>
  import(
    "./compliance/academicProgress/ELICOSBulkResultEntry/ElicosBulkResultEntry"
  )
);

const Timetable = lazy(() =>
  import("./compliance/viewTimetable/timetable/Timetable")
);

const ClassCapacityReport = lazy(() =>
  import("./compliance/viewTimetable/classCapacityReport/ClassCapacityReport")
);

const TimetableReport = lazy(() =>
  import("./compliance/viewTimetable/timeTableReport/TimetableReport")
);

const PrintAttendanceList = lazy(() =>
  import("./compliance/viewTimetable/printAttendanceList/PrintAttendanceList")
);

//intervention
const InterventionPage = lazy(() =>
  import("./compliance/intervention/InterventionPage")
);

//group management
const IntakeGroup = lazy(() =>
  import("./compliance/groupManagement/intakeGroup/IntakGroup")
);

//manage orientation
const ManageOrientation = lazy(() =>
  import("./compliance/manageOrientation/ManageOrientation")
);

//validate Prisms
const ValidatePrisms = lazy(() =>
  import("./compliance/validatePRISMS/ValidatePrisms")
);

//service Provide allocation
const ServiceProviderAllocation = lazy(() =>
  import("./compliance/serviceProviderAllocation/ServiceProviderAllocation")
);

//generate bulk certificate
const GenerateBulkCertificate = lazy(() =>
  import("./compliance/generateBulkCertificate/GenerateBulkCertificate")
);

//bulk update
const UpdateFinalOutcome = lazy(() =>
  import("./compliance/bulkUpdate/updateFinalOutcome/updateFinalOutcome")
);

const UpdateStudentCourseStatus = lazy(() =>
  import(
    "./compliance/bulkUpdate/updateStudentCourseStatus/UpdateStudentCourseStatus"
  )
);

const UpdateStudentCourse = lazy(() =>
  import("./compliance/bulkUpdate/updateStudentCourse/UpdateStudentCourse")
);

const UpdateStudentCourseTemplate = lazy(() =>
  import(
    "./compliance/bulkUpdate/updateStudentCourseTemplate/UpdateStudentCourseTemplate"
  )
);

//marketing
const Agents = lazy(() => import("./marketing/agents/Agents"));

// Communication

const MailingList = lazy(() =>
  import("./Communication/MailingList/MailingList")
);

const ManageNewsAndReminder = lazy(() =>
  import("./Communication/ManageNewsAndReminder/ManageNewsAndReminder")
);

const CommunicationLog = lazy(() =>
  import("./Communication/CommunicationLog/CommunicationLog")
);

//report page
const GenerateReport = lazy(() =>
  import("./reports/generateReport/GenerateReport")
);

const EmailTempleteSetup = lazy(() =>
  import("./reports/emailTemplateSetup/EmailTemplateSetup")
);

const Avetmiss = lazy(() => import("./reports/Avetmiss"));

const CqrReport = lazy(() => import("./reports/CqrReport"));

const MfAuthentication = lazy(() =>
  import("./mfAuthentication/MfAuthentication")
);

//profile
const ProfilePage = lazy(() => import("./Profile/Profile"));

//setting
const ComplianceSetting = lazy(() =>
  import("./Settings/compliance/ComplianceSetting")
);

const MarketingSetting = lazy(() =>
  import("./Settings/marketing/MarketingSetting")
);

const EmailSetting = lazy(() => import("./Settings/emailSetting/EmailSetting"));

const ReportSetting = lazy(() =>
  import("./Settings/reportSetting/ReportSetting")
);

const CalanderSetting = lazy(() =>
  import("./Settings/calenderSetting/CalenderSetting")
);

const MailBoxPage = lazy(() => import("./Mailbox/Mailbox"));

const LeaveInfo = lazy(() => import("./leaveInfo/LeaveInfo"));

export {
  Staff,
  ManageUserAccount,
  BulkCreateStudentUserAccount,
  BulkStudentUserAccountManagement,
  UserIpActivityTrack,
  ManageClassTimetable,
  ManageAssessmentGroup,
  // TimetableReport,
  ViewTimetable,
  // ClassCapacityReport,
  ReplacementTeacher,
  // PrintAttendanceList,
  Subject,
  ViewCourses,
  CourseSubject,
  CourseTemplate,
  CourseUpfrontFee,
  CoursePromotion,
  IntakeDates,
  PublicHoliday,
  ManageTeacher,
  TeacherMatrix,
  AddClassAttendance,
  AttendanceSummary,
  StudentAttendanceSummery,
  BulkAttendance,
  ViewApplicantList,
  ApplyOnlineByAdmin,
  ApplyShortCourse,
  GenerateInvoice,
  OfferMailingList,
  SetupAssessmentTask,
  TaskEntry,
  TaskResultEntry,
  TransferResult,
  TransferResultByUnit,
  ElicosBulkResultEntry,
  Timetable,
  ClassCapacityReport,
  TimetableReport,
  PrintAttendanceList,
  InterventionPage,
  IntakeGroup,
  ManageOrientation,
  ValidatePrisms,
  ServiceProviderAllocation,
  GenerateBulkCertificate,
  UpdateFinalOutcome,
  UpdateStudentCourseStatus,
  UpdateStudentCourse,
  UpdateStudentCourseTemplate,
  Agents,
  MailingList,
  ManageNewsAndReminder,
  CommunicationLog,
  GenerateReport,
  EmailTempleteSetup,
  MfAuthentication,
  ProfilePage,
  ComplianceSetting,
  MarketingSetting,
  EmailSetting,
  ReportSetting,
  CalanderSetting,
  MailBoxPage,
  LeaveInfo,
  Avetmiss,
  CqrReport,
};
