import React, { useState } from "react";
import SiderDemo from "../../components/Layout/SiderDemo";
import ComponentHeader from "../../components/ComponentHeader/ComponentHeader";
import BreadcrumbElement from "../../components/breadCrumb/BreadcrumbElement";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Tabs } from "antd";

const crumbList = [
  {
    title: (
      <NavLink style={{ color: "black" }} to="/mailing-list">
        Communication
      </NavLink>
    ),
  },
  {
    title: (
      <NavLink style={{ color: "black" }} to="/mailing-list">
        Mailing List
      </NavLink>
    ),
  },
];
const tabs = [
  {
    key: "/mailing-list",
    label: (
      <NavLink style={{ color: "black" }} to="/mailing-list">
        Mailing List
      </NavLink>
    ),
  },

  {
    key: "/manage-news-and-reminder",
    label: (
      <NavLink style={{ color: "black" }} to="/manage-news-and-reminder">
        Manage News & Reminder
      </NavLink>
    ),
  },
  {
    key: "/communication-log",
    label: (
      <NavLink style={{ color: "black" }} to="/communication-log">
        Communication Log
      </NavLink>
    ),
  },
];

const Communication = ({ children, tab }) => {
  const navigate = useNavigate();
  crumbList.pop();
  crumbList.push({
    title: (
      <NavLink style={{ color: "black" }} to={tab}>
        {tab.slice(1)}
      </NavLink>
    ),
  });
  return (
    <SiderDemo>
      <ComponentHeader>
        <BreadcrumbElement crumbList={crumbList} />
        <Tabs
          defaultActiveKey="ViewStaffList"
          animated={{
            inkBar: false,
            tabPane: true,
          }}
          items={tabs}
          activeKey={tab}
          onChange={(e) => {
            navigate(e);
            console.log("crumblist", crumbList);
            console.log("crumblist tabs", tabs);
            console.log("crumblist e", e);
            crumbList.pop();
            crumbList.push({
              title: (
                <NavLink style={{ color: "black" }} to={e}>
                  {e.slice(1)}
                </NavLink>
              ),
            });
          }}
        />
      </ComponentHeader>
      {children}
    </SiderDemo>
  );
};

export default Communication;
