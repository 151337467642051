import React from "react";
import { Form, InputNumber } from "antd";

const InputNumberField = ({
  name,
  label = "",
  hasRule = false,
  size = "medium",
  placeholder = "",
  readOnly = false,
  handleChange = () => {},
  fieldWidth = "fit-content",
}) => {
  return (
    <Form.Item
      style={{ margin: 0, width: fieldWidth }}
      name={name}
      label={label}
      rules={[
        {
          required: hasRule,
          message: `${label ? label : name} is required`,
        },
      ]}
    >
      <InputNumber
        readOnly={readOnly}
        onChange={handleChange}
        type="number"
        size={size}
        placeholder={placeholder}
        style={{ width: fieldWidth }}
      />
    </Form.Item>
  );
};

export default InputNumberField;
