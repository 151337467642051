import React from "react";
import { Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import { ButtonElement } from "../../../../components/input";
import InitialPaymentSummary from "./InitialPaymentSummary";
const { Option } = Select;
const { TextArea } = Input;

const PaymentInfo = ({
  initialPaymentData,
  setInitialPaymentData,
  current,
  setCurrent,
}) => {
  const [form] = Form.useForm();

  const addPaymentInfoHandler = () => {
    setCurrent(2);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexFlow: "wrap",
        gap: "1rem",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
        }}
      >
        <Form
          layout="vertical"
          onFinish={addPaymentInfoHandler}
          colon={true}
          form={form}
          className="drawerStyle"
          fields={[
            {
              name: ["upfrontFeeToPay"],
              value: initialPaymentData?.upfrontFeeToPay,
            },
            { name: ["paymentDate"], value: initialPaymentData?.paymentDate },
            {
              name: ["upfrontFeePaid"],
              value: initialPaymentData?.upfrontFeePaid,
            },
            {
              name: ["bankDepositDate"],
              value: initialPaymentData?.bankDepositDate,
            },
            { name: ["paidDuration"], value: initialPaymentData?.paidDuration },
            { name: ["durationType"], value: initialPaymentData?.durationType },
            { name: ["PaymentMode"], value: initialPaymentData?.PaymentMode },
            {
              name: ["agentCommissionIsDeducted"],
              value: initialPaymentData?.agentCommissionIsDeducted,
            },
            {
              name: ["agentInvoiceNumber"],
              value: initialPaymentData?.agentInvoiceNumber,
            },
            {
              name: ["provideAgentBonus"],
              value: initialPaymentData?.provideAgentBonus,
            },
            { name: ["bonusAmount"], value: initialPaymentData?.bonusAmount },
            { name: ["isGst"], value: initialPaymentData?.isGst },
            {
              name: ["calculateCommissionWithoutAgentBonus"],
              value: initialPaymentData?.calculateCommissionWithoutAgentBonus,
            },
            {
              name: ["isEnrolmentFee"],
              value: initialPaymentData?.isEnrolmentFee,
            },
            { name: ["enrolmentFee"], value: initialPaymentData?.enrolmentFee },
            {
              name: ["isMaterialFee"],
              value: initialPaymentData?.isMaterialFee,
            },
            { name: ["materialFee"], value: initialPaymentData?.materialFee },
            { name: ["isOshcFee"], value: initialPaymentData?.isOshcFee },
            { name: ["OSHCProvider"], value: initialPaymentData?.OSHCProvider },
            { name: ["oshcType"], value: initialPaymentData?.oshcType },
            {
              name: ["durationInMonths"],
              value: initialPaymentData?.durationInMonths,
            },
            {
              name: ["appliedOshcFee"],
              value: initialPaymentData?.appliedOshcFee,
            },
            {
              name: ["oshcStartDate"],
              value: initialPaymentData?.oshcStartDate,
            },
            {
              name: ["isOtherServiceFees"],
              value: initialPaymentData?.isOtherServiceFees,
            },
            { name: ["accomodation"], value: initialPaymentData?.accomodation },
            {
              name: ["airportPickup"],
              value: initialPaymentData?.airportPickup,
            },
            { name: ["guardianship"], value: initialPaymentData?.guardianship },
            {
              name: ["homePlacement"],
              value: initialPaymentData?.homePlacement,
            },
            {
              name: ["applySurcharge"],
              value: initialPaymentData?.applySurcharge,
            },
            {
              name: ["surchargeAmount"],
              value: initialPaymentData?.surchargeAmount,
            },
            {
              name: ["isAddBadDebtAmount"],
              value: initialPaymentData?.isAddBadDebtAmount,
            },
            {
              name: ["badDebtAmount"],
              value: initialPaymentData?.badDebtAmount,
            },
            { name: ["invoiceNo"], value: initialPaymentData?.invoiceNo },
            { name: ["remarks"], value: initialPaymentData?.remarks },
          ]}
        >
          <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
            <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
              Payment Information
            </span>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Upfront Fee To Pay"
                name={"upfrontFeeToPay"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Upfront fee to pay"
                  type="number"
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        upfrontFeeToPay: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Payment Date"
                name={"paymentDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a gender!",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        paymentDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Upfront Fee Paid"
                name={"upfrontFeePaid"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Upfront fee paid"
                  type="number"
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        upfrontFeePaid: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Bank deposit date"
                name={"bankDepositDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a gender!",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        bankDepositDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Form.Item
                  label={
                    <div style={{ whiteSpace: "nowrap" }}>Paid Duration</div>
                  }
                  name={"paidDuration"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a paid duration!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Paid duration"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          paidDuration: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={"durationType"}
                  style={{ width: "100%", alignSelf: "flex-end" }}
                >
                  <Select
                    placeholder="Duration Type"
                    defaultValue={"WEEK"}
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          durationType: e,
                        };
                      });
                    }}
                  >
                    <Option value="Day">Day</Option>
                    <Option value="WEEK">Week</Option>
                    <Option value="MONTH">Month</Option>
                    <Option value="YEAR">YEAR</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                label="Payment Mode"
                name={"PaymentMode"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a payment mode!",
                  },
                ]}
              >
                <Select
                  placeholder="Payment mode"
                  defaultValue={"BANK_DEPOSIT"}
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        PaymentMode: e,
                      };
                    });
                  }}
                >
                  <Option value="BANK_DEPOSIT">Bank Deposit</Option>
                  <Option value="CREDIT_CARD">Credit Card</Option>
                  <Option value="OTHER">Other</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                name={"agentCommissionIsDeducted"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        agentCommissionIsDeducted: e.target.checked,
                      };
                    });
                  }}
                >
                  Agent Commission is Deducted
                </Checkbox>
              </Form.Item>
              {initialPaymentData.agentCommissionIsDeducted && (
                <Form.Item
                  label={"Agent Invoice Number"}
                  name={"agentInvoiceNumber"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    placeholder="Agent Invoice Number"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          agentInvoiceNumber: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                name={"provideAgentBonus"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        provideAgentBonus: e.target.checked,
                      };
                    });
                  }}
                >
                  Provide Agent Bonus
                </Checkbox>
              </Form.Item>
              {initialPaymentData.provideAgentBonus && (
                <div
                  className="flexRowWithoutStyle"
                  style={{
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    label="Bonus Amount"
                    name={"bonusAmount"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a commission percentage!",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder=""
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            bonusAmount: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"isGst"}
                    style={{ width: "100%", alignSelf: "flex-end" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a commission percentage!",
                      },
                    ]}
                  >
                    <Radio.Group
                      defaultValue={"NO_GST"}
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            isGst: e.target.value,
                          };
                        });
                      }}
                    >
                      <Radio value={true}>GST</Radio>
                      <Radio value={false}>No GST</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
            </div>
            {initialPaymentData.provideAgentBonus && (
              <Form.Item
                name={"calculateCommissionWithoutAgentBonus"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        calculateCommissionWithoutAgentBonus: e.target.checked,
                      };
                    });
                  }}
                >
                  Calculate commission without agent bonus
                </Checkbox>
              </Form.Item>
            )}
          </div>

          {/* Miscellaneous Payment */}
          <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
            <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
              Miscellaneous Payment Information
            </span>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                name={"isEnrolmentFee"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        isEnrolmentFee: e.target.checked,
                      };
                    });
                  }}
                >
                  Enrolment Fee
                </Checkbox>
              </Form.Item>
              {initialPaymentData.isEnrolmentFee && (
                <Form.Item
                  label="Enrolment fee"
                  name={"enrolmentFee"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a enrollment fee!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enrolment fee"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          enrolmentFee: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                name={"isMaterialFee"}
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setInitialPaymentData((prevData) => {
                      return {
                        ...prevData,
                        isMaterialFee: e.target.checked,
                      };
                    });
                  }}
                >
                  Material Fee
                </Checkbox>
              </Form.Item>
              {initialPaymentData.isMaterialFee && (
                <Form.Item
                  label="Material Fee"
                  name={"materialFee"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a enrollment fee!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Material fee"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          materialFee: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <Form.Item
              name={"isOshcFee"}
              valuePropName="checked"
              style={{ width: "100%" }}
            >
              <Checkbox
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      isOshcFee: e.target.checked,
                    };
                  });
                }}
              >
                OSHC Fee
              </Checkbox>
            </Form.Item>
            {initialPaymentData?.isOshcFee && (
              <>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="OSHC Provider"
                    name={"OSHCProvider"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select a OSHC provider!",
                    //   },
                    // ]}
                  >
                    <Select
                      placeholder="OSHC Provider"
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            OSHCProvider: e,
                          };
                        });
                      }}
                    >
                      <Option value="Medibank Private">Medibank Private</Option>
                    </Select>
                  </Form.Item>
                </div>

                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="OSHC Type"
                    name={"oshcType"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select OSHC type!",
                    //   },
                    // ]}
                  >
                    <Select
                      placeholder="OSHC Type"
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            oshcType: e,
                          };
                        });
                      }}
                    >
                      <Option value="FAMILY">Family</Option>
                      <Option value="INDIVIDUAL">Individual</Option>
                    </Select>{" "}
                  </Form.Item>
                  <Form.Item
                    label="Cover Duration (in months)"
                    name={"durationInMonths"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter a duration !",
                    //   },
                    // ]}
                  >
                    <Input
                      type="number"
                      placeholder="Duration"
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            durationInMonths: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Applied OSHC Fee (OSHC Fee: 0)"
                    name={"appliedOshcFee"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter a applied OSHC fee !",
                    //   },
                    // ]}
                  >
                    <Input
                      type="number"
                      placeholder="Duration"
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            appliedOshcFee: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="OSHC Start Date"
                    name={"oshcStartDate"}
                    style={{ width: "100%" }}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            oshcStartDate: e,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                </div>
              </>
            )}

            <Form.Item
              name={"isOtherServiceFees"}
              valuePropName="checked"
              style={{ width: "100%" }}
            >
              <Checkbox
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      isOtherServiceFees: e.target.checked,
                    };
                  });
                }}
              >
                Other service fees
              </Checkbox>
            </Form.Item>
            {initialPaymentData.isOtherServiceFees && (
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Accomodation"
                  name={"accomodation"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    placeholder="Accomodation"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          accomodation: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Airport Pickup"
                  name={"airportPickup"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    placeholder="Airport Pickup"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          airportPickup: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            )}
            {initialPaymentData.isOtherServiceFees && (
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Guardianship"
                  name={"guardianship"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    placeholder="Guardianship"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          guardianship: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Home Placement"
                  name={"homePlacement"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    placeholder="Home Placement"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          homePlacement: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
            <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
              Total Payment Summary
            </span>
            <Form.Item
              name={"applySurcharge"}
              valuePropName="checked"
              style={{ width: "100%" }}
            >
              <Checkbox
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      applySurcharge: e.target.checked,
                    };
                  });
                }}
              >
                Apply Surcharge
              </Checkbox>
            </Form.Item>
            {initialPaymentData.applySurcharge && (
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Surcharge Amount"
                  name={"surchargeAmount"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a surcharge amount",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Accomodation"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          surchargeAmount: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            )}
            <Form.Item
              name={"isAddBadDebtAmount"}
              valuePropName="checked"
              style={{ width: "100%" }}
            >
              <Checkbox
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      isAddBadDebtAmount: e.target.checked,
                    };
                  });
                }}
              >
                Add bad debt amount
              </Checkbox>
            </Form.Item>
            {initialPaymentData.isAddBadDebtAmount && (
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Bad Debt Amount"
                  name={"badDebtAmount"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a bad debt amount",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Bad Debt Amount"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          badDebtAmount: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            )}
            <Form.Item
              label="Invoice No"
              name={"invoiceNo"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Invoice No"
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      invoiceNo: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Remarks"
              name={"remarks"}
              style={{ width: "100%" }}
            >
              <TextArea
                placeholder="Write something..."
                onChange={(e) => {
                  setInitialPaymentData((prevData) => {
                    return {
                      ...prevData,
                      remarks: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            {current === 1 && (
              <Form.Item>
                <ButtonElement
                  type={"default"}
                  handleClick={() => {
                    setCurrent(0);
                  }}
                  name="Back"
                >
                  Back
                </ButtonElement>
              </Form.Item>
            )}
            <Form.Item>
              <ButtonElement type="primary" htmlType="submit" name="Next">
                Next
              </ButtonElement>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div
        style={{
          width: "30%",
        }}
      >
        <InitialPaymentSummary
          detail={{
            name: "Anish Banjara",
          }}
        />
      </div>
    </div>
  );
};

export default PaymentInfo;
