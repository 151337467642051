import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;

const UpdateSanction = ({
  isUpdateSanctionModalVisible,
  setIsUpdateSanctionModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateSanctionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_PROVIDER_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateSanctionModalVisible(false);
  };
  return (
    <Modal
      title="Update Sanction"
      open={isUpdateSanctionModalVisible}
      onCancel={() => {
        setIsUpdateSanctionModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={UpdateSanctionSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Type"
            name={"type"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a Type!",
              },
            ]}
          >
            <Select placeholder="Type">
              <Option value="ACADEMIC">Academic</Option>
              <Option value="PAYMENT">Payment</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="Message"
          name={"message"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please write a message!",
            },
          ]}
        >
          <TextArea placeholder="Write a Message" />
        </Form.Item>
        <Form.Item name={"message"} style={{ width: "100%" }}>
          <Checkbox>Show Message to Student</Checkbox>
        </Form.Item>
        <Form.Item name={"message"} style={{ width: "100%" }}>
          <Checkbox>Set Active</Checkbox>
        </Form.Item>
        <Form.Item name={"message"} style={{ width: "100%" }}>
          <Checkbox>Add To Student Communication Log</Checkbox>
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Sanction"
          >
            Update Sanction
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateSanction;
