import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../../components/input";
const { Option } = Select;

const AddCertificateRegister = ({
  isAddCertificateRegisterModalVisible,
  setIsAddCertificateRegisterModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addCertificateRegisterSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_CERTIFICATE_REGISTER_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddCertificateRegisterModalVisible(false);
  };
  return (
    <Modal
      title="Add Certificate"
      open={isAddCertificateRegisterModalVisible}
      onCancel={() => {
        setIsAddCertificateRegisterModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addCertificateRegisterSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course"
            name={"course"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course!",
              },
            ]}
          >
            <Select placeholder="Select a Course">
              <Option>GE: General English-12-04-2021-Enrolled</Option>
              <Option>IELTS: IELTS Preparation-02-01-2023-Enrolled</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Type"
            name={"type"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a type!",
              },
            ]}
          >
            <Select placeholder="Select a Course">
              <Option>Certificate</Option>
              <Option>Statement of Attainment</Option>
              <Option>Transcript of Grades Acheived</Option>
              <Option>Transcript of Competency Acheived</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Document No"
            name={"documentNo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a manaual document no.",
              },
            ]}
          >
            <Input placeholder="Document Number" />
          </Form.Item>
          <Form.Item
            label="Date Issued"
            name={"date Issued"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a issued date",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Certificate"
          >
            Add Certificate
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCertificateRegister;
