import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdateIntervention = ({
  isUpdateInterventionModalVisible,
  setIsUpdateInterventionModalVisible,
}) => {
  const [inerventionData, setInerventionData] = useState({
    interventionFor: "COURSE",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const UpdateInterventionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsUpdateInterventionModalVisible(false);
  };
  return (
    <Drawer
      title="Update Intervention"
      open={isUpdateInterventionModalVisible}
      onClose={() => {
        setIsUpdateInterventionModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateInterventionSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Intervention For"
            name={"interventionFor"}
            style={{ width: "100%" }}
          >
            <Select
              onChange={(e) => {
                setInerventionData((prevData) => {
                  return {
                    ...prevData,
                    interventionFor: e,
                  };
                });
              }}
            >
              <Option value="COURSE">Course</Option>
              <Option value="SUBJECT">Subject</Option>
              <Option value="UNIT">Unit</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Course" name={"course"} style={{ width: "100%" }}>
            <Select placeholder="Course" disabled>
              <Option>EAP: English for Academic Purposes</Option>
              <Option>EAP: English for Academic Purposes - Offshore</Option>
              <Option>EAP: English for Academic Purposes - Other</Option>
              <Option>GE: General English</Option>
              <Option>GE-OS: General English - Offshore</Option>
              <Option>GE-Other: General English - Other</Option>
              <Option>IELTS: IELTS Prepration</Option>
            </Select>
          </Form.Item>
          {inerventionData.interventionFor === "SUBJECT" && (
            <Form.Item
              label="Subject"
              name={"subject"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a subject!",
                },
              ]}
            >
              <Select placeholder="Select Subject"></Select>
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Semester"
            name={"semester"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a semester!",
              },
            ]}
          >
            <Select placeholder="Select Semester"></Select>
          </Form.Item>
          <Form.Item
            label="Intervention Type"
            name={"interventionType"}
            style={{ width: "100%" }}
          >
            <Checkbox.Group>
              <Checkbox value={"ACADEMIC"}>Academic</Checkbox>
              <Checkbox value={"attendance"}>Attendance</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Intervention Strategy"
            name={"interventionStrategy"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a intervention strategy!",
              },
            ]}
          >
            <Select placeholder="Select intervention strategy"></Select>
          </Form.Item>
          <Form.Item
            label="Action Taken"
            name={"actionTaken"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please write about intervention case!",
              },
            ]}
          >
            <TextArea placeholder="Write about intervention case"></TextArea>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Action Taken to Prevent Reoccurance"
            name={"actionTakenToPreventReoccurance"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message:
                  "Please write about action taken to prevent reoccurance!",
              },
            ]}
          >
            <TextArea placeholder="Write about action taken to prevent reoccurance"></TextArea>
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Outcome Date"
            name={"outcomeDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="Escalated To"
            name={"escalatedTo"}
            style={{ width: "100%" }}
          >
            <Select placeholder="Escalated To">
              <Option>Not Escalated</Option>
              <Option> Mr Alexander Freitag(alex.freitag)</Option>
              <Option>Ms Anu Rani Regmi(Anu)</Option>
              <Option>Mr Benjamin Fairley(benjamin.fairley)</Option>
              <Option>Mr Gaurab Thapa(gaurab.thapa)</Option>
              <Option>Mr James McDonald(james.mcdonald)</Option>
              <Option>Mr Kadam Roka(kadam.roka)</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="File" name={"file"} style={{ width: "100%" }}>
            <Input type="file" />
          </Form.Item>
          <Form.Item
            label="Details of Intervention Case"
            name={"detailsOfInterventionCase"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please write about intervention case!",
              },
            ]}
          >
            <TextArea placeholder="Write about intervention case"></TextArea>
          </Form.Item>
        </div>
        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Intervention"
          >
            Update Intervention
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateIntervention;
