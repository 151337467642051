import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;

const AddCustomFieldType = ({
  isAddCustomFieldTypeModalVisible,
  setIsAddCustomFieldTypeModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddCustomFieldTypeSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddCustomFieldType_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddCustomFieldTypeModalVisible(false);
  };
  return (
    <Modal
      title="Add Custom Field Type"
      open={isAddCustomFieldTypeModalVisible}
      onCancel={() => {
        setIsAddCustomFieldTypeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddCustomFieldTypeSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Custom Field Name"
          name={"customFieldName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter custom field name!",
            },
          ]}
        >
          <Input placeholder="Custom Field Name" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Custom Field Type"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCustomFieldType;
