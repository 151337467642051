import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";

import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineUpload,
} from "react-icons/ai";
import PageHeader from "../../../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../../../components/input";
import AddButton from "../../../../../../components/button/AddButton";
import AddCertificateRegister from "./AddCertificateRegister";
import UploadCertificate from "./UploadCertificate";

const columns = [
  {
    title: "Entity Name",
    dataIndex: "entityName",
    width: 250,
    align: "center",
  },
  {
    title: "Document No",
    dataIndex: "documentNo",
    width: 50,
    align: "center",
  },
  {
    title: "Manual Document No",
    dataIndex: "manualDocumentNo",
    width: 50,
    align: "center",
  },
  {
    title: "Course Id",
    dataIndex: "courseId",
    width: 50,
    align: "center",
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    width: 200,
    align: "center",
  },
  {
    title: "Course Attempt",
    dataIndex: "courseAttempt",
    width: 50,
    align: "center",
  },
  {
    title: "Date Issued",
    dataIndex: "dateIssued",
    width: 150,
    align: "center",
  },
  {
    title: "Certificate Type",
    dataIndex: "certificateType",
    width: 150,
    align: "center",
  },
  {
    title: "Generated By",
    dataIndex: "generatedBy",
    width: 200,
    align: "center",
  },
  {
    title: "Signatory",
    dataIndex: "signatory",
    width: 200,
    align: "center",
  },
  {
    title: "Date Generated",
    dataIndex: "dateGenerated",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    entityName: "Opera Education Group Pty Ltd",
    documentNo: "1",
    manualDocumentNo: "001",
    courseId: "IELTS",
    courseName: "IELTS Preparation",
    courseAttempt: "1",
    dateIssued: "17-08-2023",
    certificateType: "Statement of Attainment",
    generatedBy: "Ms Anu Rani Regmi",
    signatory: "",
    dateGenerated: "17-08-2023 11:25:09 AM",
  },
];
const selectCourse = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "GE: General English-12-04-2021-Enrolled",
    value: "geEnrolled",
  },
  {
    label: "IELTS: IELTS Preparation-02-01-2023-Enrolled",
    value: "ieltsEnrolled",
  },
];

const CertificateRegister = () => {
  const [
    isAddCertificateRegisterModalVisible,
    setIsAddCertificateRegisterModalVisible,
  ] = useState(false);
  const [isUploadCertificateModalVisible, setIsUploadCertificateModalVisible] =
    useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      key: dataObj.id,
      entityName: dataObj?.entityName,
      documentNo: dataObj?.documentNo,
      manualDocumentNo: dataObj?.manualDocumentNo,
      courseId: dataObj?.courseId,
      courseName: dataObj?.courseName,
      courseAttempt: dataObj?.courseAttempt,
      dateIssued: dataObj?.dateIssued,
      certificateType: dataObj?.certificateType,
      generatedBy: dataObj?.generatedBy,
      signatory: dataObj?.signatory,
      dateGenerated: dataObj?.dateGenerated,
      actions: (
        <Space size="middle">
          <Tooltip title="Upload Certificate">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUploadCertificateModalVisible(true);
                }}
              >
                <AiOutlineUpload />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete selected certificate?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Certificate Register</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddCertificateRegisterModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Select Course</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Course"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="22rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      {isAddCertificateRegisterModalVisible && (
        <AddCertificateRegister
          isAddCertificateRegisterModalVisible={
            isAddCertificateRegisterModalVisible
          }
          setIsAddCertificateRegisterModalVisible={
            setIsAddCertificateRegisterModalVisible
          }
        />
      )}
      {isUploadCertificateModalVisible && (
        <UploadCertificate
          isUploadCertificateModalVisible={isUploadCertificateModalVisible}
          setIsUploadCertificateModalVisible={
            setIsUploadCertificateModalVisible
          }
        />
      )}
    </Card>
  );
};

export default CertificateRegister;
