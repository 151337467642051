import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import Offers from "../Offers";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
const { Search } = Input;
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Date Applied",
    dataIndex: "dateApplied",
    width: 150,
    align: "center",
  },
  {
    title: "Applicant Name",
    dataIndex: "applicantName",
    width: 200,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 250,
    align: "center",
  },
  {
    title: "Course Information",
    dataIndex: "courseInformation",
    width: 250,
    align: "center",
  },
  {
    title: "Mail Status",
    dataIndex: "mailStatus",
    width: 250,
    align: "center",
  },
];

const applicationStatus = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Unapproved",
    value: "UNAPPROVED",
  },
  {
    label: "Converted",
    value: "converted",
  },
  {
    label: "Pending",
    value: "panding",
  },
  {
    label: "Reconsider",
    value: "reconsider",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Offered",
    value: "offered",
  },
  {
    label: "Not Submitted",
    value: "offered",
  },
];
const mailData = [
  {
    id: "844",
    dateApplied: "28-07-2023",
    applicantName: "Maria Isabela Ordonez Marin",
    email: "isabelmarin2020@gmail.com",
    courseInformation: "+2 Course Enrolment",
    mailStatus: "",
  },
];
const searchApplicantsBy = [
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Course",
    value: "course",
  },
  {
    label: "Date Applied",
    value: "dateApplied",
  },
  {
    label: "Date of Birth",
    value: "dateOfBirth",
  },
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },

  {
    label: "Offer ID",
    value: "offerId",
  },
  {
    label: "Start Semester",
    value: "startSemester",
  },

  {
    label: "Account Manager",
    value: "accountManager",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const OfferMailingList = () => {
  const [searchBy, setSearchBy] = useState("offerId");
  const [isSendComposedMailModalVisible, setIsSendComposedMailModalVisible] =
    useState(false);
  let data = mailData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      dateApplied: dataObj?.dateApplied,
      applicantName: dataObj?.applicantName,
      email: dataObj?.email,
      courseInformation: dataObj?.courseInformation,
      mailStatus: dataObj?.mailStatus,
    };
  });
  return (
    <Offers tab={"/offer-mailing-list"}>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>Offer Mailing List</Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <ButtonElement
                handleClick={() => {
                  setIsSendComposedMailModalVisible(true);
                }}
                name="Send Mail"
              >
                Send Mail
              </ButtonElement>
            </div>
          </div>

          <div className="fxRAc" style={{ gap: "1rem" }}>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Select application status</div>
              <SelectField
                name="applicationStatus"
                defaultValue={"all"}
                placeholder="Select Application Status"
                options={applicationStatus}
                handleChange={(e) => {
                  // setSearchBy(e);
                }}
                fieldWidth="8rem"
              />
            </div>
            <div
              className="fxRAc"
              style={{ gap: "1rem", whiteSpace: "nowrap" }}
            >
              <div>Search by</div>
              <SelectField
                value={searchBy}
                name="searchApplicantsBy"
                placeholder="Search Applicants By"
                options={searchApplicantsBy}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="12rem"
              />
            </div>
            {(searchBy === "dateOfBirth" || searchBy === "dateApplied") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <DatePicker format={"DD-MM-YYYY"} style={{ width: "12rem" }} />
              </div>
            )}

            {searchBy === "course" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="course"
                  placeholder="Course"
                  options={selectCourse}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {searchBy === "startSemester" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="studentType"
                  placeholder="Start Semester"
                  options={[]}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {searchBy === "accountManager" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="studentType"
                  placeholder="Staff"
                  options={[]}
                  handleChange={(e) => {}}
                  fieldWidth="13rem"
                />
              </div>
            )}
            {searchBy === "agent" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <SelectField
                  name="agent"
                  placeholder="Agents"
                  options={[]}
                  handleChange={(e) => {}}
                  fieldWidth="10rem"
                />
              </div>
            )}

            {(searchBy === "firstName" ||
              searchBy === "lastName" ||
              searchBy === "offerId") && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <Search
                  style={{ borderRadius: "3px", width: "100%" }}
                  className="search"
                  placeholder={`Search Applicant.`}
                  allowClear
                  size="Medium"
                  //   value={searchValue}
                  onChange={(e) => {
                    //   setSearchValue(e.target.value);
                    //   onSearch(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          <Table
            dataSource={data}
            bordered={true}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
    </Offers>
  );
};

export default OfferMailingList;
