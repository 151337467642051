import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdateProviderPayment = ({
  isUpdateProviderPaymentModalVisible,
  setIsUpdateProviderPaymentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateProviderPaymentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateProviderPayment_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateProviderPaymentModalVisible(false);
  };
  return (
    <Drawer
      title="Update Provider Payment"
      open={isUpdateProviderPaymentModalVisible}
      onClose={() => {
        setIsUpdateProviderPaymentModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateProviderPaymentSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Provider"
            name={"provider"}
            style={{ width: "100%" }}
          >
            <Select placeholder="Provider" defaultValue={"Test Provider"}>
              <Option value="Test Provider">Test Provider</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Provider Amount"
            name={"providerAmount"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Provider Amount" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Form.Item
            label="Provider GST"
            name={"Provider GST"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Provider GST" />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              name={"paidToProvider"}
              valuePropName="checked"
              style={{ width: "100%" }}
            >
              <Checkbox>Paid To Provider</Checkbox>
            </Form.Item>
            <Form.Item
              label="Paid Date"
              name={"paidDate"}
              style={{ width: "100%" }}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </div>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Mode" name={"mode"} style={{ width: "100%" }}>
            <Select placeholder="Mode">
              <Option value="Bank_Deposit">Bank Deposit</Option>
              <Option value="Credit_Card">Credit Card</Option>
              <Option value="Other">Other</Option>
              <Option value="Not_Paid">Not_Paid</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Reference"
            name={"reference"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Reference" />
          </Form.Item>
        </div>
        <Form.Item label="Remarks" name={"Remarks"} style={{ width: "100%" }}>
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement block type="primary" htmlType="submit" name="Update" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateProviderPayment;
