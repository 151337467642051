import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import React from "react";
import { ButtonElement } from "../../../components/input";
import AddSemester from "./AddSemester";
import { useDispatch } from "react-redux";

export default function ManageSemester() {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Semester",
      dataIndex: "semester",
      align: "center",
    },
    {
      title: "Course Type",
      dataIndex: "course_type",
      align: "center",
    },
    {
      title: "Calender Type",
      dataIndex: "calender_type",
      align: "center",
    },
    {
      title: "Year",
      dataIndex: "year",
      align: "center",
    },
    {
      title: "Semester Start",
      dataIndex: "semester_start",
      align: "center",
    },
    {
      title: "Semester Finish",
      dataIndex: "semester_finish",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: () => (
        <Tooltip placement="top" title="Delete Record">
          <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
        </Tooltip>
      ),
    },
  ];

  const data = [
    {
      semester: 2021,
      course_type: "EICLOS",
      calender_type: "Calendar Set1	",
      year: 2021,
      semester_start: "2021/10/20",
      semester_finish: "2023/10/20",
    },
    {
      semester: 2021,
      course_type: "EICLOS",
      calender_type: "Calendar Set1	",
      year: 2021,
      semester_start: "2021/10/20",
      semester_finish: "2023/10/20",
    },
    {
      semester: 2021,
      course_type: "EICLOS",
      calender_type: "Calendar Set1	",
      year: 2021,
      semester_start: "2021/10/20",
      semester_finish: "2023/10/20",
    },
  ];

  const handleAddSemester = (value) => {
    dispatch({ type: "FETCH_OPEN_MODAL", payload: value });
  };

  return (
    <div className="flex flex-col gap-4">
      <ButtonElement
        handleClick={() => handleAddSemester("addSemester")}
        Icon={<PlusOutlined className="text-sm" />}
        name="Semester"
      />
      <Table dataSource={data} columns={columns} />
      <AddSemester />
    </div>
  );
}
