import React from "react";

const ComponentHeader = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        margin: "0.5rem 0 0.5rem 0",
        padding: "12px 24px 0 24px",
        gap: "0.5rem",
      }}
    >
      {children}
    </div>
  );
};

export default ComponentHeader;
