import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../../Assets/logo/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StudentProfile from "./StudentProfile/StudentProfile";
import StudentVaccinationDetails from "./StudentVaccinationDetails/StudentVaccinationDetails";
import StudentCourse from "./StudentCourse/StudentCourse";
import DocumentChecklist from "./DocumentChecklist/DocumentChecklist";
import CourseDeferral from "./CourseDeferral/CourseDeferral";
import EnglishTest from "./EnglishTest/EnglishTest";
import Intervention from "./Intervention/Intervention";
import StaffDiary from "../../Staff/StaffDetails/StaffDiary/StaffDiary";
import StaffDocuments from "../../Staff/StaffDetails/StaffDocuments/StaffDocuments";
import { ButtonElement } from "../../../components/input";
import Interview from "./Interview/Interview";
import Sanction from "./Sanction/Sanction";
import TrainingPlan from "./TrainingPlan/TrainingPlan";
import ClaimTracking from "./ClaimTracking/ClaimTracking";

const StudentDetails = ({
  leadData,
  record,
  isStudentDetailsModalVisible,
  setIsStudentDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return { staffState: state.StaffReducer };
  });

  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  const [current, setCurrent] = useState("profile");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const staffTabList = [
    {
      key: "profile",
      label: "Profile",
    },
    {
      key: "vaccinationDetails",
      label: "Vaccination Details",
    },
    {
      key: "course",
      label: "Course",
    },
    {
      key: "letter",
      label: "Letter",
    },
    {
      key: "documentChecklist",
      label: "Document Checklist",
    },
    // {
    //   key: "result",
    //   label: "Result",
    // },

    {
      key: "defer",
      label: "Defer",
    },

    {
      key: "englishTest",
      label: "English Test",
    },
    {
      key: "diary",
      label: "Diary",
    },
    {
      key: "upload",
      label: "Upload Documents",
    },
    {
      key: "payment",
      label: "Payment",
    },
    {
      key: "intervention",
      label: "Intervention",
    },
    {
      key: "attendence",
      label: "Attendence",
    },
    {
      key: "oshc",
      label: "OSHC",
    },
    {
      key: "interview",
      label: "Interview",
    },
    {
      key: "sanction",
      label: "Sanction",
    },
    {
      key: "trainingPlan",
      label: "Training Plan",
    },
    {
      key: "claimTracking",
      label: "Claim Tracking",
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  const navigate = useNavigate();
  console.log("record from lead details");
  console.log("record from lead store");
  console.log("store.staffState?.leadById");
  return (
    <Drawer
      title="Student Details"
      open={isStudentDetailsModalVisible}
      onClose={() => {
        setIsStudentDetailsModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Card style={{ borderRadius: "3px" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "inherit",
          }}
        >
          <Image
            src={client}
            className="clientImage"
            height={160}
            width={160}
          />
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
              width: "80%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`Sabin Khadka (ID: SD001)`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlinePhone
                  style={{
                    fontSize: "20",
                  }}
                />
                0417456836
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <GoLocation
                  style={{
                    fontSize: "16",
                  }}
                />
                1/544 Willoughby Road Willoughby NSW Australia, 2068
              </span>
              <span className="fxRAc" style={{ gap: "0.2rem" }}>
                <AiOutlineMail
                  style={{
                    fontSize: "16",
                  }}
                />{" "}
                awesomesabinkhadka@gmail.com
              </span>
            </div>
            {/* <div>
                <div>
                  <span className="profileTitle">Campaign:</span>
                  <span>
                    Further Details Here
                  </span>
                </div>
              </div> */}
            <div
              className="fxRAc"
              style={{
                gap: "0.5rem",
                position: "absolute",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>

              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>

              <div>
                <input
                  type={"file"}
                  name="file"
                  style={{ display: "none" }}
                  id="file"
                  onChange={(e) => {
                    const profileFormData = new FormData();
                    profileFormData.append("image", e.target.files[0]);
                  }}
                />
                <label for="file">
                  <div
                    className="companyLogoBtn"
                    style={{
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#00798A",
                      padding: "4px 10px",
                    }}
                  >
                    Upload Photo
                  </div>
                </label>
              </div>

              <ButtonElement
                type="primary"
                onClick={() => {}}
                name="Print Student Card"
              >
                Print Student Card
              </ButtonElement>
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            ></div>
          </div>
        </div>
        <Tabs
          defaultActiveKey="profile"
          items={staffTabList}
          activeTabKey={current}
          onChange={(key) => {
            console.log("current key", key);
            setCurrent(key);
          }}
        />
        {current === "profile" && (
          <>
            <StudentProfile />
          </>
        )}

        {current === "vaccinationDetails" && (
          <>
            <StudentVaccinationDetails />
          </>
        )}
        {current === "course" && (
          <>
            <StudentCourse />
          </>
        )}
        {current === "documentChecklist" && (
          <>
            <DocumentChecklist />
          </>
        )}
        {current === "result" && <></>}
        {current === "letter" && <></>}
        {current === "defer" && (
          <>
            <CourseDeferral />
          </>
        )}
        {current === "englishTest" && (
          <>
            <EnglishTest />
          </>
        )}
        {current === "intervention" && (
          <>
            <Intervention />
          </>
        )}

        {current === "diary" && (
          <>
            <StaffDiary />
          </>
        )}
        {current === "upload" && (
          <>
            <StaffDocuments />
          </>
        )}
        {current === "payment" && <></>}
        {current === "attendence" && <></>}
        {current === "oshc" && <></>}
        {current === "interview" && (
          <>
            <Interview />
          </>
        )}
        {current === "sanction" && (
          <>
            <Sanction />
          </>
        )}
        {current === "trainingPlan" && (
          <>
            <TrainingPlan />
          </>
        )}
        {current === "claimTracking" && (
          <>
            <ClaimTracking />
          </>
        )}
      </Card>
      {/* {isSendComposedEmailModalVisible && (
          <SendComposedMail
            composeMailRecord={{
              toUser: [store?.staffState?.leadById?.email],
            }}
            isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
            setIsSendComposedEmailModalVisible={
              setIsSendComposedEmailModalVisible
            }
          />
        )} */}
    </Drawer>
  );
};

export default StudentDetails;
