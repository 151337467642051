import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
import { AiOutlineDelete } from "react-icons/ai";
const { Option } = Select;
const { TextArea } = Input;

const columns = [
  {
    title: "Credited Date",
    dataIndex: "creditedDate",
    width: 50,
    align: "center",
  },
  {
    title: "Credit Amount",
    dataIndex: "creditAmount",
    width: 50,
    align: "center",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const creditData = [
  {
    id: "1",
    creditedDate: "23-09-2023",
    creditAmount: "66.67",
    remarks: "Credit amount is 66.67",
  },
];

const InvoiceCredit = ({
  isInvoiceCreditModalVisible,
  setIsInvoiceCreditModalVisible,
}) => {
  let data = creditData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      creditedDate: dataObj?.creditedDate,
      creditAmount: dataObj?.creditAmount,
      remarks: dataObj?.remarks,
      actions: (
        <Space size="middle" style={{ display: "flex", flexWrap: "wrap" }}>
          <Tooltip title="Delete Invoice Credit">
            <Popconfirm
              title="Are you sure to delete this selected Staff?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const InvoiceCreditSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "InvoiceCredit_REQUEST", payload: formData });
    // form.resetFields();
    setIsInvoiceCreditModalVisible(false);
  };
  return (
    <Drawer
      title="Invoice Credit"
      open={isInvoiceCreditModalVisible}
      onClose={() => {
        setIsInvoiceCreditModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={InvoiceCreditSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Schedule Amount:" style={{ width: "100%" }}>
            666.67
          </Form.Item>
          <Form.Item label="Paid Amount" style={{ width: "100%" }}>
            0.00
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Credit Amount"
            name={"creditAmount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a credit amount",
              },
            ]}
          >
            <Input type="number" placeholder="Credit Amount" />
          </Form.Item>
          <Form.Item
            label="Credited Date"
            name={"creditedDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a credited date",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <Form.Item label="Remarks" name={"remarks"}>
          <TextArea placeholder="Write Something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name={"Add Invoice Credit"}
          />
        </Form.Item>
      </Form>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table bordered dataSource={data} columns={columns} />
      </div>
    </Drawer>
  );
};

export default InvoiceCredit;
