import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddTeacherMatrix = ({
  isAddTeacherMatrixModalVisible,
  setIsAddTeacherMatrixModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const addTeacherMatrixSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsAddTeacherMatrixModalVisible(false);
  };
  return (
    <Drawer
      title="Add Teacher Matrix"
      open={isAddTeacherMatrixModalVisible}
      onClose={() => {
        setIsAddTeacherMatrixModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={addTeacherMatrixSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <Form.Item
          label="Select Teacher"
          name={"courseType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a course!",
            },
          ]}
        >
          <Select>
            <Option> Mr Alexander Freitag(alex.freitag)</Option>
            <Option>Ms Anu Rani Regmi(Anu)</Option>
            <Option>Mr Benjamin Fairley(benjamin.fairley)</Option>
            <Option>Mr Gaurab Thapa(gaurab.thapa)</Option>
            <Option>Mr James McDonald(james.mcdonald)</Option>
            <Option>Mr Kadam Roka(kadam.roka)</Option>
          </Select>
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Course Status"
            name={"courseStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course!",
              },
            ]}
          >
            <Radio.Group defaultValue={"ACTIVE"}>
              <Radio value={"ACTIVE"}>Active</Radio>
              <Radio value={"INACTIVE"}>Inactive</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Select course"
            name={"courseType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course!",
              },
            ]}
          >
            <Select>
              <Option>EAP: English for Academic Purposes</Option>
              <Option>EAP: English for Academic Purposes - Offshore</Option>
              <Option>EAP: English for Academic Purposes - Other</Option>
              <Option>GE: General English</Option>
              <Option>GE-OS: General English - Offshore</Option>
              <Option>GE-Other: General English - Other</Option>
              <Option>IELTS: IELTS Prepration</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Subject Selection Type"
            name={"subjectSelectionType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a course!",
              },
            ]}
          >
            <Radio.Group defaultValue={"ALL_SUBJECT"}>
              <Radio value={"ALL_SUBJECT"}>All Subject</Radio>
              <Radio value={"SELECTED_SUBJECT"}>Selected Subject</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Select subject"
            name={"selectSubject"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a subject!",
              },
            ]}
          >
            <Checkbox.Group>
              <Checkbox disabled>No subjects foun</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
        <Form.Item
          label="Knowledge level"
          name={"knowledgeLevel"}
          style={{ width: "100%" }}
          rules={[
            {
              required: selectOrInput === "INPUT",
              message: "Please enter knowledge level!",
            },
          ]}
        >
          {selectOrInput === "INPUT" ? (
            <Input placeholder="Knowledge Level" />
          ) : (
            <Select
              onChange={(e) => {
                setSelectOrInput(e);
              }}
            >
              <Option value="NOT_GIVEN">-</Option>
              <Option value="INPUT">Manually Specify</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Teacher Matrix"
          >
            Add Teacher Matrix
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddTeacherMatrix;
