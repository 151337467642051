import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../../components/input";
const { Option } = Select;

const UpdateEmployerContact = ({
  isUpdateEmployerContactModalVisible,
  setIsUpdateEmployerContactModalVisible,
}) => {
  const [employerContactData, setEmployerContactData] = useState({
    salutation: "Mr",
    firstName: null,
    lastName: null,
    phone: null,
    mobile: null,
    email: null,
    fax: null,
    designation: null,
    department: null,
    status: "ACTIVE",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateEmployerContactSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "UpdateEmployerContact_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateEmployerContactModalVisible(false);
  };
  return (
    <Drawer
      title="Update Employer Contact"
      open={isUpdateEmployerContactModalVisible}
      onClose={() => {
        setIsUpdateEmployerContactModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateEmployerContactSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Salutation"
            name={"salutation"}
            style={{ width: "100%" }}
          >
            <Select
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    salutation: e,
                  };
                });
              }}
            >
              <Option value="Mr">Mr</Option>
              <Option value="Miss">Miss</Option>
              <Option value="Mrs">Mrs</Option>
              <Option value="Ms">Ms</Option>
              <Option value="Dr">Dr</Option>
              <Option value="Rev">Rev</Option>
              <Option value="Hon">Hon</Option>
              <Option value="">Not Specified</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              placeholder="First Name"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    firstName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              placeholder="Last Name"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    lastName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Phone"
            name={"phone"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Phone number"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    phone: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Mobile"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    mobile: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Address"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Fax" name={"fax"} style={{ width: "100%" }}>
            <Input
              type="email"
              placeholder="College Email"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    fax: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Designation"
          name={"designation"}
          style={{ width: "100%" }}
        >
          <Input
            placeholder="Designation"
            onChange={(e) => {
              setEmployerContactData((prevData) => {
                return {
                  ...prevData,
                  designation: e.target.value,
                };
              });
            }}
          />
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Department"
            name={"department"}
            style={{ width: "100%" }}
          >
            <Input
              placeholder="Department"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    department: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Status" name={"status"} style={{ width: "100%" }}>
            <Select
              placeholder="Status"
              onChange={(e) => {
                setEmployerContactData((prevData) => {
                  return {
                    ...prevData,
                    status: e,
                  };
                });
              }}
            >
              <Option value="ACTIVE">Active</Option>
              <Option value="INACTIVE">Inctive</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Update Employer Contact"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateEmployerContact;
