import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import { Input, Popconfirm, Radio, Space, Table, Tooltip } from "antd";
import Offers from "../Offers";
const { Search } = Input;

const ApplyShortCourse = () => {
  return (
    <Offers tab={"/apply-short-course"}>
      <ComponentBody>Apply Short Course</ComponentBody>
    </Offers>
  );
};

export default ApplyShortCourse;
