import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;

const OfferChecklistComment = ({
  isOfferChecklistCommentModalVisible,
  setIsOfferChecklistCommentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const OfferChecklistCommentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_PROVIDER_REQUEST", payload: formData });
    // form.resetFields();
    setIsOfferChecklistCommentModalVisible(false);
  };
  return (
    <Modal
      title="Comment"
      open={isOfferChecklistCommentModalVisible}
      onCancel={() => {
        setIsOfferChecklistCommentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={OfferChecklistCommentSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Comment"
            name={"comment"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please write a comment!",
              },
            ]}
          >
            <TextArea placeholder="Write a comment" />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonElement block type="primary" htmlType="submit" name="Comment">
            Comment
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OfferChecklistComment;
