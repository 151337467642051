import { message } from "antd";

const INIT_STATE = {
  loading: false,
  userSubCat: [
    {
      link: "/user/manage-account",
      title: "Manage User Account",
    },
    {
      link: "/user/bulk-student-account-management",
      title: " Bulk Student User Account Management",
    },
    {
      link: "/user/create-bulk-student-account",
      title: " Bulk Create Student User Account",
    },
    {
      link: "/user/ip-activity-track",
      title: "User IP Activity Track ",
    },
  ],
};

export const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_STAFF_REQUEST": {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};
