import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AddButton from "../../../../components/button/AddButton";
import { Pagename } from "../../../../components/input";
import AddTeacherMatrix from "./AddTeacherMatrix";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import UpdateTeacherMatrix from "./UpdateTeacherMatrix";

const columns = [
  {
    title: "Subject",
    dataIndex: "subject",
    width: 250,
    align: "center",
  },
  {
    title: "Knowledge Level",
    dataIndex: "knowledgeLevel",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const matrixData = [
  {
    id: 1,
    subject: "EAP-B1-A: English for Academic Purpose",
    knowledgeLevel: "-",
  },
  {
    id: 1,
    subject: "GE-A2-A: GE Elementary",
    knowledgeLevel: "-",
  },
  {
    id: 1,
    subject: "GE-B1-A: GE Pre-Intermediate",
    knowledgeLevel: "-",
  },
];

const StaffMatrix = () => {
  const [isAddTeacherMatrixModalVisible, setIsAddTeacherMatrixModalVisible] =
    useState(false);
  const [
    isUpdateTeacherMatrixModalVisible,
    setIsUpdateTeacherMatrixModalVisible,
  ] = useState(false);
  const data = matrixData.map((dataObj) => {
    return {
      key: dataObj.id,
      subject: dataObj.subject,
      knowledgeLevel: dataObj.knowledgeLevel,
      actions: (
        <Space size="middle">
          <Tooltip title="Update Teacher Matrix">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateTeacherMatrixModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete selected teacher matrix?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Teacher Matrix</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddTeacherMatrixModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      {isAddTeacherMatrixModalVisible && (
        <AddTeacherMatrix
          isAddTeacherMatrixModalVisible={isAddTeacherMatrixModalVisible}
          setIsAddTeacherMatrixModalVisible={setIsAddTeacherMatrixModalVisible}
        />
      )}
      {isUpdateTeacherMatrixModalVisible && (
        <UpdateTeacherMatrix
          isUpdateTeacherMatrixModalVisible={isUpdateTeacherMatrixModalVisible}
          setIsUpdateTeacherMatrixModalVisible={
            setIsUpdateTeacherMatrixModalVisible
          }
        />
      )}
    </Card>
  );
};

export default StaffMatrix;
