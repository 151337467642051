import React from "react";
import { ButtonElement, SelectField } from "../../../../components/input";
import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Table, Tooltip } from "antd";
import AddManageVenue from "./AddManageVenue";
import EditManageVenue from "./EditManageVenue";

export default function ManageVenue() {
  const dispatch = useDispatch();
  const handleAddEditVenue = (value) => {
    dispatch({ type: "FETCH_OPEN_DRAWER", payload: value });
  };

  const venueList = [
    {
      label: "State",
      value: 1,
    },
    {
      label: "Venue",
      value: 2,
    },
  ];

  const columns = [
    {
      title: "Venue Code",
      dataIndex: "venue_code",
      align: "center",
    },
    {
      title: "Name/Description",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Address",
      dataIndex: "address",
      align: "center",
    },
    {
      title: "Suburb",
      dataIndex: "suburb",
      align: "center",
    },
    {
      title: "State",
      dataIndex: "state",
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "center",
    },
    {
      title: "PostCode",
      dataIndex: "postcode",
      align: "center",
    },
    {
      title: "Contact No.",
      dataIndex: "contact_no",
      align: "center",
    },
    {
      title: "ABN",
      dataIndex: "abn",
      align: "center",
    },
    {
      title: "Is Default?",
      dataIndex: "is_deafult",
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "active",
      align: "center",
    },
    {
      title: "Effective From Date",
      dataIndex: "from_date",
      align: "center",
    },
    {
      title: "Effective To Date",
      dataIndex: "to_date",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: () => (
        <div className="flex justify-center gap-2">
          <Tooltip placement="top" title="Manage Venue">
            <EditOutlined
              onClick={() => handleAddEditVenue("updateVenue")}
              className="text-[blue] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[blue] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000"
            />
          </Tooltip>{" "}
          <Tooltip placement="top" title="Delete Record">
            <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
          </Tooltip>{" "}
        </div>
      ),
    },
  ];

  const data = [
    {
      venue_code: "Opera_HO",
      name: "Head office	",
      address: "Level 1303 Pitt Street	",
      suburb: "Sydney",
      state: "NSW",
      country: "Australia",
      postcode: "2000",
      contact_no: "02 8034 8856	",
      abn: "21616701412	",
      is_default: "Yes",
      acive: "Yes",
      from_date: "",
      to_date: "",
    },
    {
      venue_code: "Opera_HO",
      name: "Head office	",
      address: "Level 1303 Pitt Street	",
      suburb: "Sydney",
      state: "NSW",
      country: "Australia",
      postcode: "2000",
      contact_no: "02 8034 8856	",
      abn: "21616701412	",
      is_default: "Yes",
      acive: "Yes",
      from_date: "",
      to_date: "",
    },
    {
      venue_code: "Opera_HO",
      name: "Head office	",
      address: "Level 1303 Pitt Street	",
      suburb: "Sydney",
      state: "NSW",
      country: "Australia",
      postcode: "2000",
      contact_no: "02 8034 8856	",
      abn: "21616701412	",
      is_default: "Yes",
      acive: "Yes",
      from_date: "",
      to_date: "",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <ButtonElement
          handleClick={() => handleAddEditVenue("addVenue")}
          Icon={<PlusOutlined className="text-sm" />}
          name="Venue"
        />
        <SelectField
          name=""
          label="View Room By"
          placeholder="Select View Room"
          options={venueList}
        />
      </div>
      <Table
        dataSource={data}
        scroll={{ x: 1500, y: 500 }}
        columns={columns}
        pagination={false}
      />
      <AddManageVenue />
      <EditManageVenue />
    </div>
  );
}
