import React, { useState } from "react";
import { ButtonElement } from "../../../components/input";
import { Divider } from "antd";

export default function CompanyDetailPreview({ setCurrent, formData }) {
  console.log("formdata", formData);
  const handleFormSubmit = () => {};
  return (
    <div className="flex flex-col gap-4">
      <div className="my-2 flex flex-col gap-4 custom-scroll">
        <div className="relative border grid grid-cols-1 border-[#00798a50] lg:grid-cols-2 gap-2 rounded-[0.5rem] p-4 mt-3 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            General Information
          </span>
          <span className="lg:col-span-2">{detailFun("Auto Id", 1)}</span>
          {detailFun("RTO Code", formData?.rto_code)}
          {detailFun("CRICOS Code", formData?.cricos_code)}
          <span className="lg:col-span-2">
            {detailFun("College Name", formData?.college_name)}
          </span>
          <span className="lg:col-span-2">
            {detailFun(
              "Training organisation type",
              formData?.oganisation_type
            )}
          </span>{" "}
          {detailFun("Contact person", formData?.contact_person)}
          {detailFun("ABN", formData?.abn)}
          {detailFun("Telephone", formData?.telephone)}
          {detailFun("Fax", formData?.fax)}
          {detailFun("Contact email", formData?.contact_email)}
          {detailFun("IT email", formData?.it_email)}
          {detailFun("Marketing email", formData?.marketing_email)}
          {detailFun("Academic email", formData?.academic_email)}
          {detailFun("Admissions email", formData?.admissions_email)}
          {detailFun("Accounts email", formData?.accounts_email)}
          {detailFun("Other email", formData?.other_email)}
          {detailFun("College URL", formData?.college_url)}
          <span className="lg:col-span-2">
            {detailFun("College Email Setup", formData?.college_email_setup)}
          </span>
          <span className="lg:col-span-2">
            {detailFun("Type of Industry", formData?.type_of_industry)}
          </span>
        </div>

        <div className="relative border  border-[#00798a50]  grid-cols-1 grid md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            AVETMISS Training Organisation Identifier (TOID) for State
          </span>
          {detailFun("TOID for ACT", formData?.toid_act)}
          {detailFun("TOID for NSW", formData?.toid_nsw)}
          {detailFun("TOID for NT", formData?.toid_nt)}
          {detailFun("TOID for QLD", formData?.toid_qld)}
          {detailFun("TOID for SA", formData?.toid_sa)}
          {detailFun("TOID for TAS", formData?.toid_tas)}
          {detailFun("TOID for VIC", formData?.toid_vic)}
          {detailFun("TOID for WA", formData?.toid_wa)}
        </div>
        <div className="relative border  border-[#00798a50]  grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            College Street Address
          </span>
          {detailFun("Address", formData?.college_address)}
          {detailFun("Suburb", formData?.college_suburb)}
          {detailFun("State", formData?.college_state)}
          {detailFun("Postcode", formData?.college_postcode)}
        </div>
        <div className="relative border  border-[#00798a50]  grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white text-[#00798a]">
            College Postal Address
          </span>
          {detailFun("Address", formData?.postal_address)}
          {detailFun("Suburb", formData?.postal_suburb)}
          {detailFun("State", formData?.postal_state)}
          {detailFun("Postcode", formData?.postal_postcode)}
        </div>
        <div className="relative border  border-[#00798a50]  grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4 rounded-[0.5rem] p-2 pt-4 mt-2 ">
          <span className="absolute left-4 top-[-0.8rem] px-1 bg-white  text-[#00798a]">
            Bank Details
          </span>
          {detailFun("Bank name", formData?.bank_name)}
          {detailFun("Branch", formData?.branch)}
          {detailFun("Account name", formData?.account_name)}
          {detailFun("Account number", formData?.account_number)}
          {detailFun("BSB", formData?.bsb)}
          {detailFun("Swift Code", formData?.swift_code)}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <ButtonElement
          htmlType="submit"
          handleClick={() => setCurrent((prev) => prev - 1)}
          name="Back"
        />
        <ButtonElement
          htmlType="submit"
          handleClick={() => handleFormSubmit()}
          name="Edit Company Details"
        />
      </div>
    </div>
  );
}

export const detailFun = (title, value) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 text-[gray]">
        {title}:<span className="text-black">{value}</span>
      </div>
      <Divider orientationMargin="0" className="my-2" />
    </div>
  );
};
