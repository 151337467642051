import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import AddButton from "../../../../components/button/AddButton";
import AddOfferDocumentChecklist from "./AddOfferDocumentChecklist";

const columns = [
  {
    title: "Document Name",
    dataIndex: "documentName",
    width: 100,
    align: "center",
  },
  {
    title: "Document Type",
    dataIndex: "documentType",
    width: 200,
    align: "center",
  },
  {
    title: "Origin",
    dataIndex: "origin",
    width: 200,
    align: "center",
  },
  {
    title: "Mandatory",
    dataIndex: "mandatory",
    width: 50,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },
];

const OfferDocumentChecklistData = [
  {
    id: "1",
    documentName: "CoE",
    documentType: "Post_Application",
    origin: "Resident Student",
    mandatory: "Yes",
    status: "INACTIVE",
  },
  {
    id: "2",
    documentName: "Copy of Passport",
    documentType: "In_Application",
    origin: "Overseas Student",
    mandatory: "YES",
    status: "ACTIVE",
  },
  {
    id: "2",
    documentName: "Proof of payment",
    documentType: "Post_Application",
    origin: "Overseas Student in Australia",
    mandatory: "YES",
    status: "ACTIVE",
  },
];

const OfferDocumentChecklist = () => {
  const [
    isAddOfferDocumentChecklistModalVisible,
    setIsAddOfferDocumentChecklistModalVisible,
  ] = useState(false);
  const [
    isUpdateOfferDocumentChecklistModalVisible,
    setIsUpdateOfferDocumentChecklistModalVisible,
  ] = useState(false);

  const data = OfferDocumentChecklistData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      documentName: dataObj?.documentName,
      documentType: dataObj?.documentType,
      origin: dataObj?.origin,
      mandatory: dataObj?.mandatory,
      status: dataObj?.status,
    };
  });
  return (
    <div className="py-4 px-2">
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Offer Document Checklist Setup</Pagename>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AddButton
              handleClick={() => {
                setIsAddOfferDocumentChecklistModalVisible(true);
              }}
            >
              Add
            </AddButton>
          </div>
        </div>
        {/* <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>View Deferral By:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div> */}
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          bordered
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isAddOfferDocumentChecklistModalVisible && (
        <AddOfferDocumentChecklist
          isAddOfferDocumentChecklistModalVisible={
            isAddOfferDocumentChecklistModalVisible
          }
          setIsAddOfferDocumentChecklistModalVisible={
            setIsAddOfferDocumentChecklistModalVisible
          }
        />
      )}
      {/* {isUpdateOfferDocumentChecklistModalVisible && (
        <UpdateOfferDocumentChecklist
          isUpdateOfferDocumentChecklistModalVisible={
            isUpdateOfferDocumentChecklistModalVisible
          }
          setIsUpdateOfferDocumentChecklistModalVisible={
            setIsUpdateOfferDocumentChecklistModalVisible
          }
        />
      )} */}
    </div>
  );
};

export default OfferDocumentChecklist;
