import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const PayScheduleFee = ({
  isPayScheduleFeeModalVisible,
  setIsPayScheduleFeeModalVisible,
}) => {
  const [payScheduleFeeData, setPayScheduleFeeData] = useState({
    transactionProcessingType: "INVOICED_AMOUNT",
    addLatePaymentFee: false,
    applySurcharge: false,
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const PayScheduleFeeSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "PayScheduleFee_REQUEST", payload: formData });
    // form.resetFields();
    setIsPayScheduleFeeModalVisible(false);
  };
  const [payFees, setPayFees] = useState([
    {
      isPayFee: false,
      payType: "Tuition Fee",
      invId: "261",
      invoiced: "666.67",
      InvoiceCr: "0.00",
      paid: "0.00",
      processingAmount: "0.00",
      agentBonus: "",
      IsAgentGst: false,
      isCalcCommAfterBonus: false,
      agentCommission: "Comm: $0.00 & GST: $0.00",
      badDebt: "0.00",
    },
  ]);
  const handleIsPayFeeChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].payType = e.target.checked;
    setPayFees(list);
  };
  const handleProcessingAmountChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].processingAmount = e.target.value;
    setPayFees(list);
  };
  const handleAgentBonusChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].agentBonus = e.target.value;
    setPayFees(list);
  };
  const handleIsAgentGstChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].IsAgentGst = e.target.checked;
    setPayFees(list);
  };
  const handleIsCalcCommAfterBonusChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].isCalcCommAfterBonus = e.target.checked;
    setPayFees(list);
  };
  const handleBadDebtChange = (e, index) => {
    console.log(e, index);
    const list = [...payFees];
    list[index].badDebt = e.target.value;
    setPayFees(list);
  };

  return (
    <Drawer
      title="Pay Schedule Fee"
      open={isPayScheduleFeeModalVisible}
      onClose={() => {
        setIsPayScheduleFeeModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={PayScheduleFeeSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div className="flex flex-col gap-4 p-4 pd-8 my-8 relative border border-[#00798a] rounded-[0.5rem] ">
          <span className="absolute top-[-1.2rem] left-4 px-2 py-1 bg-white border border-[#00798a] rounded-[4px]">
            Transaction Info
          </span>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Transaction Processing Type"
              name={"transactionProcessingType"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                defaultValue={"INVOICED_AMOUNT"}
                onChange={(e) => {
                  setPayScheduleFeeData((prevData) => {
                    return {
                      ...prevData,
                      transactionProcessingType: e.target.value,
                    };
                  });
                }}
              >
                <Radio value={"INVOICED_AMOUNT"}>Invoiced Amount</Radio>
                <Radio value={"NET_RECEIVED_AMOUNT"}>Net Received Amount</Radio>
              </Radio.Group>
            </Form.Item>
            {payScheduleFeeData?.transactionProcessingType ===
              "INVOICED_AMOUNT" && (
              <Form.Item
                label="Total Invoiced Amount"
                style={{ width: "100%" }}
              >
                $666.67
              </Form.Item>
            )}
            {payScheduleFeeData?.transactionProcessingType ===
              "NET_RECEIVED_AMOUNT" && (
              <Form.Item
                label="Net Received Amount"
                name={"netReceivedAmount"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a net received amount",
                  },
                ]}
              >
                <Input placeholder="Net Received Amount" />
              </Form.Item>
            )}
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Receipt No"
              name={"Receipt No"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a receipt number",
                },
              ]}
            >
              <Input placeholder="Receipt No" />
            </Form.Item>
            <Form.Item
              label="Payment Date"
              name={"paymentDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a payment date",
                },
              ]}
            >
              <DatePicker format={"DD-MM-YYYY"} />
            </Form.Item>
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Payment Mode"
              name={"paymentMode"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a payment mode!",
                },
              ]}
            >
              <Select placeholder="Payment Mode">
                <Option value="BANK_DEPOSIT">Bank Deposit</Option>
                <Option value="CREDIT_CARD">Credit Card</Option>
                <Option value="OTHER">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Bank Deposit Date"
              name={"bankDepositDate"}
              style={{ width: "100%" }}
            >
              <DatePicker format={"DD-MM-YYYY"} />
            </Form.Item>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#eeeeee55",
            borderRadius: "0.5rem",
            padding: "0.5rem",
          }}
        >
          {payFees.map((singleQualification, index) => {
            return (
              <div key={index}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{
                      justifyContent: "space-between",
                      gap: "0.5rem",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      style={{
                        alignSelf: "flex-end",
                      }}
                      name={"isPayFee"}
                    >
                      <Checkbox
                        name="isPayFee"
                        value={payFees[index].isPayFee}
                        onChange={(e) => {
                          handleIsPayFeeChange(e, index);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Pay Type"
                      style={{
                        width: "100%",
                        alignSelf: "flex-end",
                      }}
                    >
                      {payFees[index].payType}
                    </Form.Item>
                    <Form.Item
                      label="Invoiced"
                      style={{
                        width: "100%",
                        alignSelf: "flex-end",
                      }}
                    >
                      {payFees[index].invoiced}
                    </Form.Item>
                    <Form.Item
                      label="Invoice Cr."
                      style={{
                        width: "100%",
                        alignSelf: "flex-end",
                      }}
                    >
                      {payFees[index].InvoiceCr}
                    </Form.Item>
                    <Form.Item
                      label="paid"
                      style={{
                        width: "100%",
                        alignSelf: "flex-end",
                      }}
                    >
                      {payFees[index].paid}
                    </Form.Item>

                    <Form.Item
                      label="Processing Amount"
                      style={{ width: "100%" }}
                    >
                      <Input
                        name={"processingAmount"}
                        placeholder="processingAmount"
                        value={payFees[index].processingAmount}
                        onChange={(e) => {
                          handleProcessingAmountChange(e, index);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Agent Bonus"
                      style={{ width: "100%", alignSelf: "flex-end" }}
                    >
                      <Input
                        name={"agentBonus"}
                        placeholder="agentBonus"
                        value={payFees[index].agentBonus}
                        onChange={(e) => {
                          handleAgentBonusChange(e, index);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{
                        alignSelf: "flex-end",
                      }}
                      name={"IsAgentGst"}
                    >
                      <Checkbox
                        name="IsAgentGst"
                        value={payFees[index].IsAgentGst}
                        onChange={(e) => {
                          handleIsAgentGstChange(e, index);
                        }}
                      >
                        GST
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      style={{
                        width: "100%",
                        alignSelf: "flex-end",
                      }}
                      name={"isCalcCommAfterBonus"}
                    >
                      <Checkbox
                        name="isCalcCommAfterBonus"
                        value={payFees[index].isCalcCommAfterBonus}
                        onChange={(e) => {
                          handleIsCalcCommAfterBonusChange(e, index);
                        }}
                      >
                        Calc. Comm. After Bonus
                      </Checkbox>
                    </Form.Item>
                    <Form.Item label="Bad Debt" style={{ width: "100%" }}>
                      <Input
                        name={"badDebt"}
                        placeholder="badDebt"
                        value={payFees[index].badDebt}
                        onChange={(e) => {
                          handleBadDebtChange(e, index);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Form.Item
          label="Total Processed"
          name={"totalProcessed"}
          style={{ width: "100%" }}
        >
          AUD0.00
        </Form.Item>

        <Form.Item name={"agentCommissionIsDeducted"} valuePropName="checked">
          <Checkbox>Agent Commission is deducted</Checkbox>
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            style={{ width: "100%" }}
            name={"addLatePaymentFee"}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                setPayScheduleFeeData((prevData) => {
                  return {
                    ...prevData,
                    addLatePaymentFee: e.target.checked,
                  };
                });
              }}
            >
              Add Late Payment Fee
            </Checkbox>
          </Form.Item>
          {payScheduleFeeData?.addLatePaymentFee && (
            <Form.Item
              label="Late Payment Fee"
              name={"latePaymentFee"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a late payment fee",
                },
              ]}
            >
              <Input placeholder="Late Payment Fee" />
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            style={{ width: "100%" }}
            name={"applySurcharge"}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                setPayScheduleFeeData((prevData) => {
                  return {
                    ...prevData,
                    applySurcharge: e.target.checked,
                  };
                });
              }}
            >
              Apply Surcharge
            </Checkbox>
          </Form.Item>
          {payScheduleFeeData?.applySurcharge && (
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Form.Item
                label="Surcharge Amount"
                name={"surchargePercent"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a surcharge percentage",
                  },
                ]}
              >
                <Input placeholder="%" /> % of
              </Form.Item>
              <Form.Item
                name={"surchargeAmount"}
                style={{ width: "100%", alignSelf: "flex-end" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter surcharge amount",
                  },
                ]}
              >
                <Input placeholder="Surcharge Amount" />=
              </Form.Item>
            </div>
          )}
        </div>
        <Form.Item label="Remarks" name={"Remarks"} style={{ width: "100%" }}>
          <TextArea placeholder="Werite something.." />
        </Form.Item>
        <Form.Item
          label={"Commission Payable"}
          name={"commissionPayable"}
          style={{ width: "100%" }}
        >
          $0.00
        </Form.Item>
        <Form.Item
          label="Bonus Paid To Agent"
          name={"bonusPaidToAgent"}
          style={{ width: "100%" }}
        >
          $0.00
        </Form.Item>
        <Form.Item
          label="Amount Received"
          name={"amountReceived"}
          style={{ width: "100%" }}
        >
          AUD0.00
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Process Transaction"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PayScheduleFee;
