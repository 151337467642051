import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment/moment";
const { Option } = Select;

const UpdateClaimTracking = ({
  isUpdateClaimTrackingModalVisible,
  setIsUpdateClaimTrackingModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const UpdateClaimTrackingSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsUpdateClaimTrackingModalVisible(false);
  };
  return (
    <Drawer
      title="Update Claim Record"
      open={isUpdateClaimTrackingModalVisible}
      onClose={() => {
        setIsUpdateClaimTrackingModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={UpdateClaimTrackingSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="State"
            name={"state"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a training contract ID!",
              },
            ]}
          >
            <Select placeholder="Select a State" defaultValue={"ACT"}>
              <Option value="ACT">ACT</Option>
              <Option value="NSW">NSW</Option>
              <Option value="NT">NT</Option>
              <Option value="QLD">QLD</Option>
              <Option value="SA">SA</Option>
              <Option value="TAS">TAS</Option>
              <Option value="VIC">VIC</Option>
              <Option value="WA">WA</Option>
              <Option value="OTHER">Other - Overseas</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Claim Stage Year"
            name={"claimStageYear"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a claim stage year!",
              },
            ]}
          >
            <Select defaultValue={"1"}>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Lodgement Type"
            name={"lodgementType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter knowledge level!",
              },
            ]}
          >
            <Radio.Group defaultValue={"INITIAL"}>
              <Radio value="INITIAL">Initial</Radio>
              <Radio value="MIDWAY">Midway</Radio>
              <Radio value="FINAL">Final</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Claim Status"
            name={"claimStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select claim status!",
              },
            ]}
          >
            <Select placeholder="Select Claim Status">
              <Option value="LODGED">Lodged</Option>
              <Option value="REJECTED">Rejected</Option>
              <Option value="PAID">Paid</Option>
              <Option value="DO_NOT_CLAIM">Do Not Claim</Option>
              <Option value="RETURNED">Returned</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Lodgement ID"
            name={"lodgementId"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter lodgement Id!",
              },
            ]}
          >
            <Input placeholder="Lodgement ID" />
          </Form.Item>
          <Form.Item
            label="Lodgement Date"
            name={"lodgementDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter lodgement date!",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Claim Amount"
            name={"claimAmount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter claim amount!",
              },
            ]}
          >
            <Input type="number" placeholder="Claim Amount" />
          </Form.Item>
          <Form.Item
            label="Amount Received"
            name={"claimReceived"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter amount received!",
              },
            ]}
          >
            <Input type="number" placeholder="Amount Received" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Payment Received Date"
            name={"paymentReceivedDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter payment received date!",
            //   },
            // ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Invoice Number"
            name={"invoiceNumber"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter invoice number!",
            //   },
            // ]}
          >
            <Input placeholder="Invoice Number" />
          </Form.Item>
        </div>

        <Form.Item
          label="Notes"
          name={"Notes"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter some comments!",
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            style={{ height: "250px", marginBottom: "4rem" }}
          />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Update Claim Record"
          >
            Update Claiqm Record
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateClaimTracking;
