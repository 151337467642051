import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment/moment";
const { Option } = Select;

const AddStaffDiary = ({
  isAddStaffDiaryModalVisible,
  setIsAddStaffDiaryModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectOrInput, setSelectOrInput] = useState("SELECT");
  const AddStaffDiarySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    // form.resetFields();
    setIsAddStaffDiaryModalVisible(false);
  };
  return (
    <Drawer
      title="Add Communication Log"
      open={isAddStaffDiaryModalVisible}
      onClose={() => {
        setIsAddStaffDiaryModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddStaffDiarySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Today" style={{ width: "100%" }}>
            {moment.utc().local().format("dddd, DD MMMM YYYY")}
          </Form.Item>
          <Form.Item label="Comment by" style={{ width: "100%" }}>
            Anu Rani Regmi (Anu)
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Staff/Teacher"
            name={"knowledgeLevel"}
            style={{ width: "100%" }}
            rules={[
              {
                required: selectOrInput === "INPUT",
                message: "Please enter knowledge level!",
              },
            ]}
          >
            <Select>
              <Option>Mr Alexander Freitag</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Type"
            name={"type"}
            style={{ width: "100%" }}
            rules={[
              {
                required: selectOrInput === "INPUT",
                message: "Please enter knowledge level!",
              },
            ]}
          >
            <Select defaultValue={"GENERAL"}>
              <Option value="GENERAL">General</Option>
              <Option value="EMAIL_LOG">Email Log</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Status"
            name={"status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter knowledge level!",
              },
            ]}
          >
            <Select defaultValue={"INFORMED"}>
              <Option value="GENERAL">Informed</Option>
              <Option value="EMAIL_SENT">Email Sent</Option>
              <Option value="RESOLVED">Resolved</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Subject" name={"Subject"} style={{ width: "100%" }}>
            <Input placeholder="Subject" />
          </Form.Item>
        </div>
        <Form.Item
          label="Comment/Log"
          name={"Comment/Log"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter some comments!",
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            style={{ height: "250px", marginBottom: "4rem" }}
          />
        </Form.Item>
        <Form.Item label="File Attachment" style={{ width: "100%" }}>
          <input type="file" style={{ margin: " 0.5rem 0" }} />
          <input type="file" style={{ margin: " 0.5rem 0" }} />
          <input type="file" style={{ margin: " 0.5rem 0" }} />
          <input type="file" style={{ margin: " 0.5rem 0" }} />
          <input type="file" style={{ margin: " 0.5rem 0" }} />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Communication Log"
          >
            Add Communication Log
          </ButtonElement>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddStaffDiary;
