import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../../components/input";

const columns = [
  {
    title: "Commented On",
    dataIndex: "commentedOn",
    width: 250,
    align: "center",
  },
  {
    title: "Commented By",
    dataIndex: "commentedBy",
    width: 150,
    align: "center",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    width: 150,
    align: "center",
  },
];

const courseLogData = [
  {
    id: 1,
    commentedOn: "22-08-2023 4:29:22 PM",
    commentedBy: "(Staff) Anu Rani Regmi",
    comment:
      "Tuition charged fee changed from 7040.00 to 5760.00. Enrolment fee changed from 200.00 to 250.00. Upfront fee changed from 7040.00 to 960.00.",
  },
  {
    id: 2,
    commentedOn: "22-08-2023 4:29:22 PM",
    commentedBy: "(Staff) Anu Rani Regmi",
    comment: "",
  },
];

const selectCourse = [
  {
    label: "All Courses",
    value: "ALL",
  },
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const CourseLog = () => {
  const data = courseLogData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      commentedOn: dataObj?.commentedOn,
      commentedBy: dataObj?.commentedBy,
      comment: dataObj?.comment,
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Course Log</Pagename>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Courses:</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={selectCourse}
              handleChange={(e) => {}}
              fieldWidth="20rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
    </Card>
  );
};

export default CourseLog;
