import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddCreditBonusAllocation = ({
  isAddCreditBonusAllocationModalVisible,
  setIsAddCreditBonusAllocationModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddCreditBonusAllocationSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddCreditBonusAllocation_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddCreditBonusAllocationModalVisible(false);
  };
  return (
    <Modal
      title="Add Credit Allocation"
      open={isAddCreditBonusAllocationModalVisible}
      onCancel={() => {
        setIsAddCreditBonusAllocationModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddCreditBonusAllocationSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Type"
            name={"type"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a type !",
              },
            ]}
          >
            <Select placeholder="Type">
              <Option value="ADVANCED_PAID">Advanced Paid</Option>
              <Option value="AGENT_CREDIT">Agent Credit</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Amount"
            name={"amount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a amount!",
              },
            ]}
          >
            <Input type="number" placeholder="Amount" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Payment Mode"
            name={"PaymentMode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a payment mode!",
              },
            ]}
          >
            <Select placeholder="Payment mode" defaultValue={"BANK_DEPOSIT"}>
              <Option value="BANK_DEPOSIT">Bank Deposit</Option>
              <Option value="CREDIT_CARD">Credit Card</Option>
              <Option value="OTHER">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Payment Date"
            name={"paymentDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a payment date",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
        <Form.Item label="Comment" name={"comment"}>
          <TextArea placeholder="Write something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement block type="primary" htmlType="submit" name="Add" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCreditBonusAllocation;
