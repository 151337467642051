import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;

const AddVaccinationDose = ({
  isAddVaccinationDoseModalVisible,
  setIsAddVaccinationDoseModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addVaccinationDoseSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_VACCINATION_DOSE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddVaccinationDoseModalVisible(false);
  };
  return (
    <Modal
      title="Add Vaccination Dose"
      open={isAddVaccinationDoseModalVisible}
      onCancel={() => {
        setIsAddVaccinationDoseModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addVaccinationDoseSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Vaccination Date"
            name={"vaccinationDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick vaccination date!",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Brand/Company"
            name={"brand"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Brand" />
          </Form.Item>
        </div>

        <Form.Item
          label="Vaccination Location"
          name={"Vaccination Location"}
          style={{ width: "100%" }}
        >
          <Input placeholder="Vaccination Location" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block={true}
            type="primary"
            htmlType="submit"
            name="Add Vaccination Dose"
          >
            Add Vaccination Dose
          </ButtonElement>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddVaccinationDose;
