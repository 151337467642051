import React, { useState } from "react";
import { Button, Checkbox, Drawer, Form, Input, Select, Steps } from "antd";
import StaffPersonalInfo from "../AddStaff/StaffPersonalInfo";
import StaffEmploymentInfo from "../AddStaff/StaffEmploymentInfo";
import StaffSystemAccessInfo from "../AddStaff/StaffSystemAccessInfo";

const { Option } = Select;

const UpdateStaff = ({
  fromAddTeacher,
  record,
  isUpdateStaffModalVisible,
  setIsUpdateStaffModalVisible,
}) => {
  //   const dispatch = useDispatch();
  const [staffData, setStaffData] = useState({
    title: "Mr",
    photo: null,
    firstName: record?.firstName,
    lastName: record?.lastName,
    countryId: record?.countryId,
    countryName: record?.countryName,
    address: record?.address,
    suburb: record?.suburb,
    state: record?.state,
    postCode: record?.postCode,
    phone: record?.phone,
    mobile: record?.mobile,
    email: record?.email,
    collegeEmail: record?.collegeEmail,
    signatoryText: record?.signatoryText,
    emergencyContactName: record?.emergencyContactName,
    emergencyContactNumber: record?.emergencyContactNumber,

    staffNumber: record?.staffNumber,
    positionId: record?.positionId,
    positionName: record?.positionName,
    status: record?.status ?? "ACTIVE",
    userName: record?.userName,
    password: "",
    confirmPassword: "",
    securityQuestion: record?.securityQuestion,
    securityAnswer: record?.securityAnswer,
  });
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);

  const UpdateStaffHandler = () => {
    // dispatch({ type: "ADD_STAFF_REQUEST", payload: { ...value } });

    form.resetFields();
    setIsUpdateStaffModalVisible(false);
    console.log("Will be added when api is provided");
  };
  const forms = [
    <StaffPersonalInfo
      staffData={staffData}
      setStaffData={setStaffData}
      current={current}
      setCurrent={setCurrent}
    />,
    <StaffEmploymentInfo
      staffData={staffData}
      setStaffData={setStaffData}
      current={current}
      setCurrent={setCurrent}
    />,
    <StaffSystemAccessInfo
      staffData={staffData}
      setStaffData={setStaffData}
      current={current}
      setCurrent={setCurrent}
      fromAddTeacher={fromAddTeacher}
      UpdateStaffHandler={UpdateStaffHandler}
    />,
  ];

  return (
    <Drawer
      title={fromAddTeacher ? "Update Teacher" : "Update Staff"}
      open={isUpdateStaffModalVisible}
      onClose={() => {
        setIsUpdateStaffModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          //   onChange={(e) => {
          //     setCurrent(e);
          //   }}
          items={[
            {
              title: "Personal Information",
            },
            {
              title: "Employment Information",
            },
            {
              title: "System Access Information",
            },
          ]}
        />
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default UpdateStaff;
