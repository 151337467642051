import React from "react";
import { Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import { ButtonElement } from "../../../../components/input";
import InitialPaymentSummary from "./InitialPaymentSummary";
const { Option } = Select;

const ScheduleInfo = ({
  initialPaymentData,
  setInitialPaymentData,
  current,
  setCurrent,
  addInitialPaymentHandler,
}) => {
  const [form] = Form.useForm();

  const addScheduleInfoHandler = () => {
    addInitialPaymentHandler();
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexFlow: "wrap",
        gap: "1rem",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
        }}
      >
        <Form
          layout="vertical"
          onFinish={addScheduleInfoHandler}
          colon={true}
          form={form}
          className="drawerStyle"
          fields={[
            {
              name: ["generateNextPaymentSchedule"],
              value: initialPaymentData?.generateNextPaymentSchedule,
            },
            { name: ["amount"], value: initialPaymentData?.amount },
            {
              name: ["noOfInstallments"],
              value: initialPaymentData?.noOfInstallments,
            },
            { name: ["frequency"], value: initialPaymentData?.frequency },
            {
              name: ["frequencyType"],
              value: initialPaymentData?.frequencyType,
            },
            {
              name: ["invoiceType"],
              value: initialPaymentData?.invoiceType,
            },
          ]}
        >
          <Form.Item
            name={"generateNextPaymentSchedule"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setInitialPaymentData((prevData) => {
                  return {
                    ...prevData,
                    generateNextPaymentSchedule: e.target.checked,
                  };
                });
              }}
            >
              Generate Next Payment Schedule(s)
            </Checkbox>
          </Form.Item>
          {initialPaymentData?.generateNextPaymentSchedule && (
            <>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Amout"
                  name={"amount"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select a OSHC provider!",
                  //   },
                  // ]}
                >
                  <Input
                    placeholder="Amount"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          amount: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="No. of Installments"
                  name={"noOfInstallments"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select OSHC type!",
                  //   },
                  // ]}
                >
                  <Input
                    type="number"
                    placeholder="No. of Installments"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          noOfInstallments: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <div
                  className="flexRowWithoutStyle"
                  style={{
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    label="Frequency"
                    name={"frequency"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a frequency!",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Frequency"
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            frequency: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"frequencyType"}
                    style={{ width: "100%", alignSelf: "flex-end" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a frequency type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Frequency Type"
                      defaultValue={"WEEK"}
                      onChange={(e) => {
                        setInitialPaymentData((prevData) => {
                          return {
                            ...prevData,
                            frequencyType: e.target.value,
                          };
                        });
                      }}
                    >
                      <Option value="DAY">Day</Option>
                      <Option value="WEEK">Week</Option>
                      <Option value="MONTH">Month</Option>
                      <Option value="Year">Year</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Invoice Type"
                  name={"invoiceType"}
                  style={{ width: "100%" }}
                >
                  <Radio.Group
                    type="number"
                    placeholder="Invoice Type"
                    onChange={(e) => {
                      setInitialPaymentData((prevData) => {
                        return {
                          ...prevData,
                          invoiceType: e.target.value,
                        };
                      });
                    }}
                  >
                    <Radio value={"SAME_INVOICE_NO"}>Same Invoice No</Radio>
                    <Radio value={"AUTO_GENERATED_INVOICE_NO"}>
                      Auto Generate Invoice No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </>
          )}
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            {current === 2 && (
              <Form.Item>
                <ButtonElement
                  type={"default"}
                  handleClick={() => {
                    setCurrent(1);
                  }}
                  name="Back"
                >
                  Back
                </ButtonElement>
              </Form.Item>
            )}
            <Form.Item>
              <ButtonElement
                type="primary"
                htmlType="submit"
                name="Submit Application"
              >
                Submit
              </ButtonElement>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div
        style={{
          width: "30%",
        }}
      >
        <InitialPaymentSummary
          detail={{
            name: "Anish Banjara",
          }}
        />
      </div>
    </div>
  );
};

export default ScheduleInfo;
