import React from "react";
import {
  ButtonElement,
  InputFieldElement,
  ModalElement,
  TextField,
} from "../../../../components/input";
import { Form } from "antd";

export default function AddContract() {
  return (
    <ModalElement title="Add Contract" keyType="addContract">
      <Form layout="vertical" className="flex flex-col gap-4">
        <InputFieldElement
          name="contract_code"
          label="Contract Code"
          placeholder="Contract Code"
          fieldWidth="100%"
        />
        <TextField
          name="contract_description"
          label="Contract Description"
          placeholder="Contract Description"
          fieldWidth="100%"
        />
        <ButtonElement name="Add Contract" bottonWidth="100%" />
      </Form>
    </ModalElement>
  );
}
