import React, { useState } from "react";
import {
  ComponentBody,
  Pagename,
  SelectField,
  SiderDemo,
} from "../../components/input";
import PageHeader from "../../components/PageHeader/PageHeader";
import { DatePicker, Input, Popconfirm, Space, Table, Tooltip } from "antd";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import AddButton from "../../components/button/AddButton";
const { Search } = Input;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: 300,
    align: "center",
  },
  {
    title: "Size",
    dataIndex: "Size",
    width: 100,
    align: "center",
  },
  {
    title: "Extension",
    dataIndex: "extension",
    width: 100,
    align: "center",
  },
  {
    title: "Last Write Time",
    dataIndex: "lastWriteTime",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const CollegeMaterialsData = [
  {
    id: "SD001",
    name: "AVETMISS",
    Size: "-",
    extension: "Dir",
    lastWriteTime: "28-07-2023 2:40:30 PM",
  },
  {
    id: "SD002",
    name: "EAP Int_Unit_1_Reading Assessment Answer- Set A.pdf",
    Size: "938.85 KB",
    extension: ".pdf",
    lastWriteTime: "13-10-2022 10:44:11 AM",
  },
];

const CollegeMaterials = () => {
  const [isRenameStaffFileModalVisible, setIsRenameStaffFileModalVisible] =
    useState(false);
  const [
    isAddCollegeMaterialFolderModalVisible,
    setIsAddCollegeMaterialFolderModalVisible,
  ] = useState(false);
  const [
    isAddCollegeMaterialFileModalVisible,
    setIsAddCollegeMaterialFileModalVisible,
  ] = useState(false);
  let data = CollegeMaterialsData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      name: dataObj?.name,
      Size: dataObj?.Size,
      extension: dataObj?.extension,
      lastWriteTime: dataObj?.lastWriteTime,
      actions: (
        <Space size="middle">
          <Tooltip title="Rename">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsRenameStaffFileModalVisible(true);
                }}
              >
                <AiOutlineEdit />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Preview">
            <a>
              <div className="bordered" onClick={() => {}}>
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Delete File">
            <Popconfirm
              title="Are you sure to delete this selected file?"
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "red" }}>
                <div className="bordered">
                  <AiOutlineDelete />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    };
  });

  return (
    <SiderDemo>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>College Materials</Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <AddButton
                handleClick={() => {
                  setIsAddCollegeMaterialFileModalVisible(true);
                }}
              >
                Add File
              </AddButton>
              <AddButton
                handleClick={() => {
                  setIsAddCollegeMaterialFolderModalVisible(true);
                }}
              >
                Add Folder
              </AddButton>
            </div>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "2rem",
          }}
        >
          <Table
            bordered
            size="small"
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </div>
      </ComponentBody>
    </SiderDemo>
  );
};

export default CollegeMaterials;
