import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddSystemFields = ({
  isAddSystemFieldsModalVisible,
  setIsAddSystemFieldsModalVisible,
}) => {
  const [systemFieldData, setSystemFieldData] = useState({
    field: "TYPE",
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddSystemFieldsSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddSystemFields_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddSystemFieldsModalVisible(false);
  };
  return (
    <Modal
      title="Add System Fields"
      open={isAddSystemFieldsModalVisible}
      onCancel={() => {
        setIsAddSystemFieldsModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddSystemFieldsSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Name"
            name={"name"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a name!",
              },
            ]}
          >
            <Select placeholder="Name">
              <Option value="DEFER_HOLIDAY_SUSPENSION">
                Defer/Holiday/Suspension
              </Option>
              <Option value="DIARY_AGENT">Diary - Agent</Option>
              <Option value="DIARY_OFFER">Diary - Offer</Option>
              <Option value="DIARY_STAFF">Diary - Staff</Option>
              <Option value="DIARY_STUDENT">Diary - Student</Option>
              <Option value="DIARY_ALUMNI">Diary - Alumni</Option>
              <Option value="ELICOS_LEVEL">ELICOS Level</Option>
              <Option value="ENGLISH_TEST">English Test</Option>
              <Option value="TUTION_PROTECTION_SCHEME">
                Tution Protection Scheme
              </Option>
              <Option value="VISA_STATUS">Visa Status</Option>
              <Option value="CLASS_TYPE">Class Type</Option>
              <Option value="PROFESSIONAL_DEVELOPMENT_CATEGORY">
                Professional DEvelopment Category
              </Option>
              <Option value="OUTCOME_LEVEL">Outcome Level</Option>
              <Option value="WORK_PLACEMENT_ACTIVITY">
                Work Placement Activity
              </Option>
              <Option value="PAYMENT_OPTION">Payment Option</Option>
              <Option value="OFFER_DOCUMENT_CHECKLIST_DOCUMENT_TYPE">
                Offer Document Checklist - Document Type
              </Option>
              <Option value="VACCINATION_TYPE">Vaccination Type</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Field"
            name={"field"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter !",
              },
            ]}
          >
            <Select
              placeholder="Field"
              onChange={(e) => {
                setSystemFieldData((prevData) => {
                  return {
                    ...prevData,
                    field: e,
                  };
                });
              }}
            >
              <Option value="TYPE">Type</Option>
              <Option value="STATUS">Status</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {systemFieldData.field === "STATUS" && (
            <Form.Item
              label="Value"
              name={"value"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a value",
                },
              ]}
            >
              <Select placeholder="Value">
                <Option value="APPROVED">Approved</Option>
                <Option value="INTERNAM">Internal</Option>
                <Option value="REJECTED">Rejected</Option>
                <Option value="UPLOADED">Uploaded</Option>
              </Select>{" "}
            </Form.Item>
          )}
          <Form.Item
            label="Display Value"
            name={"displayValue"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a display value",
              },
            ]}
          >
            <Input placeholder="Display Value" />
          </Form.Item>
        </div>

        <Form.Item name={"isDefault"} valuePropName="checked">
          <Checkbox>Set as default</Checkbox>
        </Form.Item>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add System Fields"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSystemFields;
