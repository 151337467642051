import React from "react";
import { Button } from "antd";
import { UserAddOutlined, DownOutlined } from "@ant-design/icons";

const ButtonElement = ({
  name = "",
  Icon = "",
  size = "medium",
  value = "",
  buttonType = "default",
  handleClick = () => {},
  htmlType = "button",
  block = false,
  bottonWidth = "fit-content",
}) => {
  return (
    <Button
      className="flex flex-row align-middle justify-center ant-primary-btn"
      size={size}
      icon={Icon}
      block={block}
      type={buttonType}
      onClick={() => handleClick(value)}
      style={{ borderRadius: "3px", width: bottonWidth }}
      htmlType={htmlType}
    >
      {name}
    </Button>
  );
};

export default ButtonElement;
