import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;

const AddLedgerAccount = ({
  isAddLedgerAccountModalVisible,
  setIsAddLedgerAccountModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddLedgerAccountSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_LEDGER_ACCOUNT_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddLedgerAccountModalVisible(false);
  };
  return (
    <Modal
      title="Add Ledger Account"
      open={isAddLedgerAccountModalVisible}
      onCancel={() => {
        setIsAddLedgerAccountModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddLedgerAccountSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Account Type"
            name={"accountType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a account type !",
              },
            ]}
          >
            <Select placeholder="Account Type" defaultValue={"BANK_ACCOUNT"}>
              <Option value="BANK_ACCOUNT">Bank Account</Option>
              <Option value="RECEIVABLE_ACCOUNT">Receivable Account</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Account Number"
            name={"accountNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter an account number!",
              },
            ]}
          >
            <Input placeholder="Account Number" />
          </Form.Item>
        </div>
        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Ledger Account"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLedgerAccount;
