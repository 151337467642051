import { Divider, Tag } from "antd";
import moment from "moment";
import React from "react";

const AgentCommissionSummary = ({ detail }) => {
  const caseDetails = (title, value) => {
    return (
      <div>
        <div className="profileTitle">{title}:</div>
        <div>{value}</div>
      </div>
    );
  };
  return (
    <div
      style={{
        borderLeft: "1px solid #f0f2f5",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
      }}
    >
      <div>
        <h4>STUDENT INFORMATION:</h4>
      </div>
      <Divider orientationMargin="0" style={{ margin: "0" }} />
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Transaction No", "120")}
        </div>
        <div style={{ width: "100%" }}>
          {caseDetails("Agent name", "Opera Education Group Pty Ltd")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Commission rate applied ", "0%+No GST")}
        </div>
        <div style={{ width: "100%" }}>{caseDetails("GST", "AUD 0")}</div>
      </div>
      {/* <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Finish Date", "18/06/2021")}
        </div>
        <div style={{ width: "100%" }}>
          {caseDetails("Student Course Template", "General English")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>{caseDetails("Status", "Enrolled")}</div>
        <div style={{ width: "100%" }}>
          {caseDetails("Start Date", "12-04-2021")}
        </div>
      </div>
      <div className="fxRJsb" style={{ gap: "0.3rem" }}>
        <div style={{ width: "100%" }}>
          {caseDetails("Result Calculation Method", "Subject Based")}
        </div>
      </div> */}
    </div>
  );
};

export default AgentCommissionSummary;
