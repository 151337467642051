import React, { useEffect, useState } from "react";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Button,
  theme,
  Divider,
  Drawer,
  Dropdown,
  Tooltip,
} from "antd";
import {
  AiFillSetting,
  AiOutlineBook,
  AiOutlineFieldTime,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineSetting,
  AiOutlineUser,
} from "react-icons/ai";
import { MdOutlineSecurity } from "react-icons/md";
import { RiAdminLine } from "react-icons/ri";
import { BsPeople, BsInfoCircle } from "react-icons/bs";
import { MdOutlineLocalOffer, MdOutlinePhoneInTalk } from "react-icons/md";
import {
  PiChalkboardTeacherLight,
  PiListNumbersLight,
  PiStudentLight,
} from "react-icons/pi";
import { FaMessage } from "react-icons/fa6";
import { ImArrowUp } from "react-icons/im";
import { SiCoinmarketcap } from "react-icons/si";
import { GrCompliance } from "react-icons/gr";
import { HiOutlineDocumentReport } from "react-icons/hi";
import khrouchLogo from "../../Assets/logo/smartRTOLogo2.jpg";
import khrouchLogo2 from "../../Assets/logo/smartRTOLogo1.jpg";
import Client from "../../Assets/logo/client.png";
import { Link, NavLink } from "react-router-dom";
import ScrollUpElement from "../scrollUpElement/ScrollUpElement";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { BiSupport } from "react-icons/bi";

const { Header, Sider, Content, Footer } = Layout;

const SiderDemo = ({ children }) => {
  const siderDemoState = useSelector((state) => state.SiderDemoReducer);
  console.log("store.siderDemoState");
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeNav, setActiveNav] = useState("");
  const actions = useDispatch();
  const setActiveNavHandler = (activeNav) => {
    actions(
      allActions(
        { activeNav },
        {
          method: "",
          endPoint: ``,
          attempt: "SET_ACTIVE_NAV_REQUEST",

          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  function handleClick(e) {
    setCurrent(e.key);
  }

  const items = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: <NavLink to="/profile"> Profile</NavLink>,
    },
    {
      key: "4",
      icon: <BsInfoCircle />,
      label: <NavLink to="/leave-info">Leave Info</NavLink>,
    },
    {
      key: "2",
      icon: <MdOutlineSecurity />,
      label: (
        <NavLink to="/setting/mf-authentication">
          Multi-Factor Authentication
        </NavLink>
      ),
    },
    {
      key: "3",
      icon: <LogoutOutlined />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href="">
          Logout
        </a>
      ),
    },
  ];

  const itemsLists = [
    {
      key: "/home",
      className: activeNav === 1 ? "style--nabmenu" : "",
      icon: <AiOutlineHome />,
      label: (
        <NavLink
          to={"/"}
          className={({ isActive }) => isActive && setActiveNav(1)}
        >
          Home
        </NavLink>
      ),
    },
    {
      key: "/staff",
      className: activeNav === 3 ? "style--nabmenu" : "",
      icon: <BsPeople />,
      label: (
        <NavLink
          to={"/staff"}
          className={({ isActive }) => isActive && setActiveNav(3)}
        >
          Staff
        </NavLink>
      ),
    },

    {
      key: "Users",
      className: activeNav === 4 ? "style--nabmenu" : "",
      icon: <AiOutlineUser />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNav(4)}
          to="/user/manage-account"
        >
          Users
        </NavLink>
      ),
    },
    {
      key: "/courses",
      className: activeNav === 5 ? "style--nabmenu" : "",
      icon: <AiOutlineBook />,
      label: (
        <NavLink
          className={({ isActive }) => {
            isActive && setActiveNav(5);
            // setActiveNavHandler(5);
          }}
          to="/courses"
        >
          Courses
        </NavLink>
      ),
    },
    {
      key: "/timetable",
      className: activeNav === 6 ? "style--nabmenu" : "",
      icon: <AiOutlineFieldTime />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNav(6)}
          to="/manage-class-timetable"
        >
          Timetable
        </NavLink>
      ),
    },
    {
      key: "/teacher",
      className: activeNav === 7 ? "style--nabmenu" : "",
      icon: <PiChalkboardTeacherLight />,
      label: (
        <NavLink
          to="/manage-teacher"
          className={({ isActive }) => isActive && setActiveNav(7)}
        >
          Teacher
        </NavLink>
      ),
    },
    {
      key: "/offers",
      className: activeNav === 8 ? "style--nabmenu" : "",
      icon: <MdOutlineLocalOffer />,
      label: (
        <NavLink
          to="/offers"
          className={({ isActive }) => isActive && setActiveNav(8)}
        >
          Offers
        </NavLink>
      ),
    },

    {
      key: "/student",
      className: activeNav === 9 ? "style--nabmenu" : "",
      icon: <PiStudentLight />,
      label: (
        <NavLink
          to="/student"
          className={({ isActive }) => isActive && setActiveNav(9)}
        >
          Student
        </NavLink>
      ),
    },
    {
      key: "/compliance",
      icon: <GrCompliance />,
      label: "Compliance",
      children: [
        {
          key: 101,
          label: (
            <NavLink
              to="/compliance/attendance-management/add-class-attendance"
              activeClassName="active"
            >
              Attendance Management
            </NavLink>
          ),
        },
        {
          key: 102,
          label: (
            <NavLink
              to="/compliance/academic-progress/setup-assessment-task"
              activeClassName="active"
            >
              Academic Progress
            </NavLink>
          ),
        },
        {
          key: 103,
          label: (
            <NavLink activeClassName="active" to="/compliance/view-timetable">
              View Timetable
            </NavLink>
          ),
        },
        {
          key: 104,
          label: (
            <NavLink to="/compliance/intervention" activeClassName="active">
              Intervention
            </NavLink>
          ),
        },
        {
          key: 105,
          label: (
            <NavLink to="/compliance/intake-group" activeClassName="active">
              Group Management
            </NavLink>
          ),
        },
        {
          key: 106,
          label: (
            <NavLink
              to="/compliance/update-final-outcome"
              activeClassName="active"
            >
              Bulk Update
            </NavLink>
          ),
        },
        {
          key: 107,
          label: (
            <NavLink
              to="/compliance/generate-bulk-certificate"
              activeClassName="active"
            >
              Generate Bulk Certificate
            </NavLink>
          ),
        },
        {
          key: 108,
          label: (
            <NavLink
              to="/compliance/manage-orientation"
              activeClassName="active"
            >
              Manage Orientation
            </NavLink>
          ),
        },
        {
          key: 109,
          label: (
            <NavLink to="/compliance/validate-prisms" activeClassName="active">
              Validate PRISMS
            </NavLink>
          ),
        },
        {
          key: 110,
          label: (
            <NavLink
              to="/compliance/service-provider-allocation"
              activeClassName="active"
            >
              Service Request Allocation
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "/mailing-list",
      className: activeNav === 20 ? "style--nabmenu" : "",
      icon: <MdOutlinePhoneInTalk />,
      label: (
        <NavLink
          to="/mailing-list"
          className={({ isActive }) => isActive && setActiveNav(20)}
        >
          Communication
        </NavLink>
      ),
    },
    {
      key: "/support",
      className: activeNav === 26 ? "style--nabmenu" : "",
      icon: <BiSupport />,
      label: (
        <NavLink
          to="/support"
          className={({ isActive }) => isActive && setActiveNav(26)}
        >
          Support
        </NavLink>
      ),
    },

    {
      key: "/marketing",
      className: activeNav === 21 ? "style--nabmenu" : "",
      icon: <SiCoinmarketcap />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNav(21)}
          to="/marketing/agents"
        >
          {" "}
          Marketing
        </NavLink>
      ),
    },
    {
      key: "/student-payment",
      className: activeNav === 23 ? "style--nabmenu" : "",
      icon: <PiListNumbersLight />,
      label: (
        <NavLink
          to="/student-payment"
          className={({ isActive }) => isActive && setActiveNav(23)}
        >
          Accounts
        </NavLink>
      ),
    },
    {
      key: "/reports",
      className: activeNav === 24 ? "style--nabmenu" : "",
      icon: <HiOutlineDocumentReport />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNav(24)}
          to="/report/generate-report"
        >
          {" "}
          Reports
        </NavLink>
      ),
    },
    {
      key: "/college-materials",
      className: activeNav === 25 ? "style--nabmenu" : "",
      icon: <AiOutlineBook />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNav(25)}
          to="/college-materials"
        >
          College Materials
        </NavLink>
      ),
    },
  ];

  return (
    <Layout hasSider={true} style={{ display: "flex" }}>
      <Sider
        className="sider"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: "white",
          height: "100vh",
          marginBottom: "1rem",
          position: "sticky",
          overflow: "scroll",
          backgroundColor: "white",
          overflowX: "hidden",
          top: 0,
          left: 0,
        }}
      >
        <div
          className="logo"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontSize: "1.2rem",
            marginBottom: "1rem",
            height: "64px",
          }}
        >
          {collapsed ? (
            <img src={khrouchLogo2} style={{ width: "100%" }} alt="" />
          ) : (
            <img src={khrouchLogo} style={{ width: "100%" }} alt="" />
          )}
        </div>

        <Menu
          theme="light"
          mode="inline"
          onClick={handleClick}
          defaultSelectedKeys={[current]}
          items={itemsLists}
        />
      </Sider>
      <Drawer
        title={
          <div
            className="logo"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={khrouchLogo.src} alt="logo" style={{ height: "50px" }} />
          </div>
        }
        placement="left"
        onClose={() => {
          setVisible(false);
        }}
        open={visible}
        width="70vw"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Menu
          theme="light"
          mode="inline"
          style={{ marginLeft: "-2rem" }}
          defaultSelectedKeys={["1"]}
          onClick={handleClick}
          selectedKeys={[current]}
          items={itemsLists}
        />
      </Drawer>
      <Layout className="site-layout" style={{ display: "flex" }}>
        <Header
          className="site-layout-background"
          style={{
            padding: "0 1.2rem 0 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "white",
            height: "3rem",
          }}
        >
          <Button
            className="hidden md:block"
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined className=" h-full p-0 m-0" />
              ) : (
                <MenuFoldOutlined className="h-full p-0 m-0" />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              padding: "0 1.5rem",
              margin: 0,
              height: "inherit",
              width: "auto",
            }}
          />

          <MenuUnfoldOutlined
            className="on-mobile ml-4"
            onClick={() => {
              setVisible(true);
            }}
          />
          <div className="flex flex-row h-[3rem] gap-1 items-center px-4 cursor-pointer ">
            {/* <Tooltip title="Mailbox">
              <NavLink to={"/mail-box"}>
                <AiOutlineMail className="w-[2.2rem] h-auto p-2 hover:bg-slate-300 transition-all ease-in-out duration-500  rounded-[100%] h-auto ease-in" />
              </NavLink>
            </Tooltip> */}
            <Tooltip title="Mailbox">
              <NavLink to={"/mailbox"}>
                <AiOutlineMail className="w-[2.2rem] h-auto p-2 hover:bg-slate-300 transition-all ease-in-out duration-500  rounded-[100%] h-auto ease-in" />
              </NavLink>
            </Tooltip>
            <Tooltip title="Organization Settings">
              <NavLink to={"/organizational-settings/general-settings"}>
                <AiOutlineSetting className="w-[2.2rem] h-auto p-2 hover:bg-slate-300 transition-all ease-in-out duration-500  rounded-[100%] h-auto ease-in" />
              </NavLink>
            </Tooltip>

            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <div className="flex h-[3rem] pl-2 flex-row gap-2 items-center">
                <img
                  src={Client}
                  alt="image"
                  className="w-[2.3rem] h-[2.3rem] bg-slate-300 rounded-[100%] contain"
                />{" "}
                User
              </div>
            </Dropdown>
          </div>
        </Header>

        <Content className="site-layout-background">
          {children}
          <ScrollUpElement />
        </Content>
      </Layout>
    </Layout>
  );
};
export default SiderDemo;
