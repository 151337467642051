import React from "react";
import {
  ButtonElement,
  DatepickerElement,
  InputFieldElement,
  ModalElement,
  SelectField,
} from "../../../components/input";
import { Form } from "antd";
import InputNumberField from "../../../components/inputNumberField/InputNumberField";

export default function AddSemester() {
  const courseList = [
    {
      label: "EILCOS",
      value: 1,
    },
  ];

  const calenderList = [
    {
      label: "Calender Set1",
      Value: 1,
    },
    {
      label: "Calender Set2",
      Value: 2,
    },
  ];
  return (
    <ModalElement title="Add Semester" keyType="addSemester">
      <Form layout="vertical" className="grid grid-cols-2 gap-4">
        <InputFieldElement
          name="semester_name"
          placeholder="Semester Name"
          label="Semester Name"
          fieldWidth="100%"
        />
        <SelectField
          name="course_type"
          label="Course Type"
          placeholder="Select Course Type "
          options={courseList}
          fieldWidth="100%"
        />
        <InputNumberField
          name="year"
          label="Year"
          placeholder="Year"
          fieldWidth="100%"
        />
        <SelectField
          name="calender_type"
          label="Calender Type"
          placeholder="Select Calender Type "
          options={calenderList}
          fieldWidth="100%"
        />
        <DatepickerElement
          name="semester_start"
          placeholder="DD/MM/YYYY"
          label="Semester Start"
          fieldWidth="100%"
        />
        <DatepickerElement
          name="semester_finish"
          placeholder="DD/MM/YYYY"
          label="Semester Finish"
          fieldWidth="100%"
        />
        <div className="col-span-2">
          <ButtonElement name="Add Semester" bottonWidth="100%" />
        </div>
      </Form>
    </ModalElement>
  );
}
