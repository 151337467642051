import React from "react";
import { ButtonElement, DrawerElement } from "../../../../components/input";
import { Table, Tooltip } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import AddContractSchedule from "./AddContractSchedule";

export default function ViewContractList() {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Contract Code",
      dataIndex: "contract_code",
      align: "center",
    },
    {
      title: "State",
      dataIndex: "state",
      align: "center",
    },
    {
      title: "Schedule Code",
      dataIndex: "schedule_code",
      align: "center",
    },
    {
      title: "Funding Source",
      dataIndex: "funding_source",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: () => (
        <div>
          <Tooltip placement="top" title="Delete Record">
            <CloseOutlined className="text-[red] rounded-[100%] cursor-pointer width-[fit-content] text-[1rem] border p-2 hover:bg-[red] hover:rounded-[4px] hover:text-[white] transition-all ease-in-out duration-1000" />
          </Tooltip>{" "}
        </div>
      ),
    },
  ];

  const data = [
    {
      contract_code: "test",
      state: "NSW",
      schedule_code: "123",
      funding_source:
        "ACT: AAP - Australian Apprenticeships (funded contracts: 11, unfunded contracts: 20) (11)",
      description: "test test",
    },
    {
      contract_code: "test",
      state: "NSW",
      schedule_code: "123",
      funding_source:
        "ACT: AAP - Australian Apprenticeships (funded contracts: 11, unfunded contracts: 20) (11)",
      description: "test test",
    },
    {
      contract_code: "test",
      state: "NSW",
      schedule_code: "123",
      funding_source:
        "ACT: AAP - Australian Apprenticeships (funded contracts: 11, unfunded contracts: 20) (11)",
      description: "test test",
    },
  ];

  const handleAddEditContractSchedule = (value) => {
    dispatch({ type: "FETCH_OPEN_MODAL", payload: value });
  };

  return (
    <DrawerElement title="Contract Schedule List" keyType="viewContract">
      <div className="flex flex-col gap-4">
        <ButtonElement
          handleClick={() =>
            handleAddEditContractSchedule("addContractSchedule")
          }
          Icon={<PlusOutlined className="text-sm" />}
          name="Contract Schedule"
        />
        <Table dataSource={data} columns={columns} />
        <AddContractSchedule />
      </div>
    </DrawerElement>
  );
}
