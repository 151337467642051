import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../../../components/input";
import SubjectSummary from "./SubjectSummary";
const { Option } = Select;

const AddStudentSubjectEnrollment = ({
  isAddStudentSubjectEnrollmentModalVisible,
  setIsAddStudentSubjectEnrollmentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddStudentSubjectEnrollmentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "Subject Enrollment StudentSubjectEnrollment_REQUEST",
      payload: formData,
    });
    // form.resetFields();
    setIsAddStudentSubjectEnrollmentModalVisible(false);
  };
  return (
    <Drawer
      title="Add Subject Enrollment"
      open={isAddStudentSubjectEnrollmentModalVisible}
      onClose={() => {
        setIsAddStudentSubjectEnrollmentModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
          }}
        >
          <Form
            layout="vertical"
            onFinish={AddStudentSubjectEnrollmentSubmitHandler}
            colon={true}
            form={form}
            style={{ width: "100%" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Course"
                name={"course"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a course!",
                  },
                ]}
              >
                <Select>
                  <Option>EAP: English for Academic Purposes</Option>
                  <Option>EAP: English for Academic Purposes - Offshore</Option>
                  <Option>EAP: English for Academic Purposes - Other</Option>
                  <Option>GE: General English</Option>
                  <Option>GE-OS: General English - Offshore</Option>
                  <Option>GE-Other: General English - Other</Option>
                  <Option>IELTS: IELTS Prepration</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Semester"
                name={"semester"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a semester!",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Term"
                name={"term"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a term",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
              <Form.Item
                label="Type of Enrolment"
                name={"typeOfEnrollment"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter type of enrollment",
                  },
                ]}
              >
                <Radio.Group defaultValue={"COURSE_STAGE"}>
                  <Radio value={"COURSE_STAGE"}>Course Stage</Radio>
                  <Radio value={"SUBJECT"}>Subject</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <Form.Item
              label="Select subject"
              name={"selectSubject"}
              rules={[
                {
                  required: true,
                  message: "Please select a subject!",
                },
              ]}
            >
              <Select placeholder="Select subject">
                <Option>GE-A2-A: GE Elementary</Option>
                <Option>GE-B1_1-A: GE Intermediate</Option>
                <Option>GE-B1-A: GE Pre-Intermediate</Option>
                <Option>GE-B2-A: GE Upper-Intermediate</Option>
                <Option>GE-C1-A: GE Advanced</Option>
              </Select>
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Class Batch"
                name={"classBatch"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select a class batch!",
                  },
                ]}
              >
                <Select placeholder="Class Batch"></Select>
              </Form.Item>
              <Form.Item
                label="Activity Start Date"
                name={"activityStartDate"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please pick activity start date !",
                //   },
                // ]}
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Activity Finish Date"
                name={"activityFinishDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick a activity finish date!",
                  },
                ]}
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
              <Form.Item
                label="Venue/Training Location"
                name={"venue"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a venue/training Location !",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Funding Source State"
                name={"fundingSourceState"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please pick a funding source state!",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
              <Form.Item
                label="Funding Source NAT"
                name={"fundingSourceNat"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a venue/training Location !",
                //   },
                // ]}
              >
                <Select></Select>
              </Form.Item>
            </div>

            <Form.Item>
              <ButtonElement
                block={true}
                type="primary"
                htmlType="submit"
                name="Enrol"
              >
                Enrol
              </ButtonElement>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <SubjectSummary
            detail={{
              name: "Anish Banjara",
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default AddStudentSubjectEnrollment;
