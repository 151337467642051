import React, { Children } from "react";

const ComponentBody = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        margin: "8px 15px",
        padding: "16px",
        flexDirection: "column",
        backgroundColor: "white",
      }}
    >
      {children}
    </div>
  );
};

export default ComponentBody;
