import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../../components/input";
const { Option } = Select;

const AddServiceCategory = ({
  isAddServiceCategoryModalVisible,
  setIsAddServiceCategoryModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddServiceCategorySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddServiceCategory_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddServiceCategoryModalVisible(false);
  };
  return (
    <Modal
      title="Add Service Category"
      open={isAddServiceCategoryModalVisible}
      onCancel={() => {
        setIsAddServiceCategoryModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={AddServiceCategorySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
        <Form.Item
          label="Service Name"
          name={"serviceName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a service name!",
            },
          ]}
        >
          <Input placeholder="Service Name" />
        </Form.Item>
        <Form.Item
          label="Category Name"
          name={"categoryName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a category name!",
            },
          ]}
        >
          <Input placeholder="Category Name" />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Service Category"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddServiceCategory;
