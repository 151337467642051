import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const AddMiscellaneousPayment = ({
  isAddMiscellaneousPaymentModalVisible,
  setIsAddMiscellaneousPaymentModalVisible,
}) => {
  const [miscellaneousPaymentData, setMiscellaneousPaymentData] = useState({
    paymentType: null,
    transactionType: "MISCELLANEOUS",
    isAddStudentCourseInfo: null,
    OSHCProvider: null,
    applicationDate: null,
    oshcType: null,
    durationInMonths: null,
    appliedOshcFee: null,
    oshcStartDate: null,
    isDeductAsAgentRebate: null,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AddMiscellaneousPaymentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AddMiscellaneousPayment_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddMiscellaneousPaymentModalVisible(false);
  };
  return (
    <Drawer
      title="Add Miscellaneous Payment"
      open={isAddMiscellaneousPaymentModalVisible}
      onClose={() => {
        setIsAddMiscellaneousPaymentModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <Form
        layout="vertical"
        onFinish={AddMiscellaneousPaymentSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["transactionType"],
            value: miscellaneousPaymentData?.transactionType,
          },
          {
            name: ["paymentType"],
            value: miscellaneousPaymentData?.paymentType,
          },
          {
            name: ["isAddStudentCourseInfo"],
            value: miscellaneousPaymentData?.isAddStudentCourseInfo,
          },
          {
            name: ["OSHCProvider"],
            value: miscellaneousPaymentData?.OSHCProvider,
          },
          {
            name: ["applicationDate"],
            value: miscellaneousPaymentData?.applicationDate,
          },
          { name: ["oshcType"], value: miscellaneousPaymentData?.oshcType },
          {
            name: ["durationInMonths"],
            value: miscellaneousPaymentData?.durationInMonths,
          },
          {
            name: ["appliedOshcFee"],
            value: miscellaneousPaymentData?.appliedOshcFee,
          },
          {
            name: ["oshcStartDate"],
            value: miscellaneousPaymentData?.oshcStartDate,
          },
          {
            name: ["isDeductAsAgentRebate"],
            value: miscellaneousPaymentData?.isDeductAsAgentRebate,
          },
        ]}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Transaction Type"
            name={"transactionType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a transaction type !",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setMiscellaneousPaymentData((prevData) => {
                  return {
                    ...prevData,
                    transactionType: e.target.value,
                  };
                });
              }}
            >
              <Radio value={"MISCELLANEOUS"}>Miscellaneous</Radio>
              <Radio value={"SERVICE"}>Service</Radio>
            </Radio.Group>
          </Form.Item>
          {miscellaneousPaymentData.transactionType === "MISCELLANEOUS" && (
            <Form.Item
              label="Payment Type"
              name={"paymentType"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a payment type !",
                },
              ]}
            >
              <Select
                placeholder="Payment Type"
                onChange={(e) => {
                  setMiscellaneousPaymentData((prevData) => {
                    return {
                      ...prevData,
                      paymentType: e,
                    };
                  });
                }}
              >
                <Option value="ENROLLMENT_FEES">Enrollment Fees</Option>
                <Option value="LATE_PAYMENT_FEE">Late Payment Fee</Option>
                <Option value="OSHC">OSHC</Option>
                <Option value="PRINTING">Printing</Option>
              </Select>
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            name={"isAddStudentCourseInfo"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setMiscellaneousPaymentData((prevData) => {
                  return {
                    ...prevData,
                    isAddStudentCourseInfo: e.target.checked,
                  };
                });
              }}
            >
              Add student course info
            </Checkbox>
          </Form.Item>
          {miscellaneousPaymentData.isAddStudentCourseInfo && (
            <Form.Item
              label="Select Course"
              name={"selectCourse"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a course",
                },
              ]}
            >
              <Select>
                <Option>GE: General English-12-04-2021 - Enrolled</Option>
                <Option>IELTS: IELTS Prepration-12-04-2021 - Enrolled</Option>
              </Select>
            </Form.Item>
          )}
        </div>
        {miscellaneousPaymentData?.paymentType === "OSHC" && (
          <>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="OSHC Provider"
                name={"OSHCProvider"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a OSHC provider!",
                //   },
                // ]}
              >
                <Select
                  placeholder="OSHC Provider"
                  onChange={(e) => {
                    setMiscellaneousPaymentData((prevData) => {
                      return {
                        ...prevData,
                        OSHCProvider: e,
                      };
                    });
                  }}
                >
                  <Option value="Medibank Private">Medibank Private</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Application Date"
                name={"applicationDate"}
                style={{ width: "100%" }}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setMiscellaneousPaymentData((prevData) => {
                      return {
                        ...prevData,
                        applicationDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="OSHC Type"
                name={"oshcType"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select OSHC type!",
                //   },
                // ]}
              >
                <Select
                  placeholder="OSHC Type"
                  onChange={(e) => {
                    setMiscellaneousPaymentData((prevData) => {
                      return {
                        ...prevData,
                        oshcType: e,
                      };
                    });
                  }}
                >
                  <Option value="FAMILY">Family</Option>
                  <Option value="INDIVIDUAL">Individual</Option>
                </Select>{" "}
              </Form.Item>
              <Form.Item
                label="Cover Duration (in months)"
                name={"durationInMonths"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter a duration !",
                //   },
                // ]}
              >
                <Input
                  type="number"
                  placeholder="Duration"
                  onChange={(e) => {
                    setMiscellaneousPaymentData((prevData) => {
                      return {
                        ...prevData,
                        durationInMonths: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Applied OSHC Fee (OSHC Fee: 0)"
                name={"appliedOshcFee"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter a applied OSHC fee !",
                //   },
                // ]}
              >
                <Input
                  type="number"
                  placeholder="Duration"
                  onChange={(e) => {
                    setMiscellaneousPaymentData((prevData) => {
                      return {
                        ...prevData,
                        appliedOshcFee: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="OSHC Start Date"
                name={"oshcStartDate"}
                style={{ width: "100%" }}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setMiscellaneousPaymentData((prevData) => {
                      return {
                        ...prevData,
                        oshcStartDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
          </>
        )}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Payment Status"
            name={"paymentStatus"}
            rules={[
              {
                required: true,
                message: "Please select a payment status !",
              },
            ]}
          >
            <Select placeholder="Payment Status">
              <Option value="NORMAL_PAID">Normal (Paid)</Option>
              <Option value="SCHEDULE_NOT_PAID">Schedule (Not Paid)</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a due date !",
              },
            ]}
          >
            <DatePicker format="DD-MM_YYYY" />
          </Form.Item>
        </div>
        <Form.Item
          label="Receipt No"
          name={"receiptNo"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a receipt number!",
            },
          ]}
        >
          <Input placeholder="Receipt No" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Payment Date"
            name={"paymentDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a payment date !",
              },
            ]}
          >
            <DatePicker format="DD-MM_YYYY" />
          </Form.Item>
          <Form.Item
            label="Bank Deposit Date"
            name={"bankDepositDate"}
            style={{ width: "100%" }}
          >
            <DatePicker format="DD-MM_YYYY" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Payment Mode"
            name={"paymentMode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a payment mode!",
              },
            ]}
          >
            <Select placeholder="Payment Mode">
              <Option>Credit Card</Option>
              <Option>Bank Deposit</Option>
              <Option>Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Bad Debt"
            name={"badDebt"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Bad Debt" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Total Amount (AUD)"
            name={"totalAmount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a total amount!",
              },
            ]}
          >
            <Input type="number" placeholder="Total Amount" />
          </Form.Item>
          <Form.Item
            label="Invoice No"
            name={"invoiceNo"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Invoice No" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            name={"isGstIncluded"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox>GST Included?</Checkbox>
          </Form.Item>
          <Form.Item label="GST Amount" style={{ width: "100%" }}>
            AUD 4.55
          </Form.Item>
        </div>
        <Form.Item
          name={"isDeductAsAgentRebate"}
          valuePropName="checked"
          style={{ width: "100%" }}
        >
          <Checkbox
            onChange={(e) => {
              setMiscellaneousPaymentData((prevData) => {
                return {
                  ...prevData,
                  isDeductAsAgentRebate: e.target.checked,
                };
              });
            }}
          >
            Deduct as Agent Rebate
          </Checkbox>
        </Form.Item>
        {miscellaneousPaymentData.isDeductAsAgentRebate && (
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Rebate Amount"
              name={"rebateAmount"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a rebate amount!",
                },
              ]}
            >
              <Input type="number" placeholder="Rebate Amount" />
            </Form.Item>
            <Form.Item
              label="Rebate Paid Date"
              name={"rebatePaidDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a rebate paid date!",
                },
              ]}
            >
              <DatePicker format="DD-MM_YYYY" />
            </Form.Item>
          </div>
        )}
        <Form.Item label="Remarks" name={"remarks"} style={{ width: "100%" }}>
          <TextArea placeholder="Write Something..." />
        </Form.Item>

        <Form.Item>
          <ButtonElement
            block
            type="primary"
            htmlType="submit"
            name="Add Miscellaneous Payment"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddMiscellaneousPayment;
