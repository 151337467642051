import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { ButtonElement } from "../../../../components/input";
const { Option } = Select;
const { TextArea } = Input;

const UpdateServiceProviderFacility = ({
  isUpdateServiceProviderFacilityModalVisible,
  setIsUpdateServiceProviderFacilityModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const UpdateServiceProviderFacilitySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "UpdateServiceProviderFacility_REQUEST",
      payload: formData,
    });
    // form.resetFields();
    setIsUpdateServiceProviderFacilityModalVisible(false);
  };
  return (
    <Modal
      title="Update Service Provider Facility"
      open={isUpdateServiceProviderFacilityModalVisible}
      onCancel={() => {
        setIsUpdateServiceProviderFacilityModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={UpdateServiceProviderFacilitySubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Service Provider Price"
            name={"serviceProviderPrice"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a service provider price!",
              },
            ]}
          >
            <Input type="number" placeholder="	Service Provider Price" />
          </Form.Item>
          <Form.Item
            label="Std Price"
            name={"stdPrice"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a standard price!",
              },
            ]}
          >
            <Input type="number" placeholder="Standard Price" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="GST Inc."
            name={"gstInc"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select Yes if GST Incuded and No otherwise",
              },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>{" "}
          </Form.Item>
        </div>
        <Form.Item label="Comment" name={"comment"} style={{ width: "100%" }}>
          <TextArea placeholder="Comment" />
        </Form.Item>

        <Form.Item>
          <ButtonElement block type="primary" htmlType="submit" name="Update" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateServiceProviderFacility;
