import { Card, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { Pagename, SelectField } from "../../../../components/input";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import UpdateDocumentChecklist from "./UpdateDocumentChecklist";
import { EditFilled, EditOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Document Name",
    dataIndex: "documentName",
    width: 250,
    align: "center",
  },
  {
    title: "Document Type/Stage",
    dataIndex: "documentType",
    width: 150,
    align: "center",
  },
  {
    title: "Origin",
    dataIndex: "origin",
    width: 150,
    align: "center",
  },
  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    width: 100,
    align: "center",
  },
  {
    title: "Compulsory",
    dataIndex: "compulsory",
    width: 100,
    align: "center",
  },
  {
    title: "Provided",
    dataIndex: "provided",
    width: 100,
    align: "center",
  },
  {
    title: "Last Checked By",
    dataIndex: "lastCheckedBy",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const documentChecklistData = [
  {
    id: 1,
    documentName: "Copy of Passport",
    documentType: "In_Application",
    origin: "Overseas Student in Australia",
    lastUpdated: "17-08-2023",
    compulsory: "YES",
    provided: "NO",
    lastCheckedBy: "Ms. Anu Rani Regmi ",
  },
  {
    id: 2,
    documentName: "Proof of payment",
    documentType: "	Post_Application",
    origin: "Overseas Student in Australia",
    lastUpdated: "17-08-2023",
    compulsory: "YES",
    provided: "NO",
    lastCheckedBy: "Ms. Anu Rani Regmi ",
  },
];

const DocumentChecklist = () => {
  const [
    isUpdateDocumentChecklistModalVisible,
    setIsUpdateDocumentChecklistModalVisible,
  ] = useState(false);

  const data = documentChecklistData.map((dataObj) => {
    return {
      key: dataObj?.id,
      id: dataObj?.id,
      documentName: dataObj?.documentName,
      documentType: dataObj?.documentType,
      origin: dataObj?.origin,
      lastUpdated: dataObj?.lastUpdated,
      compulsory: dataObj?.compulsory,
      provided: dataObj?.provided,
      lastCheckedBy: dataObj?.lastCheckedBy,
      actions: (
        <Space size="middle">
          <Tooltip title="Upload Document/Comment">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateDocumentChecklistModalVisible(true);
                }}
              >
                <EditFilled />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  return (
    <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
      <PageHeader>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <Pagename>Document Checklist</Pagename>
        </div>
        <div className="fxRAc" style={{ gap: "1rem" }}>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Document Checklist Offer</div>
            <SelectField
              name="selectCourse"
              placeholder="Select Offer"
              options={[
                {
                  label: "4",
                  value: "4",
                },
                {
                  label: "879",
                  value: "879",
                },
              ]}
              handleChange={(e) => {}}
              fieldWidth="5rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Document Type</div>
            <SelectField
              name="documentType"
              placeholder="Document Type"
              options={[
                {
                  label: "All",
                  value: "ALL",
                },
                {
                  label: "In Application",
                  value: "IN_APPLICATION",
                },
                {
                  label: "Post Application",
                  value: "POST_APPLICATION",
                },
              ]}
              handleChange={(e) => {}}
              fieldWidth="10rem"
            />
          </div>
          <div className="fxRAc" style={{ gap: "1rem", whiteSpace: "nowrap" }}>
            <div>Status</div>
            <SelectField
              name="selectStatus"
              placeholder="Select Status"
              options={[
                {
                  label: "All",
                  value: "ALL",
                },
              ]}
              handleChange={(e) => {}}
              fieldWidth="5rem"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className="fxC"
        style={{
          marginTop: "1rem",
        }}
      >
        <Table
          size="small"
          className="style-antd-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      {isUpdateDocumentChecklistModalVisible && (
        <UpdateDocumentChecklist
          isUpdateDocumentChecklistModalVisible={
            isUpdateDocumentChecklistModalVisible
          }
          setIsUpdateDocumentChecklistModalVisible={
            setIsUpdateDocumentChecklistModalVisible
          }
        />
      )}
    </Card>
  );
};

export default DocumentChecklist;
