import React, { useState } from "react";
import ComponentBody from "../../../components/ComponentBody/ComponentBody";
import {
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {
  ButtonElement,
  Pagename,
  SelectField,
} from "../../../components/input";
import Communication from "../Communication";
const { Search } = Input;
const columns = [
  {
    title: "Student ID",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Student Name",
    dataIndex: "studentName",
    width: 150,
    align: "center",
  },
  {
    title: "Nick Name",
    dataIndex: "nickName",
    width: 200,
    align: "center",
  },
  {
    title: "Course ID",
    dataIndex: "courseId",
    width: 100,
    align: "center",
  },
  {
    title: "Course Attempt",
    dataIndex: "courseAttempt",
    width: 50,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 50,
    align: "center",
  },
  {
    title: "Contact Email",
    dataIndex: "contactEmail",
    width: 250,
    align: "center",
  },
  {
    title: "College Email",
    dataIndex: "collegeEmail",
    width: 250,
    align: "center",
  },
  {
    title: "Mail Status",
    dataIndex: "mailStatus",
    width: 250,
    align: "center",
  },
  {
    title: "College Mail Status",
    dataIndex: "collegeMailStatus",
    width: 250,
    align: "center",
  },
];
const staffColumns = [
  {
    title: "Staff Id",
    dataIndex: "id",
    width: 50,
    align: "center",
  },
  {
    title: "Title",
    dataIndex: "title",
    width: 150,
    align: "center",
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    width: 200,
    align: "center",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    width: 100,
    align: "center",
  },
  {
    title: "Position",
    dataIndex: "position",
    width: 50,
    align: "center",
  },

  {
    title: "Private Email",
    dataIndex: "privateEmail",
    width: 250,
    align: "center",
  },
  {
    title: "College Email",
    dataIndex: "collegeEmail",
    width: 250,
    align: "center",
  },
  {
    title: "Mail Status",
    dataIndex: "mailStatus",
    width: 250,
    align: "center",
  },
];

const applicationStatus = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Withdrawn",
    value: "WITHDRAWN",
  },
  {
    label: "Unapproved",
    value: "UNAPPROVED",
  },
  {
    label: "New Course Request",
    value: "newCourseRequest",
  },
  {
    label: "New Application Request",
    value: "newApplicationRequest",
  },
  {
    label: "Pending",
    value: "panding",
  },
  {
    label: "Enrolled",
    value: "ENROLLED",
  },
  {
    label: "Converted",
    value: "converted",
  },
  {
    label: "Finished",
    value: "FINISHED",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    label: "Expired Offer",
    value: "expiredOffer",
  },

  {
    label: "Reconsider",
    value: "reconsider",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Offered",
    value: "offered",
  },
];
const positionList = [
  {
    label: "Academic Manager ",
    value: "academicManager",
  },
  {
    label: "Chief Financial Officer/Manager of Operations",
    label: "chiefFinancialOfficer",
  },
  {
    label: "College Administratior",
    label: "collegeAdministratior",
  },
  {
    label: "Director of Studies",
    label: "directorOfStudies",
  },
  {
    label: "Marketing and Admin Support",
    label: "marketingAndAdminSupport",
  },
  {
    label: "Marketing Assistant",
    label: "marketingAssistant",
  },
  {
    label: "Marketing Manager",
    label: "marketingManager",
  },
  {
    label: "Operations Manager",
    label: "operationsManager",
  },
  {
    label: "Principal Executive Officer",
    label: "principalExecutiveOfficer",
  },
  {
    label: "Student Service Officer",
    label: "studentServiceOfficer",
  },
  {
    label: "System Administratior",
    label: "systemAdministratior",
  },
  {
    label: "Teacher",
    label: "teacher",
  },
  {
    label: "Teacher - Part time",
    label: "teacherParttime",
  },
];
const mailData = [
  {
    id: "ID20221031",
    studentName: "Smarika Nepal",
    nickName: "Smarika",
    courseId: "GE",
    courseAttempt: "1",
    status: "Current Studen",
    contactEmail: "smarikanepal1994@gmail.com",
    collegeEmail: "OPS00001015@opera.nsw.edu.au",
    mailStatus: "",
    collegeMailStatus: "",
  },
];
const staffMailData = [
  {
    id: "13",
    title: "Mr",
    firstName: "Raju",
    lastName: "Manandhar 2",
    position: "Teacher",
    privateEmail: "manandhar44771@gmail.com",
    collegeEmail: "OPS00001015@opera.nsw.edu.au",
    mailStatus: "",
  },
];
const selectType = [
  {
    label: "Student Class List",
    value: "studentClassList",
  },
  {
    label: "Student Course",
    value: "studentcourse",
  },
  {
    label: "Student Course Between Dates",
    value: "studentCourseBetweenDates",
  },
  {
    label: "Student Course Finish Date Between Dates",
    value: "studentCourseFinishDateBetweenDates",
  },
  {
    label: "Subject Starting Between Dates",
    value: "subjectStartingBetweenDates",
  },
  {
    label: "Subject Census Date Between Dates",
    value: "subjectCensusDateBetweenDates",
  },
  {
    label: "Particular Student",
    value: "particularStudent",
  },
  {
    label: "Semester and Term",
    value: "semesterAndTerm",
  },
  {
    label: "Student Course Start Date between dates",
    value: "studentCourseStartDateBetweenDates",
  },
];
const searchApplicantsBy = [
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Course",
    value: "course",
  },
  {
    label: "Date Applied",
    value: "dateApplied",
  },
  {
    label: "Date of Birth",
    value: "dateOfBirth",
  },
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },

  {
    label: "Offer ID",
    value: "offerId",
  },
  {
    label: "Start Semester",
    value: "startSemester",
  },

  {
    label: "Account Manager",
    value: "accountManager",
  },
];

const selectCourse = [
  {
    label: "EAP: English for Academic Purposes",
    value: "eap",
  },
  {
    label: "EAP: English for Academic Purposes - Offshore",
    value: "eapOffshore",
  },
  {
    label: "EAP: English for Academic Purposes - Other",
    value: "eapOther",
  },
  {
    label: "GE: General English",
    value: "ge",
  },
  {
    label: "GE-OS: General English - Offshore",
    value: "geOffshore",
  },
  {
    label: "GE-Other: General English - Other",
    value: "geOther",
  },
  {
    label: "IELTS: IELTS Prepration",
    value: "ielts",
  },
];

const MailingList = () => {
  const [searchBy, setSearchBy] = useState("offerId");
  const [isSendComposedMailModalVisible, setIsSendComposedMailModalVisible] =
    useState(false);
  const [mailingType, setMailingType] = useState("STUDENT_MAILING_LIST");
  let data = mailData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      studentName: dataObj?.studentName,
      nickName: dataObj?.nickName,
      courseId: dataObj?.courseId,
      courseAttempt: dataObj?.courseAttempt,
      status: dataObj?.status,
      contactEmail: dataObj?.contactEmail,
      collegeEmail: dataObj?.collegeEmail,
      mailStatus: dataObj?.mailStatus,
      collegeMailStatus: dataObj?.collegeMailStatus,
    };
  });
  let staffData = staffMailData.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj?.id,
      title: dataObj?.title,
      firstName: dataObj?.firstName,
      lastName: dataObj?.lastName,
      position: dataObj?.position,
      privateEmail: dataObj?.privateEmail,
      collegeEmail: dataObj?.collegeEmail,
      mailStatus: dataObj?.mailStatus,
    };
  });
  return (
    <Communication tab={"/mailing-list"}>
      <ComponentBody>
        {mailingType === "STAFF_MAILING_LIST" ? (
          <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Mailing list type::</div>
              <SelectField
                name="selectType"
                placeholder="Select Type:"
                options={[
                  {
                    label: "All Active Staff",
                    value: "allActiveStaff",
                  },
                  {
                    label: "Staff Postition",
                    value: "staffPosition",
                  },
                ]}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="10rem"
              />
              {searchBy === "allActiveStaff" && (
                <div className="fxRAc" style={{ gap: "1rem" }}>
                  <div>Status:</div>
                  <SelectField
                    name="status"
                    placeholder="Status"
                    options={[
                      {
                        label: "Active",
                        value: "active",
                      },
                      {
                        label: "Inactive",
                        value: "inactive",
                      },
                      {
                        label: "All",
                        value: "all",
                      },
                    ]}
                    fieldWidth="12rem"
                  />
                </div>
              )}
              {searchBy === "allActiveStaff" && (
                <div className="fxRAc" style={{ gap: "1rem" }}>
                  <div>Select Position:</div>
                  <SelectField
                    name="selectPosition"
                    placeholder="Position"
                    options={positionList}
                    fieldWidth="12rem"
                  />
                </div>
              )}
            </div>
            <ButtonElement name="View Staff List" />
          </div>
        ) : (
          <div className="fxRJs" style={{ gap: "0.8rem", flexWrap: "wrap" }}>
            <div className="fxRAc" style={{ gap: "1rem" }}>
              <div>Select Type:</div>
              <SelectField
                name="selectType"
                placeholder="Select Type:"
                options={selectType}
                handleChange={(e) => {
                  setSearchBy(e);
                }}
                fieldWidth="10rem"
              />
            </div>
            {searchBy !== "particularStudent" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select Course Type:</div>
                <SelectField
                  name="courseType"
                  placeholder="Course Type"
                  options={[
                    {
                      label: "ELICOS",
                      value: "elicos",
                    },
                  ]}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {(searchBy === "studentcourse" ||
              searchBy === "studentCourseBetweenDates" ||
              searchBy === "studentCourseFinishDateBetweenDates" ||
              searchBy === "subjectStartingBetweenDates" ||
              searchBy === "subjectCensusDateBetweenDates" ||
              searchBy === "studentCourseStartDateBetweenDates") && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select Course Status:</div>
                <SelectField
                  name="selectCourseStatus"
                  placeholder="Select Course Status"
                  options={[
                    {
                      label: "Active",
                      value: "active",
                    },
                    {
                      label: "Inactive",
                      value: "inactive",
                    },
                    {
                      label: "All",
                      value: "all",
                    },
                  ]}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {(searchBy === "studentcourse" ||
              searchBy === "studentCourseBetweenDates" ||
              searchBy === "studentCourseFinishDateBetweenDates" ||
              searchBy === "subjectStartingBetweenDates" ||
              searchBy === "subjectCensusDateBetweenDates" ||
              searchBy === "studentCourseStartDateBetweenDates") && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select Course:</div>
                <SelectField
                  name="selectCourseStatus"
                  placeholder="Select Course Status"
                  options={selectCourse}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {(searchBy === "semesterAndTerm" ||
              searchBy === "studentClassList") && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select semester:</div>
                <SelectField
                  name="selectSemester"
                  placeholder="Select semester"
                  options={[
                    {
                      label: "2023",
                      value: "2023",
                    },
                  ]}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {(searchBy === "semesterAndTerm" ||
              searchBy === "studentClassList") && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select term:</div>
                <SelectField
                  name="selectTerm"
                  placeholder="Select Term"
                  options={[
                    {
                      label: "1",
                      value: "1",
                    },
                  ]}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {(searchBy === "subjectCensusDateBetweenDates" ||
              searchBy === "subjectStartingBetweenDates") && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Subject:</div>
                <SelectField
                  name="subject"
                  placeholder="Subject"
                  options={[]}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {searchBy === "particularStudent" && (
              <div
                className="fxRAc"
                style={{ gap: "1rem", whiteSpace: "nowrap" }}
              >
                <div>Student Id:</div>
                <Search
                  style={{ borderRadius: "3px", width: "100%" }}
                  className="search"
                  placeholder={`Search student ID.`}
                  allowClear
                  size="Medium"
                  //   value={searchValue}
                  onChange={(e) => {
                    //   setSearchValue(e.target.value);
                    //   onSearch(e.target.value);
                  }}
                />
              </div>
            )}
            {searchBy === "studentClassList" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Specify Batch:</div>
                <Search
                  style={{ borderRadius: "3px", width: "100%" }}
                  className="search"
                  placeholder={`Search Batch.`}
                  allowClear
                  size="Medium"
                  //   value={searchValue}
                  onChange={(e) => {
                    //   setSearchValue(e.target.value);
                    //   onSearch(e.target.value);
                  }}
                />
              </div>
            )}
            {searchBy === "studentClassList" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select Class:</div>
                <SelectField
                  name="selectClass"
                  placeholder="Select Class"
                  options={[]}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {searchBy === "studentClassList" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>
                  Include student(s) already finished the class in last:
                </div>
                <SelectField
                  name="selectClass"
                  placeholder="Select Class"
                  options={[
                    {
                      label: "1",
                      value: "1",
                    },
                  ]}
                  fieldWidth="12rem"
                />{" "}
                Weeks
              </div>
            )}

            {(searchBy === "studentCourseStartDateBetweenDates" ||
              searchBy === "studentcourse" ||
              searchBy === "studentCourseBetweenDates" ||
              searchBy === "studentCourseFinishDateBetweenDates") && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Select Status:</div>
                <SelectField
                  name="selectStatus"
                  placeholder="Select Status"
                  options={applicationStatus}
                  fieldWidth="12rem"
                />
              </div>
            )}
            {searchBy === "studentCourseBetweenDates" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Course Duration Between:</div>
                <DatePicker format={"DD-MM-YYYY"} /> and{" "}
                <DatePicker format={"DD-MM-YYYY"} />
              </div>
            )}
            {searchBy === "studentCourseFinishDateBetweenDates" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Course Finish Date Between:</div>
                <DatePicker format={"DD-MM-YYYY"} /> and{" "}
                <DatePicker format={"DD-MM-YYYY"} />
              </div>
            )}
            {searchBy === "subjectStartingBetweenDates" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Subject Starting Between:</div>
                <DatePicker format={"DD-MM-YYYY"} /> and{" "}
                <DatePicker format={"DD-MM-YYYY"} />
              </div>
            )}
            {searchBy === "subjectCensusDateBetweenDates" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Subject Census Between:</div>
                <DatePicker format={"DD-MM-YYYY"} /> and{" "}
                <DatePicker format={"DD-MM-YYYY"} />
              </div>
            )}
            {searchBy === "studentCourseStartDateBetweenDates" && (
              <div className="fxRAc" style={{ gap: "1rem" }}>
                <div>Student Course Start Between:</div>
                <DatePicker format={"DD-MM-YYYY"} /> and{" "}
                <DatePicker format={"DD-MM-YYYY"} />
              </div>
            )}
            <ButtonElement name="View Student List" />
          </div>
        )}
      </ComponentBody>
      <ComponentBody>
        <PageHeader>
          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Pagename>
              {mailingType === "STAFF_MAILING_LIST"
                ? "Staff Mailing List"
                : "Student Mailing List"}
            </Pagename>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <ButtonElement
                handleClick={() => {
                  setIsSendComposedMailModalVisible(true);
                }}
                name="Send Mail"
              >
                Send Mail
              </ButtonElement>
            </div>
          </div>

          <div className="fxRAc" style={{ gap: "1rem" }}>
            <Radio.Group
              defaultValue="ACTIVE"
              value={mailingType}
              onChange={(e) => {
                setMailingType(e.target.value);
              }}
              style={{ width: "100%" }}
            >
              <Radio.Button
                value="STUDENT_MAILING_LIST"
                style={{ borderRadius: "3px" }}
              >
                Student Mailing List
              </Radio.Button>
              <Radio.Button
                value="STAFF_MAILING_LIST"
                style={{ borderRadius: "3px" }}
              >
                Staff Mailing List
              </Radio.Button>
            </Radio.Group>
          </div>
        </PageHeader>
        <div
          className="fxC"
          style={{
            marginTop: "1rem",
          }}
        >
          {mailingType === "STAFF_MAILING_LIST" ? (
            <Table
              dataSource={staffData}
              bordered={true}
              columns={staffColumns}
              pagination={false}
            />
          ) : (
            <Table
              dataSource={data}
              bordered={true}
              columns={columns}
              pagination={false}
            />
          )}
        </div>
      </ComponentBody>
    </Communication>
  );
};

export default MailingList;
