import React from "react";
import {
  ButtonElement,
  DatepickerElement,
  ModalElement,
  RadioElement,
  SelectField,
} from "../../../components/input";
import { Form } from "antd";
import InputNumberField from "../../../components/inputNumberField/InputNumberField";

export default function AddSemesterDivision() {
  const courseType = [
    {
      label: "EICLOS",
      value: 1,
    },
  ];
  const yearList = [
    {
      label: 2023,
      value: 1,
    },
    {
      label: 2024,
      value: 2,
    },
  ];

  const termType = [
    {
      label: "New Term",
      value: 1,
    },
    {
      label: "Continue From Previous Semester",
      value: 2,
    },
  ];

  const termList = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
  ];

  return (
    <ModalElement title="Add Semester Divisions" keyType="addSemesterDivision">
      <Form layout="vertical" className="grid grid-cols-2 gap-4">
        <SelectField
          name="courseType"
          label="Course Type"
          placeholder="Select Course Type"
          options={courseType}
          fieldWidth="100%"
        />
        <SelectField
          name="year"
          label="Year"
          placeholder="Select Year"
          options={yearList}
          fieldWidth="100%"
        />
        <SelectField
          name="semester"
          label="Semester"
          placeholder="Select Semester"
          options={yearList}
          fieldWidth="100%"
        />
        <RadioElement
          name="term_type"
          label="Term Adding Type"
          options={termType}
          fieldWidth="100%"
        />
        <SelectField
          name="term"
          label="Term"
          placeholder="Select Term"
          options={termList}
          fieldWidth="100%"
        />
        <InputNumberField
          name="no_week"
          placeholder="No of Weeks"
          label="Number of Weeks"
          fieldWidth="100%"
        />
        <DatepickerElement
          name="start_week"
          placeholder="DD/MM/YYYY"
          fieldWidth="100%"
          label="Start Week"
        />
        <InputNumberField
          name="no_hours"
          placeholder="hourse per Week"
          label="No. of Hours"
          fieldWidth="100%"
        />
        <ButtonElement name="Add Record" bottonWidth="100%" />
      </Form>
    </ModalElement>
  );
}
